import { useQuery } from 'react-query';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useSelectedCompanyIds } from '@deepstream/ui/selectedCompanyIds';

export const useAdminContractsReporting = (companyId, currency) => {
  const adminApi = useAdminApi();
  const selectedCompanyIds = useSelectedCompanyIds();

  return useQuery(
    ['adminContractsReporting', { companyId, currency, selectedCompanyIds }],
    wrap(adminApi.getContractsReportingData),
  );
};
