import { TFunction } from 'i18next';
import { AnyScope, FieldScope, LineItemsSection, LinkedEvaluationSection, Live, RfxAuctionLineItemsSection, RfxSection, Section, SectionType } from './types';
import { FieldType } from '../exchangesConfig';

export const isAuctionLineItemSection = <Scope extends AnyScope = Live>(section: RfxSection<Scope>):
  section is RfxAuctionLineItemsSection<Scope> =>
  section.type === SectionType.AUCTION_LINE_ITEMS;

export const isLinkedEvaluationSection = (section: Section | RfxSection): section is LinkedEvaluationSection =>
  section.type === SectionType.EVALUATION && 'linkedSectionId' in section;

export const isSectionLinkedFromEvaluation = (
  section: Section | RfxSection,
  sectionById: Record<RfxSection['_id'], RfxSection>,
) =>
  Object.values(sectionById).some(
    (rfxSection) =>
      isLinkedEvaluationSection(rfxSection) &&
      rfxSection.linkedSectionId === section._id,
  );

export const renderSectionName = (section: { type: SectionType; name?: string } | undefined, t: TFunction) => {
  if (!section) {
    return;
  }

  switch (section.type) {
    case SectionType.AUCTION_TERMS:
      return t('request.sectionType.auction-terms', { ns: 'translation' });
    case SectionType.AUCTION_LINE_ITEMS:
      return t('request.sectionType.auction-line-items', { ns: 'translation' });
    case SectionType.CLARIFICATIONS:
      return t('request.clarifications.clarification_other', { ns: 'translation' });
    case SectionType.BULLETINS:
      return t('request.bulletin.bulletin_other', { ns: 'translation' });
    case SectionType.CHAT:
      return t('request.chats.chat', { ns: 'translation' });
    case SectionType.INTERNAL_DOCUMENT:
      return t('request.internalDocuments.internalDocuments', { ns: 'translation' });
    default:
      return section.name;
  }
};

export const getDefaultFieldTags = (
  responseTagConfig: NonNullable<LineItemsSection['responseTagConfig']>,
  fieldType: FieldType,
) => {
  switch (responseTagConfig.fieldScope) {
    case FieldScope.ALL_FIELDS:
      return [...responseTagConfig.tags];
      break;
    case FieldScope.ALL_PRICE_FIELDS:
      if (fieldType === FieldType.PRICE) {
        return [...responseTagConfig.tags];
      } else {
        return responseTagConfig.tags.slice(0, 1);
      }
      break;
    case FieldScope.CUSTOM:
      return responseTagConfig.tags.slice(0, 1);
      break;
  }
};
