import { compact, dropRightWhile, filter, find, findIndex, findLast, first, flatMap, get, has, isNil, last, map, pick, reject, some } from 'lodash';
import { isFuture, isPast } from 'date-fns';
import { isEmptyExchangeDef } from './isEmptyExchangeDef';
import { RfqRecipient } from './rfq-recipient';
import { RfqSender } from './rfq-sender';
import { isAuctionStage } from './stage';
import {
  Attachment,
  BidStatus, ChatSection, CollaboratorInviteStatus,
  CurrencyExchangeDefinition, Details,
  ExchangeDefinition, ExchangeProvider, ExchangeType,
  LineItemsSection, Meta, Page, PageRole,
  PageType, PaymentStageExchangeDefinition, Rfq, RfqStatus,
  Section, SectionType, Sender, SenderType, SenderUser,
  Stage, User, VesselPricingSection,
} from './types';

const BID_SUBMITTED = 'bidSubmitted';
const BID_COMPLIANT = 'bidCompliant';
const AWARDED = 'awarded';
const inactiveBidStatuses = [BidStatus.WITHDRAWN, BidStatus.UNSUCCESSFUL, BidStatus.NO_INTEND_TO_BID];

const pickSummary = (rfq: Details) => pick(rfq, [
  'subject',
  'reference',
  'autoReferenceNumber',
  'description',
  'attachments',
  'isEnhancedListing',
  'productsAndServices',
]);

type Options = {
  useDraft?: boolean;
};

/**
 * A class to centralize querying the state of an `RfqEnvelope`. The
 * idea is that this could replace direct access of the `rfqEnvelope`
 *
 * Advantages:
 * - Decouples components from the `RfqEnvelope` structure
 * - Consolidates duplicated/similar code to query RFQ state across all components
 * - Easily tested
 */

export class RfqQuery {
  rfqEnvelope: Rfq;
  useDraft?: boolean;

  /**
   * @param rfqEnvelope The request to query
   * @param useDraft    If true, the draft state will be queried regardless of whether
   *                    the request is live or not. This is to support revisions, where
   *                    there is a simultaneous draft and live state.
   */
  constructor(rfqEnvelope, options: Options = {}) {
    const { useDraft } = options;

    this.rfqEnvelope = rfqEnvelope;
    this.useDraft = useDraft;
  }

  get envelope() {
    return this.rfqEnvelope;
  }

  // Why two differently named id getters?
  get _id() {
    return this.rfqEnvelope._id;
  }

  get rfqId() {
    return this._id;
  }

  get meta(): Meta {
    return this.rfqEnvelope.meta;
  }

  get numRevisions() {
    return this.meta.numRevisions;
  }

  get issueDate() {
    return this.meta.issueDate;
  }

  get recipients(): Array<RfqRecipient> {
    return this.rfqEnvelope.recipients.map(recipient => this.getRecipient(recipient._id));
  }

  get recipientIds() {
    return this.rfqEnvelope.recipients.map(recipient => recipient._id);
  }

  get isLive() {
    return this.hasBeenIssued;
  }

  get isDeleted() {
    return this.meta.status === RfqStatus.DELETED;
  }

  get hasBeenIssued() {
    return Boolean(this.meta.issueDate);
  }

  get isEnhancedListing() {
    return this.rfq.isEnhancedListing;
  }

  get rfq(): Details {
    return this.useDraft || !this.hasBeenIssued
      ? this.rfqEnvelope.draft
      : this.rfqEnvelope.live;
  }

  get subject() {
    return this.rfq.subject;
  }

  get summary() {
    return pickSummary(this.rfq);
  }

  get pages(): Page[] {
    return this.rfq.pages || [];
  }

  // pages that aren't chat/evaluation
  get bidPages(): Page[] {
    return reject(
      this.pages,
      page => page.type === PageType.CHAT || page.type === PageType.EVALUATION || page.type === PageType.AUCTION,
    );
  }

  get pageNames() { return map(this.rfq.pages, 'name'); }
  get pageIds() { return map(this.rfq.pages, '_id'); }

  get sections(): Section[] { return this.rfq.sections; }
  get sectionIds(): string[] { return map(this.sections, '_id'); }

  get senders(): Sender[] {
    return this.rfqEnvelope.senders.filter(sender => sender.inviteStatus !== CollaboratorInviteStatus.REJECTED);
  }

  get allSenders(): Sender[] {
    return this.rfqEnvelope.senders;
  }

  get creator() {
    return first(this.senders);
  }

  /**
   * @deprecated
   */
  get buyer() {
    return first(this.senders);
  }

  /**
   * @deprecated
   */
  get buyerId(): string {
    return get(this.buyer, 'company._id') as string;
  }

  /**
   * @deprecated
   */
  get clients() {
    return this.senders.filter(sender => sender.type === SenderType.CLIENT);
  }

  get collaborators() {
    // the request owner is always on the first position in the senders list
    // exclude him from collaborators list
    return this.senders.slice(1, this.senders.length);
  }

  get senderUsers(): SenderUser[] {
    return flatMap(this.senders, sender => sender.users.map(user => Object.assign(user, { company: sender.company })));
  }

  get recipientUsers(): User[] {
    return flatMap(this.recipients, 'users');
  }

  get senderCompanyIds() {
    return this.senders.map(sender => get(sender, 'company._id'));
  }

  get stages(): Stage[] {
    return this.rfq.stages;
  }

  get firstStage(): Stage {
    const stage = first(this.stages);

    // We expect a request to always have at least one stage.
    if (!stage) {
      throw new Error('First stage is not defined');
    }

    return stage;
  }

  get lastStage(): Stage {
    const stage = last(this.stages);

    // We expect a request to always have at least one stage.
    if (!stage) {
      throw new Error('Last stage is not defined');
    }

    return stage;
  }

  get isMultiStageRequest() {
    return this.stages.length > 1;
  }

  get lastStageWithRecipients() {
    const recipientStages = compact(map(this.recipients, 'stageId'));
    return findLast(this.stages, stage => recipientStages.includes(stage._id));
  }

  get firstStageWithActiveRecipients() {
    const stagesWithActiveRecipients =
      compact(
        map(
          reject(this.recipients, recipient => inactiveBidStatuses.includes(recipient.bid.status)),
          'stageId',
        ),
      );
    return this.stages.find(stage => stagesWithActiveRecipients.includes(stage._id));
  }

  get hasBeenAwarded(): boolean {
    return this.meta.status === RfqStatus.AWARDED;
  }

  get awardedMessage() {
    return this.meta.awarded && this.meta.awarded.message;
  }

  get awardedAttachments(): Attachment[] | undefined {
    return this.meta.awarded && this.meta.awarded.attachments;
  }

  get hasBeenClosed(): boolean {
    return this.meta.status === RfqStatus.CLOSED;
  }

  get hasEnded() {
    return this.hasBeenAwarded || this.hasBeenClosed;
  }

  get status() {
    return this.meta.status;
  }

  get bidDeadline() {
    return this.lastStage.completionDeadline;
  }

  get isPastBidDeadline() {
    return this.bidDeadline && new Date(this.bidDeadline).getTime() - new Date().getTime() < 0;
  }

  get exchangeDefs(): Array<ExchangeDefinition> {
    return flatMap(this.sectionIds, sectionId => this.getExchangeDefs(sectionId));
  }

  get biddingRecipients(): Array<RfqRecipient> {
    return this.rfqEnvelope.recipients
      .filter(recipient => [BID_SUBMITTED, BID_COMPLIANT, AWARDED].includes(recipient.bid.status))
      .map(recipient => this.getRecipient(recipient._id));
  }

  get owners() {
    return filter(this.senderUsers, 'isOwner');
  }

  get winners() {
    return this.hasBeenAwarded ? filter(this.recipients, 'isWinner') : [];
  }

  get winnerNames() {
    return map(this.winners, 'name');
  }

  get winnerIds() {
    return map(this.winners, '_id');
  }

  get hasVesselPricingSection() {
    return findIndex(this.sections, { type: SectionType.VESSEL_PRICING }) > -1;
  }

  get hasBulletinSection() {
    return findIndex(this.sections, { type: SectionType.BULLETINS }) > -1;
  }

  get bulletinsSection() {
    return find(this.sections, { type: SectionType.BULLETINS });
  }

  get vesselPricingSection() {
    return <VesselPricingSection>find(this.sections, { type: SectionType.VESSEL_PRICING });
  }

  get lineItemsSections() {
    return <LineItemsSection[]>filter(this.sections, { type: SectionType.LINE_ITEMS });
  }

  get chatSection() {
    return <ChatSection>find(this.sections, { type: SectionType.CHAT });
  }

  get clarificationsSection() {
    return find(this.sections, { type: SectionType.CLARIFICATIONS });
  }

  get pageForChatSection() {
    return this.chatSection && this.getPageForSection(this.chatSection._id);
  }

  get awardedChatExchange() {
    return this.recipients.find(recipient => recipient.bidAwardedChatExchange);
  }

  get emptyStageIndexes() {
    const exchangeDefStartStages = this.exchangeDefs.map(exchange => first(exchange.stages || []));

    return reject(
      this.stages.map(
        (stage, index) => !exchangeDefStartStages.includes(stage._id) ? index : undefined,
      ),
      isNil,
    );
  }

  get activeStageIndex() {
    return findIndex(
      this.stages,
      (stage) => stage.completionDeadline && isFuture(new Date(stage.completionDeadline)),
    );
  }

  get activeStage() {
    return this.activeStageIndex > -1
      ? this.stages[this.activeStageIndex]
      : undefined;
  }

  get evaluationSections() {
    return get(this.pages.find(page => page.type === PageType.EVALUATION), 'sections');
  }

  get hasAuctionStage() {
    return this.stages.some(isAuctionStage);
  }

  get isEvaluationEnabled() {
    return this.rfq.settings.isEvaluationEnabled;
  }

  get isPubliclyAvailable() {
    return this.rfq.settings.isPubliclyAvailable;
  }

  getCurrencyDef(sectionId: string): CurrencyExchangeDefinition {
    const section: Section = find(this.sections, { _id: sectionId })!;
    return <CurrencyExchangeDefinition>find(get(section, 'docXDefs'), { type: ExchangeType.CURRENCY });
  }

  getSenderUser(userId: string) {
    // FIXME:
    const user = this.senderUsers.find(user => user._id === userId);

    if (!user) {
      throw new Error(`User with id ${userId} could not be found in the senders' users`);
    }

    return user;
  }

  getStage(stageId: string) {
    const stage = find(this.stages, stage => stage._id === stageId);

    if (!stage) {
      throw new Error(`Stage with id ${stage} could not be found`);
    }

    return stage;
  }

  isPastCompletionDeadline(stageId: string) {
    const { completionDeadline } = this.getStage(stageId);

    return completionDeadline && isPast(new Date(completionDeadline));
  }

  getSection<T extends Section = Section>(sectionId): T {
    return <T>find(this.rfq.sections, section => section._id === sectionId);
  }

  hasSection(sectionId: string) {
    return Boolean(this.getSection(sectionId));
  }

  getSectionsByType(type: SectionType): Section[] {
    return filter(this.sections, { type });
  }

  getExchangeDefs(sectionId: string): ExchangeDefinition[] {
    const section = this.getSection(sectionId);

    if (!section) {
      throw new Error(`Section "${sectionId}" does not exist.`);
    }

    if (!has(section, 'docXDefs')) {
      throw new Error(`Section "${sectionId}" does not have any exchange definitions`);
    }

    return section.docXDefs;
  }

  getBidExchangeDefs(sectionId: string): ExchangeDefinition[] {
    const section = this.getSection(sectionId);

    // Since evaluation sections can't have bid exchange defs, we can return early
    if (section.type === SectionType.EVALUATION) {
      return [];
    }

    return flatMap(
      this.recipients,
      (recipient: RfqRecipient) => recipient.getBidExchangeDefs(sectionId),
    );
  }

  getNonEmptyExchangeDefs(sectionId) {
    return reject(this.getExchangeDefs(sectionId), isEmptyExchangeDef);
  }

  getExchangeDef(exchangeId): ExchangeDefinition | undefined {
    return find(this.exchangeDefs, exchangeDef => exchangeDef._id === exchangeId);
  }

  getPaymentStagesPercentageTotal(sectionId) {
    return this
      .getNonEmptyExchangeDefs(sectionId)
      .reduce((total, exchangeDef) => total + (<PaymentStageExchangeDefinition>exchangeDef).percentage, 0);
  }

  getRecipient(recipientId: string): RfqRecipient {
    const recipient = find(this.rfqEnvelope.recipients, { _id: recipientId });

    if (!recipient) {
      throw new Error(`The request does not have a recipient with id: ${recipientId}`);
    }

    return new RfqRecipient(this, recipient);
  }

  getSender(companyId: string): RfqSender {
    const sender = find(this.rfqEnvelope.senders, { company: { _id: companyId } });

    if (!sender) {
      throw new Error(`The request does not have a sender with id: ${companyId}`);
    }

    return new RfqSender(this, sender);
  }

  getVisibleExchangeDefs(stageId, sectionId): Array<ExchangeDefinition> {
    if (!stageId) {
      throw new Error('No stage Id');
    }

    const includedStageIds = dropRightWhile(this.stages, stage => stage._id !== stageId)
      .map(stage => stage._id);

    return this
      .getExchangeDefs(sectionId)
      .filter(exchangeDef => {
        const section = this.getSectionForExchange(exchangeDef._id);

        if (!section) {
          return false;
        }

        const stageIds = section?.stages || exchangeDef.stages;

        return stageIds
          ? includedStageIds.includes(first(stageIds)!)
          : true;
      });
  }

  getPageForSection(sectionId) {
    const page = find(this.pages, page => page.sections.includes(sectionId));

    if (!page) {
      throw new Error(`Section ${sectionId} could not be found in any page.`);
    }

    return page;
  }

  getSectionForExchange(exchangeId) {
    const section = find(
      this.sections,
      section => some(section.docXDefs, docXDef => docXDef._id === exchangeId),
    );

    if (!section) {
      throw new Error(`Exchange ${exchangeId} could not be found in any section.`);
    }

    return section;
  }

  canEditPage(pageId, userId) {
    const user = this.getSenderUser(userId);
    const role = user.rfqRoles?.[pageId];
    return role === PageRole.EDITOR;
  }

  canReadPage(pageId, userId) {
    const user = this.getSenderUser(userId);
    const role = user.rfqRoles?.[pageId];
    return role && [PageRole.READER, PageRole.COMMENTER, PageRole.RESPONDER, PageRole.EDITOR].includes(role);
  }

  canReadSection(sectionId, userId) { return this.canReadPage(this.getPageForSection(sectionId)._id, userId); }

  isPageVisible(pageId, userId) {
    return this.canReadPage(pageId, userId);
  }

  isSectionVisible(sectionId): boolean {
    return this.getExchangeProvider(sectionId) !== ExchangeProvider.NONE;
  }

  isSupplierSectionVisible(sectionId): boolean {
    return [ExchangeProvider.SUPPLIER, ExchangeProvider.BOTH].includes(this.getExchangeProvider(sectionId)!);
  }

  isSender(senderId) {
    return some(this.allSenders, { company: { _id: senderId } });
  }

  /**
   * @deprecated
   */
  isBuyer(companyId) {
    return findIndex(this.allSenders, { company: { _id: companyId } }) === 0;
  }

  isCreator(companyId) {
    return this.creator?._id === companyId;
  }

  isCollaborator(companyId) {
    return findIndex(this.senders, { company: { _id: companyId } }) > 0;
  }

  isRecipient(recipientId) {
    return some(this.rfqEnvelope.recipients, { company: { _id: recipientId } });
  }

  containsExchange(sectionId: string, exchangeId: string): boolean {
    return Boolean(find(this.getExchangeDefs(sectionId), { _id: exchangeId }));
  }

  getExchangeProvider(sectionId: string): ExchangeProvider | undefined {
    return this.getSection(sectionId).providedBy;
  }

  getPage(pageId: string) {
    return this.pages.find(page => page._id === pageId);
  }

  getPageIndex(pageId: string) {
    const index = this.pages.findIndex(page => page._id === pageId);

    if (index === -1) {
      throw new Error(`Could not find page: ${pageId}`);
    }

    return index;
  }

  getPrevPage(pageId: string) {
    const pageIndex = this.getPageIndex(pageId);
    return pageIndex > 0 ? this.pages[pageIndex - 1] : null;
  }

  getNextPage(pageId: string) {
    const pageIndex = this.getPageIndex(pageId);
    return pageIndex < this.pages.length - 1 ? this.pages[pageIndex + 1] : null;
  }

  getCompanyName(companyId: string) {
    return this.isSender(companyId)
      ? this.getSender(companyId).name
      : this.getRecipient(companyId).name;
  }

  // Gets the same exchange across all recipients
  getExchangesById(exchangeId) {
    return this.recipients.map(recipient => recipient.getExchangeById(exchangeId));
  }

  getBidSections(sectionId) {
    return this.recipients.map(recipient => recipient.getBidSection(sectionId));
  }

  isStageLive(stageId): boolean {
    const liveStages = this.rfqEnvelope.live.stages;

    return Boolean(find(liveStages, { _id: stageId }));
  }

  isSenderOwner(userId: string): boolean {
    return some(this.owners, { _id: userId });
  }
}
