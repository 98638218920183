import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { DEFAULT_LOCALE } from '@deepstream/common/user-utils';
import resourcesToBackend from 'i18next-resources-to-backend';
import { MissingInterpolationError, MissingTranslationError } from '@deepstream/errors';
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as en from '@deepstream/locales/en';

export const initReactTranslations = async () => {
  return i18n
    .use(resourcesToBackend(async (language, namespace) => {
      switch (language) {
        case 'en':
        case 'en-GB': {
          const languageBundle = en;

          return languageBundle[namespace];
        }
        case 'es':
        case 'es-ES': {
          const languageBundle = await import('@deepstream/locales/es');

          return languageBundle[namespace];
        }
        case 'fr':
        case 'fr-FR': {
          const languageBundle = await import('@deepstream/locales/fr');

          return languageBundle[namespace];
        }
        case 'pt':
        case 'pt-PT': {
          const languageBundle = await import('@deepstream/locales/pt');

          return languageBundle[namespace];
        }
        case 'zh':
        case 'zh-CN': {
          const languageBundle = await import('@deepstream/locales/zh_CN');

          return languageBundle[namespace];
        }
        default:
          return null;
      }
    }))
    .use(initReactI18next)
    .init({
      ns: [
        'translation', // default NS
        'company',
        'companyProfile',
        'contracts',
        'dashboard',
        'evaluation',
        'exchangeAction',
        'exchangeActionLabel',
        'general',
        'integration',
        'notifications',
        'onboarding',
        'chatbot',
        'reporting',
        'request',
        'supplierDiscovery',
        'preQualification',
      ],
      lng: DEFAULT_LOCALE,
      fallbackLng: DEFAULT_LOCALE,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      saveMissing: true,
      missingKeyHandler: (lngs, ns, key, fallbackValue, updateMissing, options) => {
        Sentry.captureException(new MissingTranslationError(`Missing translation for key "${key}"`, {
          lngs,
          ns,
          key,
          fallbackValue,
          updateMissing,
          options,
        }));
      },
      missingInterpolationHandler: (text, value) => {
        Sentry.captureException(new MissingInterpolationError(`Missing interpolation in text "${text}"`, {
          text,
          value,
        }));
      },
    });
};
