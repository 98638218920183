import * as React from 'react';
import {
  getExchangeFieldValue,
  isFormulaField,
  isSupplierReplyField,
} from '@deepstream/common/rfq-utils';
import { createCurrencyConverter } from '@deepstream/common';
import { getCurrencySymbol, localeFormatPrice } from '@deepstream/utils';
import { isNumber } from '@reach/utils';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { formatOptions } from './formatOptions';
import { NumberFormat } from '../NumberFormat';
import { useCurrentUserLocale } from '../useCurrentUser';

type TConvertedCurrency = ({ value, currencyCode }: any) => {
  value: any;
  currencyCode: any;
};

export const CurrencyCodeContext = React.createContext('');
// @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'TConvertedCurrency'.
export const CurrencyConversionContext = React.createContext<TConvertedCurrency>(null);

export const CurrencyCodeProvider = React.memo(({ code, ...props }: { code: string; children: React.ReactNode }) =>
  <CurrencyCodeContext.Provider value={code} {...props} />,
);

export const CurrencyConversionProvider = ({ children, ...props }) => {
  const {
    exchangeRates,
    targetCurrency,
  } = props;

  const convertCurrency = React.useMemo(() => {
    return createCurrencyConverter(targetCurrency, exchangeRates);
  }, [targetCurrency, exchangeRates]);

  return (
    <CurrencyConversionContext.Provider value={convertCurrency}>
      {children}
    </CurrencyConversionContext.Provider>
  );
};

export const useCurrencyCode = () => React.useContext(CurrencyCodeContext);
export const useCurrencyConversion = () => React.useContext(CurrencyConversionContext);

interface CurrencyProps {
  currency: string;
  amount: number;
  showCode?: boolean;
  showTitle?: boolean;
  decimalPlaces?: number;
  className?: string;
}

export const CurrencyFormat: React.FC<CurrencyProps> = ({
  currency,
  amount,
  showCode,
  showTitle,
  decimalPlaces,
  className,
}) => {
  const locale = useCurrentUserLocale();
  const formattedCurrency = localeFormatPrice(amount, currency, { locale, showCode, decimalPlaces });

  return (
    <span
      className={className}
      style={{ fontVariantNumeric: 'tabular-nums' }}
      title={showTitle ? formattedCurrency : undefined}
    >
      {formattedCurrency}
    </span>
  );
};

export const CurrencyAmount = ({
  value,
  placeholder,
  showCode,
  decimalPlaces,
  showTitle,
}: {
  value: any;
  placeholder?: string | React.ReactNode,
  showCode?: boolean;
  decimalPlaces?: number;
  showTitle?: boolean;
}) => {
  const currencyCode = useCurrencyCode();

  return !isNumber(value) || Number.isNaN(value) ? (
    placeholder ? (
      <>{placeholder}</> // eslint-disable-line
    ) : (
      <EmDash />
    )
  ) : currencyCode ? (
    <CurrencyFormat
      className="currency-amount"
      currency={currencyCode}
      amount={value}
      showCode={showCode}
      decimalPlaces={decimalPlaces}
      showTitle={showTitle}
    />
  ) : (
    <NumberFormat
      className="currency-amount"
      displayType="text"
      // @ts-expect-error ts(2783) FIXME: 'thousandSeparator' is specified more than once, so this usage will be overwritten.
      thousandSeparator
      value={value}
      // @ts-expect-error ts(2783) FIXME: 'decimalScale' is specified more than once, so this usage will be overwritten.
      decimalScale={decimalPlaces}
      {...formatOptions.money}
    />
  );
};

export const ConvertedCurrency = ({ value, currencyCode, decimalPlaces = 2 }) => {
  const convertCurrency = useCurrencyConversion();
  const converted = convertCurrency({ value, currencyCode });

  return (
    <CurrencyFormat
      amount={converted.value}
      currency={converted.currencyCode}
      decimalPlaces={decimalPlaces}
      showTitle
    />
  );
};

export const CurrencyAmountCell = ({ cell: { value } }) => (
  <CurrencyAmount value={value} />
);

export type CurrencyCodeProps = {
  /**
   * When true, will return the current currency's symbol instead of its code.
   * Falls back to the currency code if the symbol is not available.
   */
  symbol?: boolean;
};

export const CurrencyCode = ({ symbol }: CurrencyCodeProps) => {
  const currencyCode = useCurrencyCode();
  const value = symbol ? getCurrencySymbol(currencyCode) : currencyCode;
  return <>{value}</>;
};

export const FieldCurrencyAmount = ({ value, placeholder }: { value: any; placeholder?: string | React.ReactNode }) => {
  const currencyCode = useCurrencyCode();

  return !isNumber(value) || Number.isNaN(value) ? (
    placeholder ? (
      <>{placeholder}</> // eslint-disable-line
    ) : (
      <EmDash />
    )
  ) : currencyCode ? (
    <CurrencyFormat currency={currencyCode} amount={value} />
  ) : (
    <NumberFormat displayType="text" value={value} {...formatOptions.money} />
  );
};

export const CurrencyAmountInCurrency = ({ value, currencyCode, decimalPlaces, showTooltip }: {
  value: any;
  currencyCode: string;
  decimalPlaces?: number;
  showTooltip?: boolean;
}) => {
  return !isNumber(value) || Number.isNaN(value) ? (
    <EmDash />
  ) : currencyCode ? (
    <CurrencyFormat currency={currencyCode} amount={value} decimalPlaces={decimalPlaces} showTitle={showTooltip} />
  ) : (
    <NumberFormat displayType="text" value={value} {...formatOptions.money} />
  );
};

export const BuyerProvidedCurrencyAmountCell = ({ row: { original: exchange }, cell: { value } }) => {
  const currencyCode = getExchangeFieldValue(exchange, 'evaluatorFieldCurrency');

  return (
    <CurrencyAmountInCurrency value={value} currencyCode={currencyCode} />
  );
};

export const BuyerOrSupplierCurrencyAmountCell = ({
  row: { original: exchange },
  column: { EmptyCell = EmDash as React.FC<any>, showTooltip, textAlign, ...column },
  cell: { value },
}) => {
  const fieldId = column.id;

  const field = exchange.def.fields[fieldId];

  if (!field || !isNumber(value) || Number.isNaN(value)) {
    return (
      <EmptyCell exchange={exchange} showTooltip={showTooltip} />
    );
  }

  const isSupplierReply = isSupplierReplyField(field);

  const currencyCode = isSupplierReply || isFormulaField(field)
    ? exchange.currency
    : getExchangeFieldValue(exchange, 'evaluatorFieldCurrency');

  return (
    <Truncate sx={{ width: '100%', textAlign }}>
      <CurrencyAmountInCurrency
        value={value}
        currencyCode={currencyCode}
        decimalPlaces={column.decimalPlaces}
        showTooltip={showTooltip}
      />
    </Truncate>
  );
};
