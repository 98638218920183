import { useMemo } from 'react';
import { compact } from 'lodash';
import { PropertyList } from '@deepstream/ui/PropertyList';
import { Id } from './Id';

export const ExternalSystemPropertyList = ({ externalSystem }: { externalSystem: any }) => {
  const externalSystemProperties = useMemo(
    () => {
      if (!externalSystem) return [];

      return compact([
        { name: 'System ID', value: externalSystem.systemId, Component: Id },
        { name: 'Description', value: externalSystem.description },
        { name: 'Allow many-to-one mapping?', value: externalSystem.allowManyToOneMapping ? 'Yes' : 'No' },
        { name: 'Has external supplier statuses?', value: externalSystem.externalData?.hasSupplierStatuses ? 'Yes' : 'No' },
        { name: 'Has external supplier basic info?', value: externalSystem.externalData?.hasSupplierBasicInfo ? 'Yes' : 'No' },
      ]);
    },
    [externalSystem],
  );

  return (
    <PropertyList properties={externalSystemProperties} />
  );
};
