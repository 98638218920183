import { useMemo } from 'react';

import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useQuery } from 'react-query';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { Text } from 'rebass/styled-components';
import { AddOrEditExternalSystemModal } from '../../AddOrEditExternalSystemModal';
import { useAdminNavigation } from '../../AppRouting';

type ExternalSystemsTableTableProps = {
  company: CompanyMinimized;
  externalSystems: any[];
};

const ExternalSystemsTable = ({ company, externalSystems }: ExternalSystemsTableTableProps) => {
  const navigation = useAdminNavigation();

  const columns = useMemo(
    () => [
      {
        Header: 'System ID',
        accessor: 'systemId',
        Cell: TruncateCell,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: TruncateCell,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: TruncateCell,
      },
    ],
    [],
  );

  return (
    <BasicTableStyles>
      <Table
        columns={columns}
        data={externalSystems}
        isPaginated
        smallPageControls
        onRowClick={externalSystem => navigation.navigateToCompanyExternalSystem(company._id, externalSystem._id)}
      />
    </BasicTableStyles>
  );
};

export const ExternalSystemsPanel = ({ company }: { company: CompanyMinimized }) => {
  const addExternalSystemModal = useModalState();
  const adminApi = useAdminApi();

  const { data: externalSystems, isLoading, isError } = useQuery(
    ['externalSystems', { companyId: company._id }],
    wrap(adminApi.getExternalSystems),
  );

  return (
    <>
      <Panel>
        <PanelHeader heading="External systems">
          <Button small iconLeft="plus" onClick={addExternalSystemModal.open}>
            Add external system
          </Button>
        </PanelHeader>
        <PanelDivider />
        {isLoading ? (
          <PanelPadding>
            <Loading />
          </PanelPadding>
        ) : isError ? (
          <PanelPadding>Oh no</PanelPadding>
        ) : externalSystems.length ? (
          <ExternalSystemsTable company={company} externalSystems={externalSystems} />
        ) : (
          <PanelPadding>
            <Text fontSize={2} color="gray">
              No external systems yet
            </Text>
          </PanelPadding>
        )}
      </Panel>
      <AddOrEditExternalSystemModal
        company={company}
        isOpen={addExternalSystemModal.isOpen}
        onCancel={addExternalSystemModal.close}
        onSave={addExternalSystemModal.close}
        onRequestClose={addExternalSystemModal.close}
      />
    </>
  );
};
