import { useQuery } from 'react-query';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Company } from '@deepstream/ui/ui/types';
import { Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { assertDefined } from '@deepstream/utils';
import { ParentChildCompaniesTable } from './ChildCompaniesPanel';

export const ParentCompanyPanel = ({ company }: { company: Company }) => {
  const adminApi = useAdminApi();

  const { parentCompanyId } = company;

  assertDefined(parentCompanyId, 'parentCompanyId');

  const { data: parentCompany, isLoading, isError } = useQuery(
    ['company', { companyId: parentCompanyId }],
    wrap(adminApi.getCompany),
  );

  return (
    <Panel>
      <PanelHeader heading="Parent company">
        <Text color="subtext">
          <Icon icon="info-circle" regular mr={1} mt="10px" />
          This relationship can be managed from the parent company page
        </Text>
      </PanelHeader>
      <PanelDivider />
      {isLoading ? (
        <PanelPadding>
          <Loading />
        </PanelPadding>
        ) : isError ? (
          <PanelPadding>Oh no</PanelPadding>
        ) : parentCompany ? (
          <ParentChildCompaniesTable
            currentCompanyId={company._id}
            companies={[parentCompany]}
          />
        ) : (
          null
        )}
    </Panel>
  );
};
