import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { set, get, isPlainObject, noop, isString, isEmpty, find, reduce, isObject, isArray } from 'lodash';
import { Schema } from 'yup';

type Values = {
  [field: string]: any;
};

export type ValidationErrors<T> = {
  [K in keyof T]?: T[K] extends object ? ValidationErrors<T[K]> : string
};

export type ValidationResult<T> = {
  errors: ValidationErrors<T>;
  isValid: boolean;
};

type UseYupOptions = {
  validateOnChange?: boolean;
};

export const ValidationContext = createContext<any>({
  validate: noop,
  errors: {},
  isValid: true,
});

export const useErrors = (path?: string) => {
  const validationResult = useContext(ValidationContext);
  const { errors } = validationResult;

  if (path) {
    // If the key doesn't exist, default to empty object (ie: no errors)
    const pathErrors = get(errors, path) as any ?? {};

    return isPlainObject(pathErrors)
      ? {
        errors: pathErrors,
        isValid: isEmpty(pathErrors),
      }
      // Ignore higher level errors
      : {
        errors: {},
        isValid: true,
      };
  }

  return validationResult;
};

export const useError = (path: string) => {
  const validationResult = useContext(ValidationContext);
  const { errors } = validationResult;
  const fieldError = get(errors, path) as any;
  const error = isString(fieldError)
    ? fieldError
    : Array.isArray(fieldError)
      ? find(fieldError, isString)
      : null;

  return {
    error,
    isValid: !error,
  };
};

/**
 * Transform Yup errors to a ValidationErrors object
 */
const yupToValidationErrors = <T extends Values>(yupError: any): ValidationErrors<T> => {
  const errors: any = {} as ValidationErrors<Values>;

  if (yupError.inner.length === 0) {
    set(errors, yupError.path, yupError.message);
    return errors;
  }

  for (const error of yupError.inner) {
    set(errors, error.path, error.message);
  }

  return errors;
};

export const countErrors = (errors: any) => {
  return reduce(errors, (count, value) => {
    if (isObject(value) || isArray(value)) {
      return count + countErrors(value);
    } else if (value) {
      return count + 1;
    } else {
      return count;
    }
  }, 0);
};

const EMPTY_ERRORS = {};

export const useSchema = <T extends Values>(
  values: T,
  validationSchema: Schema<any>,
  options: UseYupOptions = {},
) => {
  const [errors, setErrors] = useState<ValidationErrors<T>>(EMPTY_ERRORS);
  const isValid = isEmpty(errors);

  const validate = useCallback(
    async (): Promise<ValidationResult<T>> => {
      try {
        await validationSchema.validate(values, { abortEarly: false });

        setErrors(EMPTY_ERRORS);

        return {
          errors: EMPTY_ERRORS,
          isValid: true,
        };
      } catch (error) {
        const newErrors = yupToValidationErrors<T>(error);

        setErrors(newErrors);

        return {
          errors: newErrors,
          isValid: isEmpty(newErrors),
        };
      }
    },
    [validationSchema, values],
  );

  useEffect(
    () => {
      if (options.validateOnChange) {
        validate();
      }
    },
    [values, validationSchema, options.validateOnChange, validate],
  );

  return {
    validate,
    errors,
    isValid,
  };
};

export const Validation = ({ values, schema, children }) => {
  const validationResult = useSchema(
    values,
    schema,
    { validateOnChange: true },
  );

  return (
    <ValidationContext.Provider value={validationResult}>
      {children}
    </ValidationContext.Provider>
  );
};
