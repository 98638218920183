import * as React from 'react';
import { useQuery } from 'react-query';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { PanelLoadingWrapper } from '@deepstream/ui/ui/Loading';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Company } from '@deepstream/ui/ui/types';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as Title from '../../title';
import { CompanyQuestionnaireTemplatesTable } from './CompanyQuestionnaireTemplatesTable';
import { CompanyQuestionnairesTable } from './CompanyQuestionnairesTable';

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

export const CompanyPreQualification = ({ company }: { company: Company }) => {
  const adminApi = useAdminApi();

  const companyId = company._id;

  const { data: questionnaireTemplates, status: questionnaireTemplatesStatus } = useQuery(
    ['questionnaireTemplates', { companyId }],
    wrap(adminApi.getCompanyQuestionnaireTemplates),
  );

  const { data: questionnaires, status: questionnairesStatus } = useQuery(
    ['receivedQuestionnaires', { companyId }],
    wrap(adminApi.getCompanyReceivedQuestionnaires),
  );

  return (
    <Stack gap={4}>
      <Panel heading="Questionnaire templates">
        <PanelLoadingWrapper status={questionnaireTemplatesStatus} data={questionnaireTemplates} emptyDataMessage="No questionnaire templates yet">
          {questionnaireTemplates && (
            <CompanyQuestionnaireTemplatesTable templates={questionnaireTemplates} />
          )}
        </PanelLoadingWrapper>
      </Panel>

      <Panel heading="Questionnaires received">
        <PanelLoadingWrapper status={questionnairesStatus} data={questionnaires} emptyDataMessage="No questionnaires received yet">
          {questionnaires && (
            <CompanyQuestionnairesTable questionnaires={questionnaires} />
          )}
        </PanelLoadingWrapper>
      </Panel>
    </Stack>
  );
};
