import { useMemo, useState } from 'react';
import {
  FlexPanelHeader,
  Panel,
  PanelDivider,
  PanelHeader,
  PanelText,
} from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { localeFormatPrice } from '@deepstream/utils';
import { APP_ADMIN_LOCALE } from '@deepstream/common/constants';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { SelectDropdownMenu } from '@deepstream/ui/ui/MultiSelect';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Box, Flex } from 'rebass/styled-components';
import { has, isNaN } from 'lodash';
import { LegacyMultiStageLineItemsEventType, MultiStageLineItemsEventType } from '@deepstream/common';
import { useSystemFeatureFlags } from '@deepstream/ui/systemFeatureFlags';
import { getFeatureAdoptionInfo, getOutOfInfo, renderFactorAsPercent, renderPercentage } from '../reports/utils';
import { ClientAdoptionRateModal } from '../reports/ClientAdoptionRateModal';
import {
  ProductDashboardAsideMetric,
  ProductDashboardMetric,
  ProductDashboardSection,
} from './ProductDashboardUtils';
import { MultiStageLineItemsFunnel } from './MultiStageLineItemsFunnel';
import { MetricsValueWithPreviousValue } from './MetricsValueWithPreviousValue';
import { ProductDashboardExcelSubmitTabContent } from './ProductDashboardExcelSubmitTabContent';

const renderAccuracyPercentage = ({ accurate, total }) =>
  renderPercentage({ count: accurate, total });

const renderAccurateOfTotal = ({ accurate, total }) => {
  if (!total) {
    return 'N/A';
  }

  return `${accurate} of ${total}`;
};

const renderPrevious30DaysDifference = (current: number, previous: number) => {
  const difference = current - previous;
  const isPositive = difference > 0;

  if (isNaN(difference)) {
    return null;
  }

  return (
    <Box color={isPositive ? 'success' : 'danger'} fontSize={1}>
      <Flex fontWeight="bold" alignItems="center" sx={{ gap: 1 }}>
        <Icon icon={isPositive ? 'arrow-up' : 'arrow-down'} color={isPositive ? 'success' : 'danger'} />
        {renderFactorAsPercent(difference)}
      </Flex>
      previous 30 days
    </Box>
  );
};

const NewFeaturesUserInteractionMetricsSection = ({ data }) => {
  const {
    last7Days = 0,
    previous7Days = 0,
    last30Days = 0,
    previous30Days = 0,
    allTime = 0,
  } = data.data.newFeaturesRecipientUserCount;

  return (
    <ProductDashboardSection
      heading="New UX - unique supplier users"
      tooltip={
        <>
          The total unique supplier users who took an action on a request
          using the new UX.
        </>
      }
      icon="users"
    >
      <ProductDashboardMetric
        label="All time"
        value={allTime}
      />
      <ProductDashboardMetric
        label="Last 7 days"
        value={
          <MetricsValueWithPreviousValue
            value={last7Days}
            previousValue={previous7Days}
            previousValueLabel="previous 7 days"
          />
        }
      />
      <ProductDashboardMetric
        label="Last 30 days"
        value={
          <MetricsValueWithPreviousValue
            value={last30Days}
            previousValue={previous30Days}
            previousValueLabel="previous 30 days"
          />
        }
      />
    </ProductDashboardSection>
  );
};

const RequestsPanel = ({ status, data }) => {
  const clientAdoptionRateModal = useModalState();

  return (
    <Panel>
      <PanelHeader heading="Requests" />
      <PanelDivider />
      {status === 'loading' ? (
        <PanelText>
          <Loading />
        </PanelText>
      ) : status === 'error' ? (
        <PanelText>Oh no</PanelText>
      ) : data.data ? (
        <>
          <ProductDashboardSection heading="Requests issued" icon="share">
            <ProductDashboardMetric label="Today" value={data.data.issuedRequests.today} />
            <ProductDashboardMetric label="This month" value={data.data.issuedRequests.thisMonth} />
            <ProductDashboardMetric label="Last month" value={data.data.issuedRequests.lastMonth} />
          </ProductDashboardSection>
          {data.data.requestValueAccuracy && (
            <ProductDashboardSection
              heading="Request value accuracy rate"
              tooltip="% of awarded requests where the client indicates that the calculated request value is accurate"
              icon="check-circle"
              isIconRegular
            >
              <ProductDashboardMetric
                label="Today"
                value={renderAccuracyPercentage(data.data.requestValueAccuracy.today)}
                subValue={renderAccurateOfTotal(data.data.requestValueAccuracy.today)}
              />
              <ProductDashboardMetric
                label="This week"
                value={renderAccuracyPercentage(data.data.requestValueAccuracy.thisWeek)}
                subValue={renderAccurateOfTotal(data.data.requestValueAccuracy.thisWeek)}
              />
              <ProductDashboardMetric
                label="Last week"
                value={renderAccuracyPercentage(data.data.requestValueAccuracy.lastWeek)}
                subValue={renderAccurateOfTotal(data.data.requestValueAccuracy.lastWeek)}
              />
            </ProductDashboardSection>
          )}
          {data.data.confirmedRequestValue && (
            <ProductDashboardSection
              heading="Confirmed request value"
              tooltip={(
                <>
                  Combined total of <br />
                  Calculated request value (where confirmed as accurate on award)<br />
                  Adjusted request value (where provided manually on award)<br />
                  <br />
                  Values are converted to USD using FX rate at the time of award<br />
                </>
              )}
              icon="circle-dollar"
              isIconRegular
            >
              <ProductDashboardMetric
                label="All time"
                value={(
                  localeFormatPrice(data.data.confirmedRequestValue.allTime, 'USD', { locale: APP_ADMIN_LOCALE, decimalPlaces: 2 })
                )}
              />
              <ProductDashboardMetric
                label="This week"
                value={(
                  localeFormatPrice(data.data.confirmedRequestValue.thisWeek, 'USD', { locale: APP_ADMIN_LOCALE, decimalPlaces: 2 })
                )}
              />
              <ProductDashboardMetric
                label="Last week"
                value={(
                  localeFormatPrice(data.data.confirmedRequestValue.lastWeek, 'USD', { locale: APP_ADMIN_LOCALE, decimalPlaces: 2 })
                )}
              />
            </ProductDashboardSection>
          )}

          {data.data.newFeaturesEnablementByCompany &&
            data.data.newFeaturesEnablementByRequest && (
              <ProductDashboardSection
                heading="New features enablement"
                tooltip={
                  <>
                    The proportion of paying clients and requests where the new features are enabled.<br />
                    <br />
                    New features – Lots; Award scenarios; New UX (Supplier and Buyer); New award flow.
                  </>
                }
                icon="toggle-on"
              >
                <ProductDashboardMetric
                  label="Paying clients"
                  value={renderPercentage({
                    count: data.data.newFeaturesEnablementByCompany.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length,
                    total: data.data.newFeaturesEnablementByCompany.length,
                  })}
                  subValue={getFeatureAdoptionInfo(data.data.newFeaturesEnablementByCompany)}
                  onViewMoreClick={data.data.newFeaturesEnablementByCompany.length ? clientAdoptionRateModal.open : undefined}
                />
                <ProductDashboardMetric
                  label="All requests"
                  value={renderPercentage({
                count: data.data.newFeaturesEnablementByRequest.all.count,
                    total: data.data.newFeaturesEnablementByRequest.all.total,
                  })}
                  subValue={`${data.data.newFeaturesEnablementByRequest.all.count} of ${data.data.newFeaturesEnablementByRequest.all.total} requests`}
                />
                <ProductDashboardMetric
                  label="Live requests"
                  value={renderPercentage({
                count: data.data.newFeaturesEnablementByRequest.live.count,
                    total: data.data.newFeaturesEnablementByRequest.live.total,
                  })}
                  subValue={`${data.data.newFeaturesEnablementByRequest.live.count} of ${data.data.newFeaturesEnablementByRequest.live.total} requests`}
                />
              </ProductDashboardSection>
            )}

          {data.data.newFeaturesRecipientUserCount && (
            <NewFeaturesUserInteractionMetricsSection data={data} />
          )}
        </>
      ) : data ? (
        <PanelText>No data</PanelText>
      ) : null}
      {data?.data?.newFeaturesEnablementByCompany && clientAdoptionRateModal.isOpen && (
        <ClientAdoptionRateModal
          isOpen={clientAdoptionRateModal.isOpen}
          onOk={clientAdoptionRateModal.close}
          onCancel={clientAdoptionRateModal.close}
          heading="New features rollout – Paying clients"
          featureAdoption={data.data.newFeaturesEnablementByCompany}
        />
      )}
    </Panel>
  );
};

const MultiStageLineItemsPanel = ({ status, data }) => {
  const clientAdoptionRateModal = useModalState();

  const systemFeatureFlags = useSystemFeatureFlags();
  const shouldRenderFunnel = useMemo(() => {
    const funnelData = data?.data?.multiStageLineItemsFunnel;
    if (!funnelData) {
      return false;
    }

    return systemFeatureFlags?.setupMultiStageLineItemsFlowEnabled
      ? Object.values(MultiStageLineItemsEventType).every((eventType) =>
          has(funnelData, eventType),
        )
      : Object.values(LegacyMultiStageLineItemsEventType).every((eventType) =>
          has(funnelData, eventType),
        );
  }, [data?.data?.multiStageLineItemsFunnel, systemFeatureFlags]);

  return (
    <Panel>
      <PanelHeader heading="Multi-stage line items" />
      <PanelDivider />
      {status === 'loading' ? (
        <PanelText>
          <Loading />
        </PanelText>
      ) : status === 'error' ? (
        <PanelText>Oh no</PanelText>
      ) : data.data ? (
        <>
          {data.data.multiStageResponsesFeatureAdoption &&
            data.data.sentOrRevisedRequests && (
              <ProductDashboardSection
                heading="Adoption and usage"
                tooltip={
                  <>
                    <b>Client adoption rate</b>
                    <br />
                    % of “paying companies” that have issued 1 or more
                    requests (including revisions) with This feature enabled.
                    <br />
                    <br />
                    <b>Request usage rate</b>
                    <br />% of requests with this feature enabled either on
                    issue or when first enabled on revision.
                  </>
                }
                icon="users"
                isIconRegular
              >
                <ProductDashboardMetric
                  label="Client adoption rate (now)"
                  value={renderPercentage({
                    count: data.data.multiStageResponsesFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length,
                    total: data.data.multiStageResponsesFeatureAdoption.length,
                  })}
                  subValue={getFeatureAdoptionInfo(data.data.multiStageResponsesFeatureAdoption)}
                  onViewMoreClick={data.data.multiStageResponsesFeatureAdoption.length ? clientAdoptionRateModal.open : undefined}
                />
                <ProductDashboardMetric
                  label="Request usage rate (this week)"
                  value={renderPercentage({
                count: data.data.sentOrRevisedRequests.thisWeek.withMultiStageResponses,
                    total: data.data.sentOrRevisedRequests.thisWeek.total,
                  })}
                  subValue={`${data.data.sentOrRevisedRequests.thisWeek.withMultiStageResponses} of ${data.data.sentOrRevisedRequests.thisWeek.total} requests`}
                />
                <ProductDashboardMetric
                  label="Request usage rate (last week)"
                  value={renderPercentage({
                    count:
                      data.data.sentOrRevisedRequests.lastWeek
                        .withMultiStageResponses,
                    total: data.data.sentOrRevisedRequests.lastWeek.total,
                  })}
                  subValue={`${data.data.sentOrRevisedRequests.lastWeek.withMultiStageResponses} of ${data.data.sentOrRevisedRequests.lastWeek.total} requests`}
                />
              </ProductDashboardSection>
            )}
          {shouldRenderFunnel && (
            <MultiStageLineItemsFunnel
              funnelData={data.data.multiStageLineItemsFunnel}
            />
          )}
        </>
      ) : data ? (
        <PanelText>No data</PanelText>
      ) : null}
      {data?.data?.multiStageResponsesFeatureAdoption && clientAdoptionRateModal.isOpen && (
        <ClientAdoptionRateModal
          isOpen={clientAdoptionRateModal.isOpen}
          onOk={clientAdoptionRateModal.close}
          onCancel={clientAdoptionRateModal.close}
          heading="Multi-stage line items – Client adoption rate"
          featureAdoption={data.data.multiStageResponsesFeatureAdoption}
        />
      )}
    </Panel>
  );
};

const feedbackContextOptions = [{ value: 'supplier', label: 'Supplier request feedback email' }, { value: 'buyer', label: 'Buyer award or close request flow' }];

const UserFeedbackPanel = ({ status, data }) => {
  const clientAdoptionRateModal = useModalState();
  const [selectedFeedbackContexts, setSelectedFeedbackContexts] = useState([feedbackContextOptions[0]]);
  const feedbackContext = selectedFeedbackContexts[0].value;

  return (
    <Panel>
      <FlexPanelHeader heading="User feedback">
        <Box width="224px">
          <SelectDropdownMenu
            buttonText={selectedFeedbackContexts[0].label}
            variant="secondary-outline"
            menuWidth={270}
            items={feedbackContextOptions}
            itemToString={item => item ? item.label : ''}
            selectedItems={selectedFeedbackContexts}
            onChange={setSelectedFeedbackContexts}
          />
        </Box>
      </FlexPanelHeader>
      <PanelDivider />
      {status === 'loading' ? (
        <PanelText>
          <Loading />
        </PanelText>
      ) : status === 'error' ? (
        <PanelText>Oh no</PanelText>
      ) : data.data?.userFeedback ? (
        <>
          {data.data.userFeedback.responseRate && (
            <ProductDashboardSection
              heading="Response rate"
              tooltip="The proportion of feedback submissions out of the total feedback requests issued."
              icon="reply"
            >
              <ProductDashboardMetric
                label="All time"
                value={renderPercentage({
                  count: data.data.userFeedback.responseRate.allTime[feedbackContext].count,
                  total: data.data.userFeedback.responseRate.allTime[feedbackContext].total,
                })}
                subValue={getOutOfInfo(
                  data.data.userFeedback.responseRate.allTime[feedbackContext].count,
                  data.data.userFeedback.responseRate.allTime[feedbackContext].total,
                )}
              />
              <ProductDashboardAsideMetric
                label="Last 30 days"
                value={renderPercentage({
                  count: data.data.userFeedback.responseRate.last30Days[feedbackContext].count,
                  total: data.data.userFeedback.responseRate.last30Days[feedbackContext].total,
                })}
                subValue={getOutOfInfo(
                  data.data.userFeedback.responseRate.last30Days[feedbackContext].count,
                  data.data.userFeedback.responseRate.last30Days[feedbackContext].total,
                )}
                aside={renderPrevious30DaysDifference(
                  data.data.userFeedback.responseRate.last30Days[feedbackContext].count /
                    data.data.userFeedback.responseRate.last30Days[feedbackContext].total,
                  data.data.userFeedback.responseRate.previous30Days[feedbackContext].count /
                    data.data.userFeedback.responseRate.previous30Days[feedbackContext].total,
                )}
              />
              <ProductDashboardMetric
                label="AVG per request"
                value={renderFactorAsPercent(data.data.userFeedback.responseRate.avgPerRequest[feedbackContext].value)}
                subValue={`${data.data.userFeedback.responseRate.avgPerRequest[feedbackContext].totalRequests} requests`}
              />
            </ProductDashboardSection>
          )}
          {data.data.sentOrRevisedRequests && (
            <ProductDashboardSection
              heading="Customer satisfaction score (CSAT)"
              tooltip="The proportion of feedback submissions where the user was satisfied (rated 4 or 5) out of the total feedback submissions made."
              icon="face-smile"
              isIconRegular
            >
              <ProductDashboardMetric
                label="All time"
                value={renderPercentage({
                  count: data.data.userFeedback.csat.allTime[feedbackContext].count,
                  total: data.data.userFeedback.csat.allTime[feedbackContext].total,
                })}
                subValue={getOutOfInfo(
                  data.data.userFeedback.csat.allTime[feedbackContext].count,
                  data.data.userFeedback.csat.allTime[feedbackContext].total,
                )}
              />
              <ProductDashboardAsideMetric
                label="Last 30 days"
                value={renderPercentage({
                  count: data.data.userFeedback.csat.last30Days[feedbackContext].count,
                  total: data.data.userFeedback.csat.last30Days[feedbackContext].total,
                })}
                subValue={getOutOfInfo(
                  data.data.userFeedback.csat.last30Days[feedbackContext].count,
                  data.data.userFeedback.csat.last30Days[feedbackContext].total,
                )}
                aside={renderPrevious30DaysDifference(
                  data.data.userFeedback.csat.last30Days[feedbackContext].count /
                    data.data.userFeedback.csat.last30Days[feedbackContext].total,
                  data.data.userFeedback.csat.previous30Days[feedbackContext].count /
                    data.data.userFeedback.csat.previous30Days[feedbackContext].total,
                )}
              />
              <ProductDashboardMetric
                label="AVG per request"
                value={renderFactorAsPercent(data.data.userFeedback.csat.avgPerRequest[feedbackContext].value)}
                subValue={`${data.data.userFeedback.csat.avgPerRequest[feedbackContext].totalRequests} requests`}
              />
            </ProductDashboardSection>
          )}
        </>
      ) : data ? (
        <PanelText>No data</PanelText>
      ) : null}
      {data?.data?.multiStageResponsesFeatureAdoption && clientAdoptionRateModal.isOpen && (
        <ClientAdoptionRateModal
          isOpen={clientAdoptionRateModal.isOpen}
          onOk={clientAdoptionRateModal.close}
          onCancel={clientAdoptionRateModal.close}
          heading="Multi-stage line items – Client adoption rate"
          featureAdoption={data.data.multiStageResponsesFeatureAdoption}
        />
      )}
    </Panel>
  );
};

const LotsPanel = ({ status, data }) => {
  const clientAdoptionRateModal = useModalState();

  return (
    <Panel>
      <PanelHeader heading="Lots" />
      <PanelDivider />
      {status === 'loading' ? (
        <PanelText>
          <Loading />
        </PanelText>
      ) : status === 'error' ? (
        <PanelText>Oh no</PanelText>
      ) : data.data ? (
        <>
          {data.data.lotsFeatureAdoption &&
            data.data.sentOrRevisedRequests && (
              <ProductDashboardSection
                heading="Adoption and usage"
                tooltip={
                  <>
                    <b>Client adoption rate</b>
                    <br />
                    % of “paying companies” that have issued 1 or more
                    requests (including revisions) with lots enabled.
                    <br />
                    <br />
                    <b>Request usage rate</b>
                    <br />
                    % of requests with lots enabled and an active
                    deadline during the period.
                  </>
                }
                icon="users"
                isIconRegular
              >
                <ProductDashboardMetric
                  label="Client adoption rate (now)"
                  value={renderPercentage({
                    count: data.data.lotsFeatureAdoption.filter(({ hasAdoptedFeature }) => hasAdoptedFeature).length,
                    total: data.data.lotsFeatureAdoption.length,
                  })}
                  subValue={getFeatureAdoptionInfo(data.data.lotsFeatureAdoption)}
                  onViewMoreClick={data.data.lotsFeatureAdoption.length ? clientAdoptionRateModal.open : undefined}
                />
                <ProductDashboardMetric
                  label="Request usage rate (this week)"
                  value={renderPercentage({
                    count: data.data.sentOrRevisedRequests.thisWeek.withLots,
                    total: data.data.sentOrRevisedRequests.thisWeek.total,
                  })}
                  subValue={`${data.data.sentOrRevisedRequests.thisWeek.withLots} of ${data.data.sentOrRevisedRequests.thisWeek.total} requests`}
                />
                <ProductDashboardMetric
                  label="Request usage rate (last week)"
                  value={renderPercentage({
                    count:
                      data.data.sentOrRevisedRequests.lastWeek
                        .withLots,
                    total: data.data.sentOrRevisedRequests.lastWeek.total,
                  })}
                  subValue={`${data.data.sentOrRevisedRequests.lastWeek.withLots} of ${data.data.sentOrRevisedRequests.lastWeek.total} requests`}
                />
              </ProductDashboardSection>
            )}
        </>
      ) : data ? (
        <PanelText>No data</PanelText>
      ) : null}
      {data?.data?.lotsFeatureAdoption && clientAdoptionRateModal.isOpen && (
        <ClientAdoptionRateModal
          isOpen={clientAdoptionRateModal.isOpen}
          onOk={clientAdoptionRateModal.close}
          onCancel={clientAdoptionRateModal.close}
          heading="Lots – Client adoption rate"
          featureAdoption={data.data.lotsFeatureAdoption}
        />
      )}
    </Panel>
  );
};

export const ProductDashboardRequestsTabContent = ({ status, data }) => {
  return (
    <>
      <RequestsPanel status={status} data={data} />
      <MultiStageLineItemsPanel status={status} data={data} />
      <UserFeedbackPanel status={status} data={data} />
      <LotsPanel status={status} data={data} />
      <ProductDashboardExcelSubmitTabContent status={status} data={data} />
    </>
  );
};
