import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import { DropdownQuestionElement } from '@deepstream/common/legacy-pre-q-utils';
import {
  ActionType,
  AddressQuestionExchangeDefinition,
  Attachment,
  BulletinExchangeDefinition,
  canIncludeFieldInFormula,
  CheckboxesQuestionExchangeDefinition,
  DateTimeQuestionExchangeDefinition,
  DocumentQuestionExchangeDefinition,
  ExchangeRateDocument,
  ExchangeType,
  ExtendedDateTimeBase,
  ExtendedDateTimeFactory,
  FieldConfig,
  getEmptyResponseValue,
  getExchangeFieldValue,
  getScore,
  getStageIdFromTag,
  GridQuestionExchangeDefinition,
  isBuyerReplyField,
  isDefinitionField,
  isFieldDisabled,
  isFormulaField,
  isSupplierReplyField,
  LineItemFormulaFieldConfig,
  Live,
  Modify,
  MultipleChoiceQuestionExchangeDefinition,
  PredefinedQuestionOption,
  PriceWithCurrency,
  QuestionAddress,
  QuestionAddressField,
  QuestionDocument,
  QuestionFormat,
  QuestionResponseByType,
  QuestionType,
  QuestionYesNo,
  ReplyFieldConfig,
  SerializedDate,
  ShortTextSchemaType,
  UploadCannotProvideOption,
  YesNoOption,
  YesNoQuestionExchangeDefinition,
} from '@deepstream/common/rfq-utils';
import {
  cloneDeep,
  difference,
  filter,
  findIndex,
  first,
  fromPairs,
  get,
  intersection,
  isEmpty,
  isNaN,
  isNil,
  isUndefined,
  keyBy,
  lowerCase,
  map,
  mapValues,
  omit,
  omitBy,
  pick,
  pickBy,
  size,
  toLower,
  isFinite,
  range,
  sortBy,
} from 'lodash';
import { Box, Flex, Text } from 'rebass/styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useQuery } from 'react-query';
import { evaluate, formulaParser } from '@deepstream/formula';
import { createCurrencyConverter } from '@deepstream/common';
import { delay, getCurrencySymbol, immutableUpdate, localeFormatFactorAsPercent } from '@deepstream/utils';
import { diffFactor } from '@deepstream/utils/math';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { EditableGridDataProvider } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { useIntercom } from 'react-use-intercom';
import { LabelConfig, LabelConfigProvider } from '../LabelConfigProvider';
import { Select2Element } from '../pre-q/Select2Element';
import { useDeviceSize } from '../ui/useDeviceSize';
import { Currency, EvaluationExchangeSnapshot, ExchangeHistoryAction, ExchangeSnapshot, LineItemsExchangeSnapshot, UploadPurpose } from '../types';
import { TextField, TextFieldBase } from '../form/TextField';
import { CurrencyCode, CurrencyCodeProvider } from '../ui/Currency';
import { RadioField, RadioFieldBase } from '../form/RadioField';
import { useExchange } from '../useExchange';
import { FilesField, FilesFieldProps } from '../form/FilesField';
import { CheckboxField, CheckboxFieldArray } from '../form/CheckboxField';
import { FieldContainer } from '../form/FieldContainer';
import { addressFields, contactFields } from '../draft/questions';
import { SelectField, getItemLabel, getItemLabelWithDescription } from '../form/SelectField';
import { useCountryOptions } from '../ui/countries';
import { getGridItemPosition } from '../gridUtils';
import { phoneNumberRegExp } from '../utils';
import { CurrencySelectField } from '../form/CurrencySelectField';
import { MoneyField } from '../form/MoneyField';
import { DatePicker, GlobalDatePickerStyles } from '../ui/DatePicker';
import { DEFAULT_CURRENCY, useCurrencySelectItems } from '../ui/currencies';
import { ErrorMessage } from '../form/Field';
import { DatetimeField } from '../form/DatetimeField';
import { UnspscCodeField } from '../form/UnspscCodeField';
import { useAvailableBulletins } from './useAvailableBulletins';
import { useBulletins } from '../modules/RequestMessages/useBulletins';
import { useRfqId } from '../useRfq';
import { useCurrentCompanyId } from '../currentCompanyId';
import { useExchangeRates } from '../useExchangeRates';
import { useCurrentUserLocale } from '../useCurrentUser';
import * as rfx from '../rfx';
import { NameArray } from '../NameArray';
import { MultiSelectField } from '../form/MultiSelectField';
import { useApi, wrap } from '../api';
import {
  EditableQuestionResponseGrid,
  GridQuestionResponseValidation,
  createEmptyGridQuestionResponseRow,
  setGridResponseValueInRow,
  DEFAULT_MAX_GRID_ROWS,
} from '../ui/ExchangeDefsGrid/QuestionResponseGrid';
import { ExpandViewButton } from '../modules/Request/Comparison/ExpandViewButton';
import { StageName } from '../StageName';

// Autofocus will only be used after the user has already expressed their intent to reply
/* eslint-disable jsx-a11y/no-autofocus */

const {
  NONE, SUBMIT, COUNTERSIGN, DEVIATE, REFER_TO_BULLETIN, RESOLVE, ACCEPT,
  REJECT, CLOSE, REPLACE, AGREE, APPROVE, APPROVE_DOCUMENT, UPLOAD_DOCUMENT,
  REQUIRE_MORE_INFO, REVISE,
} = ActionType;

const CUSTOM_OPTION = '___custom___';

export const CHANGE_EXCHANGE_MAX_ATTACHMENTS = 20;

const extendedDateTimeFactory = new ExtendedDateTimeFactory();

type QuestionDocumentWithCustomOption = Modify<
  QuestionDocument,
  { selectedOption: YesNoOption | UploadCannotProvideOption | typeof CUSTOM_OPTION }
>;
type QuestionYesNoWithCustomOption = Modify<QuestionYesNo, { selectedOption: YesNoOption | typeof CUSTOM_OPTION }>;

const showAdditionalDocumentFields = (selectedOption) =>
  [CUSTOM_OPTION, PredefinedQuestionOption.YES, PredefinedQuestionOption.UPLOAD_DOCUMENT].includes(selectedOption);
const areAdditionalDocumentFieldsRequired = (selectedOption) =>
  [PredefinedQuestionOption.YES, PredefinedQuestionOption.UPLOAD_DOCUMENT].includes(selectedOption);

const getAddressSchema = (t) => ({
  [QuestionAddressField.COMPANY_NAME]: yup.string().required(t('general.required')),
  [QuestionAddressField.LINE_ONE]: yup.string().required(t('general.required')),
  [QuestionAddressField.LINE_TWO]: yup.string(),
  [QuestionAddressField.CITY]: yup.string().required(t('general.required')),
  [QuestionAddressField.STATE]: yup.string(),
  [QuestionAddressField.POSTCODE]: yup.string().required(t('general.required')),
  [QuestionAddressField.COUNTRY]: yup.string().required(t('general.required')),
  [QuestionAddressField.CONTACT_NAME]: yup.string().required(t('general.required')),
  [QuestionAddressField.EMAIL]: yup
    .string()
    .trim()
    .email(t('errors.invalidEmail'))
    .required(t('general.required')),
  [QuestionAddressField.PHONE]: yup
    .string()
    .trim()
    .matches(phoneNumberRegExp, t('errors.invalidPhoneNumber'))
    .required(t('general.required')),
});

export const getActionFilesAttachmentsLimit = (exchange: ExchangeSnapshot) =>
  exchange.isChat || exchange.isClarification || exchange.def.type === ExchangeType.BULLETIN ? CHANGE_EXCHANGE_MAX_ATTACHMENTS : 1;

type ActionFilesFieldProps = Pick<
  FilesFieldProps,
  'name' | 'download' | 'required' | 'label' | 'accept' | 'onChange'
> & {
  purpose?: UploadPurpose;
  max: number;
};

export const ActionFilesField = ({
  name,
  download,
  required,
  label,
  purpose = 'rfq',
  accept,
  max,
  onChange,
}: ActionFilesFieldProps) => {
  const { t } = useTranslation();
  const exchange = useExchange();
  const latestExtension = (exchange?.latestAttachment as Attachment)?.name.split('.').pop();
  const [currentExtension, setCurrentExtension] = React.useState<string | null>(null);
  const showExtensionMismatchMessage = currentExtension && latestExtension && currentExtension !== latestExtension;

  return (
    <FilesField
      required={required}
      name={name}
      label={label}
      max={max}
      download={download}
      purpose={purpose}
      details={
        showExtensionMismatchMessage && (
          <IconText
            icon="info-circle"
            iconColor="primary"
            color="subtext"
            text={t('request.exchange.extensionMismatchWarning', { currentExtension, latestExtension })}
            gap={1}
            fontSize={1}
          />
        )
      }
      onChange={attachments => {
        setCurrentExtension(attachments[0]?.name.split('.').pop() ?? null);
        if (onChange) {
          onChange(attachments);
        }
      }}
      accept={accept}
    />
  );
};

const ActionCommentField = ({
  name,
  required,
  hideError,
  placeholder: placeholderProp,
  autoFocus,
  isMultiLine = true,
}: {
  name: string;
  required?: boolean;
  hideError?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  isMultiLine?: boolean;
}) => {
  const { t } = useTranslation();

  const placeholder = React.useMemo(
    () => placeholderProp || (required
      ? t('request.exchange.leaveAComment')
      : t('request.exchange.leaveAnOptionalComment')),
    [placeholderProp, required, t],
  );

  return (
    <TextField
      name={name}
      label={t('general.comment', { count: 1 })}
      required={required}
      isMultiLine={isMultiLine}
      hideError={hideError}
      placeholder={placeholder}
      rows={2}
      autoFocus={autoFocus}
    />
  );
};

export const getFormulaContext = (
  exchange: LineItemsExchangeSnapshot,
  exchangeRates: ExchangeRateDocument | undefined,
  valuesMap: { [fieldId: string]: any },
) => {
  const targetCurrency = exchange.currency;
  const convertCurrency = createCurrencyConverter(targetCurrency, exchangeRates);

  return mapValues(
    pickBy(exchange.def.fields, canIncludeFieldInFormula),
    (field, fieldId) => {
      const fieldValue = isDefinitionField(field)
        ? get(exchange.def, field.source.key)
        : valuesMap[fieldId] as any[];

      const fieldRole = fieldId?.split(':')[1];
      const fieldNeedsConversion = field.type === 'price' && fieldRole === 'evaluator';
      if (fieldNeedsConversion) {
        const converted = convertCurrency({ value: fieldValue, currencyCode: getExchangeFieldValue(exchange, 'evaluatorFieldCurrency') });
        return converted.value;
      }
      return fieldValue;
    },
  );
};

const useComputeFormulaField = (fieldId: string, valuesMap: { [fieldId: string]: any }) => {
  const exchangeRates = useExchangeRates();
  const exchange: LineItemsExchangeSnapshot = useExchange();
  const fieldSource = exchange.def.fields[fieldId]?.source;
  const formulaContext = getFormulaContext(exchange, exchangeRates, valuesMap);

  if (fieldSource.type !== 'formula') {
    return null;
  }

  try {
    const { expression } = formulaParser.parse(fieldSource.formula);
    return evaluate(expression, formulaContext);
  } catch (error) {
    return null;
  }
};

export const useFormulaFieldValueGetter = () => {
  const exchangeRates = useExchangeRates();

  return (valuesMap: { [fieldId: string]: any }, field: LineItemFormulaFieldConfig, exchange?: LineItemsExchangeSnapshot) => {
    if (!exchange) {
      return null;
    }

    const fieldSource = field.source;
    const formulaContext = getFormulaContext(exchange, exchangeRates, valuesMap);

    if (fieldSource.type !== 'formula') {
        return null;
    }

    try {
      const { expression } = formulaParser.parse(fieldSource.formula);
      return evaluate(expression, formulaContext);
    } catch (error) {
      return null;
    }
  };
};

const DisabledFormulaField = ({ fieldId }) => {
  const { t } = useTranslation();
  const exchange: LineItemsExchangeSnapshot = useExchange();
  const { values } = useFormikContext();
  // take into account values that may be outside of Formik's context (e.g. Buyer fields in a supplier added exchange)
  const value = useComputeFormulaField(
    fieldId,
    {
      ...exchange.latestReply,
      ...exchange.computedFormulas,
      ...(values as any),
    },
  );
  const field = exchange.def.fields[fieldId];
  const fieldLabel = field.label || t(`request.fields.predefinedFieldLabel.${field._id.split(':')[0]}`, { ns: 'translation' });

  return (
    <TextFieldBase
      name={field._id}
      // @ts-expect-error ts(2322) FIXME: Type 'string | null | undefined' is not assignable to type 'string | undefined'.
      label={fieldLabel}
      format={field.type === 'price' ? 'money' : 'number'}
      prefix={field.type === 'price' ? getCurrencySymbol(exchange.currency) : undefined}
      hideError
      disabled
      // @ts-expect-error ts(2322) FIXME: Type 'number | null' is not assignable to type 'string | number | undefined'.
      value={value}
    />
  );
};

type BulletinReferenceProps = {
  name: string;
  exchange: ExchangeSnapshot;
};

const ActionBulletinReferenceElement: React.FC<BulletinReferenceProps> = ({ name, exchange }) => {
  const { t } = useTranslation();

  const bulletins = useBulletins({
    rfqId: useRfqId(),
    currentCompanyId: useCurrentCompanyId({ required: true }),
  });

  const availableBulletins = useAvailableBulletins(exchange);

  const element = React.useMemo(
    () => ({
      key: name,
      label: t('request.exchange.postToReference'),
      type: 'dropdown',
      placeholder: t('request.exchange.noBulletinPosts'),
      options: availableBulletins.map(({ _id, message }) => ({
        key: _id,
        value: _id,
        label: `#${findIndex(bulletins, { _id }) + 1} ${message}`,
      })),
      validation: {
        required: true,
      },
    } as DropdownQuestionElement),
    [name, t, availableBulletins, bulletins],
  );

  return (
    <Select2Element
      name={name}
      element={element}
      answer={null}
      disabled={!element.options?.length}
      placeholder={
        element.options?.length
          ? t('request.exchange.selectABulletinPost')
          : t('request.exchange.noBulletinPosts')
      }
      maxHeight="110px"
    />
  );
};

const ActionScoreField = ({ name, autoFocus }: { name: string; autoFocus?: boolean }) => {
  const { t } = useTranslation();
  const exchange = useExchange() as EvaluationExchangeSnapshot;

  const maxPoints = getExchangeFieldValue(exchange, 'maxPoints');

  return (
    <Flex>
      <Box flex="0 0 auto" mr={3}>
        <TextField
          required
          name={name}
          label={t('general.score')}
          format="integer.positive"
          sx={{ width: 140 }}
          convertNonNumbersToNull
          suffix={toLower(t('general.point_other'))}
          inputStyle={{ textAlign: 'right' }}
          autoFocus={autoFocus}
        />
      </Box>
      <Text flex={1} mt="11px" fontSize={2}>
        ({maxPoints} {lowerCase(t('general.maximum'))})
      </Text>
    </Flex>
  );
};

const NoAnswerField = ({ name, onSelectNoAnswer }: { name: string; onSelectNoAnswer: () => void }) => {
  const { t } = useTranslation('general');

  const onChange = React.useCallback(
    (event) => {
      if (event.target.checked) {
        onSelectNoAnswer();
      }
    },
    [onSelectNoAnswer],
  );

  return (
    <CheckboxField
      name={name}
      fieldLabel={t('notApplicable')}
      label=" "
      onChange={onChange}
      checkboxStyle={{ width: '140px' }}
    />
  );
};

const PercentDiffFromStage = ({ factor, stageId }) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { stages } = rfx.useStructure();
  const stageNum = stages.findIndex(stage => stage._id === stageId) + 1;

  return (
    <Flex justifyContent="flex-end" sx={{ width: '200px' }}>
      <IconText
        color={factor === 0 ? (
          'subtext'
        ) : factor > 0 ? (
          'danger'
        ) : (
          'success'
        )}
        fontSize={1}
        icon={factor > 0 ? 'arrow-up' : 'arrow-down'}
        text={t('request.lineItems.diffFromStage', {
          diff: localeFormatFactorAsPercent(Math.abs(factor), { locale, decimalPlaces: 1 }),
          stageNum,
        })}
      />
    </Flex>
  );
};

const LineItemReplyField = ({
  field,
  exchange,
  autoFocus = false,
}) => {
  const { t } = useTranslation(['request', 'translation']);
  const theme = useTheme();
  const fieldName = field.source.key;
  const fieldLabel = field.label || t(`request.fields.predefinedFieldLabel.${field._id.split(':')[0]}`, { ns: 'translation' });
  const [formikField,, { setValue }] = useField<boolean>(fieldName);

  switch (field.type) {
    case 'string':
      return (
        <TextField
          required
          name={fieldName}
          label={fieldLabel}
          autoFocus={autoFocus}
        />
      );
    case 'number':
      return field._id === 'leadTime:submitter'
        ? (
          <TextField
            required
            name={fieldName}
            label={t('request.lineItems.leadTime', { ns: 'translation' })}
            format="integer.positive"
            suffix={t('request.lineItems.workingDaysSuffix', { ns: 'translation' })}
            sx={{ width: 200 }}
            inputStyle={{ textAlign: 'right' }}
            autoFocus={autoFocus}
          />
        ) : (
          <TextField
            required
            name={fieldName}
            label={fieldLabel}
            format="number"
            sx={{ width: 200 }}
            inputStyle={{ textAlign: 'right' }}
            autoFocus={autoFocus}
          />
        );
    case 'price': {
      const { responseTag, supplierReplyByTag } = exchange;
      const { responseTags } = field;
      const previousTag = responseTag && responseTags
        ? responseTags[responseTags.indexOf(responseTag) - 1]
        : null;
      const previousValue = previousTag ? supplierReplyByTag?.[previousTag]?.[field._id] : null;
      const getExtendedInfo = isFinite(previousValue)
        ? (value) => (
          isFinite(value)
            ? <PercentDiffFromStage factor={diffFactor(previousValue, value)} stageId={getStageIdFromTag(previousTag)} />
            : null
        )
        : null;

      return (
        <TextField
          // TODO refactor: read 'required' flag instead
          required={field._id !== 'targetPrice'}
          name={fieldName}
          label={fieldLabel}
          decimalPlaces={field.decimalPlaces}
          format="money.positive"
          prefix={field.source.role === 'submitter' ? (
            <CurrencyCode symbol />
          ) : (
            <>
              {getCurrencySymbol(getExchangeFieldValue(exchange, 'evaluatorFieldCurrency'))}
            </>
          )}
          sx={{ width: 200 }}
          inputStyle={{ textAlign: 'right' }}
          autoFocus={autoFocus}
          // @ts-expect-error ts(2322) FIXME: Type '((value: any) => Element | null) | null' is not assignable to type '((value: string | number) => ReactNode) | undefined'.
          extendedInfo={getExtendedInfo}
        />
      );
    }
    case 'boolean':
      return (
        <RadioFieldBase
          name={fieldName}
          label={fieldLabel}
          required
          options={[
            { label: t('lineItems.cell.boolean.optionAccept'), value: 'accept' },
            { label: t('lineItems.cell.boolean.optionReject'), value: 'reject' },
          ]}
          gap={1}
          // override global CSS styling of <label> in the Angular client
          labelStyle={{ fontWeight: 'normal', color: theme.colors.text }}
          showError
          value={isNil(formikField.value) ? (
            undefined
          ) : formikField.value ? (
            'accept'
          ) : (
            'reject'
          )}
          onChange={(value) => setValue(value === 'accept')}
          autoFocus={autoFocus}
        />
      );
    case 'date':
      return (
        <DatetimeField
          required
          name={fieldName}
          label={fieldLabel}
          inputContainerProps={{ width: 200 }}
          popperModifiers={{
            preventOverflow: {
              boundariesElement: 'viewport',
            },
          }}
          autoFocus={autoFocus}
        />
      );
    case 'unspscCode':
      return (
        <UnspscCodeField
          required
          name={fieldName}
          label={fieldLabel}
          initialProductOrService={exchange.productOrService}
          autoFocus={autoFocus}
        />
      );
    default:
      return null;
  }
};

const getLineItemForm = (isSupplierSideForm: boolean, shouldIncludeField: (field: FieldConfig) => boolean) => {
  const getReplyFields = (exchange) => {
    const filteredFields = (Object.values(exchange.def.fields) as FieldConfig[]).filter(shouldIncludeField) as ReplyFieldConfig[];

    const replyFields = fromPairs(filteredFields.map(field => [
      field.source.key,
      field,
    ]));

    return omitBy(
      replyFields,
      field => isFieldDisabled(field, exchange),
    );
  };

  return {
    getInitialValues: (exchange) => {
      const replyFields = getReplyFields(exchange);

      return {
        comment: '',
        ...mapValues(
          replyFields,
          (field) => getExchangeFieldValue(exchange, field._id),
        ),
      };
    },
    getValidationSchema: (t, exchange) => {
      const replyFields = getReplyFields(exchange);

      return yup.object().shape({
        comment: yup.string(),
        ...mapValues(
          replyFields,
          field => {
            switch (field.type) {
              case 'date':
                return yup.date().typeError(t('general.required')).required(t('general.required'));
              case 'boolean':
                return yup.bool().typeError(t('general.required')).required(t('general.required'));
              case 'string':
              case 'unspscCode':
                return yup.string().typeError(t('general.required')).required(t('general.required'));
              case 'price':
                // TODO refactor: read 'required' flag instead
                return field._id === 'targetPrice'
                  ? yup.number().nullable()
                  : yup.number().typeError(t('general.required')).required(t('general.required'));
              case 'number':
                return yup.number().typeError(t('general.required')).required(t('general.required'));
              default:
                throw new Error(`Unsupported field type: ${field.type}`);
            }
          },
        ),
      });
    },
    Fields: ({ exchange, autoFocus = false, columns }) => {
      const replyFields = getReplyFields(exchange);

      let canSetAutoFocus = autoFocus;

      return (
        <>
          {columns.map(column => {
            const columnId = column._id;

            const replyField = replyFields[columnId];

            if (replyField) {
              let autoFocus = false;
              if (canSetAutoFocus) {
                autoFocus = true;
                canSetAutoFocus = false;
              }
              return (
                <LineItemReplyField
                  key={columnId}
                  field={replyField}
                  exchange={exchange}
                  autoFocus={autoFocus}
                />
              );
            }

            const field = exchange.def.fields[column._id];

            // TODO show all fields once we support real-time updates
            // of formulas based on other formulas
            if (field && isFormulaField(field) && field._id === 'totalCost') {
              return (
                <DisabledFormulaField
                  key={field._id}
                  fieldId={field._id}
                />
              );
            } else {
              return null;
            }
          })}
          <ActionCommentField name="comment" />
        </>
      );
    },
    sanitize: (action, exchange) => {
      const replyFields = getReplyFields(exchange);

      const fieldValues = mapValues(
        replyFields,
        (field, key) => {
          const value = action[key];
          return value === '' || isUndefined(value)
            ? null
            : value;
        },
      );

      return {
        ...pick(action, ['comment']),
        ...fieldValues,
      };
    },
  };
};

const useResponseConfirmationLabel = () => {
  const { t } = useTranslation('translation');
  const companyId = useCurrentCompanyId({ required: true });
  const api = useApi();

  const { data: company } = useQuery(
    ['publicCompany', { companyId }],
    wrap(api.getPublicCompany),
  );

  return t('request.exchange.acceptDocumentConfirmation', { companyName: company?.name });
};

const forms = {
  comment: ({ required = true } = {}) => ({
    getInitialValues: () => ({
      comment: '',
    }),
    getValidationSchema: (t) =>
      yup.object().shape({
        comment: required ? yup.string().required(t('general.required')) : yup.string(),
      }),
    Fields: ({ autoFocus = false }) => (
      <ActionCommentField name="comment" required={required} autoFocus={autoFocus} />
    ),
  }),

  commentWithResponseConfirmation: ({
    required = true,
    responseConfirmationLabelKey,
  }: {
    required?: boolean;
    responseConfirmationLabelKey: string | (() => string);
  }) => ({
    getInitialValues: () => ({
      comment: '',
      isConfirmationChecked: false,
    }),
    getValidationSchema: (t) =>
      yup.object().shape({
        comment: required
          ? yup.string().required(t('general.required'))
          : yup.string(),
        isConfirmationChecked: yup
          .boolean()
          .required(t('general.required'))
          .oneOf([true], t('general.required')),
      }),
    sanitize: (action) => {
      // Prevent isConfirmationChecked field to be sent to the backend
      return omit(action, 'isConfirmationChecked');
    },
    Fields: ({ autoFocus = false }) => {
      const { t } = useTranslation('translation');

      return (
        <>
          <CheckboxField
            name="isConfirmationChecked"
            label={t('request.question.response', { count: 1 })}
            fieldLabel={
              typeof responseConfirmationLabelKey === 'function'
                ? responseConfirmationLabelKey()
                : t(responseConfirmationLabelKey)
            }
            required={true}
          />
          <ActionCommentField
            name="comment"
            required={required}
            autoFocus={autoFocus}
          />
        </>
      );
    },
  }),

  lineItemBuyerSubmit: getLineItemForm(false, isBuyerReplyField),
  lineItemSupplierSubmit: getLineItemForm(true, isSupplierReplyField),

  pointsWithComment: {
    getInitialValues: (exchange, { companyId, userId }) => ({
      points: getScore(exchange, { companyId, userId }),
      comment: '',
    }),
    getValidationSchema: (t: TFunction, exchange: EvaluationExchangeSnapshot) => {
      return yup.object().shape({
        points: yup
          .number()
          .nullable()
          .strict(false)
          .max(getExchangeFieldValue(exchange, 'maxPoints'), ({ max }) => t('errors.maxOf', { max })),
        comment: yup.string(),
      });
    },
    Fields: ({ labelStyle, autoFocus = false }) => (
      <LabelConfigProvider
        variant={LabelConfig.LEFT}
        style={{ points: { position: 'relative', top: '12px' }, ...labelStyle }}
      >
        <ActionScoreField name="points" autoFocus={autoFocus} />
        <ActionCommentField name="comment" />
      </LabelConfigProvider>
    ),
  },

  bulletinReference: {
    getInitialValues: () => ({
      bulletinId: null,
    }),
    getValidationSchema: (t) =>
      yup.object().shape({
        bulletinId: yup.string().required(t('general.required')),
      }),
    Fields: ({ exchange }) => (
      <ActionBulletinReferenceElement name="bulletinId" exchange={exchange} />
    ),
  },

  attachmentWithStageAndComment: (attachmentsLabelKey: string) => ({
    getInitialValues: () => ({
      attachments: [],
      comment: '',
    }),
    getValidationSchema: (t) =>
      yup.object().shape({
        attachments: yup.array(yup.object()).min(1).required(t('general.required')),
        comment: yup.string(),
      }),
    Fields: ({ exchange, numResets }) => {
      const { t } = useTranslation('translation');
      const structure = rfx.useStructure<Live>();
      const attachmentsLimit = getActionFilesAttachmentsLimit(exchange);

      const stageId = exchange.def.stages[0];

      const stageIndex = structure.stages.findIndex(stage => stage._id === stageId);
      const stage = structure.stages[stageIndex];

      return (
        <>
          <FieldContainer label={t('request.internalDocuments.requestStage')}>
            <StageName stage={stage} index={stageIndex} />
          </FieldContainer>
          <ActionFilesField
            required
            key={`attachments-${numResets}`}
            name="attachments"
            label={t(attachmentsLabelKey)}
            max={attachmentsLimit}
          />
          <ActionCommentField name="comment" />
        </>
      );
    },
  }),
  attachmentWithComment: (attachmentsLabelKey: string, acceptedFileTypes?: string) => ({
    getInitialValues: () => ({
      attachments: [],
      comment: '',
    }),
    getValidationSchema: (t) =>
      yup.object().shape({
        attachments: yup.array(yup.object()).min(1).required(t('general.required')),
        comment: yup.string(),
      }),
    Fields: ({ exchange, numResets }) => {
      const { t } = useTranslation('translation');
      const attachmentsLimit = getActionFilesAttachmentsLimit(exchange);

      return (
        <>
          <ActionFilesField
            required
            key={`attachments-${numResets}`}
            name="attachments"
            label={t(attachmentsLabelKey)}
            accept={acceptedFileTypes}
            max={attachmentsLimit}
          />
          <ActionCommentField name="comment" />
        </>
      );
    },
  }),

  attachmentAndOrComment: (attachmentsLabelKey) => ({
    getInitialValues: () => ({
      attachments: [],
      comment: '',
    }),
    getValidationSchema: () =>
      yup.object().shape({
        attachments: yup.array(yup.object()).when('comment', {
          is: isEmpty,
          then: yup.array(yup.object()).min(1).required(),
        }),
        comment: yup.string().when('attachments', {
          is: isEmpty,
          then: yup.string().required(),
        }),
      },
      [['attachments', 'comment']],
      ),
    Fields: ({ exchange, numResets, autoFocus = false }) => {
      const { t } = useTranslation();
      const attachmentsLimit = getActionFilesAttachmentsLimit(exchange);

      return (
        <>
          <ActionCommentField
            hideError
            name="comment"
            autoFocus={autoFocus}
          />
          <ActionFilesField
            key={`attachments-${numResets}`}
            name="attachments"
            label={t(attachmentsLabelKey)}
            max={attachmentsLimit}
          />
        </>
      );
    },
  }),

  messageWithAttachments: {
    getInitialValues: (exchange: ExchangeSnapshot) => {
      const exchangeDef = exchange.def as BulletinExchangeDefinition;
      return ({
        message: exchangeDef.message,
        attachments: exchangeDef.attachments || [],
      });
    },
    getValidationSchema: (t) =>
      yup.object().shape({
        message: yup.string().required(t('general.required')),
        attachments: yup.array(yup.object().required()),
      }),
    Fields: ({ exchange, numResets }) => {
      const { t } = useTranslation();
      const attachmentsLimit = getActionFilesAttachmentsLimit(exchange);

      return (
        <>
          <TextField
            name="message"
            label={t('general.message')}
            required
            isMultiLine
            rows={8}
          />
          <ActionFilesField
            key={`attachments-${numResets}`}
            name="attachments"
            label={t('request.bulletin.files')}
            purpose="rfq"
            max={attachmentsLimit}
          />
        </>
      );
    },
  },

  textAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => ({
      response: exchange.latestResponse,
      comment: '',
    }),
    getValidationSchema: (t) =>
      yup.object().shape({
        response: yup.object().shape({
          value: yup.string().nullable().when('noAnswer', {
            is: noAnswer => !noAnswer,
            then: schema => schema.required(t('general.required')),
          }),
          noAnswer: yup.boolean(),
        }),
        comment: yup.string().when('response.noAnswer', {
          is: true,
          then: yup.string().required(t('general.required')),
        }),
      }),
    Fields: ({ exchange, autoFocus = false }) => {
      const { t } = useTranslation();
      const formikContext = useFormikContext();
      const [,, valueHelper] = useField<string>('response.value');
      const [{ value: noAnswer }] = useField<boolean>('response.noAnswer');

      const onSelectNoAnswer = React.useCallback(
        async () => {
          valueHelper.setValue(getEmptyResponseValue(exchange.def) as string);
          await formikContext.validateForm();
          valueHelper.setTouched(false);
        },
        [valueHelper, exchange.def, formikContext],
      );

      return (
        <>
          <TextField
            name="response.value"
            label={t('request.question.response', { count: 1 })}
            required={!noAnswer}
            disabled={noAnswer}
            isMultiLine={exchange.def.questionType === QuestionType.LONG_TEXT}
            rows={2}
            autoFocus={autoFocus}
          />
          {!exchange.def.isRequired ? (
            <NoAnswerField name="response.noAnswer" onSelectNoAnswer={onSelectNoAnswer} />
          ) : (
            null
          )}
          <ActionCommentField
            name="comment"
            required={noAnswer}
            placeholder={noAnswer ? t('request.question.explainWhyNotApplicable') : undefined}
          />
        </>
      );
    },
  },

  priceAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => ({
      response: exchange.latestResponse,
      comment: '',
    }),
    getValidationSchema: (t) =>
      yup.object().shape({
        response: yup.object().shape({
          value: yup.object().when('noAnswer', {
            is: noAnswer => !noAnswer,
            then: yup.object().shape({
              currencyCode: yup.string().required(t('general.required')),
              amount: yup.number()
                // Empty string results in NaN, which triggers a type error instead of required error
                .transform(value => isNaN(value) ? undefined : value)
                .required(t('general.required')),
            }),
            otherwise: yup.object().shape({
              currencyCode: yup.string(),
              amount: yup.number().nullable(),
            }),
          }),
          noAnswer: yup.boolean(),
        }),
        comment: yup.string().when('response.noAnswer', {
          is: true,
          then: yup.string().required(t('general.required')),
        }),
      }),
    Fields: ({ exchange }) => {
      const { t } = useTranslation();
      const formikContext = useFormikContext();
      const [{ value: responseValue },, valueHelper] = useField<PriceWithCurrency>('response.value');
      const [,, currencyCodeHelper] = useField<string>('response.value.currencyCode');
      const [{ value: noAnswer }] = useField<boolean>('response.noAnswer');
      const fixedCurrencyCode = exchange.def.currencies?.[0];
      const hasCurrencyCode = Boolean(responseValue.currencyCode);
      const shouldSetDefaultCurrency = !fixedCurrencyCode && !hasCurrencyCode && !noAnswer;

      React.useEffect(
        () => {
          if (shouldSetDefaultCurrency) {
            currencyCodeHelper.setValue(DEFAULT_CURRENCY);
          }
        },
        [shouldSetDefaultCurrency, currencyCodeHelper],
      );

      const onSelectNoAnswer = React.useCallback(
        async () => {
          valueHelper.setValue(getEmptyResponseValue(exchange.def) as unknown as PriceWithCurrency);
          await formikContext.validateForm();
          valueHelper.setTouched(false);
        },
        [valueHelper, exchange.def, formikContext],
      );

      return (
        <>
          <FieldContainer
            label={t('request.question.response', { count: 1 })}
            showAsterisk={!noAnswer}
          >
            <Flex>
              {!fixedCurrencyCode ? (
                <Box width="85px" mr="10px">
                  <CurrencySelectField
                    hideLabel
                    name="response.value.currencyCode"
                    disabled={noAnswer}
                  />
                </Box>
              ) : (
                null
              )}
              <Box width="300px">
                <CurrencyCodeProvider code={responseValue.currencyCode}>
                  <MoneyField
                    hideLabel
                    symbol
                    name="response.value.amount"
                    disabled={noAnswer}
                  />
                </CurrencyCodeProvider>
              </Box>
            </Flex>
          </FieldContainer>
          {!exchange.def.isRequired ? (
            <NoAnswerField name="response.noAnswer" onSelectNoAnswer={onSelectNoAnswer} />
          ) : (
            null
          )}
          <ActionCommentField
            name="comment"
            required={noAnswer}
            placeholder={noAnswer ? t('request.question.explainWhyNotApplicable') : undefined}
          />
        </>
      );
    },
  },

  numberAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => ({
      response: exchange.latestResponse,
      comment: '',
    }),
    getValidationSchema: (t, exchange) => {
      const { min, max } = exchange.def.schema;

      return yup.object().shape({
        response: yup.object().shape({
          value: yup.number().when('noAnswer', {
            is: true,
            then: schema => schema.nullable(),
            otherwise: schema => schema
              // Empty string results in NaN, which would trigger a type error
              .transform(value => isNaN(value) ? undefined : value)
              .min(min, t('request.question.minimumValue', { value: min }))
              .max(max, t('request.question.maximumValue', { value: max }))
              .required(t('general.required')),
          }),
          noAnswer: yup.boolean(),
        }),
        comment: yup.string().when('response.noAnswer', {
          is: true,
          then: yup.string().required(t('general.required')),
        }),
      });
    },
    Fields: ({ exchange, autoFocus = false }) => {
      const { t } = useTranslation();
      const formikContext = useFormikContext();
      const [,, valueHelper] = useField<string>('response.value');
      const [{ value: noAnswer }] = useField<boolean>('response.noAnswer');

      const onSelectNoAnswer = React.useCallback(
        async () => {
          valueHelper.setValue(getEmptyResponseValue(exchange.def) as string);
          await formikContext.validateForm();
          valueHelper.setTouched(false);
        },
        [valueHelper, exchange.def, formikContext],
      );

      return (
        <>
          <TextField
            name="response.value"
            label={t('request.question.response', { count: 1 })}
            required={!noAnswer}
            disabled={noAnswer}
            format={exchange.def.schema.type === ShortTextSchemaType.INTEGER ? 'integer' : 'number'}
            autoFocus={autoFocus}
          />
          {!exchange.def.isRequired ? (
            <NoAnswerField name="response.noAnswer" onSelectNoAnswer={onSelectNoAnswer} />
          ) : (
            null
          )}
          <ActionCommentField
            name="comment"
            required={noAnswer}
            placeholder={noAnswer ? t('request.question.explainWhyNotApplicable') : undefined}
          />
        </>
      );
    },
  },

  multipleChoiceAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => {
      const exchangeDef = exchange.def as MultipleChoiceQuestionExchangeDefinition;
      const latestResponse = exchange.latestResponse as { value: string; noAnswer?: boolean };
      const initialValues = {
        response: {
          value: latestResponse.value,
          noAnswer: latestResponse.noAnswer ?? false,
        },
        comment: '',
        customOption: '',
      };

      // We need to convert the custom answer provided by the supplier to the
      // form structure
      if (
        !isEmpty(latestResponse.value) &&
        exchangeDef.allowCustomOption &&
        !exchangeDef.options.includes(latestResponse.value)
      ) {
        initialValues.customOption = initialValues.response.value;
        initialValues.response.value = CUSTOM_OPTION;
      }

      return initialValues;
    },
    getValidationSchema: (t) =>
      yup.object().shape({
        response: yup.object().shape({
          value: yup.string().when('noAnswer', {
            is: true,
            then: schema => schema.nullable(),
            otherwise: schema => schema.required(t('general.required')),
          }),
          noAnswer: yup.boolean(),
        }),
        comment: yup.string().when('response.noAnswer', {
          is: true,
          then: yup.string().required(t('general.required')),
        }),
        customOption: yup.string().when('response.value', {
          is: CUSTOM_OPTION,
          then: yup.string().required(t('general.required')),
        }),
      }),
    sanitize: (action) => {
      const actionClone = cloneDeep(action);

      // If the `other` option was selected we need to replace the `value` with the
      // custom answer provided by the supplier
      if (actionClone.response.value === CUSTOM_OPTION) {
        actionClone.response.value = actionClone.customOption;
      }

      delete actionClone.customOption;

      return actionClone;
    },
    Fields: ({ exchange, autoFocus = false }) => {
      const { t } = useTranslation();
      const theme = useTheme();
      const formikContext = useFormikContext();
      const [{ value },, valueHelper] = useField<string>('response.value');
      const [,, customOptionHelper] = useField<string>('customOption');
      const [{ value: noAnswer }] = useField<boolean>('response.noAnswer');

      const exchangeDef = exchange.def as MultipleChoiceQuestionExchangeDefinition;

      const onSelectNoAnswer = React.useCallback(
        async () => {
          valueHelper.setValue(getEmptyResponseValue(exchange.def) as string);
          customOptionHelper.setValue('');
          await formikContext.validateForm();
          valueHelper.setTouched(false);
          customOptionHelper.setTouched(false);
        },
        [valueHelper, exchange.def, customOptionHelper, formikContext],
      );

      const options = React.useMemo(
        () => {
          const exchangeOptions = map(
            exchangeDef.options,
            option => ({
              value: option,
              label: option,
            }),
          );

          // Includes the "other" option, if added
          return exchangeDef.allowCustomOption
            ? [
              ...exchangeOptions,
              {
                value: CUSTOM_OPTION,
                label: t('request.question.other'),
              },
            ]
            : exchangeOptions;
        },
        [exchangeDef, t],
      );

      const onChangeValue = React.useCallback(
        (value) => {
          if (value !== CUSTOM_OPTION) {
            customOptionHelper.setValue('');
            customOptionHelper.setTouched(false);
          }
        },
        [customOptionHelper],
      );

      return (
        <>
          <Box>
            <RadioField
              name="response.value"
              label={t('request.question.response', { count: 1 })}
              required={!noAnswer}
              disabled={noAnswer}
              options={options}
              onChange={onChangeValue}
              gap={1}
              // override global CSS styling of <label> in the Angular client
              labelStyle={{ fontWeight: 'normal', color: theme.colors.text }}
              showError
              autoFocus={autoFocus}
            />
            {value === CUSTOM_OPTION && (
              <Box mt="6px">
                <TextField
                  name="customOption"
                  label=" "
                  placeholder={t('request.question.pleaseSpecify')}
                />
              </Box>
            )}
          </Box>
          {!exchange.def.isRequired ? (
            <NoAnswerField name="response.noAnswer" onSelectNoAnswer={onSelectNoAnswer} />
          ) : (
            null
          )}
          <ActionCommentField
            name="comment"
            required={noAnswer}
            placeholder={noAnswer ? t('request.question.explainWhyNotApplicable') : undefined}
          />
        </>
      );
    },
  },

  documentAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => {
      const exchangeDef = exchange.def as DocumentQuestionExchangeDefinition;
      const latestResponse = exchange.latestResponse as { value: QuestionDocument };
      const responseValueClone = cloneDeep(latestResponse.value) as QuestionDocumentWithCustomOption;
      const initialValues = {
        response: {
          value: responseValueClone,
        },
        comment: '',
        customOption: '',
      };

      // We need to convert the custom answer provided by the supplier to the
      // form structure
      if (
        latestResponse.value.selectedOption &&
        exchangeDef.allowCustomOption &&
        !exchangeDef.options.includes(latestResponse.value.selectedOption)
      ) {
        initialValues.customOption = initialValues.response.value.selectedOption;
        initialValues.response.value.selectedOption = CUSTOM_OPTION;
      }

      return initialValues;
    },
    getValidationSchema: (t, exchange) => {
      return yup.object().shape({
        response: yup.object().shape({
          value: yup.object().shape({
            selectedOption: yup.string().required(t('general.required')),
            attachments: yup
              .array()
              .of(yup.object())
              .when('selectedOption', {
                is: (selectedOption) =>
                  exchange.def.requireDocument &&
                  areAdditionalDocumentFieldsRequired(selectedOption),
                then: schema => schema.min(1, t('general.required')),
              }),
            expiryDate: yup
              .date()
              .nullable()
              .min(new Date(), t('request.question.validation.noDateInPast'))
              .when('selectedOption', {
                is: (selectedOption) =>
                  exchange.def.requireExpiry &&
                  areAdditionalDocumentFieldsRequired(selectedOption),
                then: schema => schema.required(t('general.required')),
              }),
          }),
        }),
        comment: yup.string(),
        customOption: yup.string().when('response.value.selectedOption', {
          is: CUSTOM_OPTION,
          then: yup.string().required(t('general.required')),
        }),
      });
    },
    sanitize: (action) => {
      const actionClone = cloneDeep(action);

      // If the `other` option was selected we need to replace the `selectedOption` with the
      // custom answer provided by the supplier
      if (actionClone.response.value.selectedOption === CUSTOM_OPTION) {
        actionClone.response.value.selectedOption = actionClone.customOption;
      }

      delete actionClone.customOption;

      return actionClone;
    },
    Fields: ({ exchange, autoFocus = false, numResets }) => {
      const { t } = useTranslation();
      const theme = useTheme();
      const [{ value }] = useField<QuestionDocumentWithCustomOption>('response.value');
      const [,, customOptionHelper] = useField<string>('customOption');
      const [,, attachmentsHelper] = useField<Attachment[]>('response.value.attachments');
      const [,, expiryDateHelper] = useField<Date>('response.value.expiryDate');
      const attachmentsLimit = getActionFilesAttachmentsLimit(exchange);

      const exchangeDef = exchange.def as DocumentQuestionExchangeDefinition;
      const { allowCustomOption, requireDocument, requireExpiry } = exchangeDef;

      const options = React.useMemo(
        () => {
          const exchangeOptions = map(
            exchangeDef.options,
            option => ({
              value: option,
              label: t(`request.question.predefinedOption.${option}`),
            }),
          );

          // Includes the "other" option, if added
          return allowCustomOption
            ? [
              ...exchangeOptions,
              {
                value: CUSTOM_OPTION,
                label: t('request.question.other'),
              },
            ]
            : exchangeOptions;
        },
        [exchangeDef, allowCustomOption, t],
      );

      const onChangeValue = React.useCallback(
        async (value) => {
          // Kind of ugly: wait for the value to be set before applying side effects,
          // otherwise the validation will fail because the old value will be used.
          await delay(0);

          if (value !== CUSTOM_OPTION) {
            customOptionHelper.setValue('');
            customOptionHelper.setTouched(false);
          }

          if (value === PredefinedQuestionOption.NO) {
            attachmentsHelper.setValue([]);
            attachmentsHelper.setTouched(false);
            // @ts-expect-error ts(2345) FIXME: Argument of type 'null' is not assignable to parameter of type 'Date'.
            expiryDateHelper.setValue(null);
            expiryDateHelper.setTouched(false);
          }
        },
        [customOptionHelper, attachmentsHelper, expiryDateHelper],
      );

      const now = new Date();

      return (
        <>
          <Stack gap="12px">
            <RadioField
              name="response.value.selectedOption"
              label={t('request.question.response', { count: 1 })}
              required
              options={options}
              onChange={onChangeValue}
              gap={1}
              // override global CSS styling of <label> in the Angular client
              labelStyle={{ fontWeight: 'normal', color: theme.colors.text }}
              showError
              autoFocus={autoFocus}
            />
            {value.selectedOption === CUSTOM_OPTION && (
              <Box>
                <TextField
                  name="customOption"
                  label=" "
                  placeholder={t('request.question.pleaseSpecify')}
                />
              </Box>
            )}
            {requireDocument && showAdditionalDocumentFields(value.selectedOption) && (
              <ActionFilesField
                required={areAdditionalDocumentFieldsRequired(value.selectedOption)}
                key={`attachments-${numResets}`}
                name="response.value.attachments"
                label={t('general.document')}
                purpose="questionnaire"
                max={attachmentsLimit}
              />
            )}
            {requireExpiry && showAdditionalDocumentFields(value.selectedOption) && (
              <DatetimeField
                required={areAdditionalDocumentFieldsRequired(value.selectedOption)}
                name="response.value.expiryDate"
                label={t('request.question.document.expiryDate')}
                min={now}
                inputContainerProps={{ width: 200 }}
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: 'viewport',
                  },
                }}
                useEndOfDay
              />
            )}
          </Stack>
          <ActionCommentField name="comment" />
        </>
      );
    },
  },

  yesNoAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => {
      const exchangeDef = exchange.def as YesNoQuestionExchangeDefinition;
      const latestResponse = exchange.latestResponse as { value: QuestionYesNo };
      const responseValueClone = cloneDeep(latestResponse.value) as QuestionYesNoWithCustomOption;
      const initialValues = {
        response: {
          value: responseValueClone,
        },
        comment: '',
        customOption: '',
      };

      // We need to convert the custom answer provided by the supplier to the
      // form structure
      if (
        latestResponse.value.selectedOption &&
        exchangeDef.allowCustomOption &&
        !exchangeDef.options.includes(latestResponse.value.selectedOption)
      ) {
        initialValues.customOption = initialValues.response.value.selectedOption;
        initialValues.response.value.selectedOption = CUSTOM_OPTION;
      }

      return initialValues;
    },
    getValidationSchema: (t, exchange) => {
      return yup.object().shape({
        response: yup.object().shape({
          value: yup.object().shape({
            selectedOption: yup.string().required(t('general.required')),
            attachments: yup.array().of(yup.object()),
            moreInformation: yup
              .string()
              .nullable()
              .when('selectedOption', {
                is: (selectedOption) =>
                  exchange.def.requireMoreInformationOn.includes(selectedOption),
                then: schema => schema.required(t('general.required')),
              }),
          }),
        }),
        comment: yup.string(),
        customOption: yup.string().when('response.value.selectedOption', {
          is: CUSTOM_OPTION,
          then: yup.string().required(t('general.required')),
        }),
      });
    },
    sanitize: (action) => {
      const actionClone = cloneDeep(action);

      // If the `other` option was selected we need to replace the `selectedOption` with the
      // custom answer provided by the supplier
      if (actionClone.response.value.selectedOption === CUSTOM_OPTION) {
        actionClone.response.value.selectedOption = actionClone.customOption;
      }

      delete actionClone.customOption;

      return actionClone;
    },
    Fields: ({ exchange, autoFocus = false, numResets }) => {
      const { t } = useTranslation();
      const theme = useTheme();
      const [{ value }] = useField<QuestionYesNoWithCustomOption>('response.value');
      const [,, customOptionHelper] = useField<string>('customOption');
      const [,, attachmentsHelper] = useField<Attachment[]>('response.value.attachments');
      const [,, moreInformationHelper] = useField<string>('response.value.moreInformation');
      const attachmentsLimit = getActionFilesAttachmentsLimit(exchange);

      const exchangeDef = exchange.def as YesNoQuestionExchangeDefinition;
      const { allowCustomOption, requireMoreInformationOn } = exchangeDef;

      const options = React.useMemo(
        () => {
          const exchangeOptions = map(
            exchangeDef.options,
            option => ({
              value: option,
              label: t(`request.question.predefinedOption.${option}`),
            }),
          );

          // Includes the "other" option, if added
          return allowCustomOption
            ? [
              ...exchangeOptions,
              {
                value: CUSTOM_OPTION,
                label: t('request.question.other'),
              },
            ]
            : exchangeOptions;
        },
        [exchangeDef, allowCustomOption, t],
      );

      const onChangeValue = React.useCallback(
        async (value) => {
          // Kind of ugly: wait for the value to be set before applying side effects,
          // otherwise the validation will fail because the old value will be used.
          await delay(0);

          if (value !== CUSTOM_OPTION) {
            customOptionHelper.setValue('');
            customOptionHelper.setTouched(false);
          }

          if (!requireMoreInformationOn.includes(value)) {
            attachmentsHelper.setValue([]);
            attachmentsHelper.setTouched(false);
            moreInformationHelper.setValue('');
            moreInformationHelper.setTouched(false);
          }
        },
        [requireMoreInformationOn, customOptionHelper, attachmentsHelper, moreInformationHelper],
      );

      return (
        <>
          <Stack gap="12px">
            <RadioField
              name="response.value.selectedOption"
              label={t('request.question.response', { count: 1 })}
              required
              options={options}
              onChange={onChangeValue}
              gap={1}
              // override global CSS styling of <label> in the Angular client
              labelStyle={{ fontWeight: 'normal', color: theme.colors.text }}
              showError
              autoFocus={autoFocus}
            />
            {value.selectedOption === CUSTOM_OPTION && (
              <Box>
                <TextField
                  name="customOption"
                  label=" "
                  placeholder={t('request.question.pleaseSpecify')}
                />
              </Box>
            )}
            {requireMoreInformationOn.includes(value.selectedOption as YesNoOption) && (
              <>
                <TextField
                  required
                  name="response.value.moreInformation"
                  label={t('request.question.yesNo.supportingInformation')}
                  isMultiLine
                  rows={2}
                  placeholder={t('request.question.yesNo.supportingInformationPlaceholder')}
                />
                <ActionFilesField
                  key={`attachments-${numResets}`}
                  name="response.value.attachments"
                  label={t('request.question.yesNo.optionalDocument')}
                  purpose="questionnaire"
                  max={attachmentsLimit}
                />
              </>
            )}
          </Stack>
          <ActionCommentField name="comment" />
        </>
      );
    },
  },

  checkboxesAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => {
      const exchangeDef = exchange.def as CheckboxesQuestionExchangeDefinition;
      const latestResponse = cloneDeep(exchange.latestResponse) as { value: string[]; noAnswer?: boolean };
      const initialValues = {
        response: {
          value: latestResponse.value,
          noAnswer: latestResponse.noAnswer ?? false,
        },
        comment: '',
        customOption: '',
      };

      // Answer values which are not included in the exchange definition `options`. Can have 0 (if the `other`
      // option is not selected) or 1 (if the `other` option is selected) elements.
      const customOptions = difference(latestResponse.value, exchangeDef.options);
      const customOption = first(customOptions);

      // We need to convert the custom answer provided by the supplier to the
      // form structure
      if (exchangeDef.allowCustomOption && customOption) {
        const customOptionIndex = latestResponse.value.indexOf(customOption);
        initialValues.customOption = customOption;
        initialValues.response.value[customOptionIndex] = CUSTOM_OPTION;
      }

      return initialValues;
    },
    getValidationSchema: (t) =>
      yup.object().shape({
        response: yup.object().shape({
          value: yup.array().of(yup.string()).when('noAnswer', {
            is: noAnswer => !noAnswer,
            then: schema => schema.min(1, t('general.required')),
          }),
          noAnswer: yup.boolean(),
        }),
        comment: yup.string().when('response.noAnswer', {
          is: true,
          then: yup.string().required(t('general.required')),
        }),
        customOption: yup.string().when('response.value', {
          is: value => value.includes(CUSTOM_OPTION),
          then: yup.string().required(t('general.required')),
        }),
      }),
    sanitize: (action, exchange: ExchangeSnapshot) => {
      const exchangeDef = exchange.def as CheckboxesQuestionExchangeDefinition;
      const actionClone = cloneDeep(action);
      const hasCustomOption = actionClone.response.value.includes(CUSTOM_OPTION);
      const orderedValues = intersection(exchangeDef.options, actionClone.response.value);

      // If the `other` option was selected we need to add the value from the `otherOption` input
      if (hasCustomOption) {
        orderedValues.push(actionClone.customOption);
      }

      actionClone.response.value = orderedValues;

      delete actionClone.customOption;

      return actionClone;
    },
    Fields: ({ exchange, autoFocus = false }) => {
      const { t } = useTranslation();
      const formikContext = useFormikContext();
      const [{ value },, valueHelper] = useField<string[]>('response.value');
      const [,, customOptionHelper] = useField<string>('customOption');
      const [{ value: noAnswer }] = useField<boolean>('response.noAnswer');

      const exchangeDef = exchange.def as CheckboxesQuestionExchangeDefinition;

      const onSelectNoAnswer = React.useCallback(
        async () => {
          valueHelper.setValue(getEmptyResponseValue(exchange.def) as string[]);
          customOptionHelper.setValue('');
          await formikContext.validateForm();
          valueHelper.setTouched(false);
          customOptionHelper.setTouched(false);
        },
        [valueHelper, exchange.def, customOptionHelper, formikContext],
      );

      const options = React.useMemo(
        () => {
          const exchangeOptions = map(
            exchangeDef.options,
            option => ({
              value: option,
              label: option,
            }),
          );

          // Includes the "other" option, if added
          return exchangeDef.allowCustomOption
            ? [
              ...exchangeOptions,
              {
                value: CUSTOM_OPTION,
                label: t('request.question.other'),
              },
            ]
            : exchangeOptions;
        },
        [exchangeDef, t],
      );

      const onChangeValue = React.useCallback(
        (event) => {
          if (event.target.getAttribute('data-value') === CUSTOM_OPTION && !event.target.checked) {
            customOptionHelper.setValue('');
            customOptionHelper.setTouched(false);
          }
        },
        [customOptionHelper],
      );

      return (
        <>
          <Box>
            <CheckboxFieldArray
              name="response.value"
              label={t('request.question.response', { count: 1 })}
              required={!noAnswer}
              disabled={noAnswer}
              options={options}
              flexDirection="column"
              onChange={onChangeValue}
              autoFocus={autoFocus}
            />
            {value.includes(CUSTOM_OPTION) && (
              <Box mt="6px">
                <TextField
                  name="customOption"
                  label=" "
                  placeholder={t('request.question.pleaseSpecify')}
                />
              </Box>
            )}
          </Box>
          {!exchange.def.isRequired ? (
            <NoAnswerField name="response.noAnswer" onSelectNoAnswer={onSelectNoAnswer} />
          ) : (
            null
          )}
          <ActionCommentField
            name="comment"
            required={noAnswer}
            placeholder={noAnswer ? t('request.question.explainWhyNotApplicable') : undefined}
          />
        </>
      );
    },
  },

  addressAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => {
      const latestResponse = exchange.latestResponse as { value: QuestionAddress; noAnswer?: boolean };

      return {
        response: {
          value: latestResponse.value,
          noAnswer: latestResponse.noAnswer ?? false,
        },
        comment: '',
      };
    },
    getValidationSchema: (t, exchange) => {
      const { visibleFields } = exchange.def as AddressQuestionExchangeDefinition;

      return yup.object().shape({
        response: yup.object().shape({
          value: yup.mixed().when('noAnswer', {
            is: noAnswer => !noAnswer,
            then: yup.object().shape(
              pick(
                getAddressSchema(t),
                visibleFields,
              ),
            ),
          }),
          noAnswer: yup.boolean(),
        }),
        comment: yup.string().when('response.noAnswer', {
          is: true,
          then: yup.string().required(t('general.required')),
        }),
      });
    },
    Fields: ({ exchange, autoFocus = false }) => {
      const { t } = useTranslation();
      const countryOptions = useCountryOptions();
      const { isExtraSmall, isSmall } = useDeviceSize();
      const formikContext = useFormikContext();
      const [,, valueHelper] = useField<QuestionAddress>('response.value');
      const [{ value: noAnswer }] = useField<boolean>('response.noAnswer');
      // Counter used as a `key` for resetting the `SelectField` when the address is reset
      const [counter, setCounter] = React.useState(0);

      const { visibleFields } = exchange.def as AddressQuestionExchangeDefinition;

      const emptyAddress = React.useMemo(
        () => mapValues(
          keyBy(visibleFields),
          () => '',
        ),
        [visibleFields],
      );

      const onSelectNoAnswer = React.useCallback(
        async () => {
          valueHelper.setValue(emptyAddress);
          await formikContext.validateForm();
          valueHelper.setTouched(false);

          setCounter(counter => counter + 1);
        },
        [valueHelper, emptyAddress, formikContext],
      );

      const numColumns = isSmall || isExtraSmall ? 1 : 2;

      const visibleAddressFields = React.useMemo(
        () => filter(addressFields, field => visibleFields.includes(field)),
        [visibleFields],
      );

      const visibleContactFields = React.useMemo(
        () => filter(contactFields, field => visibleFields.includes(field)),
        [visibleFields],
      );

      return (
        <>
          <FieldContainer
            label={t('request.question.response', { count: 1 })}
            showAsterisk={!noAnswer}
          >
            <Stack gap="24px">
              {!isEmpty(visibleAddressFields) && (
                <Stack gap={2} sx={{ gridTemplateColumns: [null, null, '1fr 1fr'] }}>
                  {visibleAddressFields.map((field, index) => (
                    <Box
                      key={field}
                      sx={getGridItemPosition(index, size(addressFields), numColumns)}
                    >
                      {field === QuestionAddressField.COUNTRY ? (
                        <SelectField
                          key={counter}
                          name={`response.value.${field}`}
                          items={countryOptions}
                          placeholder={t(`request.question.addressField.${field}`)}
                          disabled={noAnswer}
                          shouldHandleBlur
                        />
                      ) : (
                        <TextField
                          name={`response.value.${field}`}
                          placeholder={t(`request.question.addressField.${field}`)}
                          disabled={noAnswer}
                          autoFocus={index === 0 ? autoFocus : false}
                        />
                      )}
                    </Box>
                  ))}
                </Stack>
              )}
              {!isEmpty(visibleContactFields) && (
                <Stack gap={2} sx={{ gridTemplateColumns: [null, null, '1fr 1fr'] }}>
                  {visibleContactFields.map((field, index) => (
                    <Box
                      key={field}
                      sx={{
                        ...getGridItemPosition(index, size(contactFields), numColumns),
                      }}
                    >
                      <TextField
                        key={field}
                        name={`response.value.${field}`}
                        placeholder={t(`request.question.addressField.${field}`)}
                        disabled={noAnswer}
                        autoFocus={index === 0 ? autoFocus : false}
                      />
                    </Box>
                  ))}
                </Stack>
              )}
            </Stack>
          </FieldContainer>
          {!exchange.def.isRequired ? (
            <NoAnswerField name="response.noAnswer" onSelectNoAnswer={onSelectNoAnswer} />
          ) : (
            null
          )}
          <ActionCommentField
            name="comment"
            required={noAnswer}
            placeholder={noAnswer ? t('request.question.explainWhyNotApplicable') : undefined}
            autoFocus={autoFocus}
          />
        </>
      );
    },
  },

  dateTimeAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => ({
      response: {
        ...exchange.latestResponse,
        value: exchange.latestResponse?.value
          ? extendedDateTimeFactory.fromRepresentation(exchange.latestResponse.value as SerializedDate)
          : null,
      },
      comment: '',
    }),

    sanitize: (action) => {
      action.response.value = action.response.value?.serialize();
      return action;
    },

    getValidationSchema: (t) =>
      yup.object().shape({
        response: yup.object().shape({
          value: yup.object().nullable().when('noAnswer', {
            is: noAnswer => !noAnswer,
            then: schema => schema.required(t('general.required')),
          }),
          noAnswer: yup.boolean(),
        }),
        comment: yup.string().when('response.noAnswer', {
          is: true,
          then: yup.string().required(t('general.required')),
        }),
      }),

    Fields: ({ exchange, autoFocus = false }) => {
      const { t } = useTranslation();
      const formikContext = useFormikContext();
      const [{ value }, valueMeta, valueHelper] = useField<ExtendedDateTimeBase | null>('response.value');
      const [{ value: noAnswer }] = useField<boolean>('response.noAnswer');

      const { format } = exchange.def as DateTimeQuestionExchangeDefinition<Live>;

      const onSelectNoAnswer = React.useCallback(
        async () => {
          valueHelper.setValue(getEmptyResponseValue(exchange.def) as unknown as null);
          await formikContext.validateForm();
          valueHelper.setTouched(false);
        },
        [valueHelper, exchange.def, formikContext],
      );

      const onChange = React.useCallback(
        (value: Date) =>
          valueHelper.setValue(value ? extendedDateTimeFactory.fromDate(value, exchange.def.format) : null),
        [exchange.def.format, valueHelper],
      );

      return (
        <>
          <FieldContainer
            label={t('request.question.response', { count: 1 })}
            showAsterisk={!noAnswer}
          >
            <Box maxWidth="100%" width="340px">
              <DatePicker
                popperPlacement="top-start"
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: 'viewport',
                  },
                }}
                onChange={onChange}
                value={value ? value.date : value}
                showTimeSelect={[QuestionFormat.DATETIME, QuestionFormat.TIME].includes(format)}
                showTimeSelectOnly={format === QuestionFormat.TIME}
                dateFormat={ExtendedDateTimeBase.getDateFormat(format, false)}
                disabled={noAnswer}
                onBlur={() => { valueHelper.setTouched(true); }}
                autoFocus={autoFocus}
              />
            </Box>
            {valueMeta.touched && valueMeta.error ? (
              <ErrorMessage error={valueMeta.error} fontWeight="normal" />
            ) : (
              null
            )}
          </FieldContainer>
          {!exchange.def.isRequired ? (
            <NoAnswerField name="response.noAnswer" onSelectNoAnswer={onSelectNoAnswer} />
          ) : (
            null
          )}
          <ActionCommentField
            name="comment"
            required={noAnswer}
            placeholder={noAnswer ? t('request.question.explainWhyNotApplicable') : undefined}
          />
        </>
      );
    },
  },

  gridAnswerWithComment: {
    getInitialValues: (exchange: ExchangeSnapshot) => {
      const latestResponse = exchange.latestResponse as QuestionResponseByType<QuestionType.GRID> | undefined;

      return {
        response: {
          isGridValid: !isEmpty(latestResponse?.value?.rows),
          value: latestResponse?.value || {},
          noAnswer: latestResponse?.noAnswer ?? false,
        },
        comment: '',
      };
    },
    sanitize: (action) => {
      return immutableUpdate(
        action,
        'response',
        response => omit(response, 'isGridValid'),
      );
    },
    getValidationSchema: (t) => {
      return yup.object().shape({
        response: yup.object().shape({
          // the available grid cells all interact with our
          // Validation context; instead of adding new grid
          // cells that interact with Formik, we for now use
          // tbe existing grid cells and update the Formik's
          // validation status in the ErrorMessages component.
          isGridValid: yup.boolean().when('noAnswer', {
            is: true,
            then: schema => schema.nullable(),
            otherwise: schema => schema.oneOf([true]),
          }),
          noAnswer: yup.boolean(),
        }),
        comment: yup.string().when('response.noAnswer', {
          is: true,
          then: yup.string().required(t('general.required')),
        }),
      });
    },
    Fields: ({ exchange, isExpandedView, setIsExpandedView, gridResponseKey }) => {
      const intercom = useIntercom();
      const { t } = useTranslation();
      const formikContext = useFormikContext();
      const [{ value: responseValue },, valueHelper] = useField<{ currencyCode?: string; rows?: unknown[] }>('response.value');
      const [{ value: noAnswer }] = useField<boolean>('response.noAnswer');
      const exchangeDef = exchange.def as GridQuestionExchangeDefinition;

      const { columns, rowsConfig } = exchangeDef;

      React.useEffect(() => {
        if (!responseValue?.currencyCode && !isEmpty(exchangeDef.currencies)) {
          valueHelper.setValue({
            ...responseValue,
            currencyCode: first(exchangeDef.currencies),
          });
        }
      }, [exchangeDef.currencies, responseValue, valueHelper]);

      const minRowCount = rowsConfig.isCustom ? rowsConfig.min : 1;
      const maxRowCount = rowsConfig.isCustom ? rowsConfig.max : DEFAULT_MAX_GRID_ROWS;

      const rowData = isEmpty(responseValue?.rows)
        // @ts-expect-error ts(2345) FIXME: Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
        ? range(minRowCount).map(() => createEmptyGridQuestionResponseRow(columns))
        : responseValue?.rows;

      const currencyFilterPredicate = React.useMemo(() => {
        if (!exchangeDef.currencies) {
          return undefined;
        }

        // @ts-expect-error ts(18048) FIXME: 'exchangeDef.currencies' is possibly 'undefined'.
        return (currency: Currency) => exchangeDef.currencies.includes(currency.code);
      }, [exchangeDef]);

      const currencySelectItems = useCurrencySelectItems({ filterPredicate: currencyFilterPredicate });

      const onSelectNoAnswer = React.useCallback(
        async () => {
          valueHelper.setValue(getEmptyResponseValue(exchangeDef) as object);
          await formikContext.validateForm();
          valueHelper.setTouched(false);
        },
        [valueHelper, exchangeDef, formikContext],
      );

      React.useEffect(
        () => {
          if (isExpandedView) {
            intercom.update({ verticalPadding: 140 });
          } else {
            intercom.update({ verticalPadding: 20 });
          }
        },
        [intercom, isExpandedView],
      );

      React.useEffect(
        () => {
          return () => {
            intercom.update({ verticalPadding: 20 });
          };
        },
        [intercom],
      );

      return (
        <>
          {!noAnswer && (
            <Box>
              <Flex flexDirection="row" alignItems="center" justifyContent="flex-end" mb={2}>
                <ExpandViewButton
                  isExpandedView={isExpandedView}
                  setIsExpandedView={setIsExpandedView}
                  shrinkedVariant="secondary-transparent-outline"
                  type="button"
                  mr={3}
                />
                {isEmpty(exchangeDef.currencies) ? (
                  null
                ) : (
                  <Box>
                    <SelectField
                      name="response.value.currencyCode"
                      small
                      menuWidth={200}
                      buttonStyle={{ width: 150 }}
                      disabled={size(exchangeDef.currencies) === 1}
                      items={currencySelectItems}
                      getItemLabel={getItemLabel}
                      menuZIndex={204}
                    />
                  </Box>
                )}
              </Flex>
              <GlobalDatePickerStyles zIndex={203} />
              <GridIdPrefixProvider>
                <GridMenuStateProvider>
                  <EditableGridDataProvider
                    // Used for resetting the grid when the previous response is reused
                    key={gridResponseKey}
                    // @ts-expect-error ts(2322) FIXME: Type 'unknown[] | undefined' is not assignable to type 'any[]'.
                    rowData={rowData}
                    minRows={minRowCount}
                    maxRows={maxRowCount}
                    setValueInRow={setGridResponseValueInRow}
                  >
                    <GridQuestionResponseValidation
                      showValidationErrors
                      columns={exchangeDef.columns}
                      rowsConfig={exchangeDef.rowsConfig}
                    >
                      <EditableQuestionResponseGrid
                        columns={exchangeDef.columns}
                        currencyCode={responseValue?.currencyCode}
                        isExpandedView={isExpandedView}
                      />
                    </GridQuestionResponseValidation>
                  </EditableGridDataProvider>
                </GridMenuStateProvider>
              </GridIdPrefixProvider>
            </Box>
          )}
          {!exchangeDef.isRequired ? (
            <NoAnswerField name="response.noAnswer" onSelectNoAnswer={onSelectNoAnswer} />
          ) : (
            null
          )}
          <Flex justifyContent="flex-end">
            <Box width={isExpandedView ? '50%' : '100%'}>
              <ActionCommentField
                name="comment"
                required={noAnswer}
                placeholder={noAnswer ? t('request.question.explainWhyNotApplicable') : undefined}
                isMultiLine={false}
              />
            </Box>
          </Flex>
        </>
      );
    },
  },

  agreeAndSignersWithComment: {
    getInitialValues: () => ({
      signerUserIds: [],
      comment: '',
    }),
    getValidationSchema: () =>
      yup.object().shape({
        signerUserIds: yup.array().of(yup.string()).min(1),
        comment: yup.string(),
      }),
    sanitize: (action, _, { companyUsers }) => {
      const { signerUserIds, ...newAction } = action;

      const usersById = keyBy(
        companyUsers,
        user => user._id,
      );

      newAction.signers = map(
        signerUserIds,
        userId => pick(usersById[userId], ['_id', 'name']),
      );

      return newAction;
    },
    Fields: () => {
      const { t } = useTranslation(['contracts', 'general']);
      const currentCompanyId = useCurrentCompanyId({ required: true });
      const api = useApi();

      const { data: companyUsers = [], isLoading: isLoadingUsers } = useQuery(
        ['usersForCompany', { companyId: currentCompanyId }],
        wrap(api.getUsersForCompany),
      );

      const userItems = React.useMemo(
        () => map(
          companyUsers,
          user => ({
            value: user._id,
            label: user.name,
            description: user.email,
          }),
        ),
        [companyUsers],
      );

      const getUserSelectButtonText = React.useCallback(
        (selectedItems: { value: string; label: string; description: string }[]) => (
          <Box flex={1} textAlign="left" fontWeight={400}>
            {isEmpty(selectedItems) ? (
              t('noUsersSelected', { ns: 'general' })
            ) : (
              <NameArray entities={map(selectedItems, item => item.label)} />
            )}
          </Box>
        ),
        [t],
      );

      return (
        <>
          <MultiSelectField
            name="signerUserIds"
            label={t('signature.signer_other')}
            variant="secondary-outline"
            getButtonText={getUserSelectButtonText}
            items={userItems}
            itemToString={item => item ? item.value : ''}
            renderItem={getItemLabelWithDescription}
            withSelectButton
            buttonWidth="100%"
            menuZIndex={202}
            menuWidth={324}
            itemHeight={50}
            alwaysShowCaret
            disabled={isLoadingUsers}
            required
          />
          <ActionCommentField name="comment" />
        </>
      );
    },
  },
  empty: {
    getInitialValues: () => ({}),
    getValidationSchema: () => yup.mixed(),
    Fields: () => null,
  },
};

const actionFormMap = {
  [NONE]: {
    [ExchangeType.CHAT]: forms.attachmentAndOrComment('request.exchange.attachment'),
    [ExchangeType.CHAT_NO_RECEIVER_UPLOAD]: {
      initiator: forms.attachmentAndOrComment('request.exchange.attachment'),
      receiver: forms.comment({ required: true }),
    },
    [ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD]: {
      initiator: forms.comment({ required: true }),
      receiver: forms.attachmentAndOrComment('request.exchange.attachment'),
    },
    default: forms.comment({ required: true }),
  },
  [SUBMIT]: {
    [ExchangeType.INTERNAL_DOCUMENT]: forms.attachmentWithStageAndComment('request.exchange.updatedDocument'),
    [ExchangeType.COMPLETE_OR_SIGN]: forms.attachmentWithComment('request.exchange.completedDocument'),
    [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: forms.attachmentWithComment('request.exchange.completedDocument'),
    [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: forms.attachmentWithComment('request.exchange.completedDocument'),
    [ExchangeType.DOCUMENT_REQUEST]: forms.attachmentWithComment('request.exchange.requestedDocument'),
    [ExchangeType.DOCUMENT_REQUEST_CLOSED]: forms.attachmentWithComment('request.exchange.requestedDocument'),
    [ExchangeType.DOCUMENT_REQUEST_LOCKED]: forms.attachmentWithComment('request.exchange.requestedDocument'),
    [ExchangeType.LINE_ITEM]: {
      evaluator: forms.lineItemBuyerSubmit,
      submitter: forms.lineItemSupplierSubmit,
    },
    [ExchangeType.CONTRACT]: forms.attachmentWithComment('request.exchange.contract', '.pdf'),
    [ExchangeType.EVALUATION_CRITERION]: forms.pointsWithComment,
    [ExchangeType.INCLUSIONS]: forms.comment({ required: false }),
    [ExchangeType.QUESTION]: {
      [QuestionType.SHORT_TEXT]: {
        default: forms.textAnswerWithComment,
        schema: forms.numberAnswerWithComment,
      },
      [QuestionType.LONG_TEXT]: forms.textAnswerWithComment,
      [QuestionType.MULTIPLE_CHOICE]: forms.multipleChoiceAnswerWithComment,
      [QuestionType.CHECKBOXES]: forms.checkboxesAnswerWithComment,
      [QuestionType.ADDRESS]: forms.addressAnswerWithComment,
      [QuestionType.PRICE]: forms.priceAnswerWithComment,
      [QuestionType.DATE_TIME]: forms.dateTimeAnswerWithComment,
      [QuestionType.DOCUMENT]: forms.documentAnswerWithComment,
      [QuestionType.GRID]: forms.gridAnswerWithComment,
      [QuestionType.YES_NO]: forms.yesNoAnswerWithComment,
    },
  },
  [REVISE]: {
    [ExchangeType.BULLETIN]: forms.messageWithAttachments,
  },
  [COUNTERSIGN]: {
    [ExchangeType.CONTRACT]: forms.attachmentWithComment('request.exchange.signedContract', '.pdf'),
    default: forms.attachmentWithComment('request.exchange.countersignedDocument'),
  },
  [RESOLVE]: forms.comment({ required: false }),
  [REFER_TO_BULLETIN]: forms.bulletinReference,
  [DEVIATE]: {
    [ExchangeType.CONTRACT]: forms.attachmentWithComment('request.exchange.contract', '.pdf'),
    default: forms.attachmentAndOrComment('request.exchange.alternativeDocument'),
  },
  [ACCEPT]: {
    [ExchangeType.CONTRACT]: forms.attachmentWithComment('request.exchange.contract', '.pdf'),
    [ExchangeType.ACCEPT_CLOSED]:
      forms.commentWithResponseConfirmation({ required: false, responseConfirmationLabelKey: useResponseConfirmationLabel }),
    default: forms.comment({ required: false }),
  },
  [REJECT]: {
    [ExchangeType.LINE_ITEM]: forms.comment({ required: true }),
    default: forms.comment({ required: false }),
  },
  [CLOSE]: forms.comment({ required: false }),
  [REPLACE]: forms.attachmentWithComment('request.exchange.contract', '.pdf'),
  [AGREE]: forms.agreeAndSignersWithComment,
  [APPROVE]: forms.comment({ required: false }),
  [REQUIRE_MORE_INFO]: forms.comment({ required: true }),
  [APPROVE_DOCUMENT]: forms.comment({ required: false }),
  [UPLOAD_DOCUMENT]: forms.attachmentWithComment('request.exchange.contract', '.pdf'),
} as const;

const isFormObject = (object) => object && object.hasOwnProperty('Fields');

export const getExchangeReplyFormConfig = (
  exchange: ExchangeSnapshot,
  selectedAction?: ExchangeHistoryAction,
) => {
  const { def, roles } = exchange;
  const { type: exchangeType } = def;

  const actionConfig = selectedAction
    ? actionFormMap[selectedAction.type]
    : forms.empty;

  if (isFormObject(actionConfig)) {
    return actionConfig;
  }

  const exchangeConfig = actionConfig?.[exchangeType] ?? actionConfig?.default;

  if (isFormObject(exchangeConfig)) {
    return exchangeConfig;
  }

  for (const role of roles) {
    if (isFormObject(exchangeConfig[role])) {
      return exchangeConfig[role];
    }
  }

  if (def.type === ExchangeType.QUESTION) {
    if (def.questionType) {
      if (isFormObject(exchangeConfig[def.questionType])) {
        return exchangeConfig[def.questionType];
      } else if ('schema' in def && isFormObject(exchangeConfig[def.questionType]?.schema)) {
        return exchangeConfig[def.questionType].schema;
      } else if (isFormObject(exchangeConfig[def.questionType]?.default)) {
        return exchangeConfig[def.questionType].default;
      }
    }

    throw new Error(`No form config found for the following question type '${def.questionType}'`);
  }

  throw new Error(`No form config found for any of the following roles '${roles.join(', ')}'`);
};
