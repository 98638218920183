import { useEffect, useCallback, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Box, Flex, Text } from 'rebass/styled-components';
import * as yup from 'yup';
import { useQuery, useQueryClient } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { callAll } from '@deepstream/utils/callAll';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { Panel, PanelDivider, PanelHeader, PanelPadding, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useIntercom } from 'react-use-intercom';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { Loading, Spinner } from '../../ui/Loading';
import { useMutation } from '../../useMutation';
import { useApi, wrap } from '../../api';
import { TextField } from '../../form/TextField';
import { LocationsField } from '../../form/LocationsField';
import { DiscoverySearchResultsTable } from './DiscoverySearchResultsTable';
import { useToaster } from '../../toast';
import { Bold } from '../../Bold';
import { DiscoveryFeedbackPopover } from './DiscoveryFeedbackPopover';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { RadioField } from '../../form/RadioField';
import { DiscoverySearchHistory } from './DiscoverySearchHistory';
import { useLocalStorageState } from '../../useLocalStorageState';
import { useCurrentUser } from '../../useCurrentUser';

const SUCCESSFUL_QUERY_FEEDBACK_FREQUENCY = 3;

const ScrollToElementRef = ({ elementRef }) => {
  useEffect(
    () => {
      elementRef.current.scrollIntoView({
        block: 'start',
        inline: 'start',
      });

      const isNotAtBottomOfPage = (window.innerHeight + window.pageYOffset) < document.body.offsetHeight;

      if (isNotAtBottomOfPage) {
        // To avoid the fixed header overlapping the top of the element
        window.scrollBy(0, -125);
      }
  },
  [elementRef],
);

  return null;
};

const ScrollToBottom = () => {
  useEffect(
    () => {
      const rootElement = document.getElementById('root');
      rootElement!.scrollTo({
        left: 0,
        top: (document.documentElement.scrollHeight - document.documentElement.clientHeight),
        behavior: 'smooth',
      });
    },
    [],
  );

  return null;
};

type Row = {
  metadata: Record<string, unknown>;
};

export function downloadCsv(filename: string, rows: Row[]) {
  // @ts-expect-error ts(2790) FIXME: The operand of a 'delete' operator must be optional.
  delete rows[0].metadata;

  const header = `${Object.keys(rows[0]).join(',')}\n`;

  const csvRows = rows
    .map(row => {
      // @ts-expect-error ts(2790) FIXME: The operand of a 'delete' operator must be optional.
      delete row.metadata;
      return Object.values(row).map((val) => `"${val}"`).join(','); // We wrap the val in quotes to escape commas and csv injection operators
    })
    .join('\n');

  const csv = header + csvRows;
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const temporaryDownloadLinkElement = document.createElement('a');

  temporaryDownloadLinkElement.setAttribute('href', url);
  temporaryDownloadLinkElement.setAttribute('download', filename);
  temporaryDownloadLinkElement.style.display = 'none';
  document.body.appendChild(temporaryDownloadLinkElement);
  temporaryDownloadLinkElement.click();
  document.body.removeChild(temporaryDownloadLinkElement);
}

enum PromptStrategyVersion {
  INITIAL = 'fast-initial-model',
  REVISED = 'slower-revised-model',
}

const usePollForDiscoveryQueryResults = ({
  queryId,
  currentCompanyId,
  pendingQueryIds,
  numSuccessfulQueries,
  setPendingQueryIds,
  setNumSuccessfulQueries,
  onQueryComplete,
  onQueryPending,
}: {
  queryId: string;
  currentCompanyId: string;
  pendingQueryIds?: string[];
  numSuccessfulQueries?: number;
  setPendingQueryIds?: (pendingQueryIds: string[]) => void;
  setNumSuccessfulQueries?: (numSuccessfulQueries: number) => void;
  onQueryComplete?: (results: any) => void;
  onQueryPending?: (results: any) => void;
}) => {
  const { t } = useTranslation('supplierDiscovery');
  const api = useApi();
  const toaster = useToaster();

  const [refetchInterval, setRefetchInterval] = useState<number | false>(1000);
  const isEnabled = Boolean(queryId && currentCompanyId);

  return useQuery(
    ['discoveryQuery', { queryId, companyId: currentCompanyId }],
    wrap(api.getDiscoveryQuery),
    {
      onSuccess: (discoveryQuery) => {
        switch (discoveryQuery?.status) {
          case 'success':
            setRefetchInterval(false);
            onQueryComplete?.(discoveryQuery);
            if (pendingQueryIds?.includes(discoveryQuery.queryId)) {
              // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
              setPendingQueryIds(pendingQueryIds.filter(id => id !== discoveryQuery.queryId));
              // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
              setNumSuccessfulQueries(numSuccessfulQueries + 1);
            }
            break;
          case 'error':
            toaster.error(t('results.error.queryError'));
            setRefetchInterval(false);
            if (pendingQueryIds?.includes(discoveryQuery.queryId)) {
              // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
              setPendingQueryIds(pendingQueryIds.filter(id => id !== discoveryQuery.queryId));
            }
            break;
          case 'pending':
            onQueryPending?.(discoveryQuery);
            break;
          default:
            toaster.error(t('results.error.queryError'));
            if (pendingQueryIds?.includes(discoveryQuery.queryId)) {
              // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
              setPendingQueryIds(pendingQueryIds.filter(id => id !== discoveryQuery.queryId));
            }
            setRefetchInterval(false);
        }
      },
      enabled: isEnabled,
      refetchInterval,
    },
  );
};

const Feedback = ({ queryId }: { queryId: string }) => {
  const { t } = useTranslation('supplierDiscovery');
  const [smileRef, smileHovered] = useHover();
  const [mehRef, mehHovered] = useHover();
  const [frownRef, frownHovered] = useHover();
  const [currentRating, setRating] = useState(null);
  const popover = usePopover({});

  return (
    <>
      <Flex alignItems="center" justifyContent="center">
        <Text><Bold>{t('results.howHelpful')}</Bold></Text>
        <Flex>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          <Text
            ref={smileRef}
            as="span"
            ml={3}
            sx={{ cursor: 'pointer', opacity: mehHovered || frownHovered ? 0.5 : 1 }}
            role="img"
            aria-label={t('results.smileEmoji')}
            onClick={() => {
              // @ts-expect-error ts(2345) FIXME: Argument of type '1' is not assignable to parameter of type 'SetStateAction<null>'.
              setRating(1);
              popover.open();
            }}
          >
            😀
          </Text>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          <Text
            ref={mehRef}
            as="span"
            ml={3}
            sx={{ cursor: 'pointer', opacity: smileHovered || frownHovered ? 0.5 : 1 }}
            role="img"
            aria-label={t('results.straightFaceEmoji')}
            onClick={() => {
              // @ts-expect-error ts(2345) FIXME: Argument of type '0' is not assignable to parameter of type 'SetStateAction<null>'.
              setRating(0);
              popover.open();
            }}
          >
            😐
          </Text>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          <Text
            ref={frownRef}
            as="span"
            ml={3}
            sx={{ cursor: 'pointer', opacity: smileHovered || mehHovered ? 0.5 : 1 }}
            role="img"
            aria-label={t('results.disappointedEmoji')}
            onClick={() => {
              // @ts-expect-error ts(2345) FIXME: Argument of type '-1' is not assignable to parameter of type 'SetStateAction<null>'.
              setRating(-1);
              popover.open();
            }}
          >
            😞
          </Text>
        </Flex>
      </Flex>
      {popover.isOpen && (
        <DiscoveryFeedbackPopover
          queryId={queryId}
          // @ts-expect-error ts(2322) FIXME: Type 'null' is not assignable to type 'number | undefined'.
          initialRating={currentRating}
          popover={popover}
        />
      )}
    </>
  );
};

const DiscoveryQueryResults = ({ queryId }) => {
  const { t } = useTranslation('supplierDiscovery');
  const panelRef = useRef();
  const currentCompanyId = useCurrentCompanyId();

  const { data, status } = usePollForDiscoveryQueryResults({
    queryId,
    // @ts-expect-error ts(2322) FIXME: Type 'string | null' is not assignable to type 'string'.
    currentCompanyId,
  });

  const showResultsTable = status === 'success' && queryId === data.queryId;

  if (!showResultsTable) {
    return null;
  }

  return (
    <>
      <MessageBlock style={{ marginBottom: 0, marginTop: 30, whiteSpace: 'pre-line' }} variant="info">
        {t('results.infoMessage')}
      </MessageBlock>
      <Panel ref={panelRef} mt={4}>
        <PanelHeader heading={t('results.heading')} icon="list-ul">
          <Button
            disabled={data?.status !== 'success'}
            small
            onClick={() => downloadCsv(`${t('results.downloadFilename')}.csv`, data?.result)}
            iconLeft="download"
            type="button"
          >
            {t('results.downloadYourResults')}
          </Button>
        </PanelHeader>
        <PanelDivider />
        {data.status === 'pending' ? (
          <PanelPadding>
            <ScrollToBottom />
            <Flex justifyContent="center" alignContent="center">
              <Loading paddingY={30} fontSize={18} loadingText="" />
            </Flex>
          </PanelPadding>
        ) : data?.status === 'success' ? (
          <>
            <ScrollToElementRef elementRef={panelRef} />
            <DiscoverySearchResultsTable data={data?.result} />

            <PanelDivider />

            <PanelPadding>
              <Feedback queryId={queryId} />
            </PanelPadding>
          </>
        ) : (
          <PanelPadding>
            <ScrollToBottom />
            <Flex justifyContent="center" alignContent="center">
              <PanelText>
                <Icon icon="exclamation-circle" mr={2} color="danger" />
                {t('results.error.queryError')}
              </PanelText>
            </Flex>
          </PanelPadding>
        )}
      </Panel>
    </>
  );
};

const SyncQueryParamsAndFormValues = ({
  queryId,
  dirty,
  pendingQueryIds,
  numSuccessfulQueries,
  setPendingQueryIds,
  setNumSuccessfulQueries,
  setValues,
  handleReset,
} : {
  queryId: string;
  dirty: boolean;
  pendingQueryIds: string[];
  numSuccessfulQueries: number;
  setPendingQueryIds: (pendingQueryIds: string[]) => void;
  setNumSuccessfulQueries: (numSuccessfulQueries: number) => void;
  handleReset: () => void;
  setValues: (values: any) => void;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const queryClient = useQueryClient();
  useWatchValue(
    queryId,
    useCallback(
      (nextQueryId, previousQueryId) => {
        // If the queryId changed then we want to reset the form values
        if (nextQueryId && previousQueryId) {
          handleReset();
        }
      },
      [handleReset],
    ),
  );

  usePollForDiscoveryQueryResults({
    queryId,
    currentCompanyId,
    pendingQueryIds,
    numSuccessfulQueries,
    setPendingQueryIds,
    setNumSuccessfulQueries,
    onQueryComplete: ({ params }) => {
      // If the form values have been edited then we don't want to overwrite the values
      if (!dirty) setValues(params);

      // Refetch the search history to include the new completed query
      queryClient.invalidateQueries(['discoveryQueryHistory', { companyId: currentCompanyId }]);
    },
    onQueryPending: ({ params }) => {
      // If the form values have been edited then we don't want to overwrite the values
      if (!dirty) setValues(params);
    },
  });

  return null;
};

export const DiscoverySearch = ({
  navigateToDiscoveryQuery,
  queryId,
}: {
  navigateToDiscoveryQuery: (queryId: string) => void;
  queryId?: string;
 }) => {
  const intercom = useIntercom();
  const { t } = useTranslation(['supplierDiscovery', 'general']);
  const api = useApi();
  const toaster = useToaster();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const popover = usePopover({});

  // Keeps track of the pending queries. We need this so that we can increase
  // `numSuccessfulQueries` when a pending query completes successfully (as opposed to fetching
  // the results of a query that was already completed).
  const [pendingQueryIds, setPendingQueryIds] = useLocalStorageState<string[]>({
    key: `${currentCompanyId}.${currentUser._id}.discovery.pendingQueryIds`,
    defaultValue: [],
  });

  // Keeps track of the number of successful queries since the last time we showed the feedback
  // popover. Resets to 0 when the feedback popover is shown.
  const [numSuccessfulQueries, setNumSuccessfulQueries] = useLocalStorageState<number>({
    key: `${currentCompanyId}.${currentUser._id}.discovery.numSuccessfulQueries`,
    defaultValue: 0,
  });

  useEffect(() => {
    if (numSuccessfulQueries > 0 && numSuccessfulQueries % SUCCESSFUL_QUERY_FEEDBACK_FREQUENCY === 0) {
      setNumSuccessfulQueries(0);
      popover.open();
    }
  }, [numSuccessfulQueries, setNumSuccessfulQueries, popover]);

  const [createDiscoveryQuery, createDiscoveryQueryResponse] = useMutation(
    api.createDiscoveryQuery,
    {
      onSuccess: callAll(
        ({ queryId }) => navigateToDiscoveryQuery(queryId),
        ({ queryId }) => setPendingQueryIds([...pendingQueryIds, queryId]),
      ),
    },
  );

  const sendIntercomEvent = useCallback(
    () => {
      intercom.trackEvent('supplier-discovery-feedback-triggered');
    },
    [intercom],
  );

  return (
    <>
      <Box>
        <Flex>
          <Box flex="0 0 270px" mr="38px">
            <DiscoverySearchHistory
              selectedQueryId={queryId}
              navigateToDiscoveryQuery={navigateToDiscoveryQuery}
            />
          </Box>
          <Box flex="1 1 auto">
            <Formik
              initialValues={{
                request: '',
                scope: '',
                similar: '',
                includedLocations: [],
                excludedSuppliers: '',
                details: '',
                prompt: '',
              }}
              validationSchema={yup.object().shape({
                request: yup.string().required(t('required', { ns: 'general' })).max(500, t('search.error.lte500Chars')),
                scope: yup.string().required(t('required', { ns: 'general' })).max(1000, t('search.error.lte1000Chars')),
                similar: yup.string().max(500, t('search.error.lte500Chars')),
                includedLocations: yup.array(yup.string()),
                excludedSuppliers: yup.string().max(500, t('search.error.lte500Chars')),
                details: yup.string().max(1000, t('search.error.detailsLte1000Chars')),
                prompt: yup.string().required(t('required', { ns: 'general' })),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const query = {
                    promptStrategyVersion: values.prompt,
                    companyId: currentCompanyId,
                    request: values.request,
                    scope: values.scope,
                    similar: values.similar,
                    excludedSuppliers: values.excludedSuppliers,
                    details: values.details,
                    includedLocations: values.includedLocations,
                  };

                  await createDiscoveryQuery(query);
                } catch (err) {
                  setSubmitting(false);
                  toaster.error(`${t('error.somethingWentWrong')}. ${(err as any).message}`);
                }
              }}
            >
              {({ handleReset, dirty, setValues }) => {
                return (
                  <Form>
                    <SyncQueryParamsAndFormValues
                      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
                      queryId={queryId}
                      setValues={setValues}
                      handleReset={handleReset}
                      dirty={dirty}
                      pendingQueryIds={pendingQueryIds}
                      setPendingQueryIds={setPendingQueryIds}
                      numSuccessfulQueries={numSuccessfulQueries}
                      setNumSuccessfulQueries={setNumSuccessfulQueries}
                    />
                    <MessageBlock variant="info" mt={0} mb={3}>
                      <Trans i18nKey="search.infoMessage" ns="supplierDiscovery">
                        {' '}
                        <InlineButton
                          type="button"
                          onClick={sendIntercomEvent}
                          data-test="discovery-feedback-button"
                        />
                        {' '}
                      </Trans>
                    </MessageBlock>
                    <Panel>
                      <PanelHeader heading={t('search.heading')}>
                        <Button
                          disabled={!dirty}
                          onClick={() => handleReset()}
                          variant="primary-outline"
                          small
                          iconLeft="refresh"
                          type="button"
                        >
                          {t('search.clearForm')}
                        </Button>
                      </PanelHeader>

                      <PanelDivider />

                      <LabelConfigProvider variant={LabelConfig.LEFT}>
                        <PanelPadding>
                          <TextField
                            label={t('search.fields.request.label')}
                            placeholder={t('search.fields.request.placeholder')}
                            name="request"
                            required
                          />
                        </PanelPadding>

                        <PanelDivider />

                        <PanelPadding>
                          <TextField
                            name="scope"
                            label={t('search.fields.scope.label')}
                            placeholder={t('search.fields.scope.placeholder')}
                            isMultiLine
                            rows={4}
                            required
                          />
                        </PanelPadding>

                        <PanelDivider />

                        <PanelPadding>
                          <LocationsField
                            name="includedLocations"
                            expandCountryCodesToNames
                          />
                        </PanelPadding>

                        <PanelDivider />

                        <PanelPadding>
                          <TextField
                            label={t('search.fields.similar.label')}
                            placeholder={t('search.fields.similar.placeholder')}
                            name="similar"
                          />
                        </PanelPadding>

                        <PanelDivider />

                        <PanelPadding>
                          <TextField
                            label={t('search.fields.excludedSuppliers.label')}
                            placeholder={t('search.fields.excludedSuppliers.placeholder')}
                            name="excludedSuppliers"
                          />
                        </PanelPadding>

                        <PanelDivider />

                        <PanelPadding>
                          <TextField
                            isMultiLine
                            rows={4}
                            label={t('search.fields.details.label')}
                            placeholder={t('search.fields.details.placeholder')}
                            name="details"
                          />
                        </PanelPadding>

                        <PanelDivider />

                        <PanelPadding>
                          <RadioField
                            required
                            name="prompt"
                            label={t('search.fields.prompt.label')}
                            options={[
                              {
                                value: PromptStrategyVersion.INITIAL,
                                label: t('search.fields.prompt.option.initial.label'),
                                description: t('search.fields.prompt.option.initial.description'),
                              },
                              {
                                value: PromptStrategyVersion.REVISED,
                                label: t('search.fields.prompt.option.revised.label'),
                                description: t('search.fields.prompt.option.revised.description'),
                              },
                            ]}
                            showError
                          />
                        </PanelPadding>
                      </LabelConfigProvider>

                      <PanelDivider />

                      <PanelPadding>
                        <Flex alignItems="flex-end" justifyContent="flex-end">
                          {createDiscoveryQueryResponse?.isLoading ? (
                            <Button disabled={true} iconLeft="search" type="submit">
                              <Text>{t('search.searching')}</Text>
                              <Spinner ml={1} />
                            </Button>
                          ) : (
                            <Button iconLeft="search" type="submit">
                              {t('search.search')}
                            </Button>
                          )}
                        </Flex>
                      </PanelPadding>
                    </Panel>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Flex>
        <DiscoveryQueryResults
          key={queryId}
          queryId={queryId}
        />
      </Box>
      {popover.isOpen && (
        <DiscoveryFeedbackPopover
          queryId={queryId}
          popover={popover}
        />
      )}
    </>
  );
};
