/** @deprecated */
export enum LegacyMultiStageLineItemsEventType {
  BANNER_SEEN = 'multiStageLineItems:bannerSeen',
  CTA_CLICKED = 'multiStageLineItems:ctaClicked',
  FEATURE_ENABLED = 'multiStageLineItems:featureEnabled',
}

export enum MultiStageLineItemsEventType {
  SUGGESTION_PANEL_SEEN = 'multiStageLineItems:suggestionPanelSeen',
  FLOW_OPENED = 'multiStageLineItems:flowOpened',
  GUIDELINES_STEP_COMPLETED = 'multiStageLineItems:guidelinesStepCompleted',
  REVIEW_STAGES_STEP_COMPLETED = 'multiStageLineItems:reviewStagesStepCompleted',
  SELECT_SECTIONS_STEP_COMPLETED = 'multiStageLineItems:selectSectionsStepCompleted',
  CONFIGURE_SECTION_STEP_COMPLETED = 'multiStageLineItems:configureSectionStepCompleted',
  ADD_SECTIONS_STEP_COMPLETED = 'multiStageLineItems:addSectionsStepCompleted',
  DELETE_SECTIONS_STEP_COMPLETED = 'multiStageLineItems:deleteSectionsStepCompleted',
  FLOW_SUBMITTED = 'multiStageLineItems:flowSubmitted',
}

export const LegacyMultiStageLineItemsEventTypes = [
  LegacyMultiStageLineItemsEventType.BANNER_SEEN,
  LegacyMultiStageLineItemsEventType.CTA_CLICKED,
  LegacyMultiStageLineItemsEventType.FEATURE_ENABLED,
] as const;

export const MultiStageLineItemsEventTypes = [
  MultiStageLineItemsEventType.SUGGESTION_PANEL_SEEN,
  MultiStageLineItemsEventType.FLOW_OPENED,
  MultiStageLineItemsEventType.GUIDELINES_STEP_COMPLETED,
  MultiStageLineItemsEventType.REVIEW_STAGES_STEP_COMPLETED,
  MultiStageLineItemsEventType.SELECT_SECTIONS_STEP_COMPLETED,
  MultiStageLineItemsEventType.CONFIGURE_SECTION_STEP_COMPLETED,
  MultiStageLineItemsEventType.ADD_SECTIONS_STEP_COMPLETED,
  MultiStageLineItemsEventType.DELETE_SECTIONS_STEP_COMPLETED,
  MultiStageLineItemsEventType.FLOW_SUBMITTED,
] as const;

export type MultiStageLineItemsTrackingEvent = {
  eventType: MultiStageLineItemsEventType | LegacyMultiStageLineItemsEventType;
  currentCompanyId: string;
  data: {
    entityType: 'request' | 'requestTemplate';
    entityId: string;
  }
};

export type MultiStageLineItemsTrackedUser = {
  _id: string,
  trackingKey: string,
  name: string,
  date: Date,
  entityId: string,
  creatorCompanyId: string,
  creatorCompanyName: string,
  requestName: string,
  proceeded?: boolean,
};
