import {
  DownshiftProps,
  GetItemPropsOptions,
  useMultipleSelection,
  useSelect,
} from 'downshift';
import { compact, isEmpty, last, noop, reject, size } from 'lodash';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Box, BoxProps, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { usePopper } from 'react-popper';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { IS_SSR } from '@deepstream/ui-utils/isSsr';
import { Chip } from '@deepstream/ui-kit/elements/Chip';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { IconValue } from '@deepstream/common';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { mergeRefs } from '@deepstream/ui-kit/elements/popup/usePopover';
import { ToggleButton as SelectToggleButton } from './Select';
import { Counter } from './Badge';
import { HorizontalDivider } from './HorizontalDivider';

const LIST_ITEM_HEIGHT = 40;

type MenuProps = {
  maxHeightInItems: number;
  zIndex?: number;
  width?: string;
  itemHeight: number;
  hasHeader?: boolean;
};

const listPaddingY = 8;

const Menu = styled.ul<MenuProps>(props => css`
  box-sizing: border-box;
  position: fixed;
  padding: ${props.hasHeader ? 0 : listPaddingY}px 0 ${listPaddingY}px;
  margin: 0;
  width: ${props.width || 165}px;
  max-height: ${(props.itemHeight || LIST_ITEM_HEIGHT) * props.maxHeightInItems + listPaddingY * 2}px;

  z-index: 2;
  overflow-y: auto;

  color: ${props.theme.colors.text};

  border-radius: 4px;
  border: ${props.theme.borders.lightGray};
  background-color: ${props.theme.colors.white};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);

  ${props.zIndex ? `
    z-index: ${props.zIndex};
  ` : ''}
`);

type ListItemProps = {
  isSelected: boolean;
  isActive: boolean;
  disabled: boolean;
  itemHeight: number;
  children: any;
};

const ListItem = styled.li<ListItemProps>(props => css`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  width: 0;
  height: ${props.itemHeight}px;
  min-width: 100%;
  max-width: 100%;
  padding: 7px 10px;

  font-family: ${props.theme.fonts.primary};
  font-size: ${props.theme.fontSizes[2]}px;

  cursor: ${props.disabled ? 'default' : 'pointer'};
  ${props.disabled ? 'opacity: 0.4;' : ''}

  ${props.isActive ? `
    background-color: ${transparentize(0.90, props.theme.colors.primary)};
  ` : ''}
`);

const Placeholder = styled(Text)`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  color: ${props => props.theme.colors.subtext};
  padding: 11px 10px;
`;

export const DropdownHeading = styled(Box)`
  padding: 9px 12px;
  color: ${props => props.theme.colors.subtext};
  letter-spacing: 0.083333em;
  font-weight: 500;
  text-transform: uppercase;
  cursor: default;
`;

type IconTextRowProps = {
  icon?: IconProps['icon'] | null;
  iconColor?: string;
  text: string | React.ReactNode;
  showCaret?: boolean;
  showIcon?: boolean;
  showDivider?: boolean;
  truncate?: boolean;
};

// This component is used to ensure layout is consistent across the button/menu
const IconTextRow: React.FC<IconTextRowProps> = ({ icon, iconColor, text, showCaret = false, showIcon = true, truncate = true }) => (
  <Flex width="100%" alignItems="center" style={{ lineHeight: 'inherit', textAlign: 'left' }}>
    {showIcon && (
      <Box width={21}>
        {!!icon && (
          <Icon icon={icon} color={iconColor} aria-hidden="true" />
        )}
      </Box>
    )}
    <Box flex={1}>
      {truncate ? <Truncate>{text}</Truncate> : text}
    </Box>
    {showCaret && (
      <Box>
        <Icon icon="caret-down" color="text" aria-hidden="true" ml={2} />
      </Box>
    )}
  </Flex>
);

type ToggleButtonProps = ButtonProps & {
  variant?: ButtonProps['variant'];
  icon?: IconProps['icon'];
  text?: React.ReactNode;
  showCaret: boolean;
  width: BoxProps['width'];
  maxWidth: BoxProps['maxWidth'];
};

// Note: We don't use the child Button's `iconLeft` prop and instead use a child
// IconTextRow component to ensure consistent layout across button and menu
const ToggleButton: React.FC<ToggleButtonProps> = React.forwardRef(({
  variant,
  icon,
  text,
  showCaret,
  width,
  maxWidth,
  ...props
}, ref) => (
  <Button variant={variant} width={width} maxWidth={maxWidth} {...props} ref={ref}>
    <IconTextRow icon={icon} text={text} showCaret={showCaret} showIcon={false} />
  </Button>
));

const moveFocus = (buttonElement, numSelectedElements, index, isOpen) => {
  try {
    if (numSelectedElements === 1) {
      // If the only selected element is removed, focus should move on the toggle button and menu should be open
      buttonElement.focus();

      if (!isOpen) {
        buttonElement.click();
      }
    } else if (!index) {
      // If the first selected element is removed, focus should move to the next element's delete button
      setTimeout(() => {
        const nextChip = buttonElement.children[0].children[index];
        const deleteButton = nextChip.getElementsByClassName('chip-close-button')[0] as HTMLDivElement;
        deleteButton.focus();
      }, 0);
    } else {
      // Otherwise, focus should move to the previous element's delete button
      const previousChip = buttonElement.children[0].children[index - 1];
      const deleteButton = previousChip.getElementsByClassName('chip-close-button')[0] as HTMLDivElement;
      deleteButton.focus();
    }
  } catch (error) {} // eslint-disable-line no-empty
};

type ToggleButtonWithChipsProps = Omit<ButtonProps, 'onChange'> & {
  selectedItems: any[];
  itemToString: any;
  isOpen?: boolean;
  getRemoveButtonProps: any;
  onChange: (selectedItems: any[]) => void;
};

const ToggleButtonWithChips = React.forwardRef(({
  placeholder,
  selectedItems,
  itemToString,
  isOpen,
  onChange,
  getRemoveButtonProps,
  ...props
}: ToggleButtonWithChipsProps, ref) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <WrapperButton
      ref={mergeRefs([buttonRef, ref])}
      sx={{
        color: 'text',
        border: 'lightGray2',
        borderRadius: '4px',
        textAlign: 'left',
        maxHeight: '84px',
        overflowY: 'auto',
      }}
      {...props}
    >
      {selectedItems?.length ? (
        <Box px={10} pt={8}>
          {selectedItems.map((item, index) => {
            const onDelete = event => {
              stopEvent(event);

              moveFocus(buttonRef.current, selectedItems.length, index, isOpen);

              if (onChange) {
                onChange(reject(selectedItems, item) as any);
              }
            };

            const onKeyDown = event => {
              if (event.key === 'Enter') {
                onDelete(event);
              }

              if (['ArrowDown', 'ArrowUp', ' '].includes(event.key)) {
                stopEvent(event);
                // Toggle the list and remove focus from the chip button
                if (buttonRef.current) {
                  buttonRef.current.focus();
                  buttonRef.current.click();
                }
              }
            };

            return (
              <Chip
                key={`${itemToString(item)}${index}`}
                removeButtonProps={getRemoveButtonProps({ item, onClick: onDelete, onKeyDown })}
              >
                {itemToString(item)}
              </Chip>
            );
          })}
        </Box>
      ) : (
        <Placeholder>{placeholder}</Placeholder>
      )}
    </WrapperButton>
  );
});

export type MultiSelectProps<T> = {
  small?: boolean;
  variant?: ButtonProps['variant'];
  items: T[];
  selectedItems?: T[];
  onChange?: (selectedItems: T[]) => void;
  onSelect?: (selectedItem: T) => void;
  onDeselect?: (selectedItem: T) => void; // there isn't a `onDeselect` in downshift, but it has the same signature as `onSelect`
  itemToString: NonNullable<DownshiftProps<T>['itemToString']>;
  renderItem?: (item: T | null) => string | React.ComponentElement<T, any>;
  buttonWidth?: React.CSSProperties['width'];
  buttonMaxWidth?: React.CSSProperties['maxWidth'];
  buttonStyle?: React.CSSProperties;
  alwaysShowCaret?: boolean;
  neverShowCaret?: boolean;
  getButtonText?: (items: T[]) => React.ReactNode;
  getButtonIcon?: (items: T[]) => string;
  disabled?: boolean;
  menuMaxHeightInItems?: number;
  menuZIndex?: number;
  menuWidth?: number | string;
  truncate?: boolean;
  withChips?: boolean;
  withRegularButton?: boolean;
  withSelectButton?: boolean; // Use the ToggleButton from the Select component
  placeholder?: string;
  rightAligned?: boolean;
  isItemDisabled?: (item: T) => boolean;
  renderPreItemContent?: (item: T, index: number) => React.ReactNode;
  itemHeight?: number;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  selectedIcon?: IconValue;
};

export const MultiSelect = <T,>({
  small,
  selectedItems: propsSelectedItems,
  variant,
  buttonWidth,
  buttonMaxWidth,
  buttonStyle,
  alwaysShowCaret,
  neverShowCaret,
  getButtonText,
  getButtonIcon,
  itemToString,
  renderItem = itemToString,
  items,
  onChange = noop,
  onSelect = noop,
  onDeselect = noop,
  disabled,
  menuMaxHeightInItems = 7.6,
  menuZIndex = 0,
  menuWidth = 165,
  truncate = true,
  withChips,
  withRegularButton,
  withSelectButton,
  placeholder,
  rightAligned,
  isItemDisabled,
  renderPreItemContent,
  itemHeight = LIST_ITEM_HEIGHT,
  header,
  footer,
  selectedIcon = 'check',
}: MultiSelectProps<T>) => {
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement>();
  const [popperElement, setPopperElement] = React.useState<HTMLElement>();
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: rightAligned ? 'bottom-end' : 'bottom-start',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 2],
      },
    }],
  });

  // the length can affect the width of the button
  // (for example when renering a counter in the
  // HideColumnsSelectDropdownMenu); updating the
  // popper when the length changes makes sure that the
  // popup stays aligned with the edge of the button
  useWatchValue(
    propsSelectedItems?.length,
    () => update?.(),
  );

  const {
    getDropdownProps,
    removeSelectedItem,
    addSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    itemToKey: itemToString,
    selectedItems: propsSelectedItems,
    onStateChange: ({ selectedItems: newSelectedItems }) => {
      onChange(newSelectedItems || []);
    },
  });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    itemToString,
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            inputValue: '',
            highlightedIndex: state.highlightedIndex,
            isOpen: true, // keep the menu open after selection.
          };
        default:
          return changes;
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      const isItemSelected = selectedItems.includes(selectedItem);
      if (isItemSelected) {
        removeSelectedItem(selectedItem);
        onDeselect?.(selectedItem);
      } else {
        addSelectedItem(selectedItem);
        onSelect?.(selectedItem);
      }
    },
    initialSelectedItem: null,
  });

  const getRemoveButtonProps = React.useCallback(({ onClick, onKeyDown, item, ...props }: any = {}) => ({
    onClick: (event: React.MouseEvent) => {
      // TODO: use something like downshift's composeEventHandlers utility instead
      if (onClick) onClick(event);
      event.stopPropagation();
      removeSelectedItem(item);
    },
    onKeyDown: (event: React.KeyboardEvent) => {
      if (onKeyDown) onKeyDown(event);

      if (event.key === 'Enter') {
        event.stopPropagation();
        removeSelectedItem(item);
      }
    },
    ...props,
  }), [removeSelectedItem]);

  const toggleButtonProps = React.useMemo<any>(() => {
    const commonOptions = {
      type: 'button',
      disabled,
      style: buttonStyle,
      onClick(event: any) {
        if (update) update();
        event.stopPropagation();
      },
      // TODO: maybe translate this? It wasn't previously (before react 18) either.
      ariaLabel: isOpen ? 'close menu' : 'open menu',
    };

    const buttonOptions = withChips ? {
      ...commonOptions,
      selectedItems: selectedItems || [],
      itemToString,
      getRemoveButtonProps,
      onChange: () => {
      },
    } : withRegularButton ? {
      ...commonOptions,
      iconLeft: getButtonIcon && getButtonIcon(selectedItems) as IconProps['icon'],
      iconRight: neverShowCaret
        ? undefined
        : alwaysShowCaret || !isEmpty(selectedItems) ? 'caret-down' : undefined,
    } : withSelectButton ? {
      ...commonOptions,
      style: {
        fontSize: small ? '12px' : '14px',
        minHeight: small ? '28px' : '40px',
        lineHeight: 1.5,
        width: buttonWidth,
        ...buttonStyle,
      },
    } : {
      ...commonOptions,
      showCaret: neverShowCaret
        ? undefined
        : alwaysShowCaret || !isEmpty(selectedItems),
      width: buttonWidth,
      icon: getButtonIcon ? getButtonIcon(selectedItems) as IconProps['icon'] : 'minus',
      text: getButtonText ? getButtonText(selectedItems) : '-',
      maxWidth: buttonMaxWidth,
    };

    return getToggleButtonProps(getDropdownProps(buttonOptions as any));
  }, [
    getDropdownProps,
    neverShowCaret,
    alwaysShowCaret,
    isOpen,
    buttonMaxWidth,
    buttonStyle,
    buttonWidth,
    disabled,
    getButtonIcon,
    getButtonText,
    getRemoveButtonProps,
    getToggleButtonProps,
    itemToString,
    selectedItems,
    small,
    update,
    withChips,
    withRegularButton,
    withSelectButton,
  ]);

  const toggleMenuProps = React.useMemo(() => {
    return getMenuProps({
      hidden: !isOpen,
      ref: setPopperElement as any,
      style: styles.popper,
      ...attributes.popper,
    });
  }, [attributes.popper, getMenuProps, isOpen, styles.popper]);

  if (IS_SSR) {
    return null;
  }

  return (
    <Box style={{ position: 'relative' }} ref={setReferenceElement}>
      {withChips ? (
        <ToggleButtonWithChips
          {...toggleButtonProps}
          selectedItems={selectedItems || []}
          placeholder={placeholder}
          itemToString={itemToString}
          width={buttonWidth}
          maxWidth={buttonMaxWidth}
          isOpen={isOpen}
          onChange={(selectedItems) => onChange(selectedItems)}
          getRemoveButtonProps={getRemoveButtonProps}
        />
      ) : withRegularButton ? (
        <Button
          small={small}
          variant={variant}
          mb="2px"
          width={buttonWidth}
          maxWidth={buttonMaxWidth}
          {...toggleButtonProps}
        >
          {getButtonText && getButtonText(selectedItems)}
        </Button>
      ) : withSelectButton ? (
        <SelectToggleButton
          {...toggleButtonProps}
        >
          <Flex alignItems="center" width="100%">
            {getButtonIcon && <Icon icon={getButtonIcon(selectedItems) as IconProps['icon']} />}
            <Text flex={1}>
              {getButtonText && getButtonText(selectedItems)}
            </Text>
            {alwaysShowCaret || !isEmpty(selectedItems) ? <Icon icon="caret-down" /> : null}
          </Flex>
        </SelectToggleButton>
      ) : (
        <ToggleButton
          small={small}
          variant={variant}
          sx={selectedItems.length ? undefined : { color: 'subtext' }}
          lineHeight={1.5}
          {...toggleButtonProps}
        />
      )}
      {ReactDOM.createPortal(
        <Menu
          {...toggleMenuProps}
          zIndex={menuZIndex}
          maxHeightInItems={menuMaxHeightInItems}
          width={String(menuWidth)}
          itemHeight={itemHeight}
          hasHeader={Boolean(header)}
        >
          {header && (
            <Box sx={{ position: 'sticky', top: 0, pt: '8px', background: 'white', zIndex: 152 }}>
              {header}
              <HorizontalDivider />
            </Box>
          )}
          {items.map((item, index) => (
            <React.Fragment key={`${itemToString(item)}${index}`}>
              {renderPreItemContent?.(item, index)}
              <ListItem
                {...getItemProps({
                  item,
                  index,
                } as GetItemPropsOptions<any>)}
                ref={null}
                as="li"
                disabled={!!isItemDisabled?.(item)}
                itemHeight={itemHeight}
                isSelected={selectedItems.includes(item)}
                isActive={highlightedIndex === index}
              >
                <span>
                  <IconTextRow
                    icon={selectedItems.includes(item) ? selectedIcon : null}
                    iconColor="primary"
                    text={renderItem(item)}
                    truncate={truncate}
                  />
                </span>
              </ListItem>
            </React.Fragment>
          ))}
          {footer && (
            <Box>
              <HorizontalDivider />
              {footer}
            </Box>
          )}
        </Menu>,
        document.body!,
        'menu',
      )}
    </Box>
  );
};

export type SelectDropdownMenuProps<T extends Record<string, unknown>> = {
  buttonText?: React.ReactNode;
  buttonIcon?: IconProps['icon'];
  multi?: boolean;
  disabled?: boolean;
  onChange: (selectedItems: T[]) => void;
  allowEmptySelection?: boolean;
  highlightIncompleteSelection?: boolean;
} & Omit<MultiSelectProps<T>, 'onChange'>;

export const SelectDropdownMenu = <T extends Record<string, unknown>>({
  buttonText,
  buttonIcon,
  disabled,
  multi = false,
  onChange,
  allowEmptySelection = false,
  highlightIncompleteSelection = false,
  ...props
}: SelectDropdownMenuProps<T>) => {
  const handleChange = React.useCallback((selectedItems: T[] | null) => {
    if (!selectedItems) {
      return;
    }

    if (multi) {
      onChange(selectedItems);
    } else {
      const lastSelectedItem = last(selectedItems);

      if (!lastSelectedItem && !allowEmptySelection && props.selectedItems) {
        onChange([...props.selectedItems]);
      } else {
        onChange(compact([lastSelectedItem]));
      }
    }
  }, [allowEmptySelection, multi, onChange, props.selectedItems]);

  const variant = (
    !multi ||
    highlightIncompleteSelection
      ? size(props.selectedItems) === size(props.items)
      : isEmpty(props.selectedItems)
  )
    ? 'secondary-outline'
    : 'primary-outline';

  return (
    <MultiSelect
      onChange={handleChange}
      // keep disabled as long as no items have been loaded
      disabled={disabled || isEmpty(props.items)}
      getButtonText={buttonText ? () => buttonText : undefined}
      getButtonIcon={buttonIcon ? () => buttonIcon : undefined}
      variant={variant}
      small
      buttonWidth="100%"
      buttonStyle={{ marginBottom: 0 }}
      alwaysShowCaret
      withRegularButton
      menuMaxHeightInItems={8.6}
      {...props}
    />
  );
};

export const HideColumnsSelectDropdownMenu = <T extends Record<string, unknown>>({
  buttonText,
  disabled,
  onChange,
  onResetClick,
  isResetButtonDisabled,
  ...props
}: {
  buttonText?: React.ReactNode;
  disabled?: boolean;
  onResetClick?: () => void;
  isResetButtonDisabled?: boolean;
  onChange: (selectedItems: T[]) => void;
} & Omit<MultiSelectProps<T>, 'onChange' | 'multi'>) => {
  const { t } = useTranslation();

  const handleChange = React.useCallback((selectedItems: T[] | null) => {
    if (!selectedItems) {
      return;
    }

    onChange(selectedItems);
  }, [onChange]);

  return (
    <MultiSelect
      onChange={handleChange}
      // keep disabled as long as no items have been loaded
      disabled={disabled || isEmpty(props.items)}
      variant="secondary-outline"
      small
      buttonWidth="100%"
      buttonStyle={{ marginBottom: 0 }}
      alwaysShowCaret
      withRegularButton
      menuMaxHeightInItems={8.6}
      selectedIcon="eye"
      isItemDisabled={(item: any) => item.type === 'heading'}
      getButtonText={items => {
        const totalItemCount = size(props.items);
        const visibleItemCount = size(items);
        const hiddenItemCount = totalItemCount - visibleItemCount;

        return (
          <>
            <Icon icon="eye-slash" solid mr={1} />
            {buttonText}
            <Counter color="primary" count={hiddenItemCount} ml={1} />
          </>
        );
      }}
      header={(
        <Flex p="10px" justifyContent="space-between" alignItems="center" sx={{ gap: 2 }}>
          <Button
            iconLeft="eye"
            variant="secondary-outline"
            small
            flex={1}
            onClick={() => handleChange(props.items)}
            disabled={size(props.selectedItems) === size(props.items)}
          >
            {t('general.showAll')}
          </Button>
          <Button
            iconLeft="eye-slash"
            variant="secondary-outline"
            small
            flex={1}
            onClick={() => handleChange([])}
            disabled={size(props.selectedItems) === 0}
          >
            {t('general.hideAll')}
          </Button>
          {onResetClick && (
            <Button
              iconLeft="undo"
              variant="secondary-outline"
              small
              flex={1}
              onClick={onResetClick}
              disabled={isResetButtonDisabled}
            >
              {t('general.defaults')}
            </Button>
          )}
        </Flex>
      )}
      {...props}
    />
  );
};
