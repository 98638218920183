import * as React from 'react';

export const TableHoveredRowContext = React.createContext<{
  hoveredRowIndex: number | null;
  setHoveredRowIndex: React.Dispatch<React.SetStateAction<number>> | null;
}>({ hoveredRowIndex: null, setHoveredRowIndex: null });

export const TableHoveredRowProvider = ({ children } : { children: React.ReactNode; }) => {
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState<number | null>(null);

  const value = React.useMemo(() => ({ hoveredRowIndex, setHoveredRowIndex }), [
    hoveredRowIndex,
    setHoveredRowIndex,
  ]);

  return (
    <TableHoveredRowContext.Provider
      // @ts-expect-error ts(2322) FIXME: Type '{ hoveredRowIndex: number | null; setHoveredRowIndex: React.Dispatch<React.SetStateAction<number | null>>; }' is not assignable to type '{ hoveredRowIndex: number | null; setHoveredRowIndex: Dispatch<SetStateAction<number>> | null; }'.
      value={value}
    >
      {children}
    </TableHoveredRowContext.Provider>
  );
};

export const useHoveredTableRow = () => {
  const hoveredRowContext = React.useContext(TableHoveredRowContext);

  if (!hoveredRowContext) {
    throw new Error('no TableHoveredRowContext has been found');
  }

  return hoveredRowContext;
};
