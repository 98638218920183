import { isArray, mapValues, some, omit, intersection, without } from 'lodash';
import type { TFunction } from 'i18next';
import { getFormattedFieldLabel, isDefinitionField, isReplyField } from './fields';
import { FieldConfig, LineItemExchangeDefinition, LineItemExchangeFields, LineItemsSection, ResponseTag, StageResponseTag } from './types';
import { getDefaultFieldTags } from './section';
import { FieldType } from '../exchangesConfig';

/**
 * Returns the set of basic, immutable and non-optional line item field
 * configurations, which are part of every line item exchangeDef's `fields`.
 */
export const getBasicLineItemFields = (responseTagConfig?: LineItemsSection['responseTagConfig']) => ({
  description: {
    _id: 'description',
    type: FieldType.STRING,
    required: true,
    source: {
      type: 'definition',
      key: 'description',
    },
  },
  unit: {
    _id: 'unit',
    type: FieldType.STRING,
    required: true,
    source: {
      type: 'definition',
      key: 'unit',
    },
  },
  quantity: {
    _id: 'quantity',
    type: FieldType.NUMBER,
    required: true,
    source: {
      type: 'definition',
      key: 'quantity',
    },
  },
  currency: {
    _id: 'currency',
    type: FieldType.STRING,
    required: true,
    source: {
      type: 'parent-section-currency',
    },
  },
  totalCost: {
    _id: 'totalCost',
    type: FieldType.PRICE,
    required: true,
    source: {
      type: 'formula',
      formula: '{quantity} * {price}',
    },
  },
  price: {
    _id: 'price',
    type: FieldType.PRICE,
    required: true,
    ...(
      responseTagConfig
        ? { responseTags: getDefaultFieldTags(responseTagConfig, FieldType.PRICE) }
        : undefined
    ),
    source: {
      type: 'reply',
      role: 'submitter',
      key: 'price',
    },
    decimalPlaces: 2,
  },
} as const);

export const basicLineItemFieldIds = Object.values(getBasicLineItemFields())
  .map(field => field._id);

export const getTotalCostFormula = (fields: LineItemExchangeDefinition['fields']): string => {
  const { totalCost } = fields;

  if (!totalCost) {
    return '';
  }

  return totalCost.source.type === 'formula' ? totalCost.source.formula : '';
};

export const getAdjacentFieldId = (fieldId: string) => {
  const [fieldsetId] = fieldId.split(':');
  const ADJACENT_ROLE_MAP = {
    [`${fieldsetId}:submitter`]: `${fieldsetId}:evaluator`,
    [`${fieldsetId}:evaluator`]: `${fieldsetId}:submitter`,
    'unit': 'unit:submitter',
    'unit:submitter': 'unit',
    'quantity': 'quantity:submitter',
    'quantity:submitter': 'quantity',
  };

  return ADJACENT_ROLE_MAP[fieldId];
};

export const getAdjacentConfigurableField = (
  exchangeDefOrDefs: LineItemExchangeDefinition[] | LineItemExchangeDefinition,
  fieldId: string,
) => {
  const exchangeDefFields = isArray(exchangeDefOrDefs)
    ? exchangeDefOrDefs[0]?.fields
    : exchangeDefOrDefs.fields;

  const adjacentFieldId = getAdjacentFieldId(fieldId);

  return exchangeDefFields[adjacentFieldId];
};

export const getSupplierExchangeDefFields = (buyerExchangeDefFields: LineItemExchangeFields) => {
  return mapValues(
    buyerExchangeDefFields,
    field => {
      if (
        isDefinitionField(field!) &&
        !(basicLineItemFieldIds as string[]).includes(field._id) &&
        field._id !== 'evaluatorFieldCurrency'
      ) {
        return {
          ...omit(field, 'responseTags'),
          source: {
            type: 'reply',
            role: 'evaluator',
            key: field.source.key,
          },
        };
      } else if (isReplyField(field!) && field.responseTags) {
        return omit(field, 'responseTags');
      } else {
        return field;
      }
    },
  );
};

export const getFormattedFormula = (fields: LineItemExchangeFields | undefined, formulaField: string, t: TFunction) => {
  if (fields?.[formulaField]?.source.type !== 'formula') {
    return '';
  }

  const replaceValue = (_: string, fieldId: string) => {
    const field = fields[fieldId];

    if (!field) {
      return `[${t('request.lineItems.invalidField', { ns: 'translation' })}]`;
    }

    return getFormattedFieldLabel(field, t);
  };

  return fields[formulaField].source.formula
    .replace(/\s+/g, ' ')
    .replace(/\{([^}]+)\}/g, replaceValue as (substring: string, ...args: any[]) => string)
    .replace(/([{}])/g, '');
};

const fieldsNotToBeAddedToFormula = ['targetPrice'];
const fieldTypesAllowedInFormula = ['number', 'price', 'formula'];

/**
 * Determines whether the provided field can be used when
 * calculating a formula.
 */
export const canIncludeFieldInFormula = (field) => (
  fieldTypesAllowedInFormula.includes(field.type)
);

/**
 * Determines whether the provided field can be added
 * to a formula by a buyer user in the UI.
 */
export const canAddFieldToFormula = (field) => (
  !fieldsNotToBeAddedToFormula.includes(field._id) &&
  fieldTypesAllowedInFormula.includes(field.type)
);

export const hasSupplierPriceField = (fields?: Record<string, FieldConfig> | FieldConfig[]) => {
  return some(fields, (field: FieldConfig) => {
    return field.type === 'price' && (
      field.source.type === 'formula' || (field.source.type === 'reply' && field.source.role === 'submitter')
    );
  });
};

export const isStageResponseTag = (tag: ResponseTag): tag is StageResponseTag =>
  tag.startsWith('stage:');

export const getTagFromStageId = (stageId: string): StageResponseTag => `stage:${stageId}`;

export const getStageIdFromTag = (tag: ResponseTag) => {
  const [prefix, stageId] = tag.split(':');

  if (prefix !== 'stage') {
    throw new Error('Tag is not a stage tag');
  }

  return stageId;
};

/**
 * Determines whether replies to a specific exchange field should be disabled according
 * to the exchange's current response tag and the field's response tags.
 *
 * Returns true when there's no information about response tags.
 */
export const isFieldDisabled = (field: { responseTags?: ResponseTag[] }, exchange: { responseTag?: ResponseTag | null }) =>
  exchange.responseTag && field.responseTags && !field.responseTags.includes(exchange.responseTag);

/**
 * fieldIds to render first by default (if present; in order)
 */
const leadingFieldIds = [
  'description',
  'unit',
  'unit:submitter',
  'quantity',
  'quantity:submitter',
  'price',
  'totalCost',
  'targetPrice',
  'leadTime:submitter',
];

/**
 * fieldIds to render last by default (if present; in order)
 */
const trailingFieldIds = [
  'unspscCode',
];

/**
 * Returns the fieldIds of a line items section in the order in which
 * they should get displayed by default.
 */
export const getFieldIdsInDefaultDisplayOrder = (columnIds: string[]) => {
  const startIds = intersection(leadingFieldIds, columnIds);
  const endIds = intersection(trailingFieldIds, columnIds);
  const otherIds = without(columnIds, ...startIds, ...endIds);

  return [
    ...startIds,
    ...otherIds,
    ...endIds,
  ];
};
