import { useMemo } from 'react';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { EntityTeamMembership } from '@deepstream/common/user-utils';
import { groupBy, isEmpty, mapValues } from 'lodash';
import { Bold } from '../../../Bold';
import { List, ListContainer } from './List';

const ListItem = ({
  item,
  showType,
  count,
}: {
  item: EntityTeamMembership;
  showType?: boolean;
  count?: number;
}) => {
  const { t } = useTranslation(['translation', 'contracts', 'preQualification']);

  const typeLabel = item.type === 'request'
    ? t('requests.request')
    : item.type === 'contract'
      ? t('general.contract', { count: 1 })
      : t('questionnaire', { count: 1, ns: 'preQualification' });

  return (
    <Text as="li" mb={1}>
      {showType && (
        <>
          [{typeLabel}]
          {' '}
        </>
      )}
      <Bold>
        {item.subject ? (
          item.subject
        ) : item.type === 'request' ? (
          t('requests.untitledRequest')
        ) : item.type === 'contract' ? (
          t('untitledContract', { ns: 'contracts' })
        ) : (
          null
        )}
      </Bold>
      {' '}
      ({item.isSender ? (
        count ? (
          `${t('requests.sent')}, ${t('questionnaire.supplierCount', { count, ns: 'preQualification' })}`
        ) : (
          t('requests.sent')
        )
      ) : (
        t('requests.received')
      )})
    </Text>
  );
};

export const EntityTeamMembershipList = ({
  items,
  height,
  showType,
  noDataPlaceholder,
}: {
  items: EntityTeamMembership[];
  height?: string;
  showType?: boolean;
  noDataPlaceholder?: string;
}) => {
  const questionnaireCountByTemplateId = useMemo(
    () => mapValues(
      groupBy(items, item => item.fromQuestionnaireTemplateId),
      items => items.length,
    ),
    [items],
  );

  // Sent questionnaires are grouped by template ID and are displayed as a single item.
  const groupedItems = useMemo(
    () => {
      const groupedItems: EntityTeamMembership[] = [];

      for (const item of items) {
        if (item.type !== 'questionnaire' || !item.isSender) {
          groupedItems.push(item);
        }

        const hasBeenAdded = groupedItems.some(addedItem => (
          addedItem.fromQuestionnaireTemplateId === item.fromQuestionnaireTemplateId
        ));

        if (!hasBeenAdded) {
          groupedItems.push(item);
        }
      }

      return groupedItems;
    },
    [items],
  );

  return (
    <ListContainer height={height}>
      {isEmpty(items) && noDataPlaceholder ? (
        noDataPlaceholder
      ) : (
        <List>
          {groupedItems.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              showType={showType}
              count={item.type === 'questionnaire' && item.isSender
                // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
                ? questionnaireCountByTemplateId[item.fromQuestionnaireTemplateId]
                : undefined}
            />
          ))}
        </List>
      )}
    </ListContainer>
  );
};
