import { UserFlagsContext } from '@deepstream/ui/UserFlagsContext';
import { useCurrentCompanyId } from '@deepstream/ui/currentCompanyId';
import { useCurrentUser } from '@deepstream/ui/useCurrentUser';
import { find } from 'lodash';
import { useMemo } from 'react';

export const AdminUserFlagsProvider = (props: { children: React.ReactNode }) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();

  const value = useMemo(() => {
    const userRoles = find((user as any)?.companyRoles, { _id: currentCompanyId });

    return {
      belongsToSupplierOnlyCompany: false,
      belongsToPayingCompany: !!userRoles?.subscription,
      hasSendRfqPermission: !!userRoles?.sendRFQ,
      hasSendContractPermission: !!userRoles?.sendContracts,
      hasManagePreQualPermission: !!userRoles?.managePreQual,
      hasSendQuestionnairePermission: !!userRoles?.sendQuestionnaires,
      status: 'success' as const,
    };
  }, [user, currentCompanyId]);

  return (
    <UserFlagsContext.Provider value={value} {...props} />
  );
};
