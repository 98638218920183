import * as React from 'react';
import { useQuery } from 'react-query';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { PanelLoadingWrapper } from '@deepstream/ui/ui/Loading';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Company } from '@deepstream/ui/ui/types';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CompanyRequestsTable } from './CompanyRequestsTable';
import * as Title from '../../title';
import { CompanyTemplatesTable } from './CompanyTemplatesTable';

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

export const CompanyRequests = ({ company }: { company: Company }) => {
  const adminApi = useAdminApi();

  const companyId = company._id;

  const { data: requests, status: requestsStatus } = useQuery(
    ['requests', { companyId }],
    wrap(adminApi.getCompanyRequests),
  );

  const { data: templates, status: templatesStatus } = useQuery(
    ['templates', { companyId }],
    wrap(adminApi.getCompanyTemplates),
  );

  return (
    <Stack gap={4}>
      <Panel heading="Templates">
        <PanelLoadingWrapper status={templatesStatus} data={templates} emptyDataMessage="No templates">
          <CompanyTemplatesTable templates={templates} />
        </PanelLoadingWrapper>
      </Panel>
      <Panel heading="Requests">
        <PanelLoadingWrapper status={requestsStatus} data={requests} emptyDataMessage="No requests yet">
          <CompanyRequestsTable companyId={company._id} requests={requests} />
        </PanelLoadingWrapper>
      </Panel>
    </Stack>
  );
};
