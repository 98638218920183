import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { findIndex, first, isEmpty, isFinite, isNil, isNumber, isString, map, size, without } from 'lodash';
import { Box, Flex, Text, TextProps } from 'rebass/styled-components';
import {
  ExchangeDefinition,
  Lock,
  QuestionExchangeDefinition,
  QuestionType,
  OptionQuestionExchangeDefinition,
  isOptionBasedQuestion,
  isAddressQuestion,
  isDateTimeQuestion,
  CompanyMinimized,
  AnyScope,
  QuestionFormat,
  Draft,
  LineItemExchangeDefinition,
  isLinkedAuctionLineItemExchangeDef,
  getOrderedAddressFields,
  getExchangeFieldValue,
  Requirement,
  isGridQuestion,
  isYesNoQuestion,
  isDocumentRequestExchange,
  getExchangeTypeFromRequirement,
  lockableExchangeTypes,
  getExchangeTypeFromLock,
} from '@deepstream/common/rfq-utils';
import { localeFormatFactorAsPercent, localeFormatNumber } from '@deepstream/utils';
import { useField } from 'formik';
import { informationRequestExchangeTypes } from '@deepstream/common/exchangesConfig';
import { Icon, ObsoleteIcon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate, truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { StyledCheckbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { useIntercom } from 'react-use-intercom';
import * as rfx from '../rfx';
import { getDocumentExchangeDescriptionFromFile } from '../utils';
import { NumberFormat } from '../NumberFormat';
import { TextField, TextFieldBase } from '../form/TextField';
import { formatOptions } from '../ui/formatOptions';
import { FileField } from '../form/FilesField';
import { SelectField, SelectFieldBase } from '../form/SelectField';
import { useModalState } from '../ui/useModalState';
import { LockModal } from '../LockModal';
import { DisabledInputBox } from '../ui/Input';
import { LockedTooltip } from '../LockedTooltip';
import { ExchangeDefActions } from './ExchangeDefActions';
import { StyledRadio } from '../ui/Radio';
import { ErrorMessage } from '../ui/ErrorMessage';
import { CompanyLogo } from '../CompanyLogo';
import { StageBadge } from '../ui/Badge';
import { useCellValidationError } from './useCellValidationError';
import { useError } from './validation';
import { CurrencyAmount, CurrencyAmountInCurrency } from '../ui/Currency';
import { useCurrentUserLocale } from '../useCurrentUser';
import { useHooks } from '../useHooks';
import { useStageSelectItems } from './useStageSelectItems';

export const ExchangeDefNumberCell = ({ cell: { value: exchangeNumber } }) => (
  <>
    {rfx.useSectionWithPosition().number}.{exchangeNumber}
  </>
);

export const ExchangeDefDescriptionCell = ({
  cell: { value: description },
  row: { original: exchangeDef },
}) => {
  const { t } = useTranslation();

  const obsoleteIcon = exchangeDef.isObsolete
    ? (
      <Tooltip content={t('general.obsolete') as string}>
        <ObsoleteIcon mr={2} />
      </Tooltip>
    ) : null;

  return (
    description ? (
      <Truncate>
        {obsoleteIcon}
        {description}
      </Truncate>
    ) : (
      <Truncate color="subtext">
        {obsoleteIcon}
        <EmDash />
      </Truncate>
    )
  );
};

export const AuctionExchangeDefDescriptionCell = ({
  cell: { value },
  row: { original: exchangeDef },
}) => {
  const { t } = useTranslation();
  const { stages, exchangeDefById } = rfx.useStructure<Draft>();

  const rfxLineItemExchangeDef = isLinkedAuctionLineItemExchangeDef(exchangeDef)
    ? exchangeDefById[exchangeDef.linkedExchangeDefId] as LineItemExchangeDefinition | undefined
    : null;

  const rfxLineItemStageId = rfxLineItemExchangeDef
    ? rfxLineItemExchangeDef.stages?.[0]
    : null;

  const rfxLineItemStageIndex = rfxLineItemStageId
    ? findIndex(stages, stage => stage._id === rfxLineItemStageId)
    : -1;

  return (
    <Truncate>
      {exchangeDef.isObsolete ? (
        <Tooltip content={t('general.obsolete') as string}>
          <ObsoleteIcon mr={2} marginBottom="2px" />
        </Tooltip>
      ) : (
        null
      )}
      {rfxLineItemStageIndex > -1 ? (
        <StageBadge index={rfxLineItemStageIndex} />
      ) : (
        null
      )}
      {(value) || <EmDash />}
    </Truncate>
  );
};

export const NumberCell = ({
  row: { original: exchange },
  cell: { value },
  column: { EmptyCell = EmDash as React.FC<any>, ...column },
}) => {
  const options = isString(column.format)
    ? formatOptions[column?.format]
    : { ...column.format };

  return isNumber(value) && !Number.isNaN(value) ? (
    <Truncate>
      <NumberFormat displayType="text" thousandSeparator value={value} {...options} />
    </Truncate>
  ) : (
    <EmptyCell exchange={exchange} />
  );
};

export const NumberCell2 = ({
  row: { original: exchange },
  cell: { value },
  column: { EmptyCell = EmDash as React.FC<any>, showTooltip, textAlign, ...column },
}) => {
  const locale = useCurrentUserLocale();

  if (isFinite(value)) {
    const suffix = column.format?.suffix;
    const formattedValue = localeFormatNumber(value, { locale }) + (suffix ? ` ${suffix}` : '');

    return (
      <Tooltip content={showTooltip ? formattedValue : null}>
        <Truncate
          sx={{ width: '100%', textAlign }}
        >
          {formattedValue}
        </Truncate>
      </Tooltip>
    );
  } else {
    return (
      <EmptyCell exchange={exchange} showTooltip={showTooltip} />
    );
  }
};

export const NumberFieldCell = ({ row, column, fieldName = 'exchangeDefs' }) => (
  <TextField
    required
    hideLabel
    disabled={row.original.isObsolete}
    name={`[${fieldName}[${row.index}].${column.id}`}
    label={column.label}
    format={column.format}
    inputStyle={{ textAlign: 'right' }}
  />
);

export const AuctionNumberFieldCell = ({ row, column, cell, fieldName = 'exchangeDefs' }) => {
  const { t } = useTranslation();
  const { linkedExchangeDefId } = row.original;

  return linkedExchangeDefId ? (
    <Tooltip
      content={t('request.auction.youCantMakeChanges') as string}
      placement="top"
    >
      <DisabledInputBox
        sx={{ ...truncateStyle, cursor: 'not-allowed', textAlign: 'right' }}
      >
        {cell.value}
      </DisabledInputBox>
    </Tooltip>
  ) : (
    <TextField
      required
      hideLabel
      disabled={row.original.isObsolete}
      name={`[${fieldName}[${row.index}].${column.id}`}
      label={column.label}
      format={column.format}
      inputStyle={{ textAlign: 'right' }}
    />
  );
};

export const ObsoleteCell = ({ cell: { value }, row: { original: def } }) => (
  <Box style={{ opacity: def.isObsolete ? 0.4 : undefined }}>
    {value}
  </Box>
);

export const AlwaysObsoleteCell = ({ cell: { value }, row: { original: def } }) => (
  <Box style={{ opacity: 0.4 }}>
    {value}
  </Box>
);

export const AlwaysObsoleteCellWithIcon = ({ cell: { value }, row: { original: def } }) => (
  <Flex sx={{ opacity: 0.4, gap: 2 }} alignItems="center">
    <ObsoleteIcon display="inline" /> {value}
  </Flex>
);

export const DisabledCell = ({ cell: { value } }) => (
  <Text color="subtext">
    {value}
  </Text>
);

export const PercentCell = ({ cell: { value }, column: { showTooltip, truncate } }) => {
  const locale = useCurrentUserLocale();
  const formattedValue = localeFormatFactorAsPercent(value, { locale });
  const Wrapper = truncate ? Truncate : Text;

  return (
    <Tooltip content={showTooltip ? formattedValue : null}>
      <Wrapper>
        {formattedValue}
      </Wrapper>
    </Tooltip>
  );
};

export const PercentFieldCell = ({ cell: { value: percent }, column }) => {
  const locale = useCurrentUserLocale();
  return (
    <TextFieldBase
      disabled
      hideLabel
      label={column.label}
      value={localeFormatFactorAsPercent(percent, { locale })}
      inputStyle={{ textAlign: 'right' }}
    />
  );
};

/**
 * Contains a menu with the actions for each exchange definition row
 */
export const ExchangeDefActionsCell = ({
  row: { index },
  fieldName = 'exchangeDefs',
  allowObsoleteStateChange,
}: CellProps<ExchangeDefinition>) => (
  <ExchangeDefActions
    index={index}
    fieldName={fieldName}
    allowObsoleteStateChange={allowObsoleteStateChange}
  />
);

export const ExchangeDefDescriptionFieldCell = ({ row, column, fieldName = 'exchangeDefs' }) => {
  const { t } = useTranslation();
  const section = rfx.useSectionWithPosition();
  const [{ value: description }] = useField(`${fieldName}[${row.index}].${column.id}`);
  const { isObsolete } = row.original;

  const numbering = (
    <Box style={{ whiteSpace: 'nowrap' }}>
      {isObsolete ? <ObsoleteIcon display="inline" /> : null}
      {' '}
      {section.index + 1}.{row.index + 1}
    </Box>
  );

  return isObsolete && column.hideNumbering ? (
    <DisabledInputBox sx={truncateStyle}>
      <Tooltip content={t('general.obsolete') as string}>
        <ObsoleteIcon mr={2} />
      </Tooltip>
      {description}
    </DisabledInputBox>
  ) : (
    <TextField
      required
      hideLabel
      name={`${fieldName}[${row.index}].${column.id}`}
      label={column.label}
      disabled={isObsolete}
      prefix={column.hideNumbering ? undefined : numbering}
    />
  );
};

export const AuctionExchangeDefDescriptionFieldCell = ({ row, column, cell, fieldName = 'exchangeDefs' }) => {
  const { t } = useTranslation();
  const { stages, exchangeDefById } = rfx.useStructure<Draft>();
  const { linkedExchangeDefId, isObsolete } = row.original;

  const rfxLineItemExchangeDef = linkedExchangeDefId
    ? exchangeDefById[linkedExchangeDefId] as LineItemExchangeDefinition | undefined
    : null;

  const rfxLineItemStageId = rfxLineItemExchangeDef
    ? rfxLineItemExchangeDef.stages?.[0]
    : null;

  const rfxLineItemStageIndex = rfxLineItemStageId
    ? findIndex(stages, stage => stage._id === rfxLineItemStageId)
    : -1;

  return linkedExchangeDefId ? (
    <Tooltip
      content={t('request.auction.youCantMakeChanges') as string}
      placement="top"
    >
      <DisabledInputBox sx={{ ...truncateStyle, cursor: 'not-allowed' }}>
        {isObsolete ? (
          <Tooltip content={t('general.obsolete') as string}>
            <ObsoleteIcon mr={2} marginBottom="2px" />
          </Tooltip>
        ) : (
          null
        )}
        {rfxLineItemStageIndex > -1 ? (
          <StageBadge index={rfxLineItemStageIndex} disabled />
        ) : (
          null
        )}
        {cell.value}
      </DisabledInputBox>
    </Tooltip>
  ) : isObsolete ? (
    <DisabledInputBox sx={truncateStyle}>
      <Tooltip content={t('general.obsolete') as string}>
        <ObsoleteIcon mr={2} />
      </Tooltip>
      {row.original.description}
    </DisabledInputBox>
  ) : (
    <TextField
      required
      hideLabel
      name={`${fieldName}[${row.index}].${column.id}`}
      label={column.label}
    />
  );
};

export const ExchangeModalCompanyCell = ({ cell }: CellProps<CompanyMinimized>) => {
  const company = cell.value;

  return company ? (
    <Flex pb={1} alignItems="center">
      <Box flex="0 0 auto">
        <CompanyLogo size="xs" companyId={company._id} />
      </Box>
      <Truncate fontSize={2}>
        {company.name}
      </Truncate>
    </Flex>
  ) : (
    null
  );
};

export const SelectFieldCell = ({ row, column, fieldName = 'exchangeDefs' }) => {
  const draftExchangeDef = row.original;
  const { isObsolete } = draftExchangeDef;

  return (
    <SelectField
      name={`${fieldName}[${row.index}].${column.id}`}
      items={column.options}
      required={column.required}
      disabled={isObsolete}
    />
  );
};

export const TextFieldCell = ({ row, column, fieldName = 'exchangeDefs', placeholder }) => (
  <TextField
    required
    hideLabel
    placeholder={placeholder}
    name={`${fieldName}[${row.index}].${column.id}`}
    label={column.label}
    disabled={row.original.isObsolete || column.disabled}
  />
);

export const DisabledTextFieldCell = ({ cell, row, column, fieldName = 'exchangeDefs' }) => (
  <TextField
    hideLabel
    name={`${fieldName}[${row.index}].${column.id}`}
    label={column.label}
    value={cell.value}
    disabled
  />
);

export const AuctionTextFieldCell = ({ row, column, cell, fieldName = 'exchangeDefs' }) => {
  const { t } = useTranslation();
  const { linkedExchangeDefId } = row.original;

  return linkedExchangeDefId ? (
    <Tooltip
      content={t('request.auction.youCantMakeChanges') as string}
      placement="top"
    >
      <DisabledInputBox sx={{ ...truncateStyle, cursor: 'not-allowed' }}>
        {cell.value}
      </DisabledInputBox>
    </Tooltip>
  ) : (
    <TextField
      required
      hideLabel
      name={`${fieldName}[${row.index}].${column.id}`}
      label={column.label}
      disabled={row.original.isObsolete || column.disabled}
    />
  );
};

export const FileFieldCell = ({ row, column }) => (
  <FileField
    required
    hideLabel
    name={`exchangeDefs[${row.index}].${column.id}`}
    label={column.label}
    disabled={row.original.isObsolete}
    initialFile={row.original.file}
    purpose="rfq"
  />
);

export const DocumentExchangeFileFieldCell = ({ row, column }) => {
  const { t } = useTranslation();
  const [{ value: description },, helpers] = useField(`exchangeDefs[${row.index}].category`);
  const [{ value: exchangeType }] = useField(`exchangeDefs[${row.index}].type`);
  const isDocumentRequest = informationRequestExchangeTypes.includes(exchangeType);

  return !isDocumentRequest ? (
    <FileField
      required
      hideLabel
      name={`exchangeDefs[${row.index}].${column.id}`}
      label={column.label}
      disabled={row.original.isObsolete}
      purpose="rfq"
      initialFile={row.original.file}
      truncateFileName
      onUploadStart={file => {
        // When the exchange def doesn't have a description, derive it from the file name
        if (!description) {
          helpers.setValue(getDocumentExchangeDescriptionFromFile(file));
        }
      }}
    />
  ) : (
    <TextFieldBase
      value={t('request.documents.supplierToUpload') as string}
      disabled
    />
  );
};
export const RequirementFieldCell = ({ cell, row, column, fieldName = 'exchangeDefs' }) => {
  const { t } = useTranslation();
  const [, , typeFormik] = useField({ name: `${fieldName}[${row.index}].type` });
  const [{ value: attachments }, , attachmentsFormik] = useField({ name: `${fieldName}[${row.index}].attachments` });
  const [{ value: lock }, , lockFormik] = useField({ name: `${fieldName}[${row.index}].locking` });

  const { isObsolete, isLive } = row.original;
  const allRequirements = Object.values(Requirement);
  const availableRequirements = attachments.length
    ? without(allRequirements, Requirement.REQUEST)
    : allRequirements;

  const options = map(
    availableRequirements,
    value => ({
      value,
      label: t(`request.documents.requirements.${value}`),
    }),
  );

  const onRequirementChange = React.useCallback(
    (requirement: Requirement) => {
      const exchangeType = getExchangeTypeFromRequirement(requirement, lock);

      if (isDocumentRequestExchange(exchangeType)) {
        // Reset the attachments when selecting the document request requirement
        attachmentsFormik.setValue([]);
      }

      if (!lockableExchangeTypes.includes(exchangeType)) {
        lockFormik.setValue(null);
      }

      typeFormik.setValue(exchangeType);
    },
    [typeFormik, attachmentsFormik, lock, lockFormik],
  );

  return (
    <SelectFieldBase
      items={options}
      required={column.required}
      value={cell.value}
      disabled={isObsolete || isLive || column.disabled}
      onChange={onRequirementChange}
    />
  );
};

export const LockFieldCell = ({ row }) => {
  const intercom = useIntercom();
  const { t } = useTranslation();
  const lockModal = useModalState();
  const [{ value: exchangeType }, , exchangeTypeFormik] = useField({ name: `exchangeDefs[${row.index}].type` });
  const [{ value: lock }, , lockFormik] = useField({ name: `exchangeDefs[${row.index}].locking` });
  const senders = rfx.useSenders();

  const { isLive, isObsolete } = row.original;
  const isLockable = lockableExchangeTypes.includes(exchangeType);

  const onLockChange = React.useCallback(
    (lock: Lock | null) => {
      const newExchangeType = getExchangeTypeFromLock(exchangeType, lock);

      if (exchangeType !== newExchangeType) {
        exchangeTypeFormik.setValue(newExchangeType);
      }
      lockFormik.setValue(lock);
    },
    [exchangeType, exchangeTypeFormik, lockFormik],
  );

  return isLockable ? (
    <>
      <LockedTooltip lock={lock} senders={senders}>
        <Box>
          <Button
            type="button"
            variant="secondary-outline"
            width="100%"
            iconLeft={lock ? 'lock' : 'unlock'}
            p={0}
            onClick={() => {
              lockModal.open();

              if (!isLive) {
                intercom.trackEvent('locking-modal-opened');
              }
            }}
            disabled={isObsolete || (isLive && !lock)}
          >
            {lock ? (
              isLive ? (
                t('general.lockSet')
              ) : (
                t('general.editLock')
              )
            ) : (
              t('general.noLockSet')
            )}
          </Button>
        </Box>
      </LockedTooltip>
      <LockModal
        isOpen={lockModal.isOpen}
        close={lockModal.close}
        lock={lock}
        isReadOnly={isLive}
        onSubmit={onLockChange}
      />
    </>
  ) : (
    <TextFieldBase
      disabled
      value={row.original.type ? t('general.notApplicableShort') as string : ''}
      inputStyle={{ display: 'flex', justifyContent: 'center' }}
    />
  );
};

export const StageFieldCell = ({ cell: { value: stageIds }, row }) => {
  const [, , formik] = useField({ name: `exchangeDefs[${row.index}].stages` });
  const allStages = rfx.useStages();
  const allStageIds = map(allStages, '_id');
  const stageSelectItems = useStageSelectItems();
  const stageId = first(stageIds);
  const { isLive } = row.original;

  return (
    <SelectFieldBase
      items={stageSelectItems}
      value={stageId}
      onChange={stageId => {
        const indexFrom = allStageIds.indexOf(stageId);
        formik.setValue(allStageIds.slice(indexFrom));
      }}
      disabled={isLive}
    />
  );
};

export const RequirementCell = ({ cell: { value: requirement } }) => {
  const { t } = useTranslation();

  return requirement ? (
    <Text>
      {t(`request.documents.requirements.${requirement}`)}
    </Text>
  ) : (
    <EmDash />
  );
};

/**
 * Renders the total price of an auction line item, based on the
 * auction line item's price and quantity.
 */
export const AuctionLineItemTotal = ({ textAlign, price, quantity, showCode }: {
  textAlign?: TextProps['textAlign'];
  price?: number;
  quantity?: number;
  showCode?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Text color="subtext" fontSize={1} textAlign={textAlign}>
      {t('general.total')}
      <Text display="inline" color="text" ml={1}>
        {/*
         // @ts-expect-error ts(18048) FIXME: 'quantity' is possibly 'undefined'. */}
        <CurrencyAmount value={quantity * (price ?? NaN)} showCode={showCode} />
      </Text>
    </Text>
  );
};

const TotalInCurrency = ({ textAlign, value, currencyCode }) => {
  const { t } = useTranslation();

  return (
    <Text color="subtext" fontSize={1} textAlign={textAlign}>
      {t('general.total')}
      <Text display="inline" color="text" ml={1}>
        <CurrencyAmountInCurrency
          value={isFinite(value) ? value : NaN}
          currencyCode={currencyCode}
          decimalPlaces={2}
        />
      </Text>
    </Text>
  );
};

export const TargetPriceWithTotalCell = ({ row: { original: exchange }, column: { textAlign, decimalPlaces }, cell: { value } }) => {
  const currencyCode = getExchangeFieldValue(exchange, 'evaluatorFieldCurrency');

  return (
    <Text textAlign={textAlign}>
      <CurrencyAmountInCurrency
        value={value}
        currencyCode={currencyCode}
        decimalPlaces={isFinite(decimalPlaces) ? decimalPlaces : exchange.def.fields.targetPrice?.decimalPlaces}
      />
      <Box mt={1}>
        <TotalInCurrency
          value={isFinite(value) ? exchange?.computedFormulas?.targetTotalCost : null}
          currencyCode={currencyCode}
          textAlign={textAlign}
        />
      </Box>
    </Text>
  );
};

export const TargetPriceCell = ({ row: { original: exchange }, column: { textAlign }, cell: { value } }) => {
  const currencyCode = getExchangeFieldValue(exchange, 'evaluatorFieldCurrency');

  return (
    <Text textAlign={textAlign}>
      <CurrencyAmountInCurrency value={value} currencyCode={currencyCode} />
    </Text>
  );
};

export const UnspscCodeCell = ({ row, column: { truncate }, cell: { value } }) => {
  const { useExchangeDefById } = useHooks();
  const exchangeDefById = useExchangeDefById();

  const exchange = row.original;

  const exchangeDef = value
    ? exchangeDefById[exchange.def._id] as LineItemExchangeDefinition | undefined
    : undefined;
  const productOrService = exchange.productOrService || exchangeDef?.productOrService;

  return productOrService ? (
    <Tooltip content={`[${productOrService._id}] ${productOrService.title}`}>
      {truncate ? (
        <Truncate>
          [{productOrService._id}] {productOrService.title}
        </Truncate>
      ) : (
        <span>
          [{productOrService._id}] {productOrService.title}
        </span>
      )}
    </Tooltip>
  ) : (
    <EmDash />
  );
};

export const BooleanResponseCell = ({
  row: { original: exchange },
  cell,
  column: { EmptyCell = EmDash as React.FC<any>, showTooltip },
}) => {
  const { t } = useTranslation('request');

  if (isNil(cell.value)) {
    return (
      <EmptyCell exchange={exchange} showTooltip={showTooltip} />
    );
  } else {
    const text = cell.value
      ? t('lineItems.cell.boolean.accepted')
      : t('lineItems.cell.boolean.rejected');

    return (
      <Tooltip content={showTooltip ? text : null}>
        <Box>
          {text}
        </Box>
      </Tooltip>
    );
  }
};

export const QuestionTypeCell = ({ cell: { value: questionType } }) => {
  const { t } = useTranslation();

  return questionType ? (
    <Truncate>
      {t(`request.question.questionType.${questionType}`)}
    </Truncate>
  ) : (
    <EmDash />
  );
};

export const SwitchCell = ({ cell: { value } }) => (
  !isNil(value) ? (
    <Icon fixedWidth icon={value ? 'check' : 'times'} />
  ) : (
    <EmDash />
  )
);

export const QuestionOptionFieldCell = ({ row, exchangeFieldName }) => {
  const [{ value: exchangeDef }] = useField<OptionQuestionExchangeDefinition>(exchangeFieldName);
  const isOtherOption = row.index === size(exchangeDef.options);

  return (
    <Flex alignItems="center">
      <Box mr={2}>
        {exchangeDef.questionType === QuestionType.CHECKBOXES ? (
          <StyledCheckbox disabled style={{ background: 'transparent' }} />
        ) : (
          <StyledRadio disabled style={{ background: 'transparent' }} />
        )}
      </Box>
      {!isOtherOption ? (
        <TextField
          name={`${exchangeFieldName}.options[${row.index}]`}
          disabled={exchangeDef.isObsolete}
        />
      ) : (
        <DisabledInputBox flex={1}>
          {row.original}
        </DisabledInputBox>
      )}
    </Flex>
  );
};

export const TruncateOrDashCell = ({
  row: { original: exchange },
  cell,
  sx,
  column,
}) => {
  const { EmptyCell = EmDash as React.FC<any>, showTooltip } = column;

  if (!cell.value) {
    return EmptyCell ? (
      <EmptyCell exchange={exchange} showTooltip={showTooltip} />
    ) : (
      null
    );
  }
  return (
    <Tooltip content={showTooltip ? cell.value : null}>
      <Truncate sx={sx}>
        {cell.value}
      </Truncate>
    </Tooltip>
  );
};

export const ValidationErrorCell = ({
  cell: { value },
  row,
  column,
  fieldName = 'exchangeDefs',
  customFieldKey,
  useShowValidationErrors,
}) => {
  const error = useCellValidationError({ fieldName, customFieldKey, row, column, useShowValidationErrors });

  return error ? (
    <ErrorMessage fontSize={2} error={error} />
  ) : (
    value
  );
};

export const ImportValidationErrorCell = ({ cell: { value }, row, fieldName = 'exchangeDefs' }) => {
  const { error } = useError(`[${row.index}].${fieldName}`);

  return error ? (
    <ErrorMessage fontSize={2} error={value} />
  ) : (
    value
  );
};

export const useGetSupplierResponseCellValue = () => {
  const { t } = useTranslation();

  return React.useMemo(() => (exchangeDef: QuestionExchangeDefinition<AnyScope>) => {
    if (isOptionBasedQuestion(exchangeDef)) {
      const { options = [], allowCustomOption } = exchangeDef;

      // Includes the "other" option, if added
      const allOptions = allowCustomOption
        ? [
          ...options,
          t('request.question.other'),
        ]
        : options;

      return allOptions && !isEmpty(allOptions)
        ? allOptions
          .map(option => isEmpty(option) ? '—' : option)
          .join('; ')
        : null;
    }

    if (isAddressQuestion(exchangeDef)) {
      return !isEmpty(exchangeDef.visibleFields)
        ? getOrderedAddressFields(exchangeDef.visibleFields)
          .map(field => t(`request.question.addressField.${field}`))
          .join(', ')
        : null;
    }

    if (exchangeDef.questionType === QuestionType.PRICE && exchangeDef.currencies) {
      return `${exchangeDef.currencies?.[0]} ${t('general.price')}`;
    }

    if (isDateTimeQuestion<AnyScope>(exchangeDef)) {
      const { format } = exchangeDef;

      return format === QuestionFormat.INVALID
        ? null
        : t(`request.question.datetimeFormat.${format}`);
    }

    if (isGridQuestion(exchangeDef)) {
      return exchangeDef.columns
        .map(column => `${column.name} (${t(`request.question.grid.columnType.${column.type}`)})`)
        .join(', ');
    }

    if (isYesNoQuestion(exchangeDef)) {
      return exchangeDef.allowCustomOption
        ? t('request.question.yesNo.yesNoOther')
        : t(`request.question.questionType.${exchangeDef.questionType}`);
    }

    return t(`request.question.questionType.${exchangeDef.questionType}`);
  }, [t]);
};

export const SupplierResponseCell = ({ row }) => {
  const exchangeDef = row.original as QuestionExchangeDefinition;

  const getSupplierResponseCellValue = useGetSupplierResponseCellValue();

  const value = React.useMemo(() => getSupplierResponseCellValue(exchangeDef), [getSupplierResponseCellValue, exchangeDef]);

  return isNil(value) ? (
    <EmDash />
  ) : (
    <Truncate>{value}</Truncate>
  );
};
