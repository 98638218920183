import * as React from 'react';
import { Flex, Text, Box, TextProps } from 'rebass/styled-components';
import { get, isFunction, size } from 'lodash';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import { ApproximateTotal } from '@deepstream/common/rfq-utils';
import { DateFormat, localeFormatFactorAsPercent } from '@deepstream/utils';
import { ONE_DAY, ONE_HOUR } from '@deepstream/common/constants';

import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { withProps } from '@deepstream/ui-utils/withProps';
import { useMediaQueries } from '@deepstream/ui-kit/hooks/useMediaQueries';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { Datetime2 } from './Datetime';
import { CurrencyAmount, CurrencyCodeProvider } from './ui/Currency';
import { NameArray } from './NameArray';
import { CompanyNameText } from './ui/CompanyNameText';
import { CompanyNamesTooltip, TooltipList } from './ui/TooltipList';
import { useCurrentUserLocale } from './useCurrentUser';
import { useDeadline } from './deadline';

export const MIN_CELL_HEIGHT = 63;

export const FieldLabel = (props: TextProps) => (
  <Text as="span" fontSize={1} color="subtext" mr={1} {...props} />
);

export const FieldValue = (props: TextProps) => (
  <Text as="span" fontSize={1} mr={3} {...props} />
);

export const RequestStatusFieldValue = ({ value }) => {
  const { t } = useTranslation();

  return value ? t(`request.status.${value}`) : '—';
};

export const BidStatusFieldValue = ({ value }) => {
  const { t } = useTranslation();

  return t(`request.supplierBidStatus.${value}`);
};

export const UniversalBidStatusFieldValue = ({ value }) => {
  const { t } = useTranslation();

  return t(`request.universalSupplierBidStatus.${value}`);
};

export const DateFieldValue = withProps(Datetime2, { format: DateFormat.DD_MMM_YYYY });

export const NameArrayFieldValue = ({ value }) => (
  <NameArray entities={value} />
);

export const DeadlineFieldValue = ({ value: deadline }: { value: Date }) => {
  const { t } = useTranslation('translation');
  const { remainingMs, countdown, hasPassed } = useDeadline({
    deadline,
    refreshRateMs: 500,
  });

  if (!deadline) {
    return (
      <Text as="span">
        {t('general.notApplicableShort')}
      </Text>
    );
  }

  const { seconds, minutes, hours } = countdown;

  return (
    <Tooltip
      content={
        <Text as="span">
          <Datetime2 value={deadline} format={DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ} />
        </Text>
      }
    >
      {hasPassed ? (
        <Text as="span">
          {t('request.deadline.passed')}
        </Text>
      ) : remainingMs / ONE_HOUR < 1 ? (
        <Text as="span" color="danger">
          {minutes}{t('general.minuteLetter')} {seconds}{t('general.secondLetter')}
        </Text>
      ) : remainingMs / ONE_HOUR < 24 ? (
        <Text as="span" color="warning">
          {hours}{t('general.hourLetter')} {minutes}{t('general.minuteLetter')}
        </Text>
      ) : (
        <Text as="span">
          {Math.floor(remainingMs / ONE_DAY)}{t('general.dayLetter')} {hours}{t('general.hourLetter')}
        </Text>
      )}
    </Tooltip>
  );
};

export const CurrencyAmountValue = ({ value: spendData }: { value: ApproximateTotal }) => {
  return spendData ? (
    <CurrencyCodeProvider code={spendData.currencyCode}>
      <CurrencyAmount value={spendData.amount} />
    </CurrencyCodeProvider>
  ) : (
    <EmDash />
  );
};

export const PercentageFieldValue = ({ value, decimalPlaces }: { value: number; decimalPlaces?: number }) => {
  const locale = useCurrentUserLocale();

  return (
    <>
      {localeFormatFactorAsPercent(value, { locale, decimalPlaces })}
    </>
  );
};

const CompanyNamesLayout = ({
  value: items,
  ItemComponent,
  TooltipComponent,
}: {
  value: string[],
  ItemComponent: React.FC<{ id: string }>,
  TooltipComponent: React.FC<{ ids: string[] }>,
}) => {
  const { t } = useTranslation('translation');

  return items.length === 1 ? (
    <ItemComponent id={items[0]} />
  ) : (
    <Tooltip
      content={<TooltipComponent ids={items} />}
    >
      <Text as="span">
        <ItemComponent id={items[0]} />
        {' + '}
        {t('otherCompanyCount', {
          count: items.length - 1,
          ns: 'general',
        })}
      </Text>
    </Tooltip>
  );
};

export const CompanyNamesByIdFieldValue = ({
  value: companyIds,
}: {
  value: string[];
}) => {
  return (
    <CompanyNamesLayout
      value={companyIds}
      ItemComponent={withProps(CompanyNameText, { as: 'span' })}
      TooltipComponent={({ ids }) => <CompanyNamesTooltip companyIds={ids} />}
    />
  );
};

export const CompanyNamesFieldValue = ({
  value: companyNames,
}: {
  value: string[];
}) => {
  return (
    <CompanyNamesLayout
      value={companyNames}
      ItemComponent={({ id }) => <Text as="span">{id}</Text>}
      TooltipComponent={({ ids }) => (
        <TooltipList
          items={ids}
          ItemComponent={({ item }) => <Text>{item}</Text>}
        />
      )}
    />
  );
};

export type FieldsCellField = (
  {
    id: string;
    accessor: (value: any) => any;
  } | {
    accessor: string;
  }
) & {
  id?: string;
  label: string;
  singularLabel?: string;
  FieldValueComponent?: React.FC<{ value: any }>;
  condition?: (value: any) => boolean;
};

export type FieldsCellProps = CellProps<any> & {
  disabledMessage?: string;
  fields?: FieldsCellField[];
  titleAccessor: string;
  defaultTitle: string;
  TitleComponent?: React.FC<Pick<CellProps<any>, 'row'>>;
  RowIconComponent?: React.FC<Pick<CellProps<any>, 'row'>>;
  actionColumnWidth: number;
  ActionsComponent?: React.FC<Pick<CellProps<any>, 'row'> & {
    onExpandedStateChange: (isExpanded: boolean) => void;
  }>;
  onSelect?: (row: any) => void;
  isSelected?: (row: any) => boolean;
  isSelectionDisabled?: (row: any) => boolean;
};

export const FieldsCell: React.FC<FieldsCellProps> = ({
  row,
  disabledMessage,
  fields,
  titleAccessor,
  defaultTitle,
  TitleComponent,
  RowIconComponent,
  actionColumnWidth,
  ActionsComponent,
  onSelect,
  isSelected,
  isSelectionDisabled,
}) => {
  const [cellRef, cellHovered] = useHover<HTMLTableCellElement>();
  const [isDropdownExpanded, setIsDropdownExpanded] = React.useState(false);
  const { canHover: deviceCanHover } = useMediaQueries();

  const color = disabledMessage
    ? 'darkGrey'
    : undefined;

  return (
    <Flex
      as="td"
      ref={cellRef}
      alignItems="center"
      py="11px"
      style={{ height: 'auto' }}
      minHeight={MIN_CELL_HEIGHT}
    >
      {onSelect && (
        <Checkbox
          style={{ marginRight: '12px', alignSelf: 'flex-start', paddingTop: '3px' }}
          // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
          checked={isSelected(row)}
          onClick={stopPropagation}
          onChange={() => onSelect(row)}
          disabled={isSelectionDisabled ? isSelectionDisabled(row) : undefined}
        />
      )}
      {RowIconComponent && (
        <RowIconComponent row={row} />
      )}
      <Box flex="1 1 auto">
        <Text flex={1} fontWeight="500" color={color}>
          {TitleComponent ? (
            <TitleComponent row={row} />
          ) : (
            get(row.original, titleAccessor) || defaultTitle
          )}
        </Text>
        <Text
          flex={1}
          fontSize={1}
          mt={1}
          mr={3}
          style={truncateStyle}
          color={color}
        >
          {disabledMessage || (fields ? (
            fields.map(field => {
              if (field.condition && !field.condition(row.original)) {
                return null;
              }

              const value = isFunction(field.accessor)
                ? field.accessor(row.original)
                : get(row.original, field.accessor);

              return (
                <React.Fragment key={(field.id as string) ?? field.accessor as any}>
                  <FieldLabel>
                    {field.singularLabel && size(value) === 1 ? (
                      field.singularLabel
                    ) : (
                      field.label
                    )}
                  </FieldLabel>
                  <FieldValue>
                    {field.FieldValueComponent ? (
                      <field.FieldValueComponent value={value} />
                    ) : (
                      value ?? <EmDash />
                    )}
                  </FieldValue>
                </React.Fragment>
              );
            })
          ) : (
            null
          ))}
        </Text>
      </Box>
      <Flex flex="0 0 auto" width={actionColumnWidth ?? 'max-content'} justifyContent="flex-end">
        {ActionsComponent && (
          <Box hidden={deviceCanHover && !cellHovered && !isDropdownExpanded}>
            <ActionsComponent onExpandedStateChange={setIsDropdownExpanded} row={row} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
