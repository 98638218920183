import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { ACTION_COLUMN_WIDTH, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import {
  FROZEN_LEFT_COLUMN_WIDTH,
  RequestsReportingTablePanel,
} from './RequestsReportingTablePanel';
import {
  CurrencyAmountOrDashCell,
  DateOrDashCell,
  OwnersCell,
  RequestStatusCell,
  SectionStatusCell,
  TextOrDashCell,
  ValueConfirmedOrDashCell,
  ValueTypeOrDashCell,
  SimpleRowNumberCell,
  LinkIconOverlay,
} from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { SimpleHeader, SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { useRequestsReportingTableData } from './useRequestsReporting';

export const ValueTablePanel = ({
  config,
  useData = useRequestsReportingTableData,
}: { config: RequestsReportingConfig; useData?: typeof useRequestsReportingTableData }) => {
  const { t } = useTranslation('translation');

  const columns: EditableGridColumn[] = useMemo(() => {
    return [
      {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: withProps(SimpleRowNumberCell, { HoverOverlay: LinkIconOverlay }),
        width: ACTION_COLUMN_WIDTH,
      },
      {
        _id: 'subject',
        accessorKey: 'subject',
        Header: SortableHeader,
        label: t('request.spendAndSavings.requestName'),
        width: FROZEN_LEFT_COLUMN_WIDTH,
        sortType: 'caseInsensitive',
        ValueCell: TextOrDashCell,
      },
      {
        _id: 'extendedStatus',
        accessorKey: 'extendedStatus',
        Header: SortableHeader,
        label: t('request.spendAndSavings.requestStatus'),
        width: 160,
        ValueCell: RequestStatusCell,
      },
      {
        _id: 'finalValueStatus',
        accessorKey: 'finalValueStatus',
        Header: SortableHeader,
        label: t('request.spendAndSavings.valueStatus'),
        width: 160,
        ValueCell: SectionStatusCell,
      },
      {
        _id: 'finalValue',
        accessorKey: 'finalValue',
        Header: SortableHeader,
        label: t('request.spendAndSavings.value'),
        width: 160,
        ValueCell: CurrencyAmountOrDashCell,
      },
      {
        _id: 'isFinalValueConfirmed',
        accessorKey: 'isFinalValueConfirmed',
        Header: SortableHeader,
        label: t('request.spendAndSavings.valueConfirmed'),
        width: 160,
        ValueCell: ValueConfirmedOrDashCell,
      },
      {
        _id: 'finalValueType',
        accessorKey: 'finalValueType',
        Header: SortableHeader,
        label: t('general.type'),
        width: 160,
        ValueCell: ValueTypeOrDashCell,
      },
      {
        _id: 'createdAt',
        accessorKey: 'createdAt',
        Header: SortableHeader,
        label: t('request.spendAndSavings.creationDate'),
        width: 140,
        sortType: 'datetime',
        ValueCell: DateOrDashCell,
      },
      {
        _id: 'prospectiveCompletionDate',
        accessorKey: 'prospectiveCompletionDate',
        Header: SortableHeader,
        label: t('request.spendAndSavings.finalDeadline'),
        width: 140,
        sortType: 'datetime',
        ValueCell: DateOrDashCell,
      },
      {
        _id: 'awardedDate',
        accessorKey: 'awardedDate',
        Header: SortableHeader,
        label: t('request.spendAndSavings.awardDate'),
        width: 140,
        sortType: 'datetime',
        ValueCell: DateOrDashCell,
      },
      {
        _id: 'senderOwners',
        accessorKey: 'senderOwners',
        Header: SortableHeader,
        label: t('request.team.requestRole.owner'),
        width: 200,
        sortAccessorFn: ({ senderOwners }: any) => senderOwners.map(({ name }) => name).join(),
        sortType: 'caseInsensitive',
        ValueCell: OwnersCell,
      },
    ];
  }, [t]);

  return (
    <RequestsReportingTablePanel config={config} columns={columns} useData={useData} section="value" />
  );
};
