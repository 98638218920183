import { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { useApi, wrap } from '../../api';
import dummyData from './dummyContractsData.json';
import { useSelectedCompanyIds } from '../../selectedCompanyIds';

export const useContractsReporting = (companyId, currency, canViewManagementReportingPage) => {
  const api = useApi();
  const selectedCompanyIds = useSelectedCompanyIds();
  const options = canViewManagementReportingPage
    ? { initialData: undefined, enabled: true }
    : { initialData: dummyData as any, enabled: false };

  return useQuery(
    ['contractsReporting', { companyId, currency, selectedCompanyIds }],
    wrap(api.getContractsReportingData),
    options,
  );
};

// TODO refine type
export const ContractsReportingDataContext = createContext<any>(null);

export const useContractsReportingData = () => {
  const reportingData = useContext(ContractsReportingDataContext);

  if (!reportingData) {
    throw new Error('Contracts reporting data is falsy');
  }

  return reportingData;
};
