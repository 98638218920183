import { useMemo } from 'react';
import { first } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';

import { Contract } from '@deepstream/common/contract/contract';

import { useCurrentCompanyId } from '../../currentCompanyId';
import { useContractData, useContractId } from './contract';
import { useApi, wrap } from '../../api';
import { ExchangeSnapshot } from '../../types';
import { usePopulateExchangeActionDisplayProps } from '../../ExchangeModal/usePopulateExchangeActionDisplayProps';

export const getContractQueryKey = ({
  contractId,
  currentCompanyId,
  scope,
  isTemplate = false,
}: {
  contractId: string;
  currentCompanyId: string;
  scope: 'draft' | 'current';
  isTemplate?: boolean;
}) => [
  isTemplate ? 'contractTemplate' : 'contract',
  { contractId, currentCompanyId, isTemplate, ...(!isTemplate && { scope }) },
];

export const useContractQueryKey = ({
  contractId: contractIdProp,
  currentCompanyId: currentCompanyIdProp,
  scope,
  isTemplate = false,
}: {
  contractId?: string;
  currentCompanyId?: string;
  scope: 'draft' | 'current';
  isTemplate?: boolean;
}) => {
  const providedCurrentCompanyId = useCurrentCompanyId({ required: false });
  const currentCompanyId = currentCompanyIdProp || providedCurrentCompanyId;

  if (!currentCompanyId) {
    throw new Error('A `currentCompanyId` is required');
  }

  const providedContractId = useContractId({ required: false });
  const contractId = contractIdProp || providedContractId;

  if (!contractId) {
    throw new Error('An `contractId` is required');
  }

  const queryKey = useMemo(
    () => getContractQueryKey({ contractId, currentCompanyId, scope, isTemplate }),
    [contractId, currentCompanyId, isTemplate, scope],
  );

  return queryKey;
};

export const getAdminContractQueryKey = (contractId: string) =>
  ['adminContract', { contractId }];

export const useAdminContractQueryKey = getAdminContractQueryKey;

export const useContract = ({
  contractId,
  currentCompanyId,
  scope,
  enabled = true,
  isTemplate = false,
}: {
  contractId?: string;
  currentCompanyId?: string;
  scope: 'draft' | 'current';
  enabled?: boolean;
  isTemplate?: boolean;
}) => {
  const queryKey = useContractQueryKey({
    currentCompanyId,
    contractId,
    scope,
    isTemplate,
  });

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getContract),
    {
      enabled,
      staleTime: 60 * 1000,
    },
  );

  return useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useContractExchange = ({
  exchangeId,
  enabled = true,
  onSuccess,
  refetchInterval,
}: {
  exchangeId: string;
  enabled?: boolean;
  onSuccess?: any;
  refetchInterval?: number;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { _id: contractId, recipients } = useContractData();
  const api = useApi();
  const queryClient = useQueryClient();
  const populateExchangeActionDisplayProps = usePopulateExchangeActionDisplayProps();

  const queryKey = useMemo(
    () => [
      'contractExchange',
      { contractId, exchangeId, currentCompanyId },
    ],
    [contractId, exchangeId, currentCompanyId],
  );

  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const recipientId = first(recipients)._id;

  const contractQueryKey = useContractQueryKey({ scope: 'draft' });

  const query = useQuery(
    queryKey,
    wrap(api.getContractExchange),
    {
      staleTime: 10 * 1000,
      onSuccess,
      enabled: Boolean(exchangeId) && enabled,
      refetchInterval,
      select: (exchange: ExchangeSnapshot) => populateExchangeActionDisplayProps(exchange, recipientId),
      initialData: () =>
        queryClient
          .getQueryData<Contract>(contractQueryKey)
          ?.exchangeById[exchangeId] as ExchangeSnapshot | undefined,
    },
  );

  return useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useContractAuditQueryKey = ({
  contractId: contractIdProp,
  currentCompanyId: currentCompanyIdProp,
}: {
  contractId?: string;
  currentCompanyId?: string;
}) => {
  const providedCurrentCompanyId = useCurrentCompanyId({ required: false });
  const currentCompanyId = currentCompanyIdProp || providedCurrentCompanyId;

  if (!currentCompanyId) {
    throw new Error('A `currentCompanyId` is required');
  }

  const providedContractId = useContractId({ required: false });
  const contractId = contractIdProp || providedContractId;

  if (!contractId) {
    throw new Error('A `contractId` is required');
  }

  const queryKey = useMemo(
    () => ['contractAudit', { contractId, currentCompanyId }],
    [contractId, currentCompanyId],
  );

  return queryKey;
};

export const useContractAudit = ({
  contractId,
  currentCompanyId,
}: {
  contractId?: string;
  currentCompanyId?: string;
}) => {
  const queryKey = useContractAuditQueryKey({
    currentCompanyId,
    contractId,
  });

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getContractAudit),
  );

  return useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};
