import { Form, Formik, useField } from 'formik';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { TextFieldBase } from '@deepstream/ui/form/TextField';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Flex } from 'rebass/styled-components';
import { LightLabelAboveConfigProvider } from '@deepstream/ui/LabelConfigProvider';
import { Company } from '@deepstream/ui/ui/types';
import { assertDefined } from '@deepstream/utils';
import { z } from 'zod';
import { toFormikValidationSchema } from '@deepstream/ui-utils/zodFormikAdapter';

class LookupError extends Error {}

const LookupButton = ({
  currentCompany,
}: {
  currentCompany: CompanyMinimized;
}) => {
  const queryClient = useQueryClient();
  const [{ value: companyId }] = useField('companyId');
  const [{ value: company }, , companyFormik] = useField('company');
  const [, , companyErrorFormik] = useField('companyError');
  const adminApi = useAdminApi();

  const onSearchClick = async () => {
    try {
      const company = await queryClient.fetchQuery({
        queryKey: ['company', { companyId }],
        queryFn: wrap(adminApi.getCompany),
      });

      if (!company) {
        throw new LookupError('No company found');
      }

      const { parentCompanyId } = company;

      if (company._id === currentCompany._id) {
        throw new LookupError('Cannot add the current company as a child');
      }

      if (parentCompanyId) {
        const message = parentCompanyId === currentCompany._id
          ? 'This company is already a child of this company'
          : 'This company is already a parent of another company';
        throw new LookupError(message);
      }

      companyFormik.setValue(company);
      companyErrorFormik.setValue(null);
    } catch (error) {
      const message = error instanceof LookupError
        ? error.message
        : 'No company found';
      await companyFormik.setValue(null);
      await companyErrorFormik.setValue(message);
    }
  };

  return (
    <Button
      type="button"
      iconLeft="search"
      width="120px"
      ml={2}
      mt="25px"
      onClick={onSearchClick}
      disabled={!companyId || companyId === company?._id}
    >
      Look up
    </Button>
  );
};

type AddCompanyFormValues = {
  companyId: string;
  company: Company | null;
  companyError: string | null;
};

const Schema = z.object({
  companyId: z.string({ message: 'Required' }).trim().min(1, { message: 'Required' }),
});

export const AddChildCompanyModal = ({
  currentCompany,
  onCancel,
  onSave,
  ...props
}: ModalProps & {
  currentCompany: CompanyMinimized;
  onCancel: any;
  onSave: any;
}) => {
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const adminApi = useAdminApi();

  const [setParentCompany] = useMutation(
    adminApi.setParentCompany,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('Child company added'),
      ),
      onError: () => toaster.error('Could not add child company'),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['childCompanies', { companyId: currentCompany._id }]),
        (_, __, { companyId }) => queryClient.invalidateQueries(['company', { companyId }]),
        (_, __, { companyId }) => queryClient.invalidateQueries(['publicCompany', { companyId }]),
      ),
    },
  );

  const initialValues: AddCompanyFormValues = {
    companyId: '',
    company: null,
    companyError: null,
  };

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={initialValues}
        validationSchema={toFormikValidationSchema(Schema)}
        onSubmit={async ({ company }) => {
          assertDefined(company, 'company');

          return setParentCompany({
            companyId: company._id,
            parentCompanyId: currentCompany._id,
          });
        }}
      >
        {({ isSubmitting, dirty, isValid, values, errors, setFieldTouched, setFieldValue }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Add child company
            </ModalHeader>
            <ModalBody>
              <LightLabelAboveConfigProvider>
                <Stack gap={3}>
                  <Flex>
                    <TextFieldBase
                      required
                      name="companyId"
                      label="Company ID"
                      error={errors.companyId || values.companyError || undefined}
                      onBlur={() => setFieldTouched('companyId', true)}
                      onChange={async (event) => {
                        await setFieldValue('companyId', event.target.value);
                        await setFieldValue('companyError', null);
                      }}
                    />
                    <LookupButton currentCompany={currentCompany} />
                  </Flex>
                  <TextFieldBase
                    label="Company name look up"
                    disabled
                    value={values.companyId && values.companyId === values.company?._id ? values.company.name : ''}
                  />
                </Stack>
              </LightLabelAboveConfigProvider>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" disabled={isSubmitting || !dirty || !isValid || !values.company || values.company._id !== values.companyId}>
                Add child company
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
