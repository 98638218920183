import * as React from 'react';
import { useQuery } from 'react-query';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { PanelLoadingWrapper } from '@deepstream/ui/ui/Loading';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { AddUserToCompanyModal } from '@deepstream/ui/modules/Company/TeamManagement/AddUserToCompanyModal';
import { useCompanyUsers } from '@deepstream/ui/modules/Company/TeamManagement/useCompanyUsers';
import { Company } from '@deepstream/ui/ui/types';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as Title from '../../title';
import { CompanyUsersTable } from './CompanyUsersTable';
import { CompanyMembershipRequestsTable } from './CompanyMembershipRequestsTable';

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

export const CompanyTeam = ({ company }: { company: Company }) => {
  const adminApi = useAdminApi();
  const companyId = company._id;

  const addUserToCompanyModal = useModalState();

  const { data: users = [], status: usersStatus } = useCompanyUsers(companyId);

  const { data: membershipRequests, status: membershipRequestsStatus } =
    useQuery(
      ['membershipRequests', { companyId }],
      wrap(adminApi.getCompanyMembershipRequests),
    );

  return (
    <Stack gap={4}>
      <Panel>
        <PanelHeader heading="Users">
          <Button small iconLeft="plus" onClick={addUserToCompanyModal.open} disabled={usersStatus !== 'success'}>
            Add user
          </Button>
        </PanelHeader>
        <PanelDivider />
        <PanelLoadingWrapper status={usersStatus} data={users} emptyDataMessage="No team members yet">
          <CompanyUsersTable companyId={company._id} users={users} />
        </PanelLoadingWrapper>
      </Panel>

      <Panel heading="Requests to join">
        <PanelLoadingWrapper status={membershipRequestsStatus} data={membershipRequests} emptyDataMessage="No requests">
          {membershipRequests && (
            <CompanyMembershipRequestsTable
              companyId={company._id}
              membershipRequests={membershipRequests}
            />
          )}
        </PanelLoadingWrapper>
      </Panel>
      <AddUserToCompanyModal
        companyId={companyId}
        isPayingCompany={Boolean(company?.subscription)}
        isOpen={addUserToCompanyModal.isOpen}
        onCancel={addUserToCompanyModal.close}
        onSave={addUserToCompanyModal.close}
        onRequestClose={addUserToCompanyModal.close}
      />
    </Stack>
  );
};
