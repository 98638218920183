import * as React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { merge } from 'lodash';
import { ButtonProps, CancelButton, OkButton } from '../button/Button';
import { MessageBlock, MessageBlockProps } from '../MessageBlock';
import { PanelHeading, PanelDivider } from '../Panel';
import { Modal, ModalHeader, ModalProps } from './Modal';

export type DialogProps = ModalProps & {
  heading?: string;
  body?: React.ReactNode;
  onOk: (payload?: any) => void;
  onCancel?: () => void;
  okButtonVariant?: ButtonProps['variant'];
  okButtonText?: string;
  okButtonDisabled?: boolean;
  okButtonType?: ButtonProps['type'];
  cancelButtonVariant?: ButtonProps['variant'];
  cancelButtonText?: string;
  showCloseIcon?: boolean;
  showCancelButton?: boolean;
  maxBodyHeight?: string;
};

export const Dialog = ({
  heading,
  body,
  onOk,
  onCancel,
  okButtonVariant,
  okButtonText,
  okButtonType,
  cancelButtonVariant,
  cancelButtonText,
  showCloseIcon,
  okButtonDisabled,
  showCancelButton = true,
  maxBodyHeight,
  ...props
}: DialogProps) => {
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const handleOk = React.useCallback(
    async () => {
      try {
        setDisabled(true);
        try {
          await onOk();
        } catch (_) {
          // ignore
        }
      } finally {
        setDisabled(false);
      }
    },
    [onOk],
  );

  return (
    <Modal onRequestClose={onCancel} contentLabel={heading} {...props}>
      {showCloseIcon ? (
        <ModalHeader onClose={onCancel}>
          {heading}
        </ModalHeader>
      ) : (
        <>
          <PanelHeading p={3}>
            {heading}
          </PanelHeading>
          <PanelDivider />
        </>
      )}
      <Text p={3} fontSize={2} maxHeight={maxBodyHeight} overflowY="auto">
        {body}
      </Text>
      <PanelDivider />
      <Flex p={3} justifyContent="flex-end">
        {onCancel && showCancelButton && (
          <CancelButton
            variant={cancelButtonVariant}
            onClick={onCancel}
            mr={2}
            disabled={disabled}
          >
            {cancelButtonText}
          </CancelButton>
        )}
        <OkButton
          type={okButtonType}
          variant={okButtonVariant}
          onClick={handleOk}
          style={{ minWidth: 60 }}
          disabled={disabled || okButtonDisabled}
        >
          {okButtonText}
        </OkButton>
      </Flex>
    </Modal>
  );
};

type ConfirmDeleteDialogProps = Omit<DialogProps, 'body'> & { message: string };

export const ConfirmDeleteDialog = ({ message, style = {}, ...props }: ConfirmDeleteDialogProps) => {
  const { t } = useTranslation();

  const modalStyle = merge({}, { content: { width: '500px' } }, style);

  return (
    <Dialog
      body={
        <MessageBlock variant="warn" my={2}>
          {message}
        </MessageBlock>
      }
      style={modalStyle}
      okButtonText={t('general.delete')}
      okButtonVariant="danger"
      cancelButtonText={t('general.cancel')}
      {...props}
    />
  );
};

type ConfirmActionDialogProps = Omit<DialogProps, 'body'> & {
  message: React.ReactNode;
  variant: MessageBlockProps['variant'];
};

export const ConfirmActionDialog = ({ message, variant, ...props }: ConfirmActionDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      body={(
        <MessageBlock variant={variant} mt={0}>
          {message}
        </MessageBlock>
      )}
      style={{ content: { width: '475px', minWidth: '475px' } }}
      cancelButtonText={t('general.cancel')}
      {...props}
    />
  );
};

type AsyncActionDialogProps = Omit<DialogProps, 'body'> & {
  message: React.ReactNode;
};

export const AsyncActionDialog = ({ message, ...props }: AsyncActionDialogProps) => {
  const { t } = useTranslation('general');

  return (
    <Dialog
      body={(
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            gap: 3,
          }}
        >
          <MessageBlock variant="info" mt={0} fontWeight={500} fontSize={3}>
            {message}
          </MessageBlock>
        </Flex>
      )}
      style={{ content: { width: '375px', minWidth: '375px' } }}
      okButtonText={t('done')}
      okButtonVariant="primary"
      {...props}
    />
  );
};
