import { useState, useMemo } from 'react';

import { useModalState } from '@deepstream/ui/ui/useModalState';
import { PropertyList, PropertyListAction, TopAlignedRow } from '@deepstream/ui/PropertyList';
import { Box, Text } from 'rebass/styled-components';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useEnv } from '@deepstream/ui/env';
import { useSystemFeatureFlags } from '@deepstream/ui/systemFeatureFlags';
import { EditCompanyFeatureFlagModal, FeatureFlagConfig } from './EditCompanyFeatureFlagModal';
import { EnabledDisabledPropertyValue } from '../../EnabledDisabledPropertyValue';

const featureFlagsConfig: FeatureFlagConfig[] = [
  {
    accessor: 'auctionsEnabled',
    label: 'Auction stage',
    modalHeading: 'Edit auctions stage',
  },
  {
    accessor: 'managementReportingEnabled',
    label: 'Reporting',
    modalHeading: 'Edit management reporting',
  },
  {
    accessor: 'contractManagementEnabled',
    label: 'Contracts',
    modalHeading: 'Edit contracts',
  },
  {
    accessor: 'publicRequestsEnabled',
    label: 'Public Requests',
    modalHeading: 'Edit public requests',
  },
  {
    accessor: 'contractChatbotEnabled',
    label: 'Quest chat (DeepAI) - Contract',
    modalHeading: 'Edit Quest (Contract) chat module',
  },
  {
    accessor: 'driveChatbotEnabled',
    label: 'Quest chat (DeepAI) - Drive',
    modalHeading: 'Edit Quest (Drive) chat module',
  },
  {
    accessor: 'preQualEnabled',
    label: 'Pre-qualification',
    modalHeading: 'Edit pre-qualification',
  },
  {
    accessor: 'contractESignatureEnabled',
    label: 'Contract e-signature',
    modalHeading: 'Edit contract e-signature',
  },
  {
    accessor: 'newRequestFeaturesEnabled',
    label: 'New request features – Lots; Award scenarios; New UX (supplier and buyer); New award flow.',
    modalHeading: 'Edit new request features',
    modalBody: (
      <>
        <Stack gap={2}>
          <Text>
            This includes the following features – Lots; Award scenarios;
            New UX (Supplier and Buyer); New award flow.
          </Text>
          <Text>
            The only feature available for previously-issued requests is the new UX,
            which will be used automatically either 30 days after award or close, or
            14 days after the last user interaction with the request, whichever is longer.
          </Text>
        </Stack>
        <MessageBlock variant="warn" mt={0}>
          Once enabled this cannot be disabled
        </MessageBlock>
      </>
    ),
  },
  {
    accessor: 'notificationsIntegration',
    label: 'Notifications integration',
    modalHeading: 'Edit Notifications integration',
  },
];

export const CompanyFeatureList = ({ company }: { company: any }) => {
  const editFeatureFlagModal = useModalState();
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });
  const [selectedFeatureFlag, setSelectedFeatureFlag] = useState<FeatureFlagConfig | null>(null);
  const env = useEnv();

  const companyFeatureFlags = useMemo(() => {
    return {
      ...company.featureFlags,
      newRequestFeaturesEnabled: Boolean(company.newRequestFeaturesEnabledDate),
    };
  }, [company]);

  const properties = useMemo(
    () => featureFlagsConfig.map(featureFlag => ({
      name: featureFlag.label,
      value: Boolean(companyFeatureFlags[featureFlag.accessor]),
      heightAuto: true,
      labelWidth: '66%',
    })),
    [companyFeatureFlags],
  );

  return (
    <>
      <PropertyList
        properties={properties}
        DefaultComponent={EnabledDisabledPropertyValue}
        Row={TopAlignedRow}
      >
        {featureFlagsConfig.map(featureFlag => (
          <Box
            key={featureFlag.accessor}
            py="14px"
            width="56px"
          >
            {(
              featureFlag.accessor === 'newRequestFeaturesEnabled' &&
              (
                !systemFeatureFlags.newRequestFeaturesEnabled ||
                // Only allow disabling 'newRequestFeaturesEnabled' feature in dev and test envs
                (!['dev', 'test'].includes(env.ENV) && companyFeatureFlags[featureFlag.accessor])
              )
            ) ? (
              null
            ) : (
              <PropertyListAction
                label="Edit"
                icon="pencil-alt"
                onClick={() => {
                  setSelectedFeatureFlag(featureFlag);
                  editFeatureFlagModal.open();
                }}
              />
            )}
          </Box>
        ))}
      </PropertyList>
      {selectedFeatureFlag && (
        <EditCompanyFeatureFlagModal
          companyId={company._id}
          isOpen={editFeatureFlagModal.isOpen}
          onCancel={editFeatureFlagModal.close}
          onSave={editFeatureFlagModal.close}
          onRequestClose={editFeatureFlagModal.close}
          featureFlag={selectedFeatureFlag}
          value={Boolean(companyFeatureFlags[selectedFeatureFlag.accessor])}
        />
      )}
    </>
  );
};
