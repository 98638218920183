import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { CancelButton, OkButton, Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { compact, isEmpty, omit, pick } from 'lodash';
import { Draft, Lot, RfxSection, SectionType, isLinkedEvaluationSection, renderSectionName } from '@deepstream/common/rfq-utils';
import { DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { TextTooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import styled from 'styled-components';
import { Modal, ModalHeader } from '@deepstream/ui-kit/elements/popup/Modal';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import * as rfx from '../rfx';
import { NO_LOT_PROXY_ID, useLotSelectItems } from './useLotSelectItems';
import { SelectFieldBase, getItemLabelWithIcon, getItemLabelWithIconAndDescription } from '../form/SelectField';
import { NoLotsAddedFooter } from './SectionConfigLotSelectField';
import { StepProgress } from '../ui/StepProgress';
import { RadioFieldBase } from '../form/RadioField';
import { ResetLabelStyle } from '../modules/Exchange/InlineExchangeReply';

const Ul = styled.ul`
  padding-inline-start: 20px;
  line-height: 1.5;
  margin-bottom: 0px;
`;

const LotSelectField = ({
  isEvaluation,
  selectedLotId,
  setSelectedLotId,
  lots,
}: {
  isEvaluation?: boolean;
  selectedLotId: string | null;
  setSelectedLotId: (lotId: string | null) => void;
  lots: Lot[];
}) => {
  const { t } = useTranslation('translation');
  const lotSelectItems = useLotSelectItems({
    isEvaluation,
    lots,
  });

  const filteredLotSelectItems = React.useMemo(() => {
    return lotSelectItems.filter(lotSelectItem => {
      return !lots.find(lot => lot._id === lotSelectItem.value)?.isObsolete;
    });
  }, [lotSelectItems, lots]);

  const disabled = isEmpty(lots);

  return (
    <SelectFieldBase
      hideLabel
      items={filteredLotSelectItems}
      value={selectedLotId || NO_LOT_PROXY_ID}
      onChange={(newLotId: string) => {
        if (disabled) {
          // don't save when disabled
        } else if (newLotId === NO_LOT_PROXY_ID) {
          setSelectedLotId(null);
        } else {
          setSelectedLotId(newLotId);
        }
      }}
      disabled={disabled}
      helperText={selectedLotId ? (
        isEvaluation
          ? t('request.lots.evaluationSectionLotConfigurationLotHelperText')
          : t('request.lots.sectionLotConfigurationLotHelperText')
      ) : (
        isEvaluation
          ? t('request.lots.evaluationSectionLotConfigurationNoLotHelperText')
          : t('request.lots.sectionLotConfigurationNoLotHelperText')
      )}
      getItemLabel={getItemLabelWithIconAndDescription}
      getButtonLabel={getItemLabelWithIcon}
      menuWidth={300}
      buttonStyle={{ fontWeight: 500, width: 300 }}
      renderPreItemContent={(_item, index) => index === 1 ? <PanelDivider /> : null}
      footer={lotSelectItems.length === 1 ? <NoLotsAddedFooter /> : undefined}
    />
  );
};

const ConfigureSectionLotsModalBody = ({
  sections,
  availableLots,
  selectedLotId,
  setSelectedLotId,
  firstParagraphTranslationKey,
}: {
  sections: RfxSection[];
  availableLots: Lot[];
  selectedLotId: string | null;
  setSelectedLotId: (lotId: string | null) => void;
  firstParagraphTranslationKey: string;
}) => {
  const { t } = useTranslation('translation');
  const { sectionById, version } = rfx.useStructure<Draft>();

  const sectionIds = sections.map(section => section._id);
  const linkedSections = Object.values(sectionById)
    .filter(section => isLinkedEvaluationSection(section) && sectionIds.includes(section.linkedSectionId));

  const allSections = [
    ...sections,
    ...linkedSections,
  ];

  const tooltipContent = (
    <Ul>
      {allSections.map(section => {
        const nameProvidingSection = isLinkedEvaluationSection(section)
          ? sectionById[section.linkedSectionId]
          : section;

        return (
          <li key={section._id}>
            {renderSectionName(nameProvidingSection, t) || t('general.untitled')}
            {' '}
            {section.type !== SectionType.AUCTION_LINE_ITEMS && `(${t(`request.sectionType.${section.type}`)})`}
          </li>
        );
      })}
    </Ul>
  );

  const isEvaluation = !sections.some(section => section.type !== SectionType.EVALUATION);

  const isRevision = version > -1;

  return (
    <Stack gap={2}>
      <Text>
        <Trans
          t={t}
          i18nKey={firstParagraphTranslationKey}
          values={{ count: allSections.length }}
          components={{
            tooltip: <TextTooltip content={tooltipContent} boxProps={{ fontWeight: 500, sx: { textDecoration: 'underline' } }} />,
          }}
        />
      </Text>
      <Text>
        {t('request.lots.dialog.saveChangesModal.canAdjustConfiguration')}
      </Text>
      <LotSelectField
        selectedLotId={selectedLotId}
        setSelectedLotId={setSelectedLotId}
        isEvaluation={isEvaluation}
        lots={availableLots}
      />
      <Text mt={1}>
        {isRevision ? (
          t('request.lots.dialog.saveChangesModal.canConfigureLaterRevision')
        ) : (
          t('request.lots.dialog.saveChangesModal.canConfigureLater')
        )}
      </Text>
    </Stack>
  );
};

const ReconfigureObsoleteLotSectionsBody = ({
  lotWithSectionIds,
  availableLots,
  lotReassignments,
  setLotReassignments,
}: {
  lotWithSectionIds: AdditionalSummaryLotsSaveChangesDialogProps['newObsoleteLotsWithSectionIds'][number];
  availableLots: Lot[];
  lotReassignments: Record<string, LotReassignment>;
  setLotReassignments: React.Dispatch<React.SetStateAction<Record<string, LotReassignment>>>;
}) => {
  const { t } = useTranslation('translation');
  const { sectionById } = rfx.useStructure<Draft>();

  const { lot, lotIndex, sectionIds } = lotWithSectionIds;

  const sections = Object.values(pick(sectionById, sectionIds));

  const linkedSections = Object.values(sectionById)
    .filter(section => isLinkedEvaluationSection(section) && sectionIds.includes(section.linkedSectionId));

  const allSections = [
    ...sections,
    ...linkedSections,
  ];

  const lotReassignment = lotReassignments[lot._id];

  const selectedLotId = lotReassignment?.newLotId;

  const setSelectedLotId = (lotId: string) => {
    setLotReassignments(lotReassignments => ({
      ...lotReassignments,
      [lot._id]: {
        newLotId: lotId,
      },
    }));
  };

  const tooltipContent = (
    <Ul>
      {allSections.map(section => {
        const nameProvidingSection = isLinkedEvaluationSection(section)
          ? sectionById[section.linkedSectionId]
          : section;

        return (
          <li key={section._id}>
            {renderSectionName(nameProvidingSection, t) || t('general.untitled')}
            {' '}
            {section.type !== SectionType.AUCTION_LINE_ITEMS && `(${t(`request.sectionType.${section.type}`)})`}
          </li>
        );
      })}
    </Ul>
  );

  const isEvaluation = !sections.some(section => section.type !== SectionType.EVALUATION);

  const radioOptions: any = React.useMemo(() => {
    return [
      'keepSections',
      'reassignSections',
    ].map(value => ({
      value,
      label: t(`request.lots.dialog.saveChangesModal.reassignOption.${value}.label`),
      description: t(`request.lots.dialog.saveChangesModal.reassignOption.${value}.description`),
    }));
  }, [t]);

  return (
    <Stack gap={2}>
      <Text fontWeight={500}>
        {t('request.lot', { count: 1 })}
        {' '}
        {lotIndex + 1}
        {' – '}
        {lot.name || t('general.untitled')}
      </Text>
      <Text>
        <Trans
          t={t}
          i18nKey="request.lots.dialog.saveChangesModal.sectionAssignedToLot"
          ns="translation"
          values={{ count: allSections.length }}
          components={{
            tooltip: <TextTooltip content={tooltipContent} boxProps={{ fontWeight: 500, sx: { textDecoration: 'underline' } }} />,
          }}
        />
      </Text>

      <ResetLabelStyle mt={1}>
        <RadioFieldBase
          variant="stacked"
          options={radioOptions}
          value={lotReassignment ? 'reassignSections' : 'keepSections'}
          onChange={(value) => {
            if (value === 'reassignSections') {
              // @ts-expect-error ts(2345) FIXME: Argument of type '(lotReassignments: Record<string, LotReassignment>) => { [x: string]: LotReassignment | { newLotId: null; }; }' is not assignable to parameter of type 'SetStateAction<Record<string, LotReassignment>>'.
              setLotReassignments(lotReassignments => ({
                ...lotReassignments,
                [lot._id]: {
                  newLotId: null,
                },
              }));
            } else if (value === 'keepSections') {
              setLotReassignments(lotReassignments => omit(lotReassignments, lot._id));
            }
          }}
        />
      </ResetLabelStyle>

      {lotReassignment && (
        <Box ml="24px">
          <LotSelectField
            selectedLotId={selectedLotId}
            // @ts-expect-error ts(2322) FIXME: Type '(lotId: string) => void' is not assignable to type '(lotId: string | null) => void'.
            setSelectedLotId={setSelectedLotId}
            isEvaluation={isEvaluation}
            lots={availableLots}
          />
        </Box>
      )}

      <Text mt={1}>
        {t('request.lots.dialog.saveChangesModal.canConfigureLaterRevision')}
      </Text>

      {!lotReassignment && (
        <MessageBlock variant="warn" mt={1}>
          {t('request.lots.dialog.saveChangesModal.keepSectionsWarning')}
        </MessageBlock>
      )}
    </Stack>
  );
};

export type AdditionalSummaryLotsSaveChangesDialogProps = {
  sectionsWithLotIdsToDelete: RfxSection[];
  hasAddedNewLots: boolean;
  sectionsWithoutLots: RfxSection[];
  availableLots: Lot[];
  newObsoleteLotsWithSectionIds: {
    lot: Lot;
    lotIndex: number;
    sectionIds: string[];
  }[];
};

export type LotReassignment = {
  newLotId: string;
};

export const SummaryLotsSaveChangesDialog = ({
  sectionsWithLotIdsToDelete,
  hasAddedNewLots,
  sectionsWithoutLots,
  availableLots,
  newObsoleteLotsWithSectionIds,
  onCancel,
  onOk,
  ...props
}: DialogProps & AdditionalSummaryLotsSaveChangesDialogProps) => {
  const { t } = useTranslation(['translation', 'general']);

  const [deletedLotsReplacementLotId, setDeletedLotsReplacementLotId] = React.useState<string | null>(null);
  const [addedLotsLotId, setAddedLotsLotId] = React.useState<string | null>(null);
  const [lotReassignments, setLotReassignments] = React.useState<Record<string, LotReassignment>>({});

  const steps = compact([
    ...newObsoleteLotsWithSectionIds.map(({ lot }) => `reconfigureObsolete-${lot._id}`),
    !isEmpty(sectionsWithLotIdsToDelete) && 'deletedLots',
    hasAddedNewLots && !isEmpty(sectionsWithoutLots) && 'addedLots',
  ]);

  const [currentStep, setCurrentStep] = React.useState(steps[0]);

  const [disabled, setDisabled] = React.useState<boolean>(false);

  const handleOk = React.useCallback(
    async () => {
      try {
        setDisabled(true);
        try {
          await onOk({ deletedLotsReplacementLotId, addedLotsLotId, lotReassignments });
        } catch (_) {
          // ignore
        }
      } finally {
        setDisabled(false);
      }
    },
    [addedLotsLotId, deletedLotsReplacementLotId, lotReassignments, onOk],
  );

  const heading = currentStep === 'deletedLots' ? (
    t('request.lots.dialog.saveChangesModal.deletedLotsHeading')
  ) : currentStep === 'addedLots' ? (
    t('request.lots.dialog.saveChangesModal.addedLotsHeading')
  ) : (
    t('request.lots.dialog.saveChangesModal.reconfigureSectionsHeading')
  );

  const currentStepIndex = steps.indexOf(currentStep);

  const body = currentStep === 'deletedLots' ? (
    <ConfigureSectionLotsModalBody
      firstParagraphTranslationKey="request.lots.dialog.saveChangesModal.deletedLotsSectionAssigned"
      sections={sectionsWithLotIdsToDelete}
      availableLots={availableLots}
      selectedLotId={deletedLotsReplacementLotId}
      setSelectedLotId={setDeletedLotsReplacementLotId}
    />
  ) : currentStep === 'addedLots' ? (
    <ConfigureSectionLotsModalBody
      firstParagraphTranslationKey="request.lots.dialog.saveChangesModal.addedLotsSectionAssigned"
      sections={sectionsWithoutLots}
      availableLots={availableLots}
      selectedLotId={addedLotsLotId}
      setSelectedLotId={setAddedLotsLotId}
    />
  ) : (
    <ReconfigureObsoleteLotSectionsBody
      lotWithSectionIds={newObsoleteLotsWithSectionIds[currentStepIndex]}
      availableLots={availableLots}
      lotReassignments={lotReassignments}
      setLotReassignments={setLotReassignments}
    />
  );

  return (
    <Modal onRequestClose={onCancel} contentLabel={heading} {...props}>
      <ModalHeader onClose={onCancel}>
        {heading}
      </ModalHeader>
      <Text p={3} fontSize={2}>
        {body}
      </Text>
      <PanelDivider />
      <Flex p={3} justifyContent="space-between" alignItems="center">
        <Box>
          {steps.length > 1 && (
            <StepProgress
              currentStepIndex={currentStepIndex}
              totalStepCount={steps.length}
              currentStepName={['deletedLots', 'addedLots'].includes(currentStep) ? (
                t('request.lots.dialog.saveChangesModal.configureLots')
              ) : (
                t('request.lots.dialog.saveChangesModal.reconfigureSections', {
                  lotNumber: newObsoleteLotsWithSectionIds[currentStepIndex].lotIndex + 1,
                })
              )}
            />
          )}
        </Box>
        <Box>
          {onCancel && (
            <CancelButton
              onClick={onCancel}
              mr={2}
              disabled={disabled}
            />
          )}
          {steps[currentStepIndex - 1] && (
            <Button onClick={() => setCurrentStep(steps[currentStepIndex - 1])} mr={2}>
              {t('back', { ns: 'general' })}
            </Button>
          )}
          {steps[currentStepIndex + 1] ? (
            <Button onClick={() => setCurrentStep(steps[currentStepIndex + 1])}>
              {t('general.next')}
            </Button>
          ) : (
            <OkButton
              onClick={handleOk}
              style={{ minWidth: 60 }}
              disabled={disabled}
            >
              {t('general.saveChanges')}
            </OkButton>
          )}
        </Box>
      </Flex>
    </Modal>
  );
};
