import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat, localeFormatDate, localeFormatPrice } from '@deepstream/utils';
import { CountDate, RequestsBudgetCardsData, RequestsSavingsCardsData, RequestsValueCardsData, getCardsDateKey, getCardsPriceKey } from '@deepstream/common/reporting';
import { RfqStatus } from '@deepstream/common/rfq-utils';
import { GetTooltipConfig } from '@deepstream/ui-kit/charts/ScatterChart';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { useRequestsNavigation } from '../../RequestsNavigationContext';

type GetBudgetTooltipFn<TData extends RequestsBudgetCardsData | RequestsValueCardsData | Pick<RequestsSavingsCardsData, 'config' | 'requests'>> = GetTooltipConfig<TData['requests'][number]>;

export const useGetScatterChartTooltipConfig = <TData extends RequestsBudgetCardsData | RequestsValueCardsData | Pick<RequestsSavingsCardsData, 'config' | 'requests'>>(
  data: TData,
  section: 'budget' | 'value' | 'savings',
): GetBudgetTooltipFn<TData> => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const { navigateToSenderRequest } = useRequestsNavigation();

  return useCallback((rfqSummary: TData['requests'][number]) => {
    const priceKey = getCardsPriceKey(section);
    const dateKey = getCardsDateKey(data.config.countDate);

    return {
      onClick: () => navigateToSenderRequest({
        requestId: rfqSummary._id,
        isDraft: rfqSummary.extendedStatus === RfqStatus.DRAFT,
      }),
      buttonText: t('request.spendAndSavings.openRequest'),
      items: [
        {
          label: `${t('general.request', { count: 1 })}:`,
          value: rfqSummary.subject,
        },
        {
          label: data.config.countDate === CountDate.REQUEST_AWARD ? (
            `${t('request.spendAndSavings.awardDate')}:`
          ) : data.config.countDate === CountDate.FINAL_DEADLINE ? (
            `${t('request.spendAndSavings.finalDeadline')}:`
          ) : (
            `${t('request.spendAndSavings.creationDate')}:`
          ),
          // @ts-expect-error ts(2769) FIXME: No overload matches this call.
          value: localeFormatDate(new Date(rfqSummary[dateKey]), DateFormat.DD_MMM_YYYY, { locale }),
        },
        {
          label: section === 'budget' ? (
            `${t('request.spendAndSavings.budget')}:`
          ) : section === 'value' ? (
            `${t('request.spendAndSavings.value')}:`
          ) : (
            `${t('general.savings')}:`
          ),
          value: localeFormatPrice(rfqSummary[priceKey], data.config.currency, { locale }),
        },
      ],
    };
  }, [navigateToSenderRequest, t, data, section, locale]);
};
