import * as React from 'react';
import { useQuery } from 'react-query';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { IntegrationDataType } from '@deepstream/ui/types';
import { Company } from '@deepstream/ui/ui/types';
import { ErrorPanel } from '@deepstream/ui/ui/ErrorMessage';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as Title from '../../title';
import { ExternalCompaniesTable } from './ExternalCompaniesTable';
import { ExternalSystemsPanel } from './AdminExternalSystemsPanel';

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

export const CompanyIntegrations = ({ company }: { company: Company }) => {
  const adminApi = useAdminApi();
  const companyId = company._id;

  const { data: integrationData, isError: errorLoadingIntegrationData } = useQuery(
    ['integrationData', { companyId, type: IntegrationDataType.EXTERNAL_COMPANY_TO_INTERNAL_COMPANY }],
    wrap(adminApi.getIntegrationDataByCompany),
  );

  return (
    <Stack gap={4}>
      <ExternalSystemsPanel company={company} />
      {errorLoadingIntegrationData ? (
        <ErrorPanel error="Could not load external companies data" />
      ) : integrationData?.length ? (
        <Panel heading="External Companies Data">
          <ExternalCompaniesTable integrationData={integrationData} />
        </Panel>
      ) : (
        null
      )}
    </Stack>
  );
};
