import { Flex } from 'rebass/styled-components';
import { SHOW_CHRISTMAS_LOGO } from '@deepstream/common/constants';
import DeepStreamLogo from '../DeepStreamLogo';
import { DeepStreamLogoXmas } from '../DeepStreamLogoXmas';
import { Loading } from './Loading';
import { ErrorMessage } from './ErrorMessage';

export function FullScreenLoading({ loadingText }: { loadingText?: string }) {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" style={{ gap: 20 }}>
      {SHOW_CHRISTMAS_LOGO ? (
        <DeepStreamLogoXmas variant="black" />
      ) : (
        <DeepStreamLogo variant="black" />
      )}
      <Loading color="black" loadingText={loadingText} />
    </Flex>
  );
}

export function FullScreenError({ error }: { error: string }) {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" style={{ gap: 20 }}>
      {SHOW_CHRISTMAS_LOGO ? (
        <DeepStreamLogoXmas variant="black" />
      ) : (
        <DeepStreamLogo variant="black" />
      )}
      <ErrorMessage fontSize={2} error={error} />
    </Flex>
  );
}
