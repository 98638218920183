import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass/styled-components';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useCurrentCompanyId } from './currentCompanyId';
import { CompanySelectItem } from './useSelectParentChildCompanies';
import { DropdownHeading, SelectDropdownMenu, SelectDropdownMenuProps } from './ui/MultiSelect';
import { CompanyLogoAndName } from './CompanyLogo';
import { HorizontalDivider } from './ui/HorizontalDivider';

export const SelectParentAndChildCompanies = (props: SelectDropdownMenuProps<CompanySelectItem>) => {
  const { t } = useTranslation(['translation', 'reporting', 'general']);
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { items, selectedItems = [], onChange } = props;

  const isParentSelected = selectedItems.some(item => item._id === currentCompanyId);

  return (
    <SelectDropdownMenu
      variant="secondary-outline"
      rightAligned
      multi
      getButtonText={() => {
        if (selectedItems.length === 0 || selectedItems.length === items.length) {
          return t('parentAndAllChildCompanies', { ns: 'reporting' });
        }

        if (selectedItems.length === 1) {
          return (
            <CompanyLogoAndName
              company={selectedItems[0]}
              size="xxs"
            />
          );
        }

        if (isParentSelected) {
          return t('parentAndChildCompanyCount', { ns: 'reporting', count: selectedItems.length - 1 });
        }

        if (selectedItems.length === items.length - 1) {
          return t('allChildCompanies', { ns: 'reporting' });
        }

        return t('childCompanyCount', { ns: 'reporting', count: items.length - 1 });
      }}
      renderPreItemContent={(_item, index) => {
        if (index === 0) {
          return (
            <DropdownHeading>
              {t('parentCompany', { ns: 'reporting' })}
            </DropdownHeading>
          );
        } else if (index === 1) {
          return (
            <>
              <HorizontalDivider my={2} />
              <Flex justifyContent="space-between">
                <DropdownHeading>
                  {t('childCompanies', { ns: 'reporting' })}
                </DropdownHeading>
                <Flex sx={{ gap: 2 }} mr="12px" mt="6px">
                  <Button
                    variant="secondary-outline"
                    small
                    onClick={() => {
                      const newItems = isParentSelected
                        ? items
                        : items.slice(1);

                      onChange(newItems);
                    }}
                    disabled={isParentSelected ? (
                      selectedItems.length === items.length
                    ) : (
                      selectedItems.length === items.length - 1
                    )}
                  >
                    {t('selectAll', { ns: 'general' })}
                  </Button>
                  <Button
                    variant="secondary-outline"
                    small
                    onClick={() => {
                      const newItems = isParentSelected
                        ? items.slice(0, 1)
                        : [];

                      onChange(newItems);
                    }}
                    disabled={isParentSelected ? (
                      selectedItems.length === 1
                    ) : (
                      selectedItems.length === 0
                    )}
                  >
                    {t('deselectAll', { ns: 'general' })}
                  </Button>
                </Flex>
              </Flex>
            </>
          );
        } else {
          return null;
        }
      }}
      menuWidth={392}
      menuZIndex={102}
      allowEmptySelection
      truncate={false}
      {...props}
    />
  );
};
