// Auto-generated file created by svgr-cli source svgr-config/indexTemplate.js
// Do not edit
import Check from './Check';
import Danger from './Danger';
import DeepstreamLogoXmas from './DeepstreamLogoXmas';
import DeepstreamLogo from './DeepstreamLogo';
import EmptySearchCompaniesState from './EmptySearchCompaniesState';
import EmptyState from './EmptyState';
import Question from './Question';

export default {
  check: Check,
  danger: Danger,
  'deepstream-logo-xmas': DeepstreamLogoXmas,
  'deepstream-logo': DeepstreamLogo,
  'empty-search-companies-state': EmptySearchCompaniesState,
  'empty-state': EmptyState,
  question: Question,
};
