import { useEffect, useMemo } from 'react';
import * as React from 'react';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { difference, findIndex, intersection, isEmpty, isEqual, mapValues, omit, size, sortBy, without } from 'lodash';
import { FieldScope, getAdjacentFieldId, getDefaultFieldTags, getFieldIdsInDefaultDisplayOrder, getStageIdFromTag, LineItemExchangeDefinition, LineItemExchangeFields, LineItemsSection, ResponseTag } from '@deepstream/common/rfq-utils';
import { evaluate, formulaParser } from '@deepstream/formula';
import { localeFormatPrice } from '@deepstream/utils';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, SaveButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { FieldType } from '@deepstream/common/exchangesConfig';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { FieldContainer } from '../../../../form/FieldContainer';
import { RadioField } from '../../../../form/RadioField';
import { SwitchField, MappingSwitchField } from '../../../../form/SwitchField';
import { TextField } from '../../../../form/TextField';
import { LabelConfig, LabelConfigProvider } from '../../../../LabelConfigProvider';

import { ConfigurableFieldFormValues, SupplierResponseValue } from '../types';
import { DecimalPlacesControl } from '../../../../ui/DecimalPlacesControl';
import { FormulaField } from '../../../../form/FormulaField';
import { useCurrentUserLocale } from '../../../../useCurrentUser';
import * as rfx from '../../../../rfx';
import { ContextType, useHooks } from '../../../../useHooks';
import { addConfigurableField, createAddDefaultFieldUpdater, createAddLeadTimeFieldUpdater, updateField } from '../../../../ui/ExchangeDefsGrid/exchangeDefUpdaters';
import { hiddenFieldIds } from '../../../../ui/ExchangeDefsGrid/useConfigurableFieldColumns';
import { ConfigureFieldModalProps, InputsConfig, RelativeFieldInsertion } from './useConfigureFieldModal';

const FieldsContainer = styled(Box)`
  & > div:not(:first-child) {
    border-top: ${props => props.theme.borders.secondary}
  }
`;

const ResponseTagStageIndices = ({ tags }: { tags: ResponseTag[] }) => {
  const { stages } = rfx.useStructure();

  return (
    <>
      {tags.map(tag => {
        const stageId = getStageIdFromTag(tag);

        return findIndex(stages, stage => stage._id === stageId) + 1;
      }).join(', ')}
    </>
  );
};

const SuppliersWillRespondInStage = ({ tag }: { tag: ResponseTag }) => {
  const { t } = useTranslation();
  const { stages } = rfx.useStructure();

  const stageId = getStageIdFromTag(tag);

  const stageNum = findIndex(stages, stage => stage._id === stageId) + 1;

  return (
    <>
      {t('request.multiStageBehavior.willRespondInStage', { stageNum })}
    </>
  );
};

const FormFields = ({
  inputsConfig,
  fields,
  responseTagConfig,
}: {
  inputsConfig: InputsConfig;
  fields?: LineItemExchangeFields;
  responseTagConfig?: LineItemsSection['responseTagConfig'];
}) => {
  const { contextType } = useHooks();
  const { t } = useTranslation(['request', 'translation']);
  const { values, setFieldValue } = useFormikContext<ConfigurableFieldFormValues>();
  const locale = useCurrentUserLocale();
  const priceExample = localeFormatPrice(
    1230,
    'USD',
    { locale },
  );

  const showMultiStageBehaviorFields = contextType === ContextType.RFX && (
    values.supplierResponseEnabled || inputsConfig.isFixedSupplierResponseField
  );

  const supplierResponseTypeOptions = useMemo(() => {
    const options = [{
      label: t('lineItems.genericLineItemConfig.supplierResponseValue.freeResponseLabel'),
      description: t('lineItems.genericLineItemConfig.supplierResponseValue.freeResponseDescription'),
      value: SupplierResponseValue.REPLY,
    }];

    // `Accept or reject` option makes sense only if we have a buyer provided value
    // the configuration of the form supports it
    if (values.buyerProvidedValueEnabled) {
      options.push({
        label: t('lineItems.genericLineItemConfig.supplierResponseValue.acceptOrRejectLabel'),
        description: t('lineItems.genericLineItemConfig.supplierResponseValue.acceptOrRejectDescription'),
        value: SupplierResponseValue.BOOLEAN,
      });
    }

    return options;
  }, [t, values.buyerProvidedValueEnabled]);

  const formulaFormatOptions = useMemo(() => [
    {
      label: t('lineItems.genericLineItemConfig.formulaFormat.priceLabel'),
      description: t('lineItems.genericLineItemConfig.formulaFormat.priceDescription', { price: priceExample }),
      value: FieldType.PRICE,
    },
    {
      label: t('lineItems.genericLineItemConfig.formulaFormat.numberLabel'),
      description: t('lineItems.genericLineItemConfig.formulaFormat.numberDescription'),
      value: FieldType.NUMBER,
    },
  ], [priceExample, t]);

  useEffect(() => {
    if (
      !values.buyerProvidedValueEnabled &&
      values.supplierResponseEnabled &&
      values.supplierResponseType !== SupplierResponseValue.REPLY
    ) {
      setFieldValue('supplierResponseType', SupplierResponseValue.REPLY);
    } else if (values.supplierResponseEnabled && !values.supplierResponseType) {
      setFieldValue('supplierResponseType', SupplierResponseValue.REPLY);
    } else if (!values.supplierResponseEnabled) {
      setFieldValue('supplierResponseType', undefined);
    }
  }, [values.supplierResponseType, values.buyerProvidedValueEnabled, setFieldValue, values.supplierResponseEnabled]);

  return (
    <LabelConfigProvider
      variant={LabelConfig.LEFT}
      gap={4}
      width="250px"
      style={{
        default: {
          fontSize: 2,
          position: 'relative',
          color: 'text',
          marginBottom: '2px',
        },
      }}
    >
      <FieldsContainer>
        {!inputsConfig?.fieldName?.disabled && (
          <FieldContainer
            name="fieldName"
            label={t('lineItems.genericLineItemConfig.fieldName.label')}
            description={t('lineItems.genericLineItemConfig.fieldName.description')}
            sx={{ py: 3, px: '20px', width: '640px' }}
            showAsterisk
          >
            <TextField
              name="fieldName"
              placeholder={t('lineItems.genericLineItemConfig.fieldName.placeholder')}
              {...inputsConfig?.fieldName}
            />
          </FieldContainer>
        )}

        {inputsConfig?.enableDecimalPlaces && (
          <FieldContainer
            name="decimalPlaces"
            label={t('lineItems.decimalPlaces.label')}
            description={t('lineItems.decimalPlaces.description')}
            sx={{ py: 3, px: '20px', width: '640px' }}
          >
            <DecimalPlacesControl name="decimalPlaces" hideDescription />
          </FieldContainer>
        )}

        {inputsConfig?.enableBuyerProvided && (
          <FieldContainer
            name="buyerProvidedValue"
            label={t('lineItems.genericLineItemConfig.buyerProvidedValue.label')}
            description={t('lineItems.genericLineItemConfig.buyerProvidedValue.description')}
            sx={{ py: 3, px: '20px', width: '640px' }}
          >
            <Stack gap={3}>
              <SwitchField
                name="buyerProvidedValueEnabled"
                onChange={(checked) => {
                  if (inputsConfig?.divergentToggles && checked) {
                    setFieldValue('supplierResponseEnabled', false);
                  }
                }}
                {...inputsConfig?.buyerProvidedToggle}
              />
            </Stack>
          </FieldContainer>
        )}

        {inputsConfig?.enableSupplierResponse && (
          <FieldContainer
            name="supplierResponse"
            label={t('lineItems.genericLineItemConfig.supplierResponseEnabled.label')}
            description={t('lineItems.genericLineItemConfig.supplierResponseEnabled.description')}
            sx={{ py: 3, px: '20px', width: '640px' }}
          >
            <Stack gap={3}>
              <SwitchField
                name="supplierResponseEnabled"
                onChange={(checked) => {
                  if (inputsConfig?.divergentToggles && checked) {
                    setFieldValue('buyerProvidedValueEnabled', false);
                  }
                  if (responseTagConfig && checked) {
                    setFieldValue('supplierResponseTags', getDefaultFieldTags(responseTagConfig, values.type));
                  }
                }}
                {...inputsConfig?.supplierResponseToggle}
              />
              {values.supplierResponseEnabled && (
                <RadioField
                  name="supplierResponseType"
                  options={supplierResponseTypeOptions}
                  showError
                />
              )}
            </Stack>
          </FieldContainer>
        )}

        {showMultiStageBehaviorFields && (
          <FieldContainer
            name="multiStageBehavior"
            label={t('request.multiStageBehavior.multiStageBehavior', { ns: 'translation' })}
            description={t('request.multiStageBehavior.multiStageBehaviorFieldModalDescription', { ns: 'translation' })}
            sx={{ py: 3, px: '20px', width: '640px' }}
          >
            {responseTagConfig ? (
              <>
                <Text fontWeight={500}>
                  {t('request.multiStageBehavior.perStage.label', { ns: 'translation' })}
                </Text>
                <Text>
                  {t('request.multiStageBehavior.selectedStages', { ns: 'translation' })}
                  {': '}
                  {isEmpty(responseTagConfig.tags) ? (
                    <EmDash />
                  ) : (
                    <ResponseTagStageIndices tags={responseTagConfig.tags} />
                  )}
                </Text>
              </>
            ) : (
              <Text fontWeight={500}>
                {t('request.multiStageBehavior.single.label', { ns: 'translation' })}
              </Text>
            )}
          </FieldContainer>
        )}

        {showMultiStageBehaviorFields && responseTagConfig && (
          <FieldContainer
            name="supplierResponseTags"
            label={t('request.multiStageBehavior.requiredInLaterStages', { ns: 'translation' })}
            description={t('request.multiStageBehavior.requiredInLaterStagesDescription', { ns: 'translation' })}
            sx={{ py: 3, px: '20px', width: '640px' }}
          >
            <MappingSwitchField
              name="supplierResponseTags"
              disabled={size(responseTagConfig.tags) < 2}
              mapFromField={(value: string[]) => size(value) > 1}
              mapToField={(checked) => checked
                ? [...responseTagConfig.tags]
                : responseTagConfig.tags.slice(0, 1)}
              checkedIcon={false}
              uncheckedIcon={false}
              width={42}
              checkedText={t('request.multiStageBehavior.fieldRequired', { ns: 'translation' })}
              uncheckedText={t('request.multiStageBehavior.fieldNotRequired', { ns: 'translation' })}
            />
            <Box mt="2px">
              {size(values.supplierResponseTags) > 1 ? (
                t('request.multiStageBehavior.willRespondInAllStages', { ns: 'translation' })
              ) : size(values.supplierResponseTags) === 1 ? (
                // @ts-expect-error ts(18048) FIXME: 'values.supplierResponseTags' is possibly 'undefined'.
                <SuppliersWillRespondInStage tag={values.supplierResponseTags[0]} />
              ) : (
                null
              )}
            </Box>
          </FieldContainer>
        )}

        {inputsConfig?.isFormula && (
          <>
            <FieldContainer
              name="formula"
              label={t('lineItems.genericLineItemConfig.formulaInput.label')}
              description={t('lineItems.genericLineItemConfig.formulaInput.description')}
              infoTooltip={t('lineItems.genericLineItemConfig.formulaInput.infoTooltip')}
              sx={{ py: 3, px: '20px', width: '640px' }}
            >
              <FormulaField name="formula" fieldId={values?.fieldsetId} fields={fields} />
            </FieldContainer>

            {!inputsConfig.disableFormulaFormatSelection && (
              <FieldContainer
                name="formulaFormat"
                label={t('lineItems.genericLineItemConfig.formulaFormat.label')}
                description={t('lineItems.genericLineItemConfig.formulaFormat.description')}
                sx={{ py: 3, px: '20px', width: '640px' }}
              >
                <RadioField
                  name="formulaFormat"
                  options={formulaFormatOptions}
                  variant="inline"
                  showError
                />
              </FieldContainer>
            )}
          </>
        )}
      </FieldsContainer>
    </LabelConfigProvider>
  );
};

const validateFormulaCycles = (formula: string, fieldId: string, fields?: LineItemExchangeFields) => {
  function expandFormulaFieldsInFormula(formula: string) {
    return formula.replace(/\{([^}]+)}/g, (_, fieldId) => {
      // @ts-expect-error ts(18048) FIXME: 'fields' is possibly 'undefined'.
      const field = fields[fieldId];

      return field?.source.type === 'formula' ? field.source.formula : '';
    });
  }

  function hasFormulaCycles(formula: string) {
    return formula.includes(fieldId);
  }

  let expandedFormula = formula;
  let oldFormula = formula;
  do {
    if (hasFormulaCycles(expandedFormula)) {
      return false;
    }

    oldFormula = expandedFormula;
    expandedFormula = expandFormulaFieldsInFormula(oldFormula);
  } while (expandedFormula !== oldFormula);

  return true;
};

const validateFormulaEvaluation = (formula: string, fields?: LineItemExchangeFields) => {
  const inputValues = mapValues(
    fields,
    () => 0,
  );

  try {
    evaluate(formulaParser.parse(formula)?.expression, inputValues);

    return true;
  } catch (_) {
    return false;
  }
};

const getOrderedFieldIdsFromEdit = (
  currentFieldIds: string[],
  fieldId: string,
  previousFieldIds: string[],
  orderedFieldIdsArg?: string[],
) => {
  const orderedFieldIds = (
    orderedFieldIdsArg ||
    getFieldIdsInDefaultDisplayOrder(previousFieldIds)
  );

  const orderedCurrentFieldIds = sortBy(
    without(currentFieldIds, fieldId),
    fieldId => orderedFieldIds.indexOf(fieldId),
  );

  const adjacentFieldId = getAdjacentFieldId(fieldId);

  if (!adjacentFieldId) {
    return [...orderedCurrentFieldIds, fieldId];
  }

  const adjacentFieldIdIndexInCurrentFieldIds = orderedCurrentFieldIds.indexOf(adjacentFieldId);

  if (adjacentFieldIdIndexInCurrentFieldIds > -1) {
    const isSubmitterField = fieldId.endsWith(':submitter');
    const index = isSubmitterField
      ? adjacentFieldIdIndexInCurrentFieldIds + 1
      : adjacentFieldIdIndexInCurrentFieldIds;

    const newArray = [...orderedCurrentFieldIds];
    newArray.splice(index, 0, fieldId);
    return newArray;
  }

  const orderedPreviousFieldIds = sortBy(
    without(previousFieldIds, fieldId),
    fieldId => orderedFieldIds.indexOf(fieldId),
  );

  const adjacentFieldIdIndexInPreviousFieldIds = orderedPreviousFieldIds.indexOf(adjacentFieldId);

  if (adjacentFieldIdIndexInPreviousFieldIds > -1) {
    const newArray = [...orderedCurrentFieldIds];
    newArray.splice(adjacentFieldIdIndexInPreviousFieldIds, 0, fieldId);
    return newArray;
  }

  return [...orderedCurrentFieldIds, fieldId];
};

const getOrderedFieldIdsFromAddition = (
  currentFieldIds: string[],
  addedFieldIds: string[],
  orderedFieldIdsArg: string[] | undefined,
  relativeFieldInsertion?: RelativeFieldInsertion | null,
) => {
  const orderedFieldIds = (
    orderedFieldIdsArg ||
    getFieldIdsInDefaultDisplayOrder(currentFieldIds)
  );

  const orderedCurrentFieldIds = sortBy(
    without(currentFieldIds, ...addedFieldIds),
    fieldId => orderedFieldIds.indexOf(fieldId),
  );

  if (relativeFieldInsertion) {
    const fieldIndex = orderedCurrentFieldIds.indexOf(relativeFieldInsertion.fieldId);

    if (fieldIndex === -1) {
      return [...orderedCurrentFieldIds, ...addedFieldIds];
    }

    const newFieldIds = [...orderedCurrentFieldIds];

    const addedFieldsIndex = relativeFieldInsertion.direction === 'left'
      ? fieldIndex
      : fieldIndex + 1;

    newFieldIds.splice(addedFieldsIndex, 0, ...addedFieldIds);

    return newFieldIds;
  } else {
    return [...orderedCurrentFieldIds, ...addedFieldIds];
  }
};

export const addFieldsInOrder = (
  lineItem: LineItemExchangeDefinition,
  updater: (lineItem: LineItemExchangeDefinition) => LineItemExchangeDefinition,
  relativeFieldInsertion?: RelativeFieldInsertion | null,
) => {
  const previousFieldIds = Object.keys(lineItem.fields);
  const updatedLineItem = updater(lineItem);
  const updatedFieldIds = Object.keys(updatedLineItem.fields);
  const addedFieldIds = difference(updatedFieldIds, previousFieldIds);

  const orderedFieldIds = getOrderedFieldIdsFromAddition(
    updatedFieldIds,
    addedFieldIds,
    lineItem.orderedFieldIds,
    relativeFieldInsertion,
  );

  return {
    ...updatedLineItem,
    orderedFieldIds,
  };
};

export const ConfigureFieldModal = <T extends ConfigurableFieldFormValues,>({
  isOpen,
  close,
  config,
}: ConfigureFieldModalProps<T> & {
  isOpen: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation('request');
  const [{ value: responseTagConfig }, , responseTagConfigFormik] = useField({ name: 'section.responseTagConfig' });

  const {
    isEdit,
    relativeFieldInsertion,
    initialValues,
    heading,
    submitLabel,
    inputsConfig,
  } = config!;

  const {
    rowData: lineItemExchangeDefs,
    updateEachRowWith,
  } = useEditableGridData<LineItemExchangeDefinition>();

  const fields = lineItemExchangeDefs[0]?.fields;

  const handleSubmit = React.useCallback((fieldForm: ConfigurableFieldFormValues, inputsConfig) => {
    if (isEdit) {
      updateEachRowWith(
        (lineItem) => {
          const previousFieldIds = Object.keys(lineItem.fields);
          const updatedLineItem = updateField(lineItem, fieldForm, inputsConfig);
          const updatedFieldIds = Object.keys(updatedLineItem.fields);
          const addedFieldIds = difference(updatedFieldIds, previousFieldIds);
          // we can use `find` because there's at maximum one visible field
          // that can be added when editing a field
          const addedVisibleFieldId = addedFieldIds.find(fieldId => !hiddenFieldIds.includes(fieldId));

          if (!addedVisibleFieldId) {
            const orderedFieldIds = updatedLineItem.orderedFieldIds
              ? intersection(updatedLineItem.orderedFieldIds, Object.keys(updatedLineItem.fields))
              : getFieldIdsInDefaultDisplayOrder(Object.keys(updatedLineItem.fields));

            return {
              ...updatedLineItem,
              orderedFieldIds,
            };
          }

          const orderedFieldIds = getOrderedFieldIdsFromEdit(
            updatedFieldIds,
            addedVisibleFieldId,
            previousFieldIds,
            lineItem.orderedFieldIds,
          );

          return {
            ...updatedLineItem,
            orderedFieldIds,
          };
        },
      );
    } else {
      let updater;

      if (fieldForm.fieldsetId === 'leadTime:submitter') {
        updater = createAddLeadTimeFieldUpdater(fieldForm);
      } else if (fieldForm.fieldsetId === 'price') {
        updater = createAddDefaultFieldUpdater('price', fieldForm);
      } else {
        updater = (row) => addConfigurableField(row, fieldForm);
      }

      updateEachRowWith(lineItem => addFieldsInOrder(lineItem, updater, relativeFieldInsertion));
    }
  }, [updateEachRowWith, isEdit, relativeFieldInsertion]);

  return (
    <Modal isOpen={isOpen}>
      <Formik
        // @ts-expect-error ts(2322) FIXME: Type 'T | undefined' is not assignable to type 'FormikValues'.
        initialValues={initialValues}
        validationSchema={
          inputsConfig?.isFormula
            ? yup.object().shape({
                fieldName: yup.string().required(t('general.required', { ns: 'translation' })),
                formula: yup
                  .string()
                  .required(t('general.required', { ns: 'translation' }))
                  .test(
                    'formula-cycles',
                    t('request.lineItems.formula.editModal.invalidFormulaCycle', { ns: 'translation' }),
                    // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                    (value) => value && validateFormulaCycles(value, initialValues?.fieldsetId, fields),
                  )
                  .test(
                    'formula-evaluation',
                    t('request.lineItems.formula.editModal.invalidFormula', { ns: 'translation' }),
                    (value) => value && validateFormulaEvaluation(value, fields),
                  ),
                formulaFormat: yup.string().oneOf([
                  FieldType.NUMBER,
                  FieldType.PRICE,
                ]).required(t('general.required', { ns: 'translation' })),
              })
            : yup.object().shape({
                ...(!inputsConfig?.fieldName?.disabled ? {
                  fieldName: yup.string().required(t('general.required', { ns: 'translation' })),
                } : {}),
                ...(inputsConfig?.enableDecimalPlaces ? {
                  decimalPlaces: yup.number()
                    .transform((value) => Number.isNaN(value) ? null : value)
                    .nullable()
                    .min(0)
                    .required(t('general.required', { ns: 'translation' })),
                } : {}),
                buyerProvidedValueEnabled: yup.boolean().when('supplierResponseEnabled', {
                  is: (supplierResponseEnabled) => !supplierResponseEnabled,
                  then: yup.boolean().oneOf(
                    [true],
                    inputsConfig?.divergentToggles
                      ? t('lineItems.genericLineItemConfig.validation.oneFieldTypeChecked')
                      : t('lineItems.genericLineItemConfig.validation.atLeastOneFieldTypeChecked'),
                  ),
                }),
                supplierResponseEnabled: yup.boolean().when('buyerProvidedValueEnabled', {
                  is: (buyerProvidedValueEnabled) => !buyerProvidedValueEnabled,
                  then: yup.boolean().oneOf(
                    [true],
                    inputsConfig?.divergentToggles
                      ? t('lineItems.genericLineItemConfig.validation.oneFieldTypeChecked')
                      : t('lineItems.genericLineItemConfig.validation.atLeastOneFieldTypeChecked'),
                  ),
                }),
                supplierResponseType: yup.string().when('supplierResponseEnabled', {
                  is: (supplierResponseEnabled) => supplierResponseEnabled,

                  then: schema => schema.when('buyerProvidedValueEnabled', {
                    is: (buyerProvidedValueEnabled) => buyerProvidedValueEnabled,
                    then: yup.string().oneOf([
                      SupplierResponseValue.BOOLEAN,
                      SupplierResponseValue.REPLY,
                    ]).required(t('general.required', { ns: 'translation' })),
                    otherwise: yup.string().oneOf([
                      SupplierResponseValue.REPLY,
                    ]).required(t('general.required', { ns: 'translation' })),
                  }),
                }),
              }, [
                ['supplierResponseEnabled', 'buyerProvidedValueEnabled'],
                ['supplierResponseType', 'supplierResponseEnabled'],
              ])
        }
        onSubmit={(values) => {
          const fieldsToOmit = inputsConfig?.fieldName?.disabled
            // don't submit field name when field name is not editable
            ? ['fieldName']
            : [];

          const updatedFields = omit(values, fieldsToOmit);

          // normalize whitespace in formula
          // @ts-expect-error ts(2339) FIXME: Property 'formula' does not exist on type 'Omit<FormikValues, string>'.
          if ('formula' in updatedFields || updatedFields.formula) {
            // @ts-expect-error ts(2339) FIXME: Property 'formula' does not exist on type 'Omit<FormikValues, string>'.
            updatedFields.formula = updatedFields.formula
              .replace(/\(\s+/g, '(') // remove spaces after "("
              .replace(/\s+\)/g, ')') // remove spaces before ")"
              .replace(/\s+/g, ' ') // normalize remaining spaces
              .trim();
          }

          handleSubmit(updatedFields as T, inputsConfig);

          // whenever a user modifies the 'required' setting for a field manually,
          // we set the section's field scope to CUSTOM.
          if (
            responseTagConfig &&
            values.supplierResponseTags &&
            // @ts-expect-error ts(18048) FIXME: 'inputsConfig' is possibly 'undefined'.
            (inputsConfig.isFixedSupplierResponseField || values.supplierResponseEnabled)
          ) {
            // @ts-expect-error ts(18048) FIXME: 'initialValues' is possibly 'undefined'.
            const initialResponseTags = initialValues.supplierResponseTags || getDefaultFieldTags(responseTagConfig, values.type);

            if (!isEqual(initialResponseTags, values.supplierResponseTags)) {
              responseTagConfigFormik.setValue({
                ...responseTagConfig,
                fieldScope: FieldScope.CUSTOM,
              });
            }
          }

          close();
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalHeader>
              {heading}
            </ModalHeader>
            <ModalBody p={0}>
              <FormFields
                // @ts-expect-error ts(2322) FIXME: Type 'InputsConfig | undefined' is not assignable to type 'InputsConfig'.
                inputsConfig={inputsConfig}
                fields={fields}
                responseTagConfig={responseTagConfig}
              />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={close} />
              <SaveButton
                type="submit"
                disabled={isSubmitting || !isValid}
                label={submitLabel}
              />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
