import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { ACTION_COLUMN_WIDTH, EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { withProps } from '@deepstream/ui-utils/withProps';
import {
  FROZEN_LEFT_COLUMN_WIDTH,
  RequestsReportingTablePanel,
} from './RequestsReportingTablePanel';
import {
  CurrencyAmountOrDashCell,
  DateOrDashCell,
  OwnersCell,
  RequestStatusCell,
  SavingsCalculationMethodOrDashCell,
  SavingsConfirmedOrDashCell,
  SectionStatusCell,
  TextOrDashCell,
  UsedCell,
  SimpleRowNumberCell,
  LinkIconOverlay,
} from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { SimpleHeader, SortableHeader } from '../../ui/ExchangeDefsGrid/header';
import { useRequestsReportingTableData } from './useRequestsReporting';

export const SavingsTablePanel = ({
  config,
  useData = useRequestsReportingTableData,
}: { config: RequestsReportingConfig; useData?: typeof useRequestsReportingTableData }) => {
  const { t } = useTranslation('translation');

  const columns: EditableGridColumn[] = useMemo(() => {
    return [
      {
        _id: 'rowNumber',
        accessorKey: 'rowNumber',
        Header: SimpleHeader,
        label: '',
        ValueCell: withProps(SimpleRowNumberCell, { HoverOverlay: LinkIconOverlay }),
        width: ACTION_COLUMN_WIDTH,
      } as EditableGridColumn,
      {
        _id: 'subject',
        accessorKey: 'subject',
        Header: SortableHeader,
        label: t('request.spendAndSavings.requestName'),
        width: FROZEN_LEFT_COLUMN_WIDTH,
        sortType: 'caseInsensitive',
        ValueCell: TextOrDashCell,
      },
      {
        _id: 'extendedStatus',
        accessorKey: 'extendedStatus',
        Header: SortableHeader,
        label: t('request.spendAndSavings.requestStatus'),
        width: 160,
        ValueCell: RequestStatusCell,
      },
      {
        _id: 'savingsStatus',
        accessorKey: 'savingsStatus',
        Header: SortableHeader,
        label: t('request.spendAndSavings.savingsStatus'),
        width: 160,
        ValueCell: SectionStatusCell,
      },
      {
        _id: 'savings',
        accessorKey: 'savings',
        Header: SortableHeader,
        label: t('general.savings'),
        width: 160,
        ValueCell: CurrencyAmountOrDashCell,
      },
      {
        _id: 'areSavingsConfirmed',
        accessorKey: 'areSavingsConfirmed',
        Header: SortableHeader,
        label: t('request.spendAndSavings.savingsConfirmed'),
        width: 160,
        ValueCell: SavingsConfirmedOrDashCell,
      },
      {
        _id: 'savingsCalculationMethod',
        accessorKey: 'savingsCalculationMethod',
        Header: SortableHeader,
        label: t('request.spendAndSavings.totalSavingsCalculationMethod'),
        width: 230,
        ValueCell: SavingsCalculationMethodOrDashCell,
      },
      {
        _id: 'hasSingleResponseLineItems',
        accessorKey: 'hasSingleResponseLineItems',
        Header: SortableHeader,
        label: `${t('request.lineItems.lineItem_other')}:`,
        description: t('request.multiStageBehavior.single.label'),
        descriptionSx: { fontSize: 2, fontWeight: 500, color: 'inherit', mt: 0 },
        width: 180,
        ValueCell: UsedCell,
      },
      {
        _id: 'hasMultiResponseLineItems',
        accessorKey: 'hasMultiResponseLineItems',
        Header: SortableHeader,
        label: `${t('request.lineItems.lineItem_other')}:`,
        description: t('request.multiStageBehavior.perStage.label'),
        descriptionSx: { fontSize: 2, fontWeight: 500, color: 'inherit', mt: 0 },
        width: 180,
        ValueCell: UsedCell,
      },
      {
        _id: 'hasAuctionLineItems',
        accessorKey: 'hasAuctionLineItems',
        Header: SortableHeader,
        label: `${t('request.lineItems.lineItem_other')}:`,
        description: t('auctions.heading', { ns: 'reporting' }),
        descriptionSx: { fontSize: 2, fontWeight: 500, color: 'inherit', mt: 0 },
        width: 180,
        ValueCell: UsedCell,
      },
      {
        _id: 'createdAt',
        accessorKey: 'createdAt',
        Header: SortableHeader,
        label: t('request.spendAndSavings.creationDate'),
        width: 140,
        sortType: 'datetime',
        ValueCell: DateOrDashCell,
      },
      {
        _id: 'prospectiveCompletionDate',
        accessorKey: 'prospectiveCompletionDate',
        Header: SortableHeader,
        label: t('request.spendAndSavings.finalDeadline'),
        width: 140,
        sortType: 'datetime',
        ValueCell: DateOrDashCell,
      },
      {
        _id: 'awardedDate',
        accessorKey: 'awardedDate',
        Header: SortableHeader,
        label: t('request.spendAndSavings.awardDate'),
        width: 140,
        sortType: 'datetime',
        ValueCell: DateOrDashCell,
      },
      {
        _id: 'senderOwners',
        accessorKey: 'senderOwners',
        Header: SortableHeader,
        label: t('request.team.requestRole.owner'),
        width: 200,
        sortAccessorFn: ({ senderOwners }: any) => senderOwners.map(({ name }) => name).join(),
        sortType: 'caseInsensitive',
        ValueCell: OwnersCell,
      },
    ];
  }, [t]);

  return (
    <RequestsReportingTablePanel config={config} columns={columns} useData={useData} section="savings" />
  );
};
