import { createContext, useContext } from 'react';

export const SelectedCompanyIdsContext = createContext<string[] | null>(null);

export const useSelectedCompanyIds = () => {
  const selectedCompanyIds = useContext(SelectedCompanyIdsContext);

  if (!selectedCompanyIds) {
    throw new Error('A `selectedCompanyIds` has not been set via the `SelectedCompanyIdsContext`');
  }

  return selectedCompanyIds;
};
