import * as React from 'react';
import ReactDOM from 'react-dom';
import { useSelect, UseSelectProps } from 'downshift';
import { noop, reject } from 'lodash';
import { usePopper } from 'react-popper';
import { Box, BoxProps, Text as StyledText } from 'rebass/styled-components';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate, truncateCss } from '@deepstream/ui-kit/elements/text/Truncate2';
import { centeredTextAdjustmentCss } from '@deepstream/ui-kit/elements/text/textAdjustment';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { stopEvent } from '@deepstream/ui-utils/domEvent';

const DROPDOWN_MAX_HEIGHT = 350;

export const ToggleButton = styled('button')`
  font-family: ${props => props.theme.fonts.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  border: ${props => props.theme.borders.lightGray};
  box-shadow: none;
  padding: 0 10px;
  margin: 0;
  color: ${props => props.theme.colors.text};
  background-clip: padding-box;
  line-height: 1.5;

  &:focus, &[aria-expanded="true"] {
    border-color: ${props => props.theme.colors.primary};
  }
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.disabledBackground};
    color: ${props => props.theme.colors.disabledText};
    cursor: default;
  }

  ${centeredTextAdjustmentCss}
`;

const Menu = styled<React.FC<BoxProps & { zIndex?: number }>>(Box)`
  background-color: ${props => props.theme.colors.white};
  border: ${props => props.theme.borders.lightGray};
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;

  position: fixed;
  padding: 8px 0;
  margin-top: 4px;
  margin-bottom: 4px;
  max-height: ${props => props.maxHeight || DROPDOWN_MAX_HEIGHT}px;

  font-weight: normal;
  font-family: ${props => props.theme.fonts.primary};

  overflow-y: auto;

  z-index: ${props => props.zIndex ?? 202};
  color: ${props => props.theme.colors.text};

  &:focus {
    outline: 0;
  }
`;

const ListItem = styled(Box)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  line-height: normal;
  width: 0;
  min-width: 100%;
  max-width: 100%;
  padding: 7px 10px;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: normal;
  opacity: ${props => props.disabled ? 0.5 : 1};

  &[data-selected="true"] {
    background-color: ${props => !props.disabled ? transparentize(0.90, props.theme.colors.primary) : ''};
  }
`;

const ButtonValue = styled(StyledText)`
  text-align: left;
  ${truncateCss}
`;

const ButtonPlaceholder = styled(StyledText)`
  color: ${props => props.theme.colors.subtext};
  text-align: left;
  ${truncateCss}
`;

// TODO: Replace any with proper types
export type SelectProps<T = any> = {
  items: UseSelectProps<T>['items'];
  initialSelectedItem?: UseSelectProps<T>['initialSelectedItem'];
  itemToString: NonNullable<UseSelectProps<T>['itemToString']>;
  onChange?: (changes: any) => void;
  selectedItem?: any;
  placeholder?: string;
  disabled?: boolean;
  getItemLabel?: (item: any) => string | React.ReactNode;
  getButtonLabel?: (item: any) => string | React.ReactNode;
  // @deprecated
  placement?: 'top' | 'bottom' | 'bottom-start' | 'bottom-end';
  menuWidth?: React.CSSProperties['width'];
  buttonStyle?: React.CSSProperties;
  footer?: React.ReactNode;
  maxHeight?: React.CSSProperties['maxHeight'];
  menuZIndex?: React.CSSProperties['zIndex'];
  name?: string;
  onBlurWithoutSelectedItem?: () => void;
  small?: boolean;
  canDeselect?: boolean;
  truncateLineItem?: boolean;
  renderPreItemContent?: (item: any, index: number) => React.ReactNode;
};

export const Select: React.FC<SelectProps> = ({
  items,
  initialSelectedItem,
  placeholder,
  onBlurWithoutSelectedItem,
  onChange = noop,
  itemToString,
  disabled,
  getItemLabel,
  getButtonLabel: getButtonLabelProp,
  maxHeight,
  menuWidth,
  menuZIndex,
  placement = 'bottom-start',
  name,
  buttonStyle,
  footer = null,
  small,
  canDeselect,
  truncateLineItem = true,
  renderPreItemContent,
  ...props
}) => {
  const { t } = useTranslation('general');
  const [hasCaughtBlurEvent, setHasCaughtBlurEvent] = React.useState(false);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useSelect({
    items,
    itemToString,
    onSelectedItemChange: onChange,
    initialSelectedItem,
    selectedItem: props.selectedItem ? items.find(item => itemToString(item) === props.selectedItem) : undefined,
  });

  React.useEffect(() => {
    // Emitting an `onBlurWithoutSelectedItem()` event instead of `onBlur()` to prevent
    // the emission of a blur event before the `onChange()` call (which would cause
    // 'required' errors to show despite an item being selected).
    if (onBlurWithoutSelectedItem && hasCaughtBlurEvent && !isOpen && !selectedItem) {
      setHasCaughtBlurEvent(false);
      onBlurWithoutSelectedItem();
    }
  }, [hasCaughtBlurEvent, setHasCaughtBlurEvent, isOpen, onBlurWithoutSelectedItem, selectedItem]);

  const [referenceElement, setReferenceElement] = React.useState<HTMLElement>();
  const [popperElement, setPopperElement] = React.useState<HTMLElement>();
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{
      name: 'flip',
      options: {
        boundary: document.body,
      },
    }],
  });

  const getLabel = getItemLabel || itemToString;
  const getButtonLabel = getButtonLabelProp || getLabel;

  const toggleButtonProps = React.useMemo(() => getToggleButtonProps({
    disabled,
    ref: setReferenceElement as any,
    type: 'button' as const,
    onBlur: () => setHasCaughtBlurEvent(true),
  }), [getToggleButtonProps, disabled]);

  const menuProps = React.useMemo(() => getMenuProps({
    hidden: !isOpen,
    ref: setPopperElement as any,
    style: styles.popper,
    ...attributes.popper,
  }), [getMenuProps, styles.popper, attributes.popper, isOpen]);

  return (
    <Box flex={1} style={{ position: 'relative' }}>
      <ToggleButton
        {...toggleButtonProps}
        data-test={`select-${name}`}
        style={{
          fontSize: small ? '12px' : '14px',
          minHeight: small ? '28px' : '40px',
          lineHeight: 1.5,
          ...buttonStyle,
        }}
        onClick={(e) => {
          stopEvent(e);

          if (update) update();
          toggleButtonProps.onClick?.(e);
        }}
      >
        {selectedItem ? (
          <ButtonValue>{getButtonLabel(selectedItem)}</ButtonValue>
        ) : (
          <ButtonPlaceholder>{placeholder}</ButtonPlaceholder>
        )}
        {disabled ? (
          null
        ) : canDeselect && selectedItem ? (
          <IconButton
            icon="close"
            aria-label={t('remove')}
            color="text"
            onClick={(event) => {
              stopEvent(event);

              selectItem(null);
            }}
            sx={{
              backgroundColor: 'inherit',
              // subtracting 2px to avoid covering focus border of select component
              // height: 'calc(100% - 2px)',
              // width: '24px',
              // position: 'absolute',
              // left: 'calc(100% - 28px)',
              ':focus': {
                backgroundColor: 'none',
                boxShadow: 'none',
                outline: 'none',
              },
            }}
          />
        ) : (
          <Icon icon="caret-down" aria-hidden="true" ml={2} />
        )}
      </ToggleButton>
      {ReactDOM.createPortal(
        <Menu
          {...menuProps}
          maxHeight={maxHeight}
          minWidth={referenceElement?.getBoundingClientRect().width}
          width={menuWidth}
          zIndex={typeof menuZIndex === 'string' ? parseInt(menuZIndex, 10) : menuZIndex}
        >
          <Box as="ul" p={0}>
            {reject(items, { hidden: true }).map((item, index) => {
              const itemProps = getItemProps({ item, index });

              return (
                <React.Fragment key={`${itemToString(item)}${index}`}>
                  {renderPreItemContent?.(item, index)}
                  <ListItem
                    as="li"
                    data-selected={highlightedIndex === index}
                    {...itemProps}
                    onClick={!item.disabled
                      ? (event: any) => {
                        stopEvent(event);
                        itemProps.onClick?.(event);
                      }
                      : undefined
                    }
                    // TODO: figure out why we need to handle onTouchStart to prevent infinite rendering loop
                    onTouchStart={!item.disabled
                      ? (event: any) => {
                        stopEvent(event);
                        itemProps.onClick?.(event);
                      }
                      : undefined
                    }
                    disabled={item.disabled}
                    style={{ cursor: item.disabled ? 'default' : 'pointer' }}
                  >
                    {truncateLineItem ? (
                      <Truncate>{getLabel(item)}</Truncate>
                    ) : (
                      getLabel(item)
                    )}
                  </ListItem>
                </React.Fragment>
              );
            })}
          </Box>
          {footer}
        </Menu>,
        document.body!,
        'menu',
      )}
    </Box>
  );
};
