import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import { Panel, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Company } from '@deepstream/ui/ui/types';
import { ErrorMessage } from '@deepstream/ui/ui/ErrorMessage';
import { PageHeaderTabs } from '@deepstream/ui/page-headers/PageHeaderTabs';
import { useNavigate } from '@tanstack/react-router';
import { Box } from 'rebass/styled-components';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import * as Title from '../../title';
import { Page } from '../../Page';
import { companyConfigRoute, companyContractsRoute, companyGeneralRoute, companyIntegrationsRoute, companyPreQualificationRoute, companyReportingRoute, companyRequestsRoute, companyRoute, companyTeamRoute } from '../../AppRouting';

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

const iconProps = {
  fontSize: 4,
  mb: '7px',
  regular: true,
};

const tabStyle = {
  minWidth: '75px',
  padding: '13px 4px',
  fontSize: '11px',
};

type TabId = 'general' | 'team' | 'preQualification' | 'requests' | 'contracts' | 'reporting' | 'integrations' | 'config';

export const CompanyPage = ({
  company,
  selectedTabId,
  children,
}: { company?: Company, selectedTabId: TabId, children?: React.ReactElement }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { companyId } = companyRoute.useParams();
  const [ref, inView, entry] = useInView();

  const tabs = React.useMemo(
    () => [{
      id: 'general',
      name: 'General',
      iconTop: 'grid-2' as const,
      iconProps: { ...iconProps, regular: true },
      tabStyle,
      navigate: () => navigate({ to: companyGeneralRoute.to, params: { companyId } }),
    }, {
      id: 'team',
      name: 'Team',
      iconTop: 'users' as const,
      iconProps,
      tabStyle,
      navigate: () => navigate({ to: companyTeamRoute.to, params: { companyId } }),
    }, {
      id: 'preQualification',
      name: 'Pre-qualification',
      iconTop: 'file-check' as const,
      iconProps,
      tabStyle,
      navigate: () => navigate({ to: companyPreQualificationRoute.to, params: { companyId } }),
    }, {
      id: 'requests',
      name: 'Requests',
      iconTop: 'file-text' as const,
      iconProps,
      tabStyle,
      navigate: () => navigate({ to: companyRequestsRoute.to, params: { companyId } }),
    }, {
      id: 'contracts',
      name: 'Contracts',
      iconTop: 'file-contract' as const,
      iconProps,
      tabStyle,
      navigate: () => navigate({ to: companyContractsRoute.to, params: { companyId } }),
    }, {
      id: 'reporting',
      name: 'Reporting',
      iconTop: 'chart-bar' as const,
      iconProps,
      tabStyle,
      navigate: () => navigate({ to: companyReportingRoute.to, params: { companyId } }),
    }, {
      id: 'integrations',
      name: 'Integrations',
      iconTop: 'plug' as const,
      iconProps: { ...iconProps, regular: true },
      tabStyle,
      navigate: () => navigate({ to: companyIntegrationsRoute.to, params: { companyId } }),
    }, {
      id: 'config',
      name: 'Config',
      iconTop: 'gear' as const,
      iconProps: { ...iconProps, regular: true },
      tabStyle,
      navigate: () => navigate({ to: companyConfigRoute.to, params: { companyId } }),
    }],
    [navigate, companyId],
  );

  return (
    <Page subHeading={company && !inView && entry && <PageTitle company={company} />}>
      {company ? (
        <>
          <div ref={ref}>
            <PageTitle flexDirection="column" company={company} />
          </div>
          <Box mb="40px">
            <PageHeaderTabs
              tabs={tabs}
              selectedTabId={selectedTabId}
              tabListStyle={{ backgroundColor: 'inherit', borderBottom: theme.borders.lightGray2, height: '60px' }}
            />
          </Box>
          {children}
        </>
      ) : (
        <Panel heading="Error">
          <PanelPadding>
            <ErrorMessage error="Oh no" fontSize={2} lineHeight={1.5} />
          </PanelPadding>
        </Panel>
      )}
    </Page>
  );
};
