import * as React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';
import styled, { FlattenInterpolation, ThemeProps } from 'styled-components';

const PanelContainer = styled(Box)<{ isSelected: boolean }>`
  position: ${({ isSelected }) => (isSelected ? 'relative' : 'absolute')};
  z-index: ${({ isSelected }) => isSelected && 10};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  overflow-x: hidden;
  width: 100%;
`;
const SlidingPanel = styled(Box)<{
  isSelected: boolean;
  slideDirection: 'left' | 'right';
  extraCss?: FlattenInterpolation<ThemeProps<any>>;
}>`
  ${({ extraCss }) => extraCss}
  transition: transform 0.3s;
  transform: ${({ isSelected, slideDirection }) =>
    isSelected
      ? 'translateX(0px)'
      : slideDirection === 'right'
        ? 'translateX(100%)'
        : 'translateX(-100%)'};
`;

export const SlidingBox = ({
  isSelected,
  slideDirection,
  extraCss,
  children,
  ...props
}: {
  isSelected: boolean;
  slideDirection: 'left' | 'right';
  extraCss?: FlattenInterpolation<ThemeProps<any>>;
  children: React.ReactNode;
} & BoxProps) => {
  return (
    <PanelContainer isSelected={isSelected}>
      <SlidingPanel
        isSelected={isSelected}
        slideDirection={slideDirection}
        extraCss={extraCss}
        {...props}
      >
        {children}
      </SlidingPanel>
    </PanelContainer>
  );
};
