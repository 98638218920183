import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { useToaster } from '@deepstream/ui/toast';
import { useAdminApi } from '@deepstream/ui/api';
import { useMutation } from '@deepstream/ui/useMutation';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { TextFieldBase } from '@deepstream/ui/form/TextField';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { LightLabelAboveConfigProvider } from '@deepstream/ui/LabelConfigProvider';
import { Company } from '@deepstream/ui/ui/types';
import { assertDefined } from '@deepstream/utils';

export const RemoveChildCompanyModal = ({
  currentCompanyId,
  company,
  onCancel,
  onSave,
  ...props
}: ModalProps & {
  currentCompanyId: string
  company: Company;
  onCancel: any;
  onSave: any;
}) => {
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const adminApi = useAdminApi();

  const [setParentCompany] = useMutation(
    adminApi.setParentCompany,
    {
      onSuccess: callAll(
        onSave,
        () => toaster.success('Child company removed'),
      ),
      onError: () => toaster.error('Could not remove child company'),
      onSettled: callAll(
        () => queryClient.invalidateQueries(['childCompanies', { companyId: currentCompanyId }]),
        (_, __, { companyId }) => queryClient.invalidateQueries(['company', { companyId }]),
        (_, __, { companyId }) => queryClient.invalidateQueries(['publicCompany', { companyId }]),
      ),
    },
  );

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{ company }}
        onSubmit={async ({ company }) => {
          assertDefined(company, 'company');

          return setParentCompany({
            companyId: company._id,
            parentCompanyId: null,
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalHeader onClose={onCancel}>
              Remove child company
            </ModalHeader>
            <ModalBody>
              <LightLabelAboveConfigProvider>
                <Stack gap={3}>
                  <TextFieldBase
                    label="Company ID"
                    disabled
                    value={company._id}
                  />
                  <TextFieldBase
                    label="Company name"
                    disabled
                    value={company.name}
                  />
                </Stack>
              </LightLabelAboveConfigProvider>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <Button type="submit" variant="danger" disabled={isSubmitting}>
                Remove child company
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
