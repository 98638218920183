import { FieldConfig, FieldSource, LineItemExchangeFields, LineItemsSection, getDefaultFieldTags } from '@deepstream/common/rfq-utils';
import { v4 as uuid } from 'uuid';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldType } from '@deepstream/common/exchangesConfig';
import { ConfigurableFieldType, FormulaFieldType, PredefinedFields, SupplierResponseValue } from '../types';
import { InputsConfig, RelativeFieldInsertion } from './useConfigureFieldModal';

const getSubmitterResponseType = (submitterFieldType?: string) => {
  if (!submitterFieldType) {
    return undefined;
  } else if (submitterFieldType === 'boolean') {
    return SupplierResponseValue.BOOLEAN;
  } else {
    return SupplierResponseValue.REPLY;
  }
};

const predefinedFieldsetConfigMap: Record<string, {
  inputsConfig?: InputsConfig;
  type: ConfigurableFieldType;
}> = {
  partNumber: {
    inputsConfig: {
      divergentToggles: true,
      fieldName: { disabled: true },
      enableSupplierResponse: true,
      enableBuyerProvided: true,
    },
    type: FieldType.STRING as ConfigurableFieldType,
  },
  manufacturer: {
    inputsConfig: {
      divergentToggles: true,
      fieldName: { disabled: true },
      enableSupplierResponse: true,
      enableBuyerProvided: true,
    },
    type: FieldType.STRING as ConfigurableFieldType,
  },
  deliveryDate: {
    inputsConfig: {
      fieldName: { disabled: true },
      enableSupplierResponse: true,
      enableBuyerProvided: true,
    },
    type: FieldType.DATE as ConfigurableFieldType,
  },
  totalCost: {
    type: FieldType.PRICE as ConfigurableFieldType,
    inputsConfig: {
      fieldName: { disabled: true },
      enableSupplierResponse: false,
      enableBuyerProvided: false,
      isFormula: true,
      disableFormulaFormatSelection: true,
    },
  },
  unit: {
    type: FieldType.STRING as ConfigurableFieldType,
    inputsConfig: {
      fieldName: { disabled: true },
      enableSupplierResponse: true,
      enableBuyerProvided: true,
    },
  },
  quantity: {
    type: FieldType.NUMBER as ConfigurableFieldType,
    inputsConfig: {
      fieldName: { disabled: true },
      enableSupplierResponse: true,
      enableBuyerProvided: true,
    },
  },
};

export const useGetAddGenericFieldModalProps = () => {
  const { t } = useTranslation(['translation', 'request']);

  return useCallback((
    type: ConfigurableFieldType,
    relativeFieldInsertion?: RelativeFieldInsertion | null,
  ) => {
    return {
      relativeFieldInsertion,
      inputsConfig: {
        enableDecimalPlaces: type === 'price',
        isFormula: type === 'formula',
        enableSupplierResponse: type !== 'formula',
        enableBuyerProvided: type !== 'formula',
      },
      initialValues: {
        type,
        fieldsetId: uuid(),
        fieldName: '',

        buyerProvidedValueEnabled: false,
        supplierResponseEnabled: false,
        supplierResponseType: undefined,
        decimalPlaces: 2,
        formula: '',
        formulaFormat: FieldType.PRICE as FormulaFieldType,
      },
      heading: t(`lineItems.genericLineItemConfig.modalHeadingAdd.${type}`, { ns: 'request' }),
      submitLabel: t('lineItems.genericLineItemConfig.submitLabel', { ns: 'request' }),
    };
  }, [t]);
};

export const useGetAddPredefinedFieldModalProps = () => {
  const { t } = useTranslation();

  return useCallback((
    fieldsetId: PredefinedFields,
    responseTagConfig: LineItemsSection['responseTagConfig'] | undefined,
    relativeFieldInsertion?: RelativeFieldInsertion | null,
  ) => {
    if (fieldsetId === 'leadTime') {
      return {
        relativeFieldInsertion,
        inputsConfig: {
          fieldName: { disabled: true },
          isFixedSupplierResponseField: true,
        },
        initialValues: {
          type: FieldType.NUMBER as ConfigurableFieldType,
          fieldsetId: 'leadTime:submitter',
          supplierResponseTags: responseTagConfig
            ? getDefaultFieldTags(responseTagConfig, FieldType.NUMBER)
            : undefined,
        },
        heading: t('lineItems.predefinedLineItemsFields.leadTime.modalHeadingAdd', { ns: 'request' }),
        submitLabel: t('general.submit'),
      };
    } else if (fieldsetId === 'price') {
      return {
        relativeFieldInsertion,
        inputsConfig: {
          fieldName: { disabled: true },
          enableDecimalPlaces: true,
          isFixedSupplierResponseField: true,
        },
        initialValues: {
          type: FieldType.PRICE as ConfigurableFieldType,
          fieldsetId,
          decimalPlaces: 2,
          supplierResponseTags: responseTagConfig
            ? getDefaultFieldTags(responseTagConfig, FieldType.PRICE)
            : undefined,
        },
        heading: t('lineItems.predefinedLineItemsFields.price.modalHeadingAdd', { ns: 'request' }),
        submitLabel: t('general.submit'),
      };
    } else {
      const config = predefinedFieldsetConfigMap[fieldsetId];

      return {
        relativeFieldInsertion,
        inputsConfig: config.inputsConfig,
        initialValues: {
          type: config.type as ConfigurableFieldType,
          fieldsetId,
          fieldName: t(`lineItems.predefinedLineItemsFields.${fieldsetId}.fieldName`, { ns: 'request' }),

          buyerProvidedValueEnabled: false,
          supplierResponseEnabled: false,
          supplierResponseType: undefined,
        },
        heading: t(`lineItems.predefinedLineItemsFields.${fieldsetId}.modalHeadingAdd`, { ns: 'request' }),
        submitLabel: t('lineItems.predefinedLineItemsFields.common.submitLabel', { ns: 'request' }),
      };
    }
  }, [t]);
};

export const useGetAddFieldFromOtherSectionModalProps = () => {
  const { t } = useTranslation(['translation', 'request']);

  return useCallback((
    field: FieldConfig,
    fields: LineItemExchangeFields & Record<string, FieldConfig<FieldSource>>,
    responseTagConfig: LineItemsSection['responseTagConfig'] | undefined,
    relativeFieldInsertion?: RelativeFieldInsertion | null,
  ) => {
    const [fieldsetId] = field._id.split(':');

    const evaluatorField = fields[`${fieldsetId}:evaluator`];
    const submitterField = ['unit', 'quantity'].includes(fieldsetId) ? fields[fieldsetId] : fields[`${fieldsetId}:submitter`];

    const fieldType = evaluatorField?.type || submitterField.type;
    const isFormula = field.source.type === 'formula';

    return {
      relativeFieldInsertion,
      inputsConfig: {
        fieldName: {
          helperText: `(${t('lineItems.genericLineItemConfig.fieldName.helperText', { ns: 'request' })})`,
        },
        enableDecimalPlaces: fieldType === FieldType.PRICE,
        isFormula,
        enableSupplierResponse: !isFormula,
        enableBuyerProvided: !isFormula,
      },
      initialValues: {
        type: fieldType as ConfigurableFieldType,
        fieldsetId,
        fieldName: evaluatorField?.label || submitterField?.label,

        buyerProvidedValueEnabled: Boolean(evaluatorField),
        supplierResponseEnabled: Boolean(submitterField),
        supplierResponseType: getSubmitterResponseType(submitterField?.type),
        supplierResponseTags: responseTagConfig
          ? getDefaultFieldTags(responseTagConfig, fieldType)
          : undefined,
        decimalPlaces: field.decimalPlaces,
        ...(field.source.type === 'formula' ? {
          formula: field.source.formula,
          formulaFormat: (fieldType === FieldType.PRICE ? FieldType.PRICE : FieldType.NUMBER) as FormulaFieldType,
        } : {}),
      },
      heading: t(`lineItems.genericLineItemConfig.modalHeadingAddFromOtherSection.${fieldType}`, { ns: 'request' }),
      submitLabel: t('lineItems.genericLineItemConfig.submitLabel', { ns: 'request' }),
    };
  }, [t]);
};

export const useGetEditConfigurableFieldModalProps = (
  otherSectionFields: Partial<LineItemExchangeFields> = {},
) => {
  const { t } = useTranslation(['translation', 'request']);

  return useCallback((
    field: FieldConfig,
    fields: LineItemExchangeFields & Record<string, FieldConfig<FieldSource>>,
  ) => {
    const [fieldsetId] = field._id.split(':');

    const evaluatorFieldId = ['unit', 'quantity'].includes(fieldsetId) ? fieldsetId : `${fieldsetId}:evaluator`;
    const submitterFieldId = `${fieldsetId}:submitter`;

    const evaluatorField = fields[evaluatorFieldId];
    const submitterField = fields[submitterFieldId];

    if (fieldsetId === 'leadTime') {
      return {
        isEdit: true,
        inputsConfig: {
          fieldName: { disabled: true },
          isFixedSupplierResponseField: true,
        },
        initialValues: {
          type: FieldType.NUMBER as ConfigurableFieldType,
          fieldsetId: field._id,
          supplierResponseTags: field.responseTags,
        },
        heading: t('lineItems.predefinedLineItemsFields.leadTime.modalHeadingEdit', { ns: 'request' }),
        submitLabel: t('general.submit'),
      };
    } else if (['price', 'targetPrice'].includes(fieldsetId)) {
      return {
        isEdit: true,
        inputsConfig: {
          fieldName: { disabled: true },
          enableDecimalPlaces: true,
          isFixedSupplierResponseField: fieldsetId === 'price',
        },
        initialValues: {
          type: FieldType.PRICE as ConfigurableFieldType,
          fieldsetId,
          decimalPlaces: field.decimalPlaces,
          supplierResponseTags: field.responseTags,
        },
        heading: t(`lineItems.predefinedLineItemsFields.${fieldsetId}.modalHeadingEdit`, { ns: 'request' }),
        submitLabel: t('general.submit'),
      };
    } else if (['deliveryDate', 'manufacturer', 'partNumber', 'unit', 'quantity'].includes(fieldsetId)) {
      return {
        isEdit: true,
        inputsConfig: predefinedFieldsetConfigMap[fieldsetId].inputsConfig,
        initialValues: {
          type: field.type as ConfigurableFieldType,
          fieldsetId: field._id,
          fieldName: t(`lineItems.predefinedLineItemsFields.${fieldsetId}.fieldName`, { ns: 'request' }),

          buyerProvidedValueEnabled: Boolean(evaluatorField),
          supplierResponseEnabled: Boolean(submitterField),
          supplierResponseType: getSubmitterResponseType(submitterField?.type),
          supplierResponseTags: submitterField?.responseTags,
        },
        heading: t(`lineItems.predefinedLineItemsFields.${fieldsetId}.modalHeadingEdit`, { ns: 'request' }),
        submitLabel: t('lineItems.predefinedLineItemsFields.common.submitEditLabel', { ns: 'request' }),
      };
    } else if (fieldsetId === 'totalCost' && field.source.type === 'formula') {
      return {
        isEdit: true,
        inputsConfig: predefinedFieldsetConfigMap[fieldsetId].inputsConfig,
        initialValues: {
          type: field.type as ConfigurableFieldType,
          fieldsetId: field._id,
          fieldName: t('request.lineItems.totalCost.title'),

          formula: field.source.formula,
          formulaFormat: FieldType.PRICE as FormulaFieldType,
        },
        heading: t('request.lineItems.formula.editModal.title'),
        submitLabel: t('lineItems.predefinedLineItemsFields.common.submitEditLabel', { ns: 'request' }),
      };
    } else {
      const isFieldSharedBetweenSections = (
        otherSectionFields[evaluatorFieldId] ||
        otherSectionFields[submitterFieldId]
      );

      const fieldsetType = field.type === 'boolean'
        ? evaluatorField?.type
        : field.type;

      const isFormula = field.source.type === 'formula';
      const priceFieldConfig = fieldsetType === 'price' && !isFormula ? {
        enableDecimalPlaces: true,
      } : undefined;

      return {
        isEdit: true,
        inputsConfig: {
          ...(isFieldSharedBetweenSections ? {
              fieldName: {
                helperText: `(${t('lineItems.genericLineItemConfig.fieldName.helperText', { ns: 'request' })})`,
              },
              ...priceFieldConfig,
            } : priceFieldConfig),
          isFormula,
          enableSupplierResponse: !isFormula,
          enableBuyerProvided: !isFormula,
        },
        initialValues: {
          type: field.type as ConfigurableFieldType,
          fieldsetId: field._id,
          fieldName: evaluatorField?.label || submitterField?.label,

          buyerProvidedValueEnabled: Boolean(evaluatorField),
          supplierResponseEnabled: Boolean(submitterField),
          supplierResponseType: getSubmitterResponseType(submitterField?.type),
          supplierResponseTags: submitterField?.responseTags,
          decimalPlaces: field.decimalPlaces,
          ...(field.source.type === 'formula' ? {
            formula: field.source.formula,
            formulaFormat: fieldsetType === 'price' ? FieldType.PRICE : FieldType.NUMBER as FormulaFieldType,
          } : {}),
        },
        // TODO lineItems.genericLineItemConfig.modalHeadingEdit currently contains
        // translations for the 'description' and 'unspsceCode' fields. We should
        // move those translations to the predefinedLineItemsFields translations,
        // after the Chinese translations have been merged.
        heading: t(`lineItems.genericLineItemConfig.modalHeadingEdit.${field.source.type === 'formula' ? 'formula' : fieldsetType}`, { ns: 'request' }),
        submitLabel: t('lineItems.genericLineItemConfig.submitEditLabel', { ns: 'request' }),
      };
    }
  }, [t, otherSectionFields]);
};
