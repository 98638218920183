import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useNavigate } from '@tanstack/react-router';
import { Company } from '@deepstream/ui/ui/types';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import {
  Tab,
  TabListPanel,
  TabPanels,
  TabPanel,
  Tabs,
} from '@deepstream/ui/ui/TabsVertical';
import { SidebarLayout } from '@deepstream/ui/ui/ProfileLayout';
import { CurrencySelectFieldBase } from '@deepstream/ui/form/CurrencySelectField';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { AuctionsTabContent } from '@deepstream/ui/modules/Reporting/AuctionsTabContent';
import { SavingsCards } from '@deepstream/ui/modules/Reporting/SavingsCards';
import { TeamProductivityTabContent } from '@deepstream/ui/modules/Reporting/TeamProductivityTabContent';
import { SupplierEngagementTabContent } from '@deepstream/ui/modules/Reporting/SupplierEngagementTabContent';
import { BudgetCards } from '@deepstream/ui/modules/Reporting/BudgetCards';
import { ValueCards } from '@deepstream/ui/modules/Reporting/ValueCards';
import * as dashboard from '@deepstream/ui/modules/Reporting/dashboard';
import useDownload from '@deepstream/ui/useDownload';
import { RequestsReportingConfigControls, useRequestsReportingConfig } from '@deepstream/ui/modules/Reporting/RequestsReportingConfig';
import { BudgetTablePanel } from '@deepstream/ui/modules/Reporting/BudgetTablePanel';
import { ValueTablePanel } from '@deepstream/ui/modules/Reporting/ValueTablePanel';
import { SavingsTablePanel } from '@deepstream/ui/modules/Reporting/SavingsTablePanel';
import { Panel, PanelDivider, PanelPadding, SidebarPanelHeading } from '@deepstream/ui-kit/elements/Panel';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { RequestsNavigationProvider } from '@deepstream/ui/RequestsNavigationContext';
import { isEmpty, noop } from 'lodash';
import { CurrentCompanyIdContext } from '@deepstream/ui/currentCompanyId';
import { useToaster } from '@deepstream/ui/toast';
import { assertDefined } from '@deepstream/utils';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useQuery } from 'react-query';
import { LoadingPanel } from '@deepstream/ui/ui/Loading';
import { ErrorPanel } from '@deepstream/ui/ui/ErrorMessage';
import { useSelectParentChildCompanies } from '@deepstream/ui/useSelectParentChildCompanies';
import { useCurrentUser } from '@deepstream/ui/useCurrentUser';
import { SelectParentAndChildCompanies } from '@deepstream/ui/SelectParentAndChildCompanies';
import { SelectedCompanyIdsContext } from '@deepstream/ui/selectedCompanyIds';
import { ContractsReporting } from './ContractsReporting';
import { useAdminRequestsReporting, useAdminRequestsReportingCardsData, useAdminRequestsReportingTableData } from './useAdminRequestsReporting';
import { companyReportingRoute, useAdminNavigation } from '../../AppRouting';
import { CompanyReportingTab, tabsWithAuctions, tabsWithoutAuctions } from './companyReportingTabs';

const CompanyReportingContent = ({
  selectedTabId,
  company,
  childCompanies,
  navigateToContract,
}: {
  selectedTabId?: string;
  company: Company;
  childCompanies: { _id: string; name: string }[];
  navigateToContract: (contract: { _id: string }) => void;
}) => {
  const { t } = useTranslation(['translation', 'reporting']);
  const { isExtraSmall, isSmall } = useDeviceSize();
  const navigate = useNavigate();
  const { config, setters } = useRequestsReportingConfig({ enforceAllRequests: true });
  const adminNavigation = useAdminNavigation();
  const download = useDownload();
  const currentUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const toaster = useToaster();

  const selectParentChildCompanies = useSelectParentChildCompanies({
    storageKey: `${company._id}.${currentUser._id}.selectedParentChildCompanyIds`,
    parentCompany: company,
    childCompanies,
  });

  const selectedCompanyIds = useMemo(() => {
    if (isEmpty(selectParentChildCompanies.items)) {
      return [company._id];
    } else if (isEmpty(selectParentChildCompanies.selectedItems)) {
      return selectParentChildCompanies.items.map(item => item._id);
    } else {
      return selectParentChildCompanies.selectedItems.map(item => item._id);
    }
  }, [selectParentChildCompanies.items, selectParentChildCompanies.selectedItems, company._id]);

  const companyFeatureFlags = company.featureFlags;

  assertDefined(companyFeatureFlags, 'companyFeatureFlags');

  const tabs = companyFeatureFlags?.auctionsEnabled
    ? tabsWithAuctions
    : tabsWithoutAuctions;

  const navigateToTab = useCallback((tab: string, replace?: boolean) => {
    navigate({
      to: companyReportingRoute.to,
      params: { companyId: company._id },
      search: tab ? { tab } : undefined,
      replace,
    });
  }, [company, navigate]);

  const [lastActiveTab, setLastActiveTab] = useState<string>(tabs[0]);

  const downloadLineItemsBidsReport = useCallback(
    async () => {
      setIsLoading(true);

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const queryParams = new URLSearchParams({ companyId: company._id, timezone });
      const url = `/export/line-items-bids/csv?${queryParams.toString()}`;

      try {
        await download(url);
      } catch (error) {
        toaster.error('An error occurred when trying to download the report');
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    },
    [company._id, download, toaster],
  );

  useEffect(
    () => {
      if (selectedTabId && selectedTabId !== lastActiveTab) {
        setLastActiveTab(selectedTabId);
      }
    },
    [selectedTabId, lastActiveTab, setLastActiveTab],
  );

  useEffect(
    () => {
      if (!selectedTabId) {
        navigateToTab(lastActiveTab || tabs[0], true);
      }
    },
    [tabs, selectedTabId, lastActiveTab, navigateToTab],
  );

  const onTabsChange = useCallback(
    (index) => {
      navigateToTab(tabs[index]);
    },
    [tabs, navigateToTab],
  );

  const selectedTabIndex = (tabs as string[]).indexOf(selectedTabId || '');

  return (
    <Box fontSize={2} lineHeight={1.5}>
      <CurrentCompanyIdContext.Provider value={company._id}>
        <RequestsNavigationProvider
          navigateToSenderRequest={({ requestId }) => adminNavigation.navigateToRequest(requestId)}
          navigateToReceivedRequest={noop}
          navigateToTemplatePreview={noop}
          navigateToTemplateEdit={noop}
          navigateToRequestsTab={noop}
        >
          {selectParentChildCompanies.items.length > 1 && (
            <Flex justifyContent="flex-end" mb="20px">
              <Text mt="5px" mr={2} fontSize={1} fontWeight={500} lineHeight={1.5}>
                {t('showDataFor', { ns: 'reporting' })}
              </Text>
              <SelectParentAndChildCompanies
                {...selectParentChildCompanies}
              />
            </Flex>
          )}
          <SelectedCompanyIdsContext.Provider value={selectedCompanyIds}>
            <Tabs index={selectedTabIndex} onChange={onTabsChange}>
              <SidebarLayout
                sidebar={
                  <>
                    <TabListPanel
                      heading={(
                        <IconText
                          icon="file-text-o"
                          text="Requests"
                          sx={{ letterSpacing: 0, textTransform: 'none' }}
                        />
                      )}
                    >
                      <SidebarPanelHeading ml={2} mt="10px" mb={2}>
                        {t('request.spendAndSavings.spendEtSavings')}
                      </SidebarPanelHeading>
                      <Tab key={CompanyReportingTab.BUDGET} style={{ fontSize: 'inherit' }}>
                        <Flex alignItems="center">
                          <Text flex={1}>{t('request.spendAndSavings.budget')}</Text>
                        </Flex>
                      </Tab>
                      <Tab key={CompanyReportingTab.VALUE} style={{ fontSize: 'inherit' }}>
                        <Flex alignItems="center">
                          <Text flex={1}>{t('request.spendAndSavings.value')}</Text>
                        </Flex>
                      </Tab>
                      <Tab key={CompanyReportingTab.SAVINGS} style={{ fontSize: 'inherit' }}>
                        <Flex alignItems="center">
                          <Text flex={1}>{t('general.savings')}</Text>
                        </Flex>
                      </Tab>
                      <Box my={2}>
                        <PanelDivider />
                      </Box>
                      <SidebarPanelHeading ml={2} mt="10px" mb={2}>
                        {t('request.spendAndSavings.other')}
                      </SidebarPanelHeading>
                      <Tab key={CompanyReportingTab.TEAM_PRODUCTIVITY} style={{ fontSize: 'inherit' }}>
                        <Flex alignItems="center">
                          <Text flex={1}>{t('teamProductivity.heading', { ns: 'reporting' })}</Text>
                        </Flex>
                      </Tab>
                      <Tab key={CompanyReportingTab.SUPPLIER_ENGAGEMENT} style={{ fontSize: 'inherit' }}>
                        <Flex alignItems="center">
                          <Text flex={1}>{t('supplierEngagement.heading', { ns: 'reporting' })}</Text>
                        </Flex>
                      </Tab>
                      {companyFeatureFlags.auctionsEnabled && (
                        <Tab key={CompanyReportingTab.AUCTIONS} style={{ fontSize: 'inherit' }}>
                          <Flex alignItems="center">
                            <Text flex={1}>{t('auctions.heading', { ns: 'reporting' })}</Text>
                          </Flex>
                        </Tab>
                      )}
                    </TabListPanel>
                    <TabListPanel
                      heading={(
                        <IconText
                          icon="file-contract"
                          isIconRegular
                          text="Contracts"
                          sx={{ letterSpacing: 0, textTransform: 'none' }}
                        />
                      )}
                    >
                      <Tab key={CompanyReportingTab.CONTRACTS} style={{ fontSize: 'inherit' }}>
                        <Flex alignItems="center">
                          <Text flex={1}>Contracts</Text>
                        </Flex>
                      </Tab>
                    </TabListPanel>
                    <Panel>
                      <PanelPadding>
                        <IconText
                          icon="file-csv"
                          isIconRegular
                          color="lightGray5"
                          fontWeight={500}
                          text="Admin reports"
                          sx={{ letterSpacing: 0, textTransform: 'none', mb: 3 }}
                        />
                        <Flex alignItems="center">
                          <Box flex={1} fontWeight={500}>
                            <Text>Request bids</Text>
                            <Text>(line items)</Text>
                          </Box>
                          <Button
                            iconLeft="download"
                            variant="secondary"
                            onClick={downloadLineItemsBidsReport}
                            disabled={isLoading}
                            ml={2}
                          />
                        </Flex>
                      </PanelPadding>
                    </Panel>
                  </>
                }
                main={
                  // below, we render the tab contents only for the active
                  // tabs to avoid data reloading in all tabs when the
                  // user changes the reporting config
                  <TabPanels>
                    <TabPanel key={CompanyReportingTab.BUDGET}>
                      {selectedTabId === CompanyReportingTab.BUDGET && (
                        <dashboard.Section
                          heading={t('request.spendAndSavings.budget')}
                          infoTooltip={t('request.spendAndSavings.budgetInfo')}
                          controls={<RequestsReportingConfigControls config={config} setters={setters} enforceAllRequests />}
                        >
                          <BudgetCards config={config} useData={useAdminRequestsReportingCardsData} />
                          <BudgetTablePanel config={config} useData={useAdminRequestsReportingTableData} />
                        </dashboard.Section>
                      )}
                    </TabPanel>
                    <TabPanel key={CompanyReportingTab.VALUE}>
                      {selectedTabId === CompanyReportingTab.VALUE && (
                        <dashboard.Section
                          heading={t('request.spendAndSavings.value')}
                          infoTooltip={t('request.spendAndSavings.valueInfo')}
                          controls={<RequestsReportingConfigControls config={config} setters={setters} enforceAllRequests />}
                        >
                          <ValueCards config={config} useData={useAdminRequestsReportingCardsData} />
                          <ValueTablePanel config={config} useData={useAdminRequestsReportingTableData} />
                        </dashboard.Section>
                      )}
                    </TabPanel>
                    <TabPanel key={CompanyReportingTab.SAVINGS}>
                      {selectedTabId === CompanyReportingTab.SAVINGS && (
                        <dashboard.Section
                          heading={t('general.savings')}
                          infoTooltip={t('request.spendAndSavings.savingsInfo')}
                          controls={<RequestsReportingConfigControls config={config} setters={setters} enforceAllRequests />}
                        >
                          <SavingsCards config={config} useData={useAdminRequestsReportingCardsData} />
                          <SavingsTablePanel config={config} useData={useAdminRequestsReportingTableData} />
                        </dashboard.Section>
                      )}
                    </TabPanel>
                    <TabPanel key={CompanyReportingTab.TEAM_PRODUCTIVITY}>
                      {selectedTabId === CompanyReportingTab.TEAM_PRODUCTIVITY && (
                        <dashboard.Section
                          heading={t('teamProductivity.heading', { ns: 'reporting' })}
                          headingSx={{ marginBottom: '20px' }}
                        >
                          <TeamProductivityTabContent config={config} useData={useAdminRequestsReporting} />
                        </dashboard.Section>
                      )}
                    </TabPanel>
                    <TabPanel key={CompanyReportingTab.SUPPLIER_ENGAGEMENT}>
                      {selectedTabId === CompanyReportingTab.SUPPLIER_ENGAGEMENT && (
                        <dashboard.Section
                          heading={t('supplierEngagement.heading', { ns: 'reporting' })}
                          headingSx={{ marginBottom: '20px' }}
                        >
                          <SupplierEngagementTabContent config={config} useData={useAdminRequestsReporting} />
                        </dashboard.Section>
                      )}
                    </TabPanel>
                    {companyFeatureFlags.auctionsEnabled && (
                      <TabPanel key={CompanyReportingTab.AUCTIONS}>
                        {selectedTabId === CompanyReportingTab.AUCTIONS && (
                          <dashboard.Section
                            heading={t('auctions.heading', { ns: 'reporting' })}
                            controls={(
                              <CurrencySelectFieldBase
                                label={t('general.currency')}
                                value={config.currency}
                                onChange={setters.setCurrency}
                              />
                            )}
                          >
                            <AuctionsTabContent config={config} useData={useAdminRequestsReporting} />
                          </dashboard.Section>
                        )}
                      </TabPanel>
                    )}
                    <TabPanel key={CompanyReportingTab.CONTRACTS}>
                      {selectedTabId === CompanyReportingTab.CONTRACTS && (
                        <dashboard.Section
                          heading="Contracts"
                          headingSx={{ marginBottom: '20px' }}
                          controls={(
                            <CurrencySelectFieldBase
                              label={t('general.currency')}
                              value={config.currency}
                              onChange={setters.setCurrency}
                            />
                          )}
                        >
                          <ContractsReporting
                            navigateToContract={navigateToContract}
                            config={config}
                          />
                        </dashboard.Section>
                      )}
                    </TabPanel>
                  </TabPanels>
                }
                sidebarStyle={!isExtraSmall && !isSmall ? { maxWidth: '240px', flex: '0 0 auto' } : undefined}
                mainStyle={!isExtraSmall && !isSmall ? { flex: '1 1 auto' } : undefined}
              />
            </Tabs>
          </SelectedCompanyIdsContext.Provider>
        </RequestsNavigationProvider>
      </CurrentCompanyIdContext.Provider>
    </Box>
  );
};

export const CompanyReporting = ({
  selectedTabId,
  company,
  navigateToContract,
}: {
  selectedTabId?: string;
  company: Company;
  navigateToContract: (contract: { _id: string }) => void;
}) => {
  const { t } = useTranslation('translation');
  const adminApi = useAdminApi();

  const { data: childCompanies, isLoading, isError } = useQuery(
    ['childCompanies', { companyId: company._id }],
    wrap(adminApi.getChildCompanies),
  );

  return isLoading ? (
    <LoadingPanel />
  ) : isError ? (
    <ErrorPanel error={t('errors.unexpected')} />
  ) : childCompanies ? (
    <CompanyReportingContent
      selectedTabId={selectedTabId}
      company={company}
      childCompanies={childCompanies}
      navigateToContract={navigateToContract}
    />
  ) : (
    null
  );
};
