import * as React from 'react';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Company } from '@deepstream/ui/ui/types';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as Title from '../../title';
import { CompanyFeatureList } from './CompanyFeatureList';

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

export const CompanyConfig = ({ company }: { company: Company }) => {
  return (
    <Stack gap={4}>
      <Panel heading="Features">
        <CompanyFeatureList company={company} />
      </Panel>
    </Stack>
  );
};
