import { useMemo } from 'react';

import { AdoptionRateTableStyle } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { TextCell } from '@deepstream/ui/TextCell';
import { Datetime2Cell } from '@deepstream/ui/DatetimeCell';

import { DateFormat } from '@deepstream/utils';
import { NumberCell } from '@deepstream/ui/draft/cell';

import { INTEGRATION_TABLE_HEIGHT, INTEGRATION_TABLE_WIDTH } from './constants';

export const IntegrationsTable = ({
  integrations,
}) => {
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: TextCell,
        id: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: TextCell,
        id: 'description',
      },
      {
        Header: 'Created at',
        Cell: Datetime2Cell,
        id: 'createdAt',
        accessor: 'createdAt',
        format: DateFormat.DD_MMM_YYYY,
        sort: 'datetime',
        width: 130,
      },
      {
        Header: 'Updated at',
        Cell: Datetime2Cell,
        id: 'updatedAt',
        accessor: 'updatedAt',
        format: DateFormat.DD_MMM_YYYY,
        sort: 'datetime',
        width: 130,
      },
      {
        Header: 'Version number',
        accessor: 'versionNumber',
        Cell: NumberCell,
        id: 'versionNumber',
      },
    ],
    [],
  );

  return (
    <AdoptionRateTableStyle
      bordered
      headerBackgroundColor={theme.colors.lightGray3}
      hoverCursor="default"
      stickyHeader
      height={INTEGRATION_TABLE_HEIGHT}
      width={INTEGRATION_TABLE_WIDTH}
      cellPaddingX="10px"
    >
      <Table
        columns={columns}
        data={integrations}
        isSortable
        initialSortBy={[{ id: 'updatedAt', desc: true }]}
      />
    </AdoptionRateTableStyle>
  );
};
