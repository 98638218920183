import { useMemo } from 'react';

import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { compact } from 'lodash';

export const ExternalToInternalCompanyDataTable = ({ externalToInternalCompanyData, hasSupplierStatuses, hasSupplierBasicInfo }) => {
  const companyData = Object
    .entries(externalToInternalCompanyData[0].data as Record<string, {
        internalCompanyId: string, companyName: string, externalStatus: boolean
      }>[])
    .map(([externalCompanyId, { internalCompanyId, companyName, externalStatus }]) => ({
      externalCompanyId,
      internalCompanyId,
      companyName,
      externalStatus,
    }));

  const companyColumns = useMemo(
    () => compact([
      {
        Header: 'External company ID',
        accessor: 'externalCompanyId',
      },
      {
        Header: 'Company name',
        accessor: 'companyName',
      },
      {
        Header: 'Internal company ID',
        accessor: 'internalCompanyId',
      },
      hasSupplierStatuses ? {
        Header: 'External status',
        accessor: 'externalStatus',
      } : null,
      ...(hasSupplierBasicInfo
        ? [
            { Header: 'Description', accessor: 'description' },
            { Header: 'Address', accessor: 'address' },
            { Header: 'Number of employees', accessor: 'numberOfEmployees' },
          ]
        : []),
    ]),
    [hasSupplierStatuses, hasSupplierBasicInfo],
  );

  return (
    <StaticTableStyles>
      <Table
        isPaginated
        columns={companyColumns}
        data={companyData}
      />
    </StaticTableStyles>
  );
};
