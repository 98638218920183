import * as React from 'react';
import { CloseEventStatusCode, MessageData, StatusChange, SubscriptionManager, WsStatus, WsSubscription } from '@deepstream/message-service';
import { castArray, noop } from 'lodash';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { useAuth } from './auth/getAuthHook';
import { useEnv } from './env';

type WsContextType = {
  subscribe: (subscription: WsSubscription) => void;
  unsubscribe: (subscriptionId: string) => void;
  // TODO: Is this still necessary?
  statusChangeWrapper: { statusChange: StatusChange };
};

const WsContext = React.createContext<WsContextType>({
  subscribe: noop,
  unsubscribe: noop,
  // NB we provide the websockets status as a mutable property of the
  // persistent statusChangeWrapper to avoid interfering with the
  // subscription logic, which is tied to component remounts.
  statusChangeWrapper: { statusChange: { status: WsStatus.CLOSED } },
});

export const WebSocketProvider = (props: { children: React.ReactNode }) => {
  const { getAccessTokenSilently } = useAuth();
  const { API_GATEWAY_ENDPOINT } = useEnv();
  const [statusChange, setStatusChange] = React.useState<StatusChange>();

  const subscriptionManager = React.useMemo(() =>
    new SubscriptionManager(
      // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      API_GATEWAY_ENDPOINT,
      getAccessTokenSilently,
      setStatusChange,
    ),
    [API_GATEWAY_ENDPOINT, getAccessTokenSilently],
  );

  React.useEffect(
    () => {
      return () => {
        subscriptionManager
          .getTransport()
          .close(CloseEventStatusCode.PURPOSE_FULFILLED, 'Service removed');
      };
    },
    [subscriptionManager],
  );

  const value = React.useMemo(
    () => ({
      subscribe: subscriptionManager.subscribe,
      unsubscribe: subscriptionManager.unsubscribe,
      statusChangeWrapper: { statusChange },
    }),
    [subscriptionManager, statusChange],
  );

  return (
    // @ts-expect-error ts(2322) FIXME: Type '{ subscribe: (subscription: WsSubscription) => void; unsubscribe: (subscriptionId: string) => void; statusChangeWrapper: { statusChange: StatusChange | undefined; }; }' is not assignable to type 'WsContextType'.
    <WsContext.Provider value={value} {...props} />
  );
};

const useWs = () => {
  const ws = React.useContext(WsContext);

  if (!ws) {
    throw new Error('No WsContext');
  }

  return ws;
};

export const useHandleWsMessage = (
  topicsInput: string | string[],
  handleMessage: (message: MessageData) => void,
  notifyOnOpenConnection: boolean,
  consumerId: string,
) => {
  const subscriptionId = useUniqueId(consumerId);

  const { subscribe, unsubscribe } = useWs();

  const topics = React.useMemo(
    () => castArray(topicsInput),
    [topicsInput],
  );

  React.useEffect(() => {
    subscribe({ topics, handleMessage, notifyOnOpenConnection, subscriptionId });

    return () => {
      unsubscribe(subscriptionId);
    };
  }, [subscribe, unsubscribe, topics, handleMessage, subscriptionId, notifyOnOpenConnection]);
};

export const useLatestWsStatusChange = () => {
  const { statusChangeWrapper } = useWs();

  const [latestStatusChange, setLatestStatusChange] = React.useState(statusChangeWrapper.statusChange);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setLatestStatusChange(statusChangeWrapper.statusChange);
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [statusChangeWrapper, setLatestStatusChange]);

  return latestStatusChange;
};
