import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { Avatar, AvatarProps } from './Avatar';

const MAX_VISIBLE_USERS = 5;

export const UsersAvatarList = ({
  users,
  ...avatarProps
}: {
  users: { _id?: string; name?: string; email?: string; isPending?: boolean }[];
} & Omit<AvatarProps, 'userId'>) => {
  const { t } = useTranslation();

  const usersToShow = users.length > MAX_VISIBLE_USERS
    ? users.slice(0, MAX_VISIBLE_USERS - 1)
    : users;

  return (
    <Flex alignItems="center" height="40px">
      {usersToShow.length === 0 ? (
        <EmDash />
      ) : usersToShow.length === 1 ? (
        <Flex alignItems="center" sx={{ opacity: usersToShow[0].isPending ? 0.5 : 1 }}>
          {/*
           // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
          <Avatar userId={usersToShow[0]._id} {...avatarProps} />
          <Text pl={1}>{usersToShow[0].name || usersToShow[0].email}</Text>
        </Flex>
      ) : usersToShow.map((user, i) => (
        <Tooltip key={user._id} content={user.name || user.email}>
          <Box ml={i !== 0 ? '-6px' : undefined} sx={{ opacity: user.isPending ? 0.5 : 1 }}>
            {/*
             // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'. */}
            <Avatar userId={user._id} {...avatarProps} />
          </Box>
        </Tooltip>
      ))}
      {users.length > MAX_VISIBLE_USERS
        ? <Text ml={2}>+{t('requests.otherCount', { count: users.length - MAX_VISIBLE_USERS })}</Text>
        : null
      }
    </Flex>
  );
};
