/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/no-autofocus */
import { useRef, useEffect, useCallback, forwardRef, useState, useMemo } from 'react';
import { Box, Text } from 'rebass/styled-components';
import { isFinite, isNil, map, without } from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import styled from 'styled-components';
import {
  roundToDecimals,
  getFixedString,
} from '@deepstream/utils';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import {
  DataCellProps,
  focusGrid,
  getGridCellId,
} from '@deepstream/ui-kit/grid/core/utils';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { useGridIdPrefix } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getCellValue } from '@deepstream/ui-kit/grid/EditableGrid/getCellValue';
import { Requirement, Lock, lockableExchangeTypes, getExchangeTypeFromLock, isDocumentRequestExchange, getExchangeTypeFromRequirement } from '@deepstream/common/rfq-utils';
import { GridMenuItem, GridPopup, GridMenuContent } from '@deepstream/ui-kit/grid/EditableGrid/GridMenu';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { informationRequestExchangeTypes } from '@deepstream/common/exchangesConfig';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { parseISO } from 'date-fns';
import { NumberFormat } from '../../../NumberFormat';
import { getDocumentExchangeDescriptionFromFile, triggerInputHack } from '../../../utils';
import { InputProps } from '../../Input';
import { formatOptions } from '../../formatOptions';
import { DatePicker } from '../../DatePicker';
import { useModalState } from '../../useModalState';
import * as rfx from '../../../rfx';
import { LockedTooltip } from '../../../LockedTooltip';
import { LockModal } from '../../../LockModal';
import { FileList } from '../../FileList';
import { useUploadApi } from '../../../ExchangeModal/useUpload';
import { Select } from '../../Select';

export const TextInputCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const inputRef = useRef<HTMLInputElement>();
  const idPrefix = useGridIdPrefix();
  const {
    pendingKeyboardEvent,
    cellInputState,
    setCellValue,
    setEditedCell,
  } = useEditableGridData();
  const preventUpdateOnBlur = useRef(false);

  useEffect(() => {
    let initialValue;

    if (pendingKeyboardEvent.current) {
      initialValue = pendingKeyboardEvent.current.code === KeyCode.BACKSPACE
        ? ''
        : pendingKeyboardEvent.current.key;
      pendingKeyboardEvent.current = null;
    } else {
      initialValue = (
        cellInputState.current &&
        cellInputState.current.rowId === row.original._id &&
        cellInputState.current.columnId === column.original._id
      )
        ? cellInputState.current.value
        : getCellValue(row.original, column.original);
    }

    // @ts-expect-error ts(18048) FIXME: 'inputRef.current' is possibly 'undefined'.
    inputRef.current.value = initialValue ?? '';
    cellInputState.current = ({
      value: initialValue,
      rowId: row.original._id,
      columnId: column.original._id,
    });
  }, [pendingKeyboardEvent, cellInputState, column, row]);

  const handleBlur = useCallback(() => {
    if (!preventUpdateOnBlur.current) {
      // @ts-expect-error ts(18048) FIXME: 'inputRef.current' is possibly 'undefined'.
      setCellValue(row.original._id, column.original._id, inputRef.current.value);
    }
    setEditedCell(null);
    cellInputState.current = null;
    focusGrid(idPrefix);
  }, [cellInputState, column.original._id, idPrefix, row.original._id, setEditedCell, setCellValue, preventUpdateOnBlur]);

  const handleInputKeyDown = useCallback((event) => {
    switch (event.code) {
      case KeyCode.ESCAPE:
        preventUpdateOnBlur.current = true;
        setEditedCell(null);
        cellInputState.current = null;
        focusGrid(idPrefix);
        event.stopPropagation();
        break;
      case KeyCode.ENTER:
        if (inputRef.current) {
          setCellValue(row.original._id, column.original._id, inputRef.current.value);
          cellInputState.current = null;
          focusGrid(idPrefix);
        }
        break;
      case KeyCode.TAB:
        break;
      default:
        // any other keys: prevent propagation to avoid interfering with
        // user's editing of input value
        event.stopPropagation();
    }
  }, [cellInputState, column.original._id, idPrefix, row.original._id, setEditedCell, setCellValue]);

  const isDisabled = column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <input
      // @ts-expect-error ts(2322) FIXME: Type 'MutableRefObject<HTMLInputElement | undefined>' is not assignable to type 'LegacyRef<HTMLInputElement> | undefined'.
      ref={inputRef}
      onChange={(event) => {
        cellInputState.current = ({
          value: event.target.value,
          rowId: row.original._id,
          columnId: column.original._id,
        });
      }}
      autoFocus
      className={clsx({
        'cell-input': true,
        'disabled': isDisabled,
      })}
      onKeyDown={handleInputKeyDown}
      onKeyUp={stopPropagation}
      onBlur={handleBlur}
      disabled={isDisabled}
    />
  );
};

const StyledTextArea = styled.textarea`
  resize: none;
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.text};
  field-sizing: normal;
  padding: 10px!important;
`;

export const TextAreaInputCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const inputRef = useRef<HTMLTextAreaElement>();
  const idPrefix = useGridIdPrefix();
  const {
    pendingKeyboardEvent,
    cellInputState,
    setCellValue,
    setEditedCell,
  } = useEditableGridData();
  const preventUpdateOnBlur = useRef(false);

  useEffect(() => {
    let initialValue;

    if (pendingKeyboardEvent.current) {
      initialValue = pendingKeyboardEvent.current.code === KeyCode.BACKSPACE
        ? ''
        : pendingKeyboardEvent.current.key;
      pendingKeyboardEvent.current = null;
    } else {
      initialValue = (
        cellInputState.current &&
        cellInputState.current.rowId === row.original._id &&
        cellInputState.current.columnId === column.original._id
      )
        ? cellInputState.current.value
        : getCellValue(row.original, column.original);
    }

    // @ts-expect-error ts(18048) FIXME: 'inputRef.current' is possibly 'undefined'.
    inputRef.current.value = initialValue ?? '';
    cellInputState.current = ({
      value: initialValue,
      rowId: row.original._id,
      columnId: column.original._id,
    });
  }, [pendingKeyboardEvent, cellInputState, column, row]);

  const handleBlur = useCallback(() => {
    if (!preventUpdateOnBlur.current) {
      // @ts-expect-error ts(18048) FIXME: 'inputRef.current' is possibly 'undefined'.
      setCellValue(row.original._id, column.original._id, inputRef.current.value);
    }
    setEditedCell(null);
    cellInputState.current = null;
    focusGrid(idPrefix);
  }, [cellInputState, column.original._id, idPrefix, row.original._id, setEditedCell, setCellValue, preventUpdateOnBlur]);

  const handleInputKeyDown = useCallback((event) => {
    switch (event.code) {
      case KeyCode.ESCAPE:
        preventUpdateOnBlur.current = true;
        setEditedCell(null);
        cellInputState.current = null;
        focusGrid(idPrefix);
        event.stopPropagation();
        break;
      case KeyCode.ENTER:
        if (inputRef.current) {
          setCellValue(row.original._id, column.original._id, inputRef.current.value);
          cellInputState.current = null;
          focusGrid(idPrefix);
        }
        break;
      case KeyCode.TAB:
        break;
      default:
        // any other keys: prevent propagation to avoid interfering with
        // user's editing of input value
        event.stopPropagation();
    }
  }, [cellInputState, column.original._id, idPrefix, row.original._id, setEditedCell, setCellValue]);

  const isDisabled = column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <StyledTextArea
      // @ts-expect-error ts(2769) FIXME: No overload matches this call.
      ref={inputRef}
      onChange={(event) => {
        cellInputState.current = ({
          value: event.target.value,
          rowId: row.original._id,
          columnId: column.original._id,
        });
      }}
      autoFocus
      className={clsx({
        'cell-input': true,
        'disabled': isDisabled,
      })}
      onKeyDown={handleInputKeyDown}
      onKeyUp={stopPropagation}
      onBlur={handleBlur}
      disabled={isDisabled}
    />
  );
};

const NumberInputComponent = forwardRef((props: InputProps, ref) => (
  <Box
    as="input"
    bg="white"
    width="100%"
    color="text"
    ref={ref}
    {...props}
  />
));

export const NumberInputCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const inputRef = useRef<HTMLInputElement>();
  const idPrefix = useGridIdPrefix();
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    pendingKeyboardEvent,
    cellInputState,
    setCellValue,
    setEditedCell,
  } = useEditableGridData();
  const preventUpdateOnBlur = useRef(false);

  const { format, minValue, exclusiveMinValue, decimalPlaces } = column.original;

  const [value, setValue] = useState<number | null>(null);
  const effectiveDecimalPlaces = decimalPlaces || row.original.fields?.[column.original._id]?.decimalPlaces;

  const handleValueChange = useCallback(({ floatValue }) => {
    const truncatedValue = isFinite(floatValue)
      ? roundToDecimals(floatValue, 10)
      : null;
    const newValue = (
      isFinite(truncatedValue) &&
      // @ts-expect-error ts(18047) FIXME: 'truncatedValue' is possibly 'null'.
      (isNil(minValue) || truncatedValue >= minValue) &&
      // @ts-expect-error ts(18047) FIXME: 'truncatedValue' is possibly 'null'.
      (isNil(exclusiveMinValue) || truncatedValue > exclusiveMinValue)
    )
      ? truncatedValue
      : null;

    cellInputState.current = ({
      value: newValue,
      rowId: row.original._id,
      columnId: column.original._id,
    });

    setValue(newValue);
  }, [cellInputState, column.original._id, row.original._id, minValue, exclusiveMinValue]);

  useEffect(() => {
    if (isInitialized) {
      return;
    }

    let initialValue;

    if (pendingKeyboardEvent.current) {
      initialValue = pendingKeyboardEvent.current.code === KeyCode.BACKSPACE
        ? ''
        : pendingKeyboardEvent.current.key;
      pendingKeyboardEvent.current = null;
    } else {
      initialValue = (
        cellInputState.current &&
        cellInputState.current.rowId === row.original._id &&
        cellInputState.current.columnId === column.original._id
      )
        ? cellInputState.current.value
        : getCellValue(row.original, column.original);
    }

    const inputElement = inputRef.current;

    if (inputElement) {
      triggerInputHack(inputElement, isFinite(initialValue) ? getFixedString(initialValue) : initialValue);
      inputElement.selectionStart = inputElement.value.length;
      inputElement.selectionEnd = inputElement.value.length;
    }

    const numberValue = parseFloat(initialValue);
    const truncatedValue = isFinite(numberValue)
      ? roundToDecimals(numberValue, 10)
      : null;
    const effectiveValue = (
      isFinite(truncatedValue) &&
      // @ts-expect-error ts(18047) FIXME: 'truncatedValue' is possibly 'null'.
      (isNil(minValue) || truncatedValue >= minValue) &&
      // @ts-expect-error ts(18047) FIXME: 'truncatedValue' is possibly 'null'.
      (isNil(exclusiveMinValue) || truncatedValue > exclusiveMinValue)
    )
      ? truncatedValue
      : null;

    cellInputState.current = ({
      value: effectiveValue,
      rowId: row.original._id,
      columnId: column.original._id,
    });

    setIsInitialized(true);
  }, [isInitialized, setIsInitialized, pendingKeyboardEvent, cellInputState, column, row, minValue, exclusiveMinValue]);

  const handleBlur = useCallback(() => {
    if (!preventUpdateOnBlur.current) {
      setCellValue(row.original._id, column.original._id, value);
    }
    setEditedCell(null);
    cellInputState.current = null;
    focusGrid(idPrefix);
  }, [cellInputState, column.original._id, idPrefix, row.original._id, setEditedCell, setCellValue, value]);

  const handleInputKeyDown = useCallback((event) => {
    switch (event.code) {
      case KeyCode.ESCAPE:
        preventUpdateOnBlur.current = true;
        setEditedCell(null);
        cellInputState.current = null;
        focusGrid(idPrefix);
        event.stopPropagation();
        break;
      case KeyCode.ENTER:
        setCellValue(row.original._id, column.original._id, value);
        cellInputState.current = null;
        focusGrid(idPrefix);
        break;
      case KeyCode.TAB:
        break;
      default:
        // any other keys: prevent propagation to avoid interfering with
        // user's editing of input value
        event.stopPropagation();
    }
  }, [cellInputState, column.original._id, idPrefix, row.original._id, setEditedCell, setCellValue, value]);

  // Our way of pre-populating the input doesn't work correctly when
  // the format is set to 'money.positive' (and possibly other, currently
  // unused formats). To circumvent this issue, we set the value to 'number'
  // initially, pre-populate the input and then set the desired format.)
  const effectiveFormat = !isInitialized && format === 'money.positive'
    ? 'number'
    : format;
  const isDisabled = column.original.disabled || column.original.isDisabled?.(row.index);

  return (
    <NumberFormat
      {...effectiveDecimalPlaces ? (
        { ...formatOptions[effectiveFormat as keyof typeof formatOptions], decimalScale: effectiveDecimalPlaces }
      ) : (
        // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
        formatOptions[effectiveFormat]
      )}
      customInput={NumberInputComponent}
      onValueChange={handleValueChange}
      getInputRef={inputRef}
      value={value}
      autoFocus
      style={{ textAlign: 'right' }}
      className="cell-input"
      onKeyDown={handleInputKeyDown}
      onKeyUp={stopPropagation}
      onBlur={handleBlur}
      disabled={isDisabled}
    />
  );
};

const DateInput = styled.input`
  width: 100%;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  outline: none;
  border: 0;

  box-shadow: inset 0 0 0 2px ${props => props.theme.colors.lightPrimary};

  &:focus {
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.primary},
    0 0 8px 0 rgba(0, 0, 0, 0.15);
  }
`;

export const DateInputCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any> & { hasMinDate?: boolean }) => {
  const containerRef = useRef<HTMLDivElement>();
  const idPrefix = useGridIdPrefix();
  const {
    pendingKeyboardEvent,
    cellInputState,
    setCellValue,
    setEditedCell,
  } = useEditableGridData();
  const preventUpdateOnBlur = useRef(false);

  const { format } = column.original;

  const [stringValue, setStringValue] = useState<string | null>(null);
  const isDisabled = column.original.disabled || column.original.isDisabled?.(row.index);

  const value = useMemo(() => {
    if (!stringValue) {
      return;
    }

    const date = parseISO(stringValue);

    return !date || !isFinite(date.valueOf())
      ? undefined
      : date;
  }, [stringValue]);

  const handleValueChange = useCallback((value: Date) => {
    const stringValue = value?.toISOString() ?? null;

    cellInputState.current = ({
      value: stringValue,
      rowId: row.original._id,
      columnId: column.original._id,
    });

    setStringValue(stringValue);
  }, [cellInputState, column.original._id, row.original._id]);

  useEffect(() => {
    if (pendingKeyboardEvent.current) {
      if (pendingKeyboardEvent.current.code === KeyCode.BACKSPACE) {
        pendingKeyboardEvent.current = null;

        cellInputState.current = ({
          value: null,
          rowId: row.original._id,
          columnId: column.original._id,
        });

        setStringValue(null);
      } else {
        const pendingText = pendingKeyboardEvent.current.key;
        pendingKeyboardEvent.current = null;

        cellInputState.current = ({
          value: null,
          rowId: row.original._id,
          columnId: column.original._id,
        });

        if (containerRef.current) {
          const inputElement = containerRef.current.getElementsByTagName('input')[0];

          if (inputElement) {
            triggerInputHack(inputElement, pendingText + inputElement.value);
          }
        }
      }
    } else {
      const fieldValue = (
        cellInputState.current &&
        cellInputState.current.rowId === row.original._id &&
        cellInputState.current.columnId === column.original._id
      )
        ? cellInputState.current.value
        : getCellValue(row.original, column.original);

      setStringValue(fieldValue ?? null);
    }
  }, [pendingKeyboardEvent, cellInputState, column, row]);

  const handleSelect = useCallback((value: Date | Record<string, any>) => {
    if (!preventUpdateOnBlur.current) {
      const stringValue = value?.toISOString?.() ?? null;

      setCellValue(row.original._id, column.original._id, stringValue);
    }
    setEditedCell(null);
    cellInputState.current = null;
    focusGrid(idPrefix);
  }, [cellInputState, column.original._id, idPrefix, row.original._id, setEditedCell, setCellValue]);

  const handleInputKeyDown = useCallback((event) => {
    switch (event.code) {
      case KeyCode.ESCAPE:
        preventUpdateOnBlur.current = true;
        setEditedCell(null);
        cellInputState.current = null;
        focusGrid(idPrefix);
        event.stopPropagation();
        break;
      case KeyCode.ENTER:
        setCellValue(row.original._id, column.original._id, stringValue);
        cellInputState.current = null;
        focusGrid(idPrefix);
        break;
      case KeyCode.TAB:
        break;
      default:
        // any other keys: prevent propagation to avoid interfering with
        // user's editing of input value
        event.stopPropagation();
    }
  }, [cellInputState, column.original._id, idPrefix, row.original._id, setEditedCell, setCellValue, stringValue]);

  return (
    <div
      // @ts-expect-error ts(2322) FIXME: Type 'MutableRefObject<HTMLDivElement | undefined>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
      ref={containerRef}
      // @ts-expect-error ts(2322) FIXME: Type 'false | ((event: any) => void)' is not assignable to type 'KeyboardEventHandler<HTMLDivElement> | undefined'.
      onKeyDown={!isDisabled && handleInputKeyDown}
      // @ts-expect-error ts(2322) FIXME: Type 'false | ((event: { stopPropagation: () => void; }) => void)' is not assignable to type 'KeyboardEventHandler<HTMLDivElement> | undefined'.
      onKeyUp={!isDisabled && stopPropagation}
    >
      <DatePicker
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'viewport',
          },
        }}
        onChange={handleValueChange}
        value={value}
        portalId={`${idPrefix}-datepicker`}
        dateFormat={format}
        checkMinDateOnOpen={false}
        autoFocus
        onBlur={handleSelect}
        onClickOutside={handleSelect}
        onSelect={handleSelect}
        shouldCloseOnSelect={false}
        DateInput={DateInput}
        disabled={isDisabled}
      />
    </div>
  );
};

export const DocumentRequirementInputCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');

  const { isObsolete, isLive, attachments, locking } = row.original;
  const isDisabled = isObsolete || isLive || column.original.disabled || column.original.isDisabled?.(row.index);

  const allRequirements = Object.values(Requirement);
  const availableRequirements = attachments?.length
    ? without(allRequirements, Requirement.REQUEST)
    : allRequirements;

  const options = map(
    availableRequirements,
    value => ({
      value,
      label: t(`request.documents.requirements.${value}`),
    }),
  );
  const {
    cellInputState,
    setCellValue,
    setEditedCell,
    updateFirstMatchingRow,
  } = useEditableGridData();

  const value = getCellValue(row.original, column.original);

  const onRequirementChange = useCallback(
    (requirement: Requirement) => {
      const rowPatch = {};
      const exchangeType = getExchangeTypeFromRequirement(requirement, locking);

      if (isDocumentRequestExchange(exchangeType)) {
        // Reset the attachments when selecting the document request requirement
        Object.assign(rowPatch, { attachments: [] });
      }

      if (!lockableExchangeTypes.includes(exchangeType)) {
        Object.assign(rowPatch, { locking: null });
      }
      if (Object.keys(rowPatch).length > 0) {
        updateFirstMatchingRow(
          { _id: row.original._id },
          (row) => ({
            ...row,
            rowPatch,
          }),
        );
      }

      setCellValue(row.original._id, column.original._id, exchangeType);
    },
    [locking, setCellValue, updateFirstMatchingRow, row.original._id, column.original._id],
  );

  const idPrefix = useGridIdPrefix();
  const [menuReferenceId, setMenuReferenceId] = useState<string | null>(() => {
    const cellId = getGridCellId(idPrefix, {
      // Rows array always have a null element at the start to account for the
      // grid header, hence the +1
      rowIndex: row.index + 1,
      columnIndex: column.index,
    });
    return cellId;
  });

  const closeGridMenu = useCallback(() => {
    setMenuReferenceId(null);
    setEditedCell(null);
    cellInputState.current = null;
    focusGrid(idPrefix);
  }, [setMenuReferenceId, setEditedCell, cellInputState, idPrefix]);

  return (
    <>
      <div
        className={clsx({
          'cell-content': true,
          disabled: isDisabled,
        })}
        onClick={() => {
          if (!isDisabled) {
            const cellId = getGridCellId(idPrefix, {
              // Rows array always have a null element at the start to account for the
              // grid header, hence the +1
              rowIndex: row.index + 1,
              columnIndex: column.index,
            });

            setMenuReferenceId(menuReferenceId === cellId ? null : cellId);
          }
        }}
      >
        <Truncate>
          <IconText
            icon="caret-down"
            iconPosition="right"
            text={
              value
                ? t(`request.documents.requirements.${value}`)
                : t('request.documents.selectARequirement')
            }
          />
        </Truncate>
      </div>

      {menuReferenceId ? (
        <GridPopup
          key={menuReferenceId}
          cellId={menuReferenceId}
          close={closeGridMenu}
          popoverProps={{
            'data-test': 'document-requirements-menu',
          }}
          sameWidth
        >
          <GridMenuContent close={closeGridMenu}>
            {options.map(({ value, label }) => (
              <GridMenuItem key={value} onSelect={() => onRequirementChange(value)}>
                {label}
              </GridMenuItem>
            ))}
          </GridMenuContent>
        </GridPopup>
      ) : null}
    </>
  );
};

export const AttachmentInputCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('translation');

  const { category: description, type: exchangeType, file, isObsolete } = row.original;
  const isDocumentRequest = informationRequestExchangeTypes.includes(exchangeType);
  const isDisabled = isObsolete || isDocumentRequest;

  const { setCellValue, updateFirstMatchingRow } = useEditableGridData();
  const value = getCellValue(row.original, column.original);

  const [upload] = useUploadApi({ purpose: 'rfq' });

  return (
    <div
      className={clsx({
        'cell-content': true,
        disabled: isDisabled,
      })}
      style={{
        ...(!isDocumentRequest && { padding: 0 }),
      }}
    >
      {!isDocumentRequest ? (
        <Box sx={{ width: '100%' }}>
          <FileList
            limit={1}
            initialFiles={file ? [file] : undefined}
            initialAttachments={value || []}
            uploadFn={upload}
            disabled={isObsolete}
            truncateFileName
            onUploadStart={(file) => {
              // When the exchange def doesn't have a description, derive it from the file name
              if (!description) {
                updateFirstMatchingRow({ _id: row.original._id }, (row) => ({
                  ...row,
                  category: getDocumentExchangeDescriptionFromFile(file),
                }));
              }
            }}
            onChange={(files) => {
              setCellValue(
                row.original._id,
                column.original._id,
                files.length > 0 ? [files[0]] : [],
              );
            }}
            hideFileNameWhileUploading
          />
        </Box>
      ) : (
        <Text color="subtext">{t('request.documents.supplierToUpload')}</Text>
      )}
    </div>
  );
};

export const BooleanInputCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const { t } = useTranslation('request');

  const { isObsolete } = row.original;

  const { setCellValue } = useEditableGridData();
  const value = getCellValue(row.original, column.original);
  const items = useMemo(() =>
    [{ value: true, label: t('lineItems.cell.boolean.accepted') }, { value: false, label: t('lineItems.cell.boolean.rejected') }],
    [t],
  );
  // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
  const selectedItem = useMemo(() => items.find(item => item.value === value).label, [items, value]);

  return (
    <Box
      className={clsx({
        'cell-content': true,
        disabled: isObsolete,
      })}
      style={{ padding: 0 }}
    >
      <Box sx={{ width: '100%' }}>
        <Select
          initialSelectedItem={selectedItem}
          selectedItem={selectedItem}
          onChange={(changes) => {
            setCellValue(
              row.original._id,
              column.original._id,
              changes.selectedItem?.value,
            );
          }}
          items={items}
          itemToString={(item) => item.label}
        />
      </Box>
    </Box>
  );
};

export const LockInputCell = ({
  row,
  column,
}: DataCellProps<EditableGridColumn, any, any>) => {
  const intercom = useIntercom();
  const { t } = useTranslation();
  const senders = rfx.useSenders();

  const lock = getCellValue(row.original, column.original);
  const { isLive, isObsolete, type: exchangeType } = row.original;
  const isLockable = lockableExchangeTypes.includes(exchangeType);

  const lockModal = useModalState();

  const {
    cellInputState,
    setCellValue,
    setEditedCell,
    updateFirstMatchingRow,
  } = useEditableGridData();

  const onLockChange = useCallback(
    (lock: Lock | null) => {
      const newExchangeType = getExchangeTypeFromLock(exchangeType, lock);

      if (exchangeType !== newExchangeType) {
        updateFirstMatchingRow(
          { _id: row.original._id },
          (row) => ({
            ...row,
            type: newExchangeType,
          }),
        );
      }
      setCellValue(
        row.original._id,
        column.original._id,
        lock,
      );
    },
    [exchangeType, setCellValue, updateFirstMatchingRow, row.original._id, column.original._id],
  );

  const idPrefix = useGridIdPrefix();
  const handleModalClose = useCallback(() => {
    lockModal.close();
    setEditedCell(null);
    cellInputState.current = null;
    focusGrid(idPrefix);
  }, [setEditedCell, cellInputState, idPrefix, lockModal]);

  const isDisabled = isObsolete || (isLive && !lock) || !isLockable;

  return (
    <>
      <div
        className={clsx({
          'cell-content': true,
          disabled: isDisabled,
        })}
        onClick={() => {
          if (isLockable) {
            lockModal.open();

            if (!isLive) {
              intercom.trackEvent('locking-modal-opened');
            }
          }
        }}
      >
        {isLockable ? (
          <LockedTooltip lock={lock} senders={senders}>
            <Box display="inline-block">
              <IconText
                color={!isDisabled ? 'text' : 'lightGray'}
                iconColor={!isDisabled ? 'text' : 'lightGray'}
                icon={lock ? 'lock' : 'unlock'}
                text={
                  lock
                    ? isLive
                      ? t('general.lockSet')
                      : t('general.editLock')
                    : t('general.noLockSet')
                }
              />
            </Box>
          </LockedTooltip>
        ) : (
          <Text color="subtext">
            {row.original.type ? (t('general.notApplicableShort') as string) : ''}
          </Text>
        )}
      </div>
      <LockModal
        isOpen={lockModal.isOpen}
        close={handleModalClose}
        lock={lock}
        isReadOnly={isLive}
        onSubmit={onLockChange}
      />
    </>
  );
};

export const SelectInputCell = ({
  row,
  column,
  options,
  placeholder,
}: DataCellProps<EditableGridColumn, any, any> & { options: { value: string; label: any; hasSeparatorAbove?: boolean }[]; placeholder: string }) => {
  const {
    cellInputState,
    setCellValue,
    setEditedCell,
  } = useEditableGridData();

  const value = getCellValue(row.original, column.original) as string | null;

  const option = value
    ? options.find(option => option.value === value)
    : null;

  const onSelect = useCallback(
    (value: string | null) => {
      setCellValue(row.original._id, column.original._id, value);
    },
    [setCellValue, row.original._id, column.original._id],
  );

  const idPrefix = useGridIdPrefix();
  const [menuReferenceId, setMenuReferenceId] = useState<string | null>(() => {
    const cellId = getGridCellId(idPrefix, {
      // Rows array always have a null element at the start to account for the
      // grid header, hence the +1
      rowIndex: row.index + 1,
      columnIndex: column.index,
    });
    return cellId;
  });

  const closeGridMenu = useCallback(() => {
    setMenuReferenceId(null);
    setEditedCell(null);
    cellInputState.current = null;
    focusGrid(idPrefix);
  }, [setMenuReferenceId, setEditedCell, cellInputState, idPrefix]);

  return (
    <>
      <div
        className="cell-content"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          const cellId = getGridCellId(idPrefix, {
            // Rows array always have a null element at the start to account for the
            // grid header, hence the +1
            rowIndex: row.index + 1,
            columnIndex: column.index,
          });

          setMenuReferenceId(menuReferenceId === cellId ? null : cellId);
        }}
      >
        <Truncate>
          {option?.label || placeholder}
        </Truncate>
        {value ? (
          <IconButton
            icon="xmark"
            ml={2}
            mt="2px"
            color="text"
            onClick={() => {
              onSelect(null);
              closeGridMenu();
            }}
          />
        ) : (
          <Icon icon="caret-down" ml={2} mt="2px" color="text" />
        )}
      </div>

      {menuReferenceId ? (
        <GridPopup
          key={menuReferenceId}
          cellId={menuReferenceId}
          close={closeGridMenu}
          popoverProps={{
            'data-test': 'award-lot-menu',
          }}
          sameWidth
        >
          <GridMenuContent close={closeGridMenu}>
            {options.map(({ value, label, hasSeparatorAbove }) => (
              <GridMenuItem key={value} onSelect={() => onSelect(value)} hasSeparatorAbove={hasSeparatorAbove}>
                {label}
              </GridMenuItem>
            ))}
          </GridMenuContent>
        </GridPopup>
      ) : null}
    </>
  );
};
