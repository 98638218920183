import { useMemo } from 'react';
import { isNumber, round } from 'lodash';
import { useTranslation } from 'react-i18next';
import { localeFormatNumber } from '@deepstream/utils';
import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { Grid } from '@deepstream/ui-kit/elements/Grid';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { NegotiatedAuctionsSavings } from './NegotiatedAuctionsSavings';
import * as dashboard from './dashboard';
import { useRequestsReporting, RequestsReportingDataContext } from './useRequestsReporting';

export const AuctionsTabContent = ({
  config,
  useData = useRequestsReporting,
}: {
  config: RequestsReportingConfig;
  useData?: typeof useRequestsReporting;
}) => {
  const { t } = useTranslation('reporting');
  const locale = useCurrentUserLocale();

  const { data } = useData(config);

  const contextValue = useMemo(
    () => ({ data: data ?? {} }),
    [data],
  );

  return (
    // @ts-expect-error ts(2322) FIXME: Type '{ data: any; }' is not assignable to type 'null'.
    <RequestsReportingDataContext.Provider value={contextValue}>
      {data && (
        <dashboard.Row>
          <dashboard.Column flex={1}>
            <NegotiatedAuctionsSavings />
          </dashboard.Column>
          <Grid
            sx={{
              flex: 1,
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: '1fr 1fr',
            }}
          >
            <dashboard.Value
              icon="clock"
              heading={t('auctions.durationAverage')}
              // @ts-expect-error ts(2322) FIXME: Type 'string' is not assignable to type 'undefined'.
              headingTestAttribute="duration-average"
              value={isNumber(data.auctions.averageDurationSec) ? (
                localeFormatNumber(Math.round(data.auctions.averageDurationSec / 60), { locale })
              ) : (
                t('noData')
              )}
            />
            <dashboard.Value
              icon="gavel"
              heading={t('auctions.totalBidsAverage')}
              // @ts-expect-error ts(2322) FIXME: Type 'string' is not assignable to type 'undefined'.
              headingTestAttribute="total-bids-average"
              value={isNumber(data.auctions.averageNumBids) ? (
                localeFormatNumber(round(data.auctions.averageNumBids, 2), { locale })
              ) : (
                t('noData')
              )}
            />
            <dashboard.Value
              icon="clock"
              heading={t('auctions.extensionsPerAuctionAverage')}
              // @ts-expect-error ts(2322) FIXME: Type 'string' is not assignable to type 'undefined'.
              headingTestAttribute="extensions-per-auction-average"
              value={isNumber(data.auctions.averageNumExtensions) ? (
                localeFormatNumber(round(data.auctions.averageNumExtensions, 2), { locale })
              ) : (
                t('noData')
              )}
            />
            <dashboard.Value
              icon="clock"
              heading={t('auctions.suppliersPerAuctionAverage')}
              // @ts-expect-error ts(2322) FIXME: Type 'string' is not assignable to type 'undefined'.
              headingTestAttribute="suppliers-per-auction-average"
              value={isNumber(data.auctions.averageNumSuppliers) ? (
                localeFormatNumber(round(data.auctions.averageNumSuppliers, 2), { locale })
              ) : (
                t('noData')
              )}
            />
          </Grid>
        </dashboard.Row>
      )}
    </RequestsReportingDataContext.Provider>
  );
};
