import * as React from 'react';
import { useField } from 'formik';
import { Flex, Box } from 'rebass/styled-components';
import { isNil, noop } from 'lodash';
import { ProductTag } from '@deepstream/common/products';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { Input } from '../ui/Input';
import { FieldContainer } from './FieldContainer';
import { ErrorMessage, HelperText } from './Field';
import { useModalState } from '../ui/useModalState';
import { EditUnspscCodeModal } from '../ui/ExchangeDefsGrid/EditUnspscCodeModal';
import { getUnspscCodeTooltipContent } from '../ui/ExchangeDefsGrid/validationAwareValueCell';

// Autofocus will only be used after the user has already expressed their intent to reply
/* eslint-disable jsx-a11y/no-autofocus */

type UnspscCodeFieldBaseProps = {
  disabled?: boolean;
  error?: string;
  helperText?: string | React.ReactNode;
  extendedInfo?: (value: string | number) => React.ReactNode;
  hideLabel?: boolean;
  hideError?: boolean;
  label?: string;
  description?: string;
  name?: string;
  onChange?: (value: string | null) => void;
  required?: boolean;
  value?: string | string;
  inputStyle?: React.CSSProperties;
  initialProductOrService?: ProductTag | null;
  autoFocus?: boolean;
};

export const UnspscCodeFieldBase = React.forwardRef<HTMLInputElement, UnspscCodeFieldBaseProps>(({
  disabled,
  error,
  hideError,
  helperText,
  extendedInfo,
  hideLabel,
  label,
  description,
  name,
  onChange = noop,
  required,
  value,
  inputStyle = {},
  initialProductOrService,
  autoFocus,
}: UnspscCodeFieldBaseProps, ref) => {
  const { t } = useTranslation();
  const id = useUniqueId();

  const editUnspscCodeModal = useModalState();
  // @ts-expect-error ts(2345) FIXME: Argument of type 'ProductTag | null | undefined' is not assignable to parameter of type 'ProductTag | (() => ProductTag | null) | null'.
  const [productOrService, setProductOrService] = React.useState<ProductTag | null>(initialProductOrService);

  const updateUnspscCode = React.useCallback((productTag) => {
    setProductOrService(productTag);
    onChange(productTag?._id || null);
    editUnspscCodeModal.close();
  }, [editUnspscCodeModal, onChange, setProductOrService]);

  return (
    <>
      <FieldContainer
        name={name}
        htmlFor={id}
        label={label}
        hideLabel={hideLabel}
        showAsterisk={required}
        width="100%"
        description={description}
      >
        <Flex>
          <Input
            id={id}
            type="text"
            name={name}
            value={productOrService ? `[${productOrService._id}] ${productOrService.title}` : ''}
            disabled
            style={inputStyle}
            ref={ref}
            title={productOrService ? getUnspscCodeTooltipContent(productOrService, t) : undefined}
            autoFocus={autoFocus}
          />
          <EditButton
            type="button"
            variant="primary"
            ml={2}
            disabled={disabled}
            onClick={editUnspscCodeModal.open}
          />
        </Flex>
        {error && !hideError ? (
          <Box sx={{ wordBreak: 'break-word', textAlign: 'left' }}>
            <ErrorMessage error={error} fontWeight="normal" />
          </Box>
        ) : extendedInfo ? (
          <Box sx={{ wordBreak: 'break-word', textAlign: 'left' }} mt={1}>
            {/*
             // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string | number'. */}
            {extendedInfo(value)}
          </Box>
        ) : helperText ? (
          <Flex justifyContent="space-between">
            {helperText && (
              <HelperText text={helperText} />
            )}
          </Flex>
        ) : (
          null
        )}
      </FieldContainer>
      {editUnspscCodeModal.isOpen && (
        <EditUnspscCodeModal
          isOpen={editUnspscCodeModal.isOpen}
          onClose={editUnspscCodeModal.close}
          unspscCode={value || null}
          onSubmit={updateUnspscCode}
        />
      )}
    </>
  );
});

export interface UnspscCodeFieldProps extends UnspscCodeFieldBaseProps {
  /**
   * Use this prop if you want the value to be bound to the form (ie: for editing)
   */
  name?: string;
  /**
   * Use this prop if you want to read a value from the form, without the possibility
   * of editing (ie: for a synced read-only field)
   */
  fieldName?: string;
}

/**
 * Formik-aware UnspscCodeField component.
 *
 * If you pass a `fieldName` without a `name`, changing the value
 * won't update the corresponding value (this is useful for displaying
 * read-only derived values)
 */
export const UnspscCodeField = React.forwardRef<HTMLInputElement, UnspscCodeFieldProps>(({
  name,
  fieldName,
  ...props
}: UnspscCodeFieldProps, ref) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field, meta, formik] = useField({ name: resolvedFieldName! });
  const { value } = field;

  const onChange = React.useCallback(
    (value) => {
      formik.setTouched(true);
      formik.setValue(value);
    },
    [formik],
  );

  return (
    <UnspscCodeFieldBase
      error={meta.touched && meta.error ? meta.error : undefined}
      name={name}
      onChange={onChange}
      value={isNil(value) ? '' : value}
      ref={ref}
      {...props}
    />
  );
});
