import { constant } from 'lodash';
import * as React from 'react';

import { Flex, Box, Text, BoxProps } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { SwitchField } from '../../../form/SwitchField';
import { GlobalRole } from './types';
import {
  orderedPermissions,
  orderedRequestsPermissions,
  orderedContractsPermissions,
  orderedReportingPermissions,
  orderedPreQualPermissions,
} from './utils';
import { FieldContainer } from '../../../form/FieldContainer';

const List = (props: BoxProps) => (
  <Box
    as="ul"
    sx={{ borderRadius: 4, borderWidth: 1, borderStyle: 'solid', borderColor: 'lightGray', padding: 0 }}
    {...props}
  />
);

const Item = ({ isLast, isHeader, ...props }: { isLast?: boolean; isHeader?: boolean; children: React.ReactNode }) => (
  <Flex
    as="li"
    px={3}
    alignItems="center"
    justifyContent="space-between"
    height={50}
    style={{ fontWeight: isHeader ? 500 : 'initial' }}
    sx={isLast ? undefined : { borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'lightGray' }}
    {...props}
  />
);

const usePermissionItems = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return orderedPermissions.map(permission => ({
      id: permission,
      label: t(`teamManagement.permissions.${permission}`),
    }));
  }, [t]);
};

const useRequestsPermissionItems = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return orderedRequestsPermissions.map(permission => ({
      id: permission,
      label: t(`teamManagement.permissions.${permission}`),
    }));
  }, [t]);
};

const useContractsPermissionItems = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return orderedContractsPermissions.map(permission => ({
      id: permission,
      label: t(`teamManagement.permissions.${permission}`),
    }));
  }, [t]);
};

const usePreQualPermissionItems = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return orderedPreQualPermissions.map(permission => ({
      id: permission,
      label: t(`teamManagement.permissions.${permission}`),
    }));
  }, [t]);
};

const useReportingPermissionItems = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return orderedReportingPermissions.map(permission => ({
      id: permission,
      label: t(`teamManagement.permissions.${permission}`),
    }));
  }, [t]);
};

const ItemDescription = ({
  description,
  hasLock = false,
  subtextLight = false,
}: {
  description: string
  hasLock?: boolean,
  subtextLight?: boolean,
}) => {
  return (
    <Text color={subtextLight ? 'subtextLight' : 'gray'} fontSize={0} sx={{ whiteSpace: 'break-spaces' }}>
      {hasLock && (
        <Icon
          style={{ fontSize: '12px', marginRight: 4 }}
          icon="lock"
          light
        />
      )}
      {description}
    </Text>
  );
};

export const EditPermissionList = ({
  isItemDisabled = constant(false),
  globalRole,
}: {
  isItemDisabled?: (itemId: string, globalRole: string) => boolean;
  globalRole?: string
}) => {
  const { t } = useTranslation();
  const permissions = usePermissionItems();

  return (
    <FieldContainer label={t('teamManagement.permission_other')}>
      <List>
        {permissions.map(({ id, label }, index) => (
          <Item key={id} isLast={index === permissions.length - 1}>
            <Box>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              <Text as="label" fontSize={2} color="text" fontWeight={400} opacity={isItemDisabled(id, globalRole) ? 0.5 : 1}>
                {label}
              </Text>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              { isItemDisabled(id, globalRole)
                ? globalRole === GlobalRole.SUPER_USER ? (
                  <ItemDescription description={t('teamManagement.dialog.editPermissions.cannotBeEdited')} hasLock subtextLight />
              ) : (
                <ItemDescription description={t('teamManagement.dialog.editPermissions.notAvailable')} hasLock subtextLight />
              ) : null}
            </Box>
            <Flex>
              <SwitchField
                // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                name={id} aria-label={label} disabled={isItemDisabled(id, globalRole)} checkedIcon={false}
                uncheckedIcon={false} width={42}
              />
            </Flex>
          </Item>
        ))}
      </List>
    </FieldContainer>
  );
};

export const EditRequestsPermissionList = ({
  isItemDisabled = constant(false),
  globalRole,
}: {
  isItemDisabled?: (itemId: string, globalRole: string) => boolean;
  globalRole?: string;
}) => {
  const { t } = useTranslation();
  const requestsPermissions = useRequestsPermissionItems();

  return (
    <FieldContainer label={t('teamManagement.permission_other')}>
      <List>
        {requestsPermissions.map(({ id, label }, index) => (
          <Item key={id} isLast={index === requestsPermissions.length - 1}>
            <Box>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              <Text as="label" fontSize={2} color="text" fontWeight={400} opacity={isItemDisabled(id, globalRole) ? 0.5 : 1}>
                {label}
              </Text>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              {isItemDisabled(id, globalRole) && (
                <ItemDescription description={t('teamManagement.dialog.editPermissions.notAvailable')} hasLock subtextLight />
              )}
              {id === 'receiveRFQ' && (
                <ItemDescription description={t('teamManagement.dialog.editPermissions.userCanReceiveNewRequests')} />
              )}
            </Box>
            <Flex>
              <SwitchField
                // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                name={id} aria-label={label} disabled={isItemDisabled(id, globalRole)} checkedIcon={false}
                uncheckedIcon={false} width={42}
              />
            </Flex>
          </Item>
        ))}
      </List>
    </FieldContainer>
  );
};

export const EditContractsPermissionList = ({
  isItemDisabled = constant(false),
  globalRole,
}: {
  isItemDisabled?: (itemId: string, globalRole: string) => boolean;
  globalRole?: string
}) => {
  const { t } = useTranslation();
  const contractsPermissions = useContractsPermissionItems();

  return (
    <FieldContainer label={t('teamManagement.permission_other')}>
      <List>
        {contractsPermissions.map(({ id, label }, index) => (
          <Item key={id} isLast={index === contractsPermissions.length - 1}>
            <Box>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              <Text as="label" fontSize={2} color="text" fontWeight={400} opacity={isItemDisabled(id, globalRole) ? 0.5 : 1}>
                {label}
              </Text>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              {isItemDisabled(id, globalRole) && (
                <ItemDescription description={t('teamManagement.dialog.editPermissions.notAvailable')} hasLock subtextLight />
              )}
              {id === 'receiveContracts' && (
                <ItemDescription description={t('teamManagement.dialog.editPermissions.userCanReceiveNewContracts')} />
              )}
            </Box>
            <Flex>
              <SwitchField
                // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                name={id} aria-label={label} disabled={isItemDisabled(id, globalRole)} checkedIcon={false}
                uncheckedIcon={false} width={42}
              />
            </Flex>
          </Item>
        ))}
      </List>
    </FieldContainer>
  );
};

export const EditPreQualPermissionList = ({
  isItemDisabled = constant(false),
  globalRole,
}: {
  isItemDisabled?: (itemId: string, globalRole: string) => boolean;
  globalRole?: string
}) => {
  const { t } = useTranslation();
  const preQualPermissions = usePreQualPermissionItems();

  return (
    <FieldContainer label={t('teamManagement.permission_other')}>
      <List>
        {preQualPermissions.map(({ id, label }, index) => (
          <Item key={id} isLast={index === preQualPermissions.length - 1}>
            <Box>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              <Text as="label" fontSize={2} color="text" fontWeight={400} opacity={isItemDisabled(id, globalRole) ? 0.5 : 1}>
                {label}
              </Text>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              {isItemDisabled(id, globalRole) && (
                <ItemDescription description={t('teamManagement.dialog.editPermissions.notAvailable')} hasLock subtextLight />
              )}
              {id === 'receiveQuestionnaires' && (
                <ItemDescription description={t('teamManagement.dialog.editPermissions.userCanReceiveNewQuestionnaires')} />
              )}
            </Box>
            <Flex>
              <SwitchField
                // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                name={id} aria-label={label} disabled={isItemDisabled(id, globalRole)} checkedIcon={false}
                uncheckedIcon={false} width={42}
              />
            </Flex>
          </Item>
        ))}
      </List>
    </FieldContainer>
  );
};

export const EditReportingPermissionList = ({
  isItemDisabled = constant(false),
  globalRole,
}: {
  isItemDisabled?: (itemId: string, globalRole: string) => boolean;
  globalRole?: string;
}) => {
  const { t } = useTranslation();
  const reportingPermissions = useReportingPermissionItems();

  return (
    <FieldContainer label={t('teamManagement.permission_other')}>
      <List>
        {reportingPermissions.map(({ id, label }, index) => (
          <Item key={id} isLast={index === reportingPermissions.length - 1}>
            <Box>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              <Text as="label" fontSize={2} color="text" fontWeight={400} opacity={isItemDisabled(id, globalRole) ? 0.5 : 1}>
                {label}
              </Text>
              {/*
               // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'. */}
              {isItemDisabled(id, globalRole) && (
                <ItemDescription description={t('teamManagement.dialog.editPermissions.notAvailable')} hasLock subtextLight />
              )}
            </Box>
            <Flex>
              <SwitchField
                // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                name={id} aria-label={label} disabled={isItemDisabled(id, globalRole)} checkedIcon={false}
                uncheckedIcon={false} width={42}
              />
            </Flex>
          </Item>
        ))}
      </List>
    </FieldContainer>
  );
};
