import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import axios from 'axios';
import { initReactTranslations } from '@deepstream/ui/i18n';
import { App } from './App';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = (config) => Boolean(config.withCredentials);

Modal.setAppElement('#root');

const container = document.getElementById('root');
if (!container) {
  // This shouldn't happen, but if it does, we want to know about it
  throw new Error('Root element not found');
}
const root = createRoot(container);

initReactTranslations()
  .then(() => {
    root.render(<App />);
  })
  // eslint-disable-next-line no-console
  .catch(err => console.log(err));
