import { TeamUsers } from '@deepstream/ui/modules/Company/TeamManagement/TeamUsers';
import { useSession } from '@deepstream/ui/auth';
import { Page } from './Page';
import * as title from './title';
import { AdminUserFlagsProvider } from './AdminUserFlagsProvider';

export const TeamUsersPage = () => {
  const auth = useSession();

  return auth.user ? (
    <AdminUserFlagsProvider>
      <Page>
        <title.Container>
          <title.IconText icon="users" size="large">
            Manage admin users
          </title.IconText>
        </title.Container>
        <TeamUsers />
      </Page>
    </AdminUserFlagsProvider>
  ) : (
    null
  );
};
