import { useMemo, useRef, useState, createContext, useContext, useCallback } from 'react';
import * as React from 'react';
import {
  Tabs as ReachTabs,
  TabList as ReachTabList,
  Tab as ReachTab,
  TabPanels,
  TabsProps as ReachTabsProps,
  TabProps as ReachTabProps,
  TabListProps as ReachTabListProps,
} from '@reach/tabs';
import { transparentize } from 'polished';
import { useRect } from '@reach/rect';
import styled from 'styled-components';
import { Box, BoxProps } from 'rebass/styled-components';
import { assign, debounce } from 'lodash';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useIsomorphicLayoutEffect } from '@deepstream/ui-kit/hooks/useIsomorphicLayoutEffect';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { Bold } from '../Bold';
import { TabPanel } from './TabPanel';

const TabsContext = createContext<{ setSelectedRect?: any; canOverflow?: boolean }>({});

interface AnimatedHighlightBarProps {
  height?: number;
  selectedRect: DOMRect | null;
  tabsRect: DOMRect;
}

const AnimatedHighlightBar = ({
  height = 2,
  tabsRect,
  selectedRect,
}: AnimatedHighlightBarProps) => (
  <HighlightBar
    height={height}
    sx={{
      transition: 'all 300ms ease',
      left: selectedRect ? selectedRect.left - tabsRect.left : 0,
      width: selectedRect ? Math.round(selectedRect.width) : 0,
      top: (selectedRect ? selectedRect.bottom : 0) - tabsRect.top + 1,
    }}
  />
);

type HighlightBarProps = BoxProps & {
  height?: number;
};

const HighlightBar = ({ height = 2, sx = {}, ...props }: HighlightBarProps) => (
  <Box
    sx={{
      position: 'absolute',
      height: `${height}px`,
      marginTop: `-${height}px`,
      bg: 'primary',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      ...sx,
    }}
    {...props}
  />
);

const StyledTabs = styled(ReachTabs)`
  :root {
    --reach-tabs: 1;
  }
`;

const StyledReachTab = styled(ReachTab)`
  &[data-reach-tab] {
    position: relative;
    border: none;
    margin: 0;
    background-color: inherit;
    color: ${props => props.theme.colors.subtext};
    height: 100%;
    cursor: pointer;
    padding: ${props => props.theme.space[2]}px ${props => props.theme.space[3]}px;
    transition:
      color 300ms,
      background-color 300ms,
      border-color 300ms,
      box-shadow 300ms;
  }

  &[data-reach-tab]:focus {
    background-color: ${props => transparentize(0.85, props.theme.colors.primary)};
    outline: none;
  }

  &[data-reach-tab]:hover:not(:focus):not(:disabled) {
    background-color: ${props => transparentize(0.95, props.theme.colors.primary)};
  }

  &[data-reach-tab]:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &[data-reach-tab][data-selected] {
    color: ${props => props.theme.colors.primary};
  }
`;

type TabsProps = ReachTabsProps & {
  canOverflow?: boolean;
  style?: React.CSSProperties;
};

export const Tabs = ({ canOverflow, style, children, ...props }: TabsProps) => {
  // need to store the position of the selected Tab so we can
  // animate the bar to its position
  const [selectedRect, setSelectedRect] = useState(null);

  // need to measure the parent element so we can measure
  // the relative "left" for the bar
  const tabsRef = useRef<any>();
  const tabsRect = useRect(tabsRef);

  const contextValue = useMemo(
    () => ({ setSelectedRect, canOverflow }),
    [setSelectedRect, canOverflow],
  );

  // Put the function to change the positions on context so the
  // Tabs down the tree can easily access it
  return (
    <TabsContext.Provider value={contextValue}>
      <StyledTabs
        {...props}
        ref={tabsRef}
        style={{ position: 'relative', width: '100%', ...style }}
      >
        <>
          {/* If the tabs cannot overflow horizontally we render an animated highlight bar */}
          {Boolean(!canOverflow && tabsRect) && (
            // @ts-expect-error ts(2322) FIXME: Type 'DOMRect | null' is not assignable to type 'DOMRect'.
            <AnimatedHighlightBar selectedRect={selectedRect} tabsRect={tabsRect} />
          )}
          {children}
        </>
      </StyledTabs>
    </TabsContext.Provider>
  );
};

type TabProps = ReachTabProps & {
  style?: React.CSSProperties;
  isSelected?: boolean;
  tooltip?: string;
  className?: string;
};

export const Tab = ({ style, className, children, ...props }: TabProps) => {
  const { isSelected, tooltip } = props;

  // Each tab measures itself
  const ref = useRef<any>();
  const rect = useRect(ref, { observe: isSelected });

  // and calls up to the parent when it becomes selected
  // we useLayoutEffect to avoid flicker
  const { setSelectedRect, canOverflow } = useContext(TabsContext);

  useIsomorphicLayoutEffect(
    () => {
      if (isSelected) {
        setSelectedRect(rect);
      }
    },
    [isSelected, rect, setSelectedRect],
  );

  return (
    <Tooltip content={tooltip}>
      <div style={{ display: 'inline-block', height: '100%' }} className={className}>
        <StyledReachTab ref={ref} style={style} {...props}>
          <Box>
            <Bold>
              {children}
            </Bold>
          </Box>
          {/* If the tabs can overflow horizontally we render a simple (non animated) highlight bar
            (because the animated one causes issues on scroll) */}
          {canOverflow && isSelected && <HighlightBar />}
        </StyledReachTab>
      </div>
    </Tooltip>
  );
};

const ScrollButton = ({ icon, sx = {}, ...props }: ButtonProps & { icon: IconProps['icon'] }) => (
  <Button
    variant="wrapper"
    color="subtext"
    sx={{
      position: 'absolute',
      top: '1px',
      width: '40px',
      height: '38px',
      cursor: 'pointer',
      zIndex: 2,
      borderRadius: 0,
      marginTop: '-1px',

      '> i': {
        position: 'absolute',
        top: '13px',
        left: '12px',
        fontSize: 3,
        width: '16px',
      },

      ...sx,
    }}
    {...props}
  >
    <Icon icon={icon} />
  </Button>
);

const StyledTabList = styled(ReachTabList)`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray2};
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  background: hsla(0, 0%, 0%, 0.05);
  overflow-y: hidden;
`;

type TabListProps = ReachTabListProps & {
  style?: React.CSSProperties;
  scrollLeftBackground?: string;
  scrollRightBackground?: string;
  children: React.ReactNode;
};

export const TabList = ({
  style = {},
  children,
  scrollLeftBackground = 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%)',
  scrollRightBackground = 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 20%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%)',
  ...props
}: TabListProps) => {
  const { canOverflow } = useContext(TabsContext);
  const ref = React.useRef<any>();
  const [showLeftScrollButton, setShowLeftScrollButton] = React.useState(false);
  const [showRightScrollButton, setShowRightScrollButton] = React.useState(false);

  const updateButtonsVisibility = () => {
    if (canOverflow && ref.current) {
      const el = ref.current;
      const isOverflowed = el.clientWidth < el.scrollWidth;
      setShowLeftScrollButton(isOverflowed && el.scrollLeft !== 0);
      setShowRightScrollButton(isOverflowed && el.scrollLeft !== (el.scrollWidth - el.clientWidth));
    }
  };

  useIsomorphicLayoutEffect(
    updateButtonsVisibility,
    [children],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll = useCallback(
    debounce(updateButtonsVisibility, 100),
    [],
  );

  const scrollLeft = () => {
    const el = ref.current;
    el.scrollLeft = Math.max(el.scrollLeft - 70, 0);
  };

  const scrollRight = () => {
    const el = ref.current;
    el.scrollLeft = Math.min(el.scrollLeft + 70, el.scrollWidth - el.clientWidth);
  };

  const customStyle = canOverflow
    ? {
      display: 'flex',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    }
    : {};

  return (
    <StyledTabList
      ref={ref}
      style={assign(customStyle, style)}
      onScroll={onScroll}
      {...props}
    >
      {React.Children.toArray(children).filter(Boolean)}
      {canOverflow && showLeftScrollButton && (
        <ScrollButton
          icon="chevron-left"
          onClick={scrollLeft}
          sx={{
            left: 0,
            background: scrollLeftBackground,
          }}
        />
      )}
      {canOverflow && showRightScrollButton && (
        <ScrollButton
          icon="chevron-right"
          onClick={scrollRight}
          sx={{
            right: 0,
            background: scrollRightBackground,
          }}
        />
      )}
    </StyledTabList>
  );
};

export const TabDivider = styled(Box)`
  position: absolute;
  left: -13px;
  top: 9px;
  height: 20px;
  width: 1px;
  background-color: ${props => props.theme.colors.lightGray2};
  cursor: default;
`;

export const dividerTabStyle = {
  position: 'relative' as const,
  marginLeft: '25px',
};

export {
  TabPanel,
  TabPanels,
};
