import { omit, mapValues, zipObject, some, isFinite, update, set, get } from 'lodash';
import {
  LineItemExchangeDefinition,
  ExchangeType,
  FieldConfig,
  getAdjacentConfigurableField,
  isDefinitionField,
  isFieldValueDefined,
  getExchangeDefFieldValue,
  getEmptyFieldValue,
  getBasicLineItemFields,
  ResponseTag,
} from '@deepstream/common/rfq-utils';

import { roundToDecimals } from '@deepstream/utils';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { FieldType } from '@deepstream/common/exchangesConfig';
import {
  ConfigurableFieldFormValues,
  SupplierResponseValue,
  ConfigurableFieldType,
  FormulaFieldType,
} from '../../modules/Request/LineItems';
import { InputsConfig } from '../../modules/Request/LineItems/LineItemFields/useConfigureFieldModal';

const evaluatorFieldCurrencyField = {
  _id: 'evaluatorFieldCurrency',
  type: FieldType.STRING,
  required: true,
  source: {
    type: 'definition',
    key: 'evaluatorFieldCurrency',
  },
} as const;

/**
 * Checks if the exchange definition contains any price fields with source
 * 'definition'.
 * If so, makes sure that the exchange definition also contains an `evaluatorFieldCurrency`.
 * If not, makes sure that the exchange definition does not contain an `evaluatorFieldCurrency`.
 */
const adjustEvaluatorFieldCurrency = (exchangeDef: LineItemExchangeDefinition): LineItemExchangeDefinition => {
  const needsEvaluatorFieldCurrency = some(
    exchangeDef.fields,
    field => isDefinitionField(field) && field.type === FieldType.PRICE,
  );

  if (needsEvaluatorFieldCurrency) {
    if (exchangeDef.fields.evaluatorFieldCurrency) {
      return exchangeDef;
    } else {
      return {
        ...exchangeDef,
        fields: {
          ...exchangeDef.fields,
          evaluatorFieldCurrency: evaluatorFieldCurrencyField,
        },
        evaluatorFieldCurrency: null,
      };
    }
  } else {
    return {
      ...exchangeDef,
      fields: omit(exchangeDef.fields, 'evaluatorFieldCurrency'),
      evaluatorFieldCurrency: null,
    } as LineItemExchangeDefinition;
  }
};

export const createDescriptionFieldNameUpdater = (fieldName: string | null) => (exchangeDef: LineItemExchangeDefinition) => {
  if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
    return exchangeDef;
  }

  return {
    ...exchangeDef,
    fields: {
      ...exchangeDef.fields,
      description: {
        ...exchangeDef.fields.description,
        label: fieldName,
      },
    },
  };
};

export const createAddTotalCostFormulaUpdater = (formula: string) => (exchangeDef: LineItemExchangeDefinition) => {
  if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
    return exchangeDef;
  }

  return {
    ...exchangeDef,
    fields: {
      ...exchangeDef.fields,
      totalCost: {
        ...exchangeDef.fields.totalCost,
        source: {
          ...exchangeDef.fields.totalCost.source,
          formula,
        },
      },
    },
  };
};

export const createAddLeadTimeFieldUpdater = (fieldForm?: ConfigurableFieldFormValues) => (exchangeDef: LineItemExchangeDefinition) => {
  if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
    return exchangeDef;
  }

  const field = {
    _id: 'leadTime:submitter',
    type: FieldType.NUMBER,
    required: true,
    source: {
      type: 'reply',
      role: 'submitter',
      key: 'leadTime:submitter',
    },
  } as FieldConfig;

  if (fieldForm?.supplierResponseTags) {
    field.responseTags = fieldForm.supplierResponseTags;
  }

  return {
    ...exchangeDef,
    fields: {
      ...exchangeDef.fields,
      'leadTime:submitter': field,
    },
  } as LineItemExchangeDefinition;
};

export const createAddDefaultFieldUpdater = (fieldId: string, fieldForm?: ConfigurableFieldFormValues) =>
  (exchangeDef: LineItemExchangeDefinition) => {
    if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
      return exchangeDef;
    }

    const field = getBasicLineItemFields()[fieldId];

    if (!field) {
      throw new Error(`Unexpected default field key ${fieldId}`);
    }

    if (fieldForm) {
      if (fieldForm.decimalPlaces) {
        field.decimalPlaces = fieldForm.decimalPlaces;
      }
      if (fieldForm.supplierResponseTags) {
        field.responseTags = fieldForm.supplierResponseTags;
      }
    }

    const exchangeDefFieldValue = isDefinitionField(field)
      ? {
        [field.source.key]: getEmptyFieldValue(field.type),
      }
      : {};

    return adjustEvaluatorFieldCurrency({
      ...exchangeDef,
      fields: {
        ...exchangeDef.fields,
        [fieldId]: field,
      },
      ...exchangeDefFieldValue,
    } as LineItemExchangeDefinition);
  };

export const addTargetPriceField = (exchangeDef: LineItemExchangeDefinition) => {
  if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
    return exchangeDef;
  }

  return adjustEvaluatorFieldCurrency({
    ...exchangeDef,
    fields: {
      ...exchangeDef.fields,
      'targetPrice': {
        _id: 'targetPrice',
        type: FieldType.PRICE,
        // no required flag here -- the target price is optional
        source: {
          type: 'definition',
          key: 'targetPrice',
        },
        visibility: {
          type: 'has-role',
          params: { role: 'evaluator' },
        },
      },
    },
  } as LineItemExchangeDefinition);
};

export const addUnspscCodeField = (exchangeDef: LineItemExchangeDefinition) => {
  if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
    return exchangeDef;
  }

  return {
    ...exchangeDef,
    fields: {
      ...exchangeDef.fields,
      unspscCode: {
        _id: 'unspscCode',
        type: FieldType.UNSPSC_CODE,
        required: true,
        source: {
          type: 'definition',
          key: 'unspscCode',
        },
      },
    },
    unspscCode: null,
  } as LineItemExchangeDefinition;
};

export const makeObsolete = <TEntity extends { isObsolete?: boolean }>(entity: TEntity) => {
  return {
    ...entity,
    isObsolete: true,
  };
};

export const makeNonObsolete = <TEntity extends { isObsolete?: boolean }>(entity: TEntity) => {
  return {
    ...entity,
    isObsolete: false,
  };
};

const supplierGenericFieldGenerator = ({
  _id: fieldId,
  label,
  fieldType,
  responseType,
  decimalPlaces = 2,
  responseTags,
}: {
  _id: string;
  label: string;
  fieldType: ConfigurableFieldType;
  responseType: SupplierResponseValue,
  decimalPlaces?: number;
  responseTags?: ResponseTag[];
}): { [k: string]: FieldConfig } => {
  if (fieldType === 'formula') {
    throw new Error('Cannot create buyer field for formula field');
  }

  // Overwrite supplier's field `type` if the response type === `Accept or reject`
  const type = responseType === SupplierResponseValue.BOOLEAN
    ? FieldType.BOOLEAN
    : fieldType;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _id = `${fieldId}:submitter`;

  return {
    [_id]: {
      _id,
      label,
      type,
      required: true,
      source: {
        type: 'reply',
        role: 'submitter',
        key: _id,
      },
      ...(fieldType === 'price' ? { decimalPlaces } : {}),
      ...(responseTags ? { responseTags } : {}),
    },
  };
};

const buyerGenericFieldGenerator = ({
  _id: fieldId,
  label,
  fieldType,
  decimalPlaces = 2,
}: {
  _id: string;
  label: string;
  fieldType: ConfigurableFieldType;
  decimalPlaces?: number;
}): { [k: string]: FieldConfig } => {
  if (fieldType === 'formula') {
    throw new Error('Cannot create buyer field for formula field');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _id = ['unit', 'quantity'].includes(fieldId) ? fieldId : `${fieldId}:evaluator`;

  return {
    [_id]: {
      _id,
      label,
      type: fieldType,
      required: true,
      source: {
        type: 'definition',
        key: _id,
      },
      ...(fieldType === 'price' ? { decimalPlaces } : {}),
    },
  };
};

const formulaGenericFieldGenerator = ({
  _id: fieldId,
  label,
  formula,
  formulaFormat,
}: {
  _id: string;
  label: string;
  formula: string;
  formulaFormat: FormulaFieldType;
}): { [k: string]: FieldConfig } => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _id = `${fieldId}:submitter`;

  return {
    [_id]: {
      _id,
      label,
      type: formulaFormat,
      required: true,
      source: {
        type: 'formula',
        formula,
      },
    },
  };
};

const fieldsGenerator = (fieldForm: ConfigurableFieldFormValues): { [k: string]: FieldConfig } => {
  let supplierField: { [k: string]: FieldConfig } | undefined;
  let buyerField: { [k: string]: FieldConfig } | undefined;
  let formulaField: { [k: string]: FieldConfig } | undefined;

  if (fieldForm.supplierResponseEnabled) {
    supplierField = supplierGenericFieldGenerator({
      _id: fieldForm.fieldsetId,
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      label: fieldForm.fieldName,
      fieldType: fieldForm.type,
      // @ts-expect-error ts(2322) FIXME: Type 'SupplierResponseValue | undefined' is not assignable to type 'SupplierResponseValue'.
      responseType: fieldForm.supplierResponseType,
      decimalPlaces: fieldForm.decimalPlaces,
      responseTags: fieldForm.supplierResponseTags,
    });
  }

  if (fieldForm.buyerProvidedValueEnabled) {
    buyerField = buyerGenericFieldGenerator({
      _id: fieldForm.fieldsetId,
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      label: fieldForm.fieldName,
      fieldType: fieldForm.type,
      decimalPlaces: fieldForm.decimalPlaces,
    });
  }

  if (fieldForm.formula) {
    formulaField = formulaGenericFieldGenerator({
      _id: fieldForm.fieldsetId,
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      label: fieldForm.fieldName,
      formula: fieldForm.formula,
      // @ts-expect-error ts(2322) FIXME: Type 'FormulaFieldType | undefined' is not assignable to type 'FormulaFieldType'.
      formulaFormat: fieldForm.formulaFormat,
    });
  }

  return {
    ...(buyerField || {}),
    ...(supplierField || {}),
    ...(formulaField || {}),
  };
};

export const addConfigurableField = (
  exchangeDef: LineItemExchangeDefinition,
  fieldForm: ConfigurableFieldFormValues,
) => {
  if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
    return exchangeDef;
  }

  const fields = fieldsGenerator(fieldForm);
  const values = mapValues(fields, () => {
    return null;
  });

  return adjustEvaluatorFieldCurrency({
    ...exchangeDef,
    fields: {
      ...exchangeDef.fields,
      ...fields,
    },
    ...values,
  } as LineItemExchangeDefinition);
};

export const deleteConfigurableField = (
  exchangeDef: LineItemExchangeDefinition,
  fieldId: string,
) => {
  const [fieldUuid, role] = fieldId.split(':');

  const effectiveFieldKeys = ['unit', 'quantity'].includes(fieldUuid)
    ? [`${fieldUuid}`, `${fieldUuid}:submitter`]
    : role
    ? [`${fieldUuid}:evaluator`, `${fieldUuid}:submitter`]
    : [fieldId];

  const effectiveFieldValueKey = role || ['unit', 'quantity'].includes(fieldUuid)
    ? `${fieldUuid}:evaluator`
    : fieldId;

  return adjustEvaluatorFieldCurrency({
    ...exchangeDef,
    fields: omit(exchangeDef.fields, effectiveFieldKeys),
    [effectiveFieldValueKey]: null,
  } as LineItemExchangeDefinition);
};

export const updateField = (
  exchangeDef: LineItemExchangeDefinition,
  fieldForm: ConfigurableFieldFormValues,
  inputsConfig?: InputsConfig,
) => {
  if (exchangeDef.type !== ExchangeType.LINE_ITEM) {
    return exchangeDef;
  }

  const adjacentField = getAdjacentConfigurableField(exchangeDef, fieldForm.fieldsetId);
  const [fieldUuid, role] = fieldForm.fieldsetId.split(':');
  const currentField = exchangeDef.fields[fieldForm.fieldsetId];

  let fieldsToUpdate: Record<string, Partial<FieldConfig>> = {};
  const fieldsToRemove: string[] = [];

  let supplierFieldId;
  let buyerFieldId;

  if (role === 'submitter') supplierFieldId = fieldForm.fieldsetId;
  else if (adjacentField) supplierFieldId = adjacentField._id;

  if (role === 'evaluator' || ['unit', 'quantity'].includes(fieldForm.fieldsetId)) buyerFieldId = fieldForm.fieldsetId;
  else if (adjacentField) buyerFieldId = adjacentField._id;

  // User has just enabled `Buyer Provided Value`
  if (fieldForm.buyerProvidedValueEnabled && !buyerFieldId) {
    const addedBuyerField = buyerGenericFieldGenerator({
      _id: fieldUuid,
      fieldType: fieldForm.type,
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      label: fieldForm.fieldName,
      decimalPlaces: fieldForm.decimalPlaces,
    });

    fieldsToUpdate = {
      ...fieldsToUpdate,
      ...addedBuyerField,
    };
  }

  // User has just enabled `Supplier Response`
  if (fieldForm.supplierResponseEnabled && !supplierFieldId) {
    const addedSupplierField = supplierGenericFieldGenerator({
      _id: fieldUuid,
      fieldType: fieldForm.type,
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      label: fieldForm.fieldName,
      // @ts-expect-error ts(2322) FIXME: Type 'SupplierResponseValue | undefined' is not assignable to type 'SupplierResponseValue'.
      responseType: fieldForm.supplierResponseType,
      decimalPlaces: fieldForm.decimalPlaces,
      responseTags: fieldForm.supplierResponseTags,
    });

    fieldsToUpdate = {
      ...fieldsToUpdate,
      ...addedSupplierField,
    };
  }

  if (fieldForm?.decimalPlaces !== adjacentField?.decimalPlaces) {
    fieldsToUpdate[fieldForm.fieldsetId] = {
      ...exchangeDef.fields[fieldForm.fieldsetId],
      decimalPlaces: fieldForm.decimalPlaces,
    };
  }

  if (inputsConfig?.isFixedSupplierResponseField && fieldForm?.supplierResponseTags !== adjacentField?.responseTags) {
    fieldsToUpdate[fieldForm.fieldsetId] = {
      ...exchangeDef.fields[fieldForm.fieldsetId],
      responseTags: fieldForm.supplierResponseTags,
    };
  }

  if (currentField.source.type === 'formula') {
    if (fieldForm?.formula !== currentField?.source.formula) {
      fieldsToUpdate[fieldForm.fieldsetId] = {
        ...exchangeDef.fields[fieldForm.fieldsetId],
        ...fieldsToUpdate[fieldForm.fieldsetId],
        label: fieldForm?.fieldName,
        source: {
          ...exchangeDef.fields[fieldForm.fieldsetId].source,
          ...fieldsToUpdate[fieldForm.fieldsetId]?.source,
          formula: fieldForm.formula,
        } as any,
      };
    }

    if (fieldForm?.formulaFormat !== currentField?.type) {
      fieldsToUpdate[fieldForm.fieldsetId] = {
        ...exchangeDef.fields[fieldForm.fieldsetId],
        ...fieldsToUpdate[fieldForm.fieldsetId],
        label: fieldForm?.fieldName,
        type: fieldForm.formulaFormat,
        source: {
          ...exchangeDef.fields[fieldForm.fieldsetId].source,
          ...fieldsToUpdate[fieldForm.fieldsetId]?.source,
          formulaFormat: fieldForm.formulaFormat,
        } as any,
      };
    }

    if (fieldForm?.fieldName !== currentField?.label) {
      fieldsToUpdate[fieldForm.fieldsetId] = {
          ...exchangeDef.fields[fieldForm.fieldsetId],
          ...fieldsToUpdate[fieldForm.fieldsetId],
          label: fieldForm?.fieldName,
      };
    }
  } else if (fieldForm.type !== 'formula') {
    // User has just disabled `Supplier Response`
    if (!fieldForm.supplierResponseEnabled && supplierFieldId) {
      fieldsToRemove.push(supplierFieldId);
    }

    // User has just disabled `Buyer value`
    if (!fieldForm.buyerProvidedValueEnabled && buyerFieldId) {
      fieldsToRemove.push(buyerFieldId);
    }

    if (buyerFieldId && !fieldsToRemove.includes(buyerFieldId)) {
      fieldsToUpdate[buyerFieldId] = {
        ...exchangeDef.fields[buyerFieldId],
        label: fieldForm?.fieldName,
        decimalPlaces: fieldForm.decimalPlaces,
      };
    }

    if (supplierFieldId && !fieldsToRemove.includes(supplierFieldId)) {
      fieldsToUpdate[supplierFieldId] = {
        ...exchangeDef.fields[supplierFieldId],
        label: fieldForm?.fieldName,
        // If the field type was boolean (`Accept or reject`) and we switch back to `Free text`
        // We need to check the buyer field type since the supplier one was overwritten
        // Or keep it as it is if it was never switched to boolean
        type: fieldForm.supplierResponseType === SupplierResponseValue.BOOLEAN
          ? FieldType.BOOLEAN
          : exchangeDef.fields?.[buyerFieldId]?.type || fieldForm.type,
        decimalPlaces: fieldForm.decimalPlaces,
        responseTags: fieldForm.supplierResponseTags,
      };
    }
  }

  const updatedExchangeDef = adjustEvaluatorFieldCurrency({
    ...exchangeDef,
    fields: {
      ...omit(exchangeDef.fields, fieldsToRemove),
      ...fieldsToUpdate,
    },
    ...zipObject(fieldsToRemove, new Array(fieldsToRemove.length).fill(null)),
  } as LineItemExchangeDefinition);

  // crop decimal places in updated price definition fields
  for (const field of Object.values(fieldsToUpdate)) {
    if (
      isDefinitionField(field as FieldConfig) &&
      field.type === 'price'
    ) {
      // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'never'.
      const fieldValue = getExchangeDefFieldValue(updatedExchangeDef, field._id);

      // @ts-expect-error ts(18048) FIXME: 'field.source' is possibly 'undefined'.
      if (isFinite(fieldValue) && 'key' in field.source) {
        update(updatedExchangeDef, field.source.key, value => roundToDecimals(value, field.decimalPlaces ?? 2));
      }
    }
  }
  return updatedExchangeDef;
};

export const createClearFieldsUpdater = ({
  startRowIndex,
  endRowIndex,
  affectedColumns,
}: { startRowIndex: number, endRowIndex: number, affectedColumns: { original: EditableGridColumn }[] }) =>
  (exchangeDef: LineItemExchangeDefinition, index: number) => {
    if (!exchangeDef.isObsolete && index >= startRowIndex && index < endRowIndex) {
      const updatedRow = { ...exchangeDef };

      for (const column of affectedColumns) {
        const { disabled, isDisabled } = column.original;
        const field = exchangeDef.fields[column.original._id];

        if (!field || !isDefinitionField(field) || disabled || isDisabled?.(index)) {
          continue;
        }

        const fieldValue = get(exchangeDef, field.source.key);

        if (isFieldValueDefined(fieldValue, field.type)) {
          const newFieldValue = getEmptyFieldValue(field.type);

          set(updatedRow, field.source.key, newFieldValue);
        }
      }

      return updatedRow;
    } else {
      return exchangeDef;
    }
  };
