import { useCallback, useEffect } from 'react';
import { useEnv } from './env';
import { useSession, useSessionStatusHandler } from './auth';
import { useSystemFeatureFlags } from './systemFeatureFlags';
import { User } from './types';

const DS_LOGIN_REDIRECT = '@@deepstream/redirect-login';

const hasIncompleteUserProfile = (user: User) => (
  !user?.firstName ||
  !user.lastName ||
  !user.hasAcceptedLiveTermsOfService
);

const getPendingRequestedRoles = (user: User) =>
  Object
    .values(user.requestedRoles ?? {})
    .filter(requestedRole => requestedRole.status === 'pending');

export const useRedirectToOnboarding = ({ setLoginRedirect }: { setLoginRedirect: boolean }) => {
  const { ONBOARDING_URL } = useEnv();

  return useCallback(
    () => {
      if (setLoginRedirect) {
        localStorage.setItem(DS_LOGIN_REDIRECT, window.location.pathname);
      }

      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      window.location.href = ONBOARDING_URL;
    },
    [ONBOARDING_URL, setLoginRedirect],
  );
};

/**
 * Handles redirects based on the current user's state
 */
export const useAppInitRedirects = () => {
  const { ONBOARDING_URL } = useEnv();
  const systemFeatureFlags = useSystemFeatureFlags();

  useSessionStatusHandler({
    status: 'rejected',
    handler: useRedirectToOnboarding({ setLoginRedirect: true }),
  });

  useSessionStatusHandler({
    status: 'accepted',
    handler: useCallback(
      ({ user }) => {
        // When user's email is not verfiied redirect to the onboarding page where we send
        // the verification email
        // @ts-expect-error ts(18047) FIXME: 'user' is possibly 'null'.
        if (!user.verified) {
          window.location.href = `${ONBOARDING_URL}/signup/success`;
        }

        // When user's profile is incomplete redirect them to onboarding to complete
        // required fields
        // @ts-expect-error ts(2345) FIXME: Argument of type 'CurrentUser | null' is not assignable to parameter of type 'User'.
        if (systemFeatureFlags?.requiredTermsAndNameEnabled && hasIncompleteUserProfile(user)) {
          window.location.href = `${process.env.NX_ONBOARDING_URL}/incomplete`;
        }

        // When the user hasn't joined a company yet...
        // @ts-expect-error ts(18047) FIXME: 'user' is possibly 'null'.
        if (user.companyRoles.length === 0) {
          // @ts-expect-error ts(2345) FIXME: Argument of type 'CurrentUser | null' is not assignable to parameter of type 'User'.
          if (getPendingRequestedRoles(user).length > 0) {
            // ...but they have pending requests to join a company, redirect to the
            // onboarding company verification page where they can see the pending status
            window.location.href = `${ONBOARDING_URL}/verification`;
          } else {
            // ...and they have not requested to join any company, redirect to the "Find
            // company" onboarding page
            window.location.href = `${ONBOARDING_URL}/find-company`;
          }
        }

        // At this point we know the user has at least 1 role at a company and can remain
        // in this app,

        const redirectUrl = localStorage.getItem(DS_LOGIN_REDIRECT);

        // If there is a redirect url stored in localstorage (usually due to accessing a route
        // while logged out), then we redirect to that location
        if (redirectUrl) {
          localStorage.removeItem(DS_LOGIN_REDIRECT);
          window.location.href = redirectUrl;
        }
      },
      [ONBOARDING_URL, systemFeatureFlags?.requiredTermsAndNameEnabled],
    ),
  });
};
