import { useMemo } from 'react';
import { StaticTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { DatetimeCell } from '@deepstream/ui/DatetimeCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { withProps } from '@deepstream/ui-utils/withProps';
import { RightAlignedCell } from '@deepstream/ui/RightAlignedCell';
import { ClaimWithCompanyAndUser } from '@deepstream/ui/types';
import { CompanyLinkCell } from './CompanyLinkCell';
import { UserLinkCell } from './UserLinkCell';
import { CompanyClaimActionsCell } from './modules/Company/CompanyClaimActionsCell';

export const CompaniesToVerifyTable = ({
  claimsWithCompanyAndUser,
}: {
  claimsWithCompanyAndUser: ClaimWithCompanyAndUser[];
}) => {
  const { isExtraLarge } = useDeviceSize();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'company.name',
        Cell: nestCells(
          TruncateCell,
          withProps(
            CompanyLinkCell,
            {
              getCompanyId: ({ company }: ClaimWithCompanyAndUser) => company._id,
            },
          ),
        ),
      },
      {
        Header: 'User',
        accessor: 'user.name',
        Cell: nestCells(
          TruncateCell,
          withProps(
            UserLinkCell,
            {
              getUserId: ({ user }: ClaimWithCompanyAndUser) => user._id,
            },
          ),
        ),
      },
      {
        Header: 'Email',
        accessor: 'user.email',
        Cell: TruncateCell,
      },
      {
        Header: 'Added',
        accessor: 'claim.date',
        Cell: DatetimeCell,
        width: isExtraLarge ? 224 : 164,
      },
      {
        id: 'resolveClaimActions',
        Cell: nestCells(
          RightAlignedCell,
          withProps(
            CompanyClaimActionsCell,
            ({ row }) => ({ company: row.original.company }),
          ),
        ),
        width: 130,
      },
    ],
    [isExtraLarge],
  );

  return (
    <StaticTableStyles>
      <Table
        columns={columns}
        data={claimsWithCompanyAndUser}
        isPaginated
        smallPageControls
      />
    </StaticTableStyles>
  );
};
