import * as React from 'react';
import { Flex, SxStyleProp, Text, Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';
import { Icon } from '../icon/Icon';
import { truncateStyle } from '../text/Truncate2';
import { useHover } from '../../hooks/useHover';
import { Tooltip } from '../popup/Tooltip';

const hiddenCheckboxStyle: React.CSSProperties = {
  position: 'absolute',
  opacity: 0,
  zIndex: -1,
};

const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  align-self: center;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  width: 22px;
  height: 22px;

  padding: 3px;
  margin: -3px;
`;

type StyledCheckboxProps = {
  hover?: boolean;
  indeterminate?: boolean;
  checked?: boolean;
  focused?: boolean;
  disabled?: boolean;
};

export const StyledCheckbox = styled(Box)<StyledCheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid ${props => !props.disabled && (props.hover || props.checked || props.indeterminate)
    ? props.theme.colors.primary
    : props.theme.colors.gray};
  background: ${props => props.checked || props.indeterminate
    ? props.disabled
      ? props.theme.colors.gray
      : props.theme.colors.primary
    : props.theme.colors.white};
  border-radius: 2px;
  transition: all 150ms;
  font-size: ${props => props.theme.fontSizes[1]};

  ${props => props.focused ? 'box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.35)' : ''};
`;

const DefaultCheckboxLabel = styled(Text)`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 400;
  line-height: 18px;
  padding-left: ${props => props.theme.space[2]}px;
`;

export type CheckboxProps =
  {
    label?: string | React.ReactNode;
    truncate?: boolean;
    indeterminate?: boolean;
    onClick?: any;
    CheckboxLabel?: React.FunctionComponent<any>;
    useDefaultDisabledStyle?: boolean;
    styledCheckboxSx?: SxStyleProp;
    inputTooltip?: string;
  } &
  Omit<React.HTMLProps<HTMLInputElement>, 'label'> &
  SpaceProps;

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({
  checked,
  indeterminate,
  label,
  children,
  truncate,
  style,
  onClick,
  CheckboxLabel = DefaultCheckboxLabel,
  useDefaultDisabledStyle = true,
  styledCheckboxSx,
  inputTooltip,
  ...props
}, ref) => {
  const defaultCheckboxRef = React.useRef();
  const checkboxRef = (ref || defaultCheckboxRef) as React.MutableRefObject<HTMLInputElement>;
  const [focused, setFocus] = React.useState(false);
  const [hoverRef, hover] = useHover<HTMLLabelElement>();

  React.useEffect(
    () => {
      if (checkboxRef?.current && indeterminate) {
        checkboxRef.current.indeterminate = indeterminate;
      }
    },
    [checkboxRef, indeterminate],
  );

  const wrapperStyle = {
    cursor: props.disabled ? 'default' : 'pointer',
    opacity: props.disabled && useDefaultDisabledStyle ? 0.5 : 1,
  };

  const content = (
    <StyledCheckbox
      hover={hover}
      checked={checked}
      indeterminate={indeterminate}
      focused={focused}
      disabled={props.disabled}
      sx={styledCheckboxSx}
    >
      {indeterminate || checked ? (
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          {indeterminate ? (
            <Icon icon="minus" color="white" fontSize={12} />
          ) : checked ? (
            <Icon icon="check" color="white" fontSize={12} />
          ) : (
            null
          )}
        </Flex>
      ) : (
        null
      )}
    </StyledCheckbox>
  );

  return (
    // TODO: improve this for better accessibility
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <label
      ref={hoverRef}
      onClick={onClick}
      style={{ display: 'flex', padding: '5px 0px', margin: 0, ...wrapperStyle, ...style }}
    >
      <CheckboxContainer disabled={props.disabled}>
        <input
          type="checkbox"
          checked={checked}
          onFocus={() => setFocus(true)}
          onBlurCapture={() => setFocus(false)}
          style={hiddenCheckboxStyle}
          ref={ref}
          {...props}
        />
        {inputTooltip ? (
          <Tooltip content={inputTooltip}>
            {content}
          </Tooltip>
          ) : (
            content
          )}
      </CheckboxContainer>
      {label && (
        <CheckboxLabel style={truncate ? truncateStyle : {}}>
          {label}
        </CheckboxLabel>
      )}
      {children}
    </label>
  );
});

Checkbox.displayName = 'Checkbox';
