import * as React from 'react';
import { useQuery } from 'react-query';
import { find } from 'lodash';
import { useCurrentCompanyId } from './currentCompanyId';
import { useApi, wrap } from './api';
import { useCurrentUser } from './useCurrentUser';

export enum CompanyGroup {
  SUPPLIER = 'supplier',
  BUYER = 'buyer',
  BOTH = 'both',
}

export type UserFlags = {
  belongsToSupplierOnlyCompany: boolean;
  belongsToPayingCompany: boolean;
  hasSendRfqPermission: boolean;
  hasSendContractPermission: boolean;
  hasManagePreQualPermission: boolean;
  hasSendQuestionnairePermission: boolean;
  status: 'idle' | 'error' | 'loading' | 'success';
};

export const UserFlagsContext = React.createContext<UserFlags | null>(null);

export const UserFlagsProvider = (props: { children: React.ReactNode }) => {
  const api = useApi();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const user = useCurrentUser();

  const { data: company, status } = useQuery(
    ['publicCompany', { companyId: currentCompanyId }],
    wrap(api.getPublicCompany),
  );

  const value = React.useMemo(() => {
    const userRoles = find((user as any)?.companyRoles, { _id: currentCompanyId });

    return {
      belongsToSupplierOnlyCompany: company?.group === CompanyGroup.SUPPLIER,
      belongsToPayingCompany: !!userRoles?.subscription,
      hasSendRfqPermission: !!userRoles?.sendRFQ,
      hasSendContractPermission: !!userRoles?.sendContracts,
      hasManagePreQualPermission: !!userRoles?.managePreQual,
      hasSendQuestionnairePermission: !!userRoles?.sendQuestionnaires,
      status,
    };
  }, [user, currentCompanyId, company, status]);

  return (
    <UserFlagsContext.Provider value={value} {...props} />
  );
};

export const useUserFlags = () => {
  const userFlags = React.useContext(UserFlagsContext);
  if (!userFlags) throw new Error('no userFlags found');
  return userFlags;
};
