import * as React from 'react';
import { useQuery } from 'react-query';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { PanelLoadingWrapper } from '@deepstream/ui/ui/Loading';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Company } from '@deepstream/ui/ui/types';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import * as Title from '../../title';
import { CompanyContractsTable } from './CompanyContractsTable';

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

export const CompanyContracts = ({ company }: { company: Company }) => {
  const adminApi = useAdminApi();
  const companyId = company._id;

  const { data: contracts, status: contractsStatus } = useQuery(
    ['contracts', { companyId }],
    wrap(adminApi.getCompanyContracts),
  );

  return (
    <Stack gap={4}>
      <Panel heading="Contracts">
        <PanelLoadingWrapper status={contractsStatus} data={contracts} emptyDataMessage="No contracts yet">
          <CompanyContractsTable contracts={contracts} />
        </PanelLoadingWrapper>
      </Panel>
    </Stack>
  );
};
