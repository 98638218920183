import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Table } from '../../Table';
import { StaticTableStyles } from '../../TableStyles';
import { useRequestsReportingData } from './useRequestsReporting';
import { ProgressBarCell } from './ProgressBarCell';
import { UserCell } from '../../UserCell';

export const TeamProductivityTable = () => {
  const { t } = useTranslation('reporting');
  const { data } = useRequestsReportingData();

  const columns = useMemo(
    () => [
      {
        id: 'user',
        Header: t('teamProductivity.teamMember'),
        accessor: 'name',
        Cell: UserCell,
      },
      /**
       * These columns will eventually be added in the table.
       * We currently have no simple way of displaying this data
       */
      // {
      //   id: 'numDraftRequests',
      //   Header: t('teamProductivity.draftRequests'),
      //   accessor: 'numDraftRequests',
      //   width: 180,
      // },
      // {
      //   id: 'numLiveRequests',
      //   Header: t('teamProductivity.liveRequests'),
      //   accessor: 'numLiveRequests',
      //   width: 180,
      // },
      {
        id: 'numAwardedRequests',
        Header: t('teamProductivity.awardedRequests'),
        accessor: 'numAwardedRequests',
        width: 180,
      },
      {
        id: 'numClosedRequests',
        Header: t('teamProductivity.closedRequests'),
        accessor: 'numClosedRequests',
        width: 180,
      },
      {
        id: 'requestCompletionRateAvg',
        Header: t('teamProductivity.requestCompletionRateAvg'),
        accessor: 'supplierCompletionRateAverage',
        Cell: ProgressBarCell,
      },
    ],
    [t],
  );

  return (
    <Panel>
      <StaticTableStyles>
        <Table
          isSortable
          // @ts-expect-error ts(2339) FIXME: Property 'team' does not exist on type 'never'.
          isPaginated={data.team.length > 5}
          smallPageControls
          columns={columns}
          // @ts-expect-error ts(2339) FIXME: Property 'team' does not exist on type 'never'.
          data={data.team}
        />
      </StaticTableStyles>
    </Panel>
  );
};
