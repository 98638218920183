import { ContractRoles, REMINDERS_PAGE_ID, SUMMARY_PAGE_ID } from '@deepstream/common/contract/contract';
import { PageRole } from '@deepstream/common/rfq-utils';
import { RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { useFormikContext } from 'formik';
import { pick, keys, includes, mapValues, keyBy, compact } from 'lodash';
import { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass/styled-components';
import * as yup from 'yup';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { getItemLabelWithDescription, SelectField } from '../../../form/SelectField';
import { ModalForm } from '../../../ModalForm';
import { RequestRole, ContractTeamUser } from '../../../types';
import { useConfirmDialog, useModalState } from '../../../ui/useModalState';
import { useCurrentUser } from '../../../useCurrentUser';
import { UserDetails } from '../../../UserDetails';

import { UserSelectField } from '../../Request/Team/UserSelectField';
import { useContractData, useIsSender } from '../contract';
import {
  useAddContractTeamMember,
  useRemoveContractTeamMember,
  useUpdateContractTeamMemberRoles,
} from '../draftContract';
import { ContractTeamAddUserPagesPermissions } from './ContractTeamAddUserPagesPermissions';
import { RemoveSelfDialog, RemoveTeamMemberDialog } from '../../../RemoveTeamMemberDialog';
import { useContractRoleSelectItems } from './useContractRoleSelectItems';
import { useIsAppAdmin } from '../../../useIsAppAdmin';

type UserModalFields = {
  selectedUser: Pick<ContractTeamUser, '_id' | 'name' | 'email'> & { roleRestrictionProfile?: RoleRestrictionProfile } | undefined;
  role: RequestRole;
  pagesPermissions: ContractRoles | undefined;
};

const DEFAULT_USER_ROLE = RequestRole.TEAM_MEMBER;
const DEFAULT_PAGE_PERMISSION = PageRole.NONE;

const useTeamMembersIDs = ({
  companyId,
}: {
  companyId: string
}) => {
  const { teamById } = useContractData();
  const companyUsers = teamById[companyId];

  const { owners: ownersIDs, users } = companyUsers || {};

  const usersIDs = keys(users);

  return { ownersIDs, usersIDs };
};

const useUserRole = ({
  companyId,
}: {
  companyId: string;
}) => {
  const { ownersIDs, usersIDs } = useTeamMembersIDs({ companyId });

  return useCallback((userId: string) => {
    if (includes(ownersIDs, userId)) return RequestRole.OWNER;
    if (includes(usersIDs, userId)) return RequestRole.TEAM_MEMBER;

    return null;
  }, [ownersIDs, usersIDs]);
};

const useUserPagesPermissions = ({
  companyId,
}: {
  companyId: string;
}) => {
  const { teamById } = useContractData();
  const companyMembers = teamById?.[companyId];

  return useCallback((userId: string) => {
    return companyMembers.users[userId].contractRoles;
  }, [companyMembers]);
};

const useFormInitialValues = ({
  companyId,
  user,
}: {
  companyId: string;
  user?: ContractTeamUser;
}) => {
  const { pages: contractPages } = useContractData();
  const getUserRole = useUserRole({ companyId });
  const getPagesPermissions = useUserPagesPermissions({ companyId });
  const isSender = useIsSender();

  return useMemo((): UserModalFields => {
    const userId = user?._id;
    // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    const role = getUserRole(userId);

    const pages = compact([
      ...contractPages,
      { _id: SUMMARY_PAGE_ID },
      isSender && { _id: REMINDERS_PAGE_ID },
    ]);

    const pagesPermissionsInitialValues = mapValues(
      keyBy(pages, (page) => page._id),
      // Summary page can only have READER or EDITOR roles
      (page) => page._id === SUMMARY_PAGE_ID ? PageRole.READER : DEFAULT_PAGE_PERMISSION,
    );

    // Edit mode
    if (userId) {
      return {
        // Map `ContractTeamUser` to the format of `UserSelectField` return
        selectedUser: {
          ...pick(user, ['_id', 'name', 'email']),
          // @ts-expect-error ts(2322) FIXME: Type 'RoleRestrictionProfile | null' is not assignable to type 'RoleRestrictionProfile | undefined'.
          roleRestrictionProfile: user?.roleRestrictionProfiles?.[companyId] ?? null,
        },
        // @ts-expect-error ts(2322) FIXME: Type 'RequestRole | null' is not assignable to type 'RequestRole'.
        role,
        pagesPermissions: getPagesPermissions(userId),
      };
    }

    // New user mode
    return {
      selectedUser: undefined,
      role: DEFAULT_USER_ROLE,
      pagesPermissions: pagesPermissionsInitialValues,
    };
  }, [
    companyId,
    getPagesPermissions,
    getUserRole,
    user,
    contractPages,
    isSender,
  ]);
};

const ContractTeamUserRole = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation(['contracts', 'translation']);
  const roles = useContractRoleSelectItems();
  const currentUser = useCurrentUser();
  const { teamById, recipients } = useContractData();
  const { values } = useFormikContext<UserModalFields>();

  const isAuthorizedStakeholder = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER;
  const isSuperUser = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.SUPER_USER;
  const isEditSelf = currentUser._id === values?.selectedUser?._id;
  const isRecipient = recipients[0]?._id === companyId;
  const isSenderAuthorizedStakeholder = isAuthorizedStakeholder && !isRecipient;

  const team = teamById[companyId];
  const isSoleContractOwner = team?.owners.length === 1 && team?.owners[0] === values?.selectedUser?._id;

  return (
    <>
      <SelectField
        items={roles}
        name="role"
        getButtonLabel={item => item?.label ?? ''}
        getItemLabel={getItemLabelWithDescription}
        disabled={isSuperUser || isSenderAuthorizedStakeholder || isEditSelf || isSoleContractOwner}
      />
      {isSenderAuthorizedStakeholder && (
        <MessageBlock variant="info" mt={0}>
          <Text>
            {t('team.pagePermissionsTable.stakeholdersLimitsInfo')}
          </Text>
        </MessageBlock>
      )}
      {isSuperUser && (
        <Text color="subtext" fontSize={0} mt={-2}>
          <Icon
            style={{ fontSize: '12px', marginRight: 4 }}
            icon="lock"
            light
          />
          {t('teamManagement.cannotBeEditedForSuperUser', { ns: 'translation' })}
        </Text>
        )}
    </>
  );
};

const ContractTeamUserRemoveButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('contracts');

  return (
    <Button
      variant="danger-outline"
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      {t('team.removeUser')}
    </Button>
  );
};

const ContractTeamUserModalFields = ({
  companyId,
  user,
}: {
  companyId: string;
  user?: ContractTeamUser;
}) => {
  const { usersIDs } = useTeamMembersIDs({ companyId });
  const { values, setFieldValue } = useFormikContext<UserModalFields>();
  const { recipients } = useContractData();
  const isRecipient = recipients[0]?._id === companyId;

  useEffect(() => {
    const isAuthorizedStakeholder = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER;
    const isSuperUser = values?.selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.SUPER_USER;

    if (isAuthorizedStakeholder && !isRecipient) {
      setFieldValue('role', RequestRole.TEAM_MEMBER);
    }
    if (isSuperUser) {
      setFieldValue('role', RequestRole.OWNER);
    }
  }, [values, isRecipient, setFieldValue]);

  return (
    <>
      {user ? (
        <UserDetails user={user} />
      ) : (
        <UserSelectField companyId={companyId} existingUserIds={usersIDs} />
      )}
      <ContractTeamUserRole companyId={companyId} />
      <ContractTeamAddUserPagesPermissions companyId={companyId} />
    </>
  );
};

export const ContractTeamUserModal = ({
  user,
  companyId,
  close,
  onRemove,
}: {
  user?: ContractTeamUser;
  companyId: string;
  close: () => void;
  onRemove?: () => void;
}) => {
  const { t } = useTranslation(['contracts', 'general']);
  const currentUser = useCurrentUser();
  const isAppAdmin = useIsAppAdmin();
  const { teamById } = useContractData();

  const [removeTeamMember] = useRemoveContractTeamMember();
  const [addTeamMember] = useAddContractTeamMember({
    onSettled: close,
  });
  const [updateRoles] = useUpdateContractTeamMemberRoles({
    onSettled: close,
  });
  const initialValues = useFormInitialValues({
    companyId,
    user,
  });

  const modalHeading = user ? t('team.editUser') : t('team.addUser');
  const submitLabel = user ? t('saveChanges', { ns: 'general' }) : t('team.addUser');

  const { confirm, ...confirmDialogProps } = useConfirmDialog();
  const removeUserModalSelf = useModalState();

  const team = teamById[companyId];
  const isSoleContractOwner = user && team?.owners.length === 1 && team?.owners[0] === user._id;

  return (
    <>
      <ModalForm
        style={{ content: { width: '500px' } }}
        heading={modalHeading}
        initialValues={initialValues}
        validationSchema={
          yup.object().shape({
            selectedUser: yup.mixed().required(t('required', { ns: 'general' })),
            role: yup.string().oneOf(Object.values(RequestRole)).required(),
            pagesPermissions: yup.object().required(),
          })
        }
        disableSubmitIfNotDirty={true}
        disableSubmitIfInvalid={true}
        isOpen
        onCancel={close}
        onSubmit={async (values) => {
          const isOwner = values.role === RequestRole.OWNER;

          const payload = {
            ...pick(values.selectedUser, ['_id', 'name', 'email']),
            isOwner,
            contractRoles: isOwner
              ? mapValues(values.pagesPermissions, () => PageRole.EDITOR)
              : values.pagesPermissions,
          };

          if (user) {
            await updateRoles(isAppAdmin ? {
              companyId,
              userId: payload._id,
              isOwner: payload.isOwner,
              contractRoles: payload.contractRoles,
            } : {
              userId: payload._id,
              isOwner: payload.isOwner,
              contractRoles: payload.contractRoles,
            });
          } else {
            await addTeamMember(isAppAdmin ? { companyId, user: payload } : { user: payload });
          }

          close();
        }}
        submitLabel={submitLabel}
        footerLeftButton={user && (
          <ContractTeamUserRemoveButton
            onClick={() => {
              const removeSelf = currentUser._id === user._id;
              if (removeSelf) {
                removeUserModalSelf.open();
              } else {
                confirm(async () => {
                  await removeTeamMember(
                    isAppAdmin ? { companyId, userId: user._id } : { userId: user._id },
                    {
                      onSuccess: () => {
                        close();
                        if (onRemove) {
                          onRemove();
                        }
                      },
                    },
                  );
                });
              }
            }}
            disabled={isSoleContractOwner}
          />
        )}
      >
        <ContractTeamUserModalFields user={user} companyId={companyId} />
      </ModalForm>

      {user && (
        <RemoveTeamMemberDialog
          user={user}
          {...confirmDialogProps}
        />
      )}

      {user && (
        <RemoveSelfDialog
          user={user}
          isOpen={removeUserModalSelf.isOpen}
          close={removeUserModalSelf.close}
        />
      )}
    </>
  );
};
