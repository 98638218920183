import * as React from 'react';
import { groupBy, isEmpty, omitBy, uniq, difference } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  SxStyleProp,
  Text,
  TextProps,
} from 'rebass/styled-components';
import { transparentize } from 'polished';
import {
  LineItemExchangeDefinition,
  LineItemExchangeFields,
} from '@deepstream/common/rfq-utils';

import { useField } from 'formik';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuHeader,
  menuItemCss,
} from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { FieldType, fieldIconByType } from '@deepstream/common/exchangesConfig';
import {
  ConfigurableFieldType,
  useGetAddGenericFieldModalProps,
  useGetAddPredefinedFieldModalProps,
  PredefinedFields,
  useGetAddFieldFromOtherSectionModalProps,
} from '../modules/Request/LineItems';
import {
  createAddLeadTimeFieldUpdater,
  addTargetPriceField,
  addUnspscCodeField,
  createAddDefaultFieldUpdater,
} from '../ui/ExchangeDefsGrid/exchangeDefUpdaters';
import { useConfigureFieldModal, ConfigureFieldModalProps, RelativeFieldInsertion } from '../modules/Request/LineItems/LineItemFields/useConfigureFieldModal';
import { ConfigureFieldModal, addFieldsInOrder } from '../modules/Request/LineItems/LineItemFields/ConfigureFieldModal';
import { otherSectionFieldsetIdsToIgnore } from './draft';
import { HorizontalDivider } from '../ui/HorizontalDivider';
import { TooltipList } from '../ui/TooltipList';
import { SlidingBox } from '../ui/SlidingBox';
import { useCurrentUserLocale } from '../useCurrentUser';

const lineItemFieldIcons: Record<
  ConfigurableFieldType & 'unspscCode',
  IconProps['icon']
> = {
  string: 'text',
  number: 'hashtag',
  boolean: 'function',
  formula: 'function',
  date: 'calendar',
  price: 'dollar-sign',
  unspscCode: 'tag',
};

const lineItemFieldPageIds = {
  main: 'main',
  otherSectionFields: 'otherSectionFields',
};

const customFieldsMenuItemsConfig = ([
  FieldType.DATE,
  FieldType.STRING,
  FieldType.PRICE,
  FieldType.NUMBER,
  FieldType.FORMULA,
] as const).map((type) => ({
  type,
  icon: fieldIconByType[type],
}));

const AddLineItemFieldNavHeader = ({
  onClick,
  navigateBack = false,
  iconColor = 'subtext',
  fontSize = 2,
  fontWeight = 400,
  sx,
}: {
  onClick: () => void;
  navigateBack?: boolean;
  iconColor?: IconProps['color'];
  fontSize?: TextProps['fontSize'];
  fontWeight?: TextProps['fontWeight'];
  sx?: SxStyleProp;
}) => {
  const theme = useTheme();
  const { t } = useTranslation('request');

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      sx={{
        gap: 2,
        cursor: 'pointer',
        padding: '8px 12px',
        width: '100%',
        '&:hover': {
          backgroundColor: transparentize(0.9, theme.colors.primary),
        },
        marginBottom: 2,
        ...sx,
      }}
      onClick={onClick}
    >
      {navigateBack && <Icon icon="chevron-left" />}
      <Icon icon="link" color={iconColor} fontSize={fontSize} />
      <Text fontSize={fontSize} fontWeight={fontWeight} flex={1}>
        {t(
          'lineItems.genericLineItemConfig.addDropdown.fromOtherSectionHeading',
        )}
      </Text>
      {!navigateBack && <Icon icon="chevron-right" />}
    </Flex>
  );
};

export const AddLineItemFieldDropdown = ({
  otherSectionFields,
  disabled,
  onExpandedStateChange,
}: {
  otherSectionFields?: LineItemExchangeFields;
  disabled?: boolean;
  onExpandedStateChange?: (expanded: boolean) => void;
}) => {
  const configureFieldModal = useConfigureFieldModal();

  return (
    <>
      <AddLineItemFieldDropdownContent
        otherSectionFields={otherSectionFields}
        disabled={disabled}
        onExpandedStateChange={onExpandedStateChange}
        configureFieldModal={configureFieldModal}
      />
      {configureFieldModal.isOpen && configureFieldModal.config && (
        <ConfigureFieldModal
          {...configureFieldModal}
        />
      )}
    </>
  );
};

export const AddLineItemFieldDropdownContent = ({
  otherSectionFields,
  disabled,
  isHeaderMenu,
  onExpandedStateChange: onExpandedStateChangeProp,
  configureFieldModal,
  relativeFieldInsertion,
}: {
  otherSectionFields?: LineItemExchangeFields;
  disabled?: boolean;
  isHeaderMenu?: boolean;
  onExpandedStateChange?: (expanded: boolean) => void;
  configureFieldModal: ConfigureFieldModalProps<any>;
  relativeFieldInsertion?: RelativeFieldInsertion | null;
}) => {
  const { t } = useTranslation('translation');
  const locale = useCurrentUserLocale();
  const {
    rowData: lineItemExchangeDefs,
    updateEachRowWith,
  } = useEditableGridData<LineItemExchangeDefinition>();
  const [{ value: responseTagConfig }] = useField({ name: 'section.responseTagConfig' });

  const currentSectionFieldsetIds = React.useMemo(() => {
    if (isEmpty(lineItemExchangeDefs)) {
      return [];
    }

    return uniq(
      Object.keys(lineItemExchangeDefs[0].fields)
        .map(fieldId => fieldId.split(':')[0]),
    );
  }, [lineItemExchangeDefs]);

  const otherSectionFieldsToShow = React.useMemo(() => {
    // @ts-expect-error ts(18048) FIXME: 'field' is possibly 'undefined'.
    const otherSectionFieldsByFieldsetId = groupBy(otherSectionFields, (field) => field._id.split(':')[0]);

    // Filter the fields by the fieldsetIds to ignore and by the ones already included in the current section
    const fieldsToShow = omitBy(
      otherSectionFieldsByFieldsetId,
      (value, fieldsetId) => otherSectionFieldsetIdsToIgnore.includes(fieldsetId) || currentSectionFieldsetIds.includes(fieldsetId),
    );

    // Convert the filtered fields to array of objects
    const fieldsToShowArray = Object.entries(fieldsToShow).map(([fieldsetId, fields]) => {
        const { label, sectionNames } = fields[0] as unknown as {
          label: string;
          sectionNames: string[];
        };
        // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
        const type = fields[0].type === 'boolean' ? fields[1].type : fields[0].type;
        return {
          ...fields[0],
          label,
          sectionNames,
          fieldsetId,
          type,
        };
      },
    );

    // Sort by label value
    return fieldsToShowArray.sort((a, b) => a.label.localeCompare(b.label, locale));
  }, [otherSectionFields, currentSectionFieldsetIds, locale]);

  const getAddFieldFromOtherSectionModalProps = useGetAddFieldFromOtherSectionModalProps();
  const getAddGenericFieldModalProps = useGetAddGenericFieldModalProps();
  const getAddPredefinedFieldModalProps = useGetAddPredefinedFieldModalProps();

  const {
    openConfigureOtherSectionFieldModal,
    openConfigureGenericFieldModal,
    openConfigurePredefinedFieldModal,
  } = React.useMemo(() => {
    return {
      openConfigureOtherSectionFieldModal: (field) => {
        // @ts-expect-error ts(2345) FIXME: Argument of type '{ inputsConfig: { fieldName: { helperText: string; }; enableDecimalPlaces: boolean; isFormula: boolean; enableSupplierResponse: boolean; enableBuyerProvided: boolean; }; initialValues: { formula?: string | undefined; ... 8 more ...; decimalPlaces: number | undefined; }; heading: string; submitLabel: string; }' is not assignable to parameter of type 'ConfigureFieldModalConfig<ConfigurableFieldFormValues>'.
        configureFieldModal.open(getAddFieldFromOtherSectionModalProps(field, otherSectionFields, responseTagConfig, relativeFieldInsertion));
      },
      openConfigureGenericFieldModal: ({ type }: { type: ConfigurableFieldType }) => {
        configureFieldModal.open(getAddGenericFieldModalProps(type, relativeFieldInsertion));
      },
      openConfigurePredefinedFieldModal: ({ fieldsetId }: { fieldsetId: PredefinedFields }) => {
        configureFieldModal.open(getAddPredefinedFieldModalProps(fieldsetId, responseTagConfig, relativeFieldInsertion));
      },
    };
  }, [
    configureFieldModal,
    getAddFieldFromOtherSectionModalProps,
    otherSectionFields,
    responseTagConfig,
    relativeFieldInsertion,
    getAddGenericFieldModalProps,
    getAddPredefinedFieldModalProps,
  ]);

  const predefinedFieldsMenuItems = React.useMemo(() => {
    return [
      {
        onSelect: () => openConfigurePredefinedFieldModal({ fieldsetId: 'unit' }),
        icon: 'hashtag',
        tooltip: t('request.lineItems.unitDescription'),
        label: t('general.unit'),
        showField: !currentSectionFieldsetIds.includes('unit'),
      },
      {
        onSelect: () => openConfigurePredefinedFieldModal({ fieldsetId: 'quantity' }),
        icon: 'hashtag',
        tooltip: t('request.lineItems.quantityDescription'),
        label: t('general.quantity'),
        showField: !currentSectionFieldsetIds.includes('quantity'),
      },
      {
        onSelect: () => openConfigurePredefinedFieldModal({ fieldsetId: 'price' }),
        icon: 'dollar-sign',
        tooltip: t('request.lineItems.pricePerUnitDescription'),
        label: t('request.lineItems.pricePerUnit'),
        showField: !currentSectionFieldsetIds.includes('price'),
      },
      {
        onSelect: () => {
          const updater = createAddDefaultFieldUpdater('totalCost');
          updateEachRowWith(lineItem => addFieldsInOrder(lineItem, updater, relativeFieldInsertion));
        },
        icon: 'function',
        tooltip: t('request.lineItems.totalCostDescription'),
        label: t('request.lineItems.totalCost.title'),
        showField: !currentSectionFieldsetIds.includes('totalCost'),
      },
      {
        onSelect: () => {
          const updater = addTargetPriceField;
          updateEachRowWith(lineItem => addFieldsInOrder(lineItem, updater, relativeFieldInsertion));
        },
        icon: 'dollar-sign',
        tooltip: t('request.lineItems.targetPriceDescription'),
        label: t('request.lineItems.targetPrice'),
        showField: !currentSectionFieldsetIds.includes('targetPrice'),
      },
      {
        onSelect: () => openConfigurePredefinedFieldModal({ fieldsetId: 'deliveryDate' }),
        icon: 'calendar',
        tooltip: t('request.lineItems.deliveryDate.addField.description'),
        label: t('request.lineItems.deliveryDate.addField.title'),
        showField: !currentSectionFieldsetIds.includes('deliveryDate'),
      },
      {
        onSelect: () => {
          if (responseTagConfig) {
            openConfigurePredefinedFieldModal({ fieldsetId: 'leadTime' });
          } else {
            const updater = createAddLeadTimeFieldUpdater();
            updateEachRowWith(lineItem => addFieldsInOrder(lineItem, updater, relativeFieldInsertion));
          }
        },
        icon: 'hashtag',
        tooltip: t('request.lineItems.leadTimeDescription'),
        label: t('request.lineItems.leadTime'),
        showField: !currentSectionFieldsetIds.includes('leadTime'),
      },
      {
        onSelect: () => {
          const updater = addUnspscCodeField;
          updateEachRowWith(lineItem => addFieldsInOrder(lineItem, updater, relativeFieldInsertion));
        },
        icon: 'tag',
        iconColor: 'subtext',
        tooltip: t('request.lineItems.productAndServiceTagDescription'),
        label: t('request.lineItems.productAndServiceTag'),
        showField: !currentSectionFieldsetIds.includes('unspscCode'),
      },
      {
        onSelect: () => openConfigurePredefinedFieldModal({ fieldsetId: 'manufacturer' }),
        icon: 'text',
        tooltip: t('request.lineItems.manufacturer.addField.description'),
        label: t('request.lineItems.manufacturer.addField.title'),
        showField: !currentSectionFieldsetIds.includes('manufacturer'),
      },
      {
        onSelect: () => openConfigurePredefinedFieldModal({ fieldsetId: 'partNumber' }),
        icon: 'text',
        tooltip: t('request.lineItems.partNumber.addField.description'),
        label: t('request.lineItems.partNumber.addField.title'),
        showField: !currentSectionFieldsetIds.includes('partNumber'),
      },
    ] as const;
  }, [
    currentSectionFieldsetIds,
    openConfigurePredefinedFieldModal,
    responseTagConfig,
    t,
    updateEachRowWith,
    relativeFieldInsertion,
  ]);

  const showPredefinedFieldsSection = React.useMemo(
    () => predefinedFieldsMenuItems.some(({ showField }) => showField),
    [predefinedFieldsMenuItems],
  );

  const [selectedPageId, setSelectedPageId] = React.useState<string>(
    lineItemFieldPageIds.main,
  );

  const onExpandedStateChange = React.useCallback((expanded) => {
    if (!expanded) {
      // Reset the selected page to main when the dropdown is closed
      setSelectedPageId(lineItemFieldPageIds.main);
    }
  }, [setSelectedPageId]);

  const dropdownMenuProps = isHeaderMenu
    ? {
      variant: 'lightGray3-subtle',
      iconLeft: 'chevron-down',
      sx: { width: '12px', height: '28px' },
      onExpandedStateChange: onExpandedStateChangeProp,
    }
    : {
      variant: 'secondary-transparent-outline',
      buttonText: t('request.lineItems.addField'),
      iconLeft: 'columns',
      iconRight: 'caret-down',
      wrapperStyle: { height: 'fit-content' },
      disabled: disabled || isEmpty(lineItemExchangeDefs),
      onExpandedStateChange,
      ml: 2,
    };

  return (
    <DropdownMenu
      small
      rightAligned
      menuZIndex={151}
      menuStyle={{ width: '258px' }}
      header={isHeaderMenu && selectedPageId === lineItemFieldPageIds.main ? (
        <Box pb={2}>
          <Box
            color="subtext"
            fontSize={1}
            py={3}
            px={3}
            fontWeight={500}
          >
            {t(
              'lineItems.genericLineItemConfig.addDropdown.selectFieldToAdd',
              { ns: 'request' },
            )}
          </Box>
          <HorizontalDivider />
        </Box>
      ) : (
        null
      )}
      {...dropdownMenuProps as any}
    >
      <SlidingBox
        isSelected={selectedPageId === lineItemFieldPageIds.main}
        slideDirection="left"
        extraCss={menuItemCss}
      >
        {!isEmpty(otherSectionFieldsToShow) && (
          <>
            <AddLineItemFieldNavHeader
              onClick={() => setSelectedPageId(lineItemFieldPageIds.otherSectionFields)}
            />
            <HorizontalDivider />
          </>
          )}
        {showPredefinedFieldsSection && (
          <>
            <DropdownMenuHeader>
              {t(
                'lineItems.genericLineItemConfig.addDropdown.predefinedHeading',
                { ns: 'request' },
              )}
            </DropdownMenuHeader>
            {predefinedFieldsMenuItems.map(
                ({ onSelect, icon, tooltip, label, showField }) =>
                  showField && (
                    <DropdownMenuItem
                      onSelect={onSelect}
                      icon={icon}
                      iconColor="subtext"
                      isIconRegular
                      tooltip={tooltip}
                      key={label}
                    >
                      {label}
                    </DropdownMenuItem>
                  ),
              )}
            <HorizontalDivider />
          </>
          )}
        <DropdownMenuHeader>
          {t('lineItems.genericLineItemConfig.addDropdown.heading', { ns: 'request' })}
        </DropdownMenuHeader>
        {customFieldsMenuItemsConfig.map(({ icon, type }) => (
          <DropdownMenuItem
            onSelect={() => openConfigureGenericFieldModal({ type })}
            icon={icon}
            iconColor="subtext"
            isIconRegular
            key={type}
            tooltip= {t(`lineItems.genericLineItemConfig.addDropdown.${type}Description`, {
                ns: 'request',
              })}
          >
            {t(`lineItems.genericLineItemConfig.addDropdown.${type}`, {
                ns: 'request',
              })}
          </DropdownMenuItem>
          ))}
      </SlidingBox>
      <SlidingBox
        isSelected={selectedPageId === lineItemFieldPageIds.otherSectionFields}
        slideDirection="right"
        extraCss={menuItemCss}
      >
        <AddLineItemFieldNavHeader
          fontSize="12px"
          fontWeight={500}
          iconColor="black"
          onClick={() => setSelectedPageId(lineItemFieldPageIds.main)}
          sx={{ padding: '4px 12px' }}
          navigateBack
        />
        <HorizontalDivider />
        {otherSectionFieldsToShow.map(
            (field) => {
              const { label, sectionNames, type, fieldsetId } = field;
              const showField = !currentSectionFieldsetIds.includes(fieldsetId);

              return showField && (
                <DropdownMenuItem
                  onSelect={() => openConfigureOtherSectionFieldModal(field)}
                  key={fieldsetId}
                  tooltip={
                    <TooltipList
                      items={sectionNames}
                      title={t('lineItems.genericLineItemConfig.addDropdown.sectionNamesTooltip', { ns: 'request' })}
                      ItemComponent={({ item }) => <Text>{item}</Text>}
                    />
                  }
                >
                  <Flex sx={{ gap: 2 }}>
                    <Icon
                      icon={lineItemFieldIcons[type]}
                      color="subtext"
                      regular
                      fixedWidth
                      lineHeight="21px"
                    />
                    <Flex flexDirection="column">
                      {label}
                      <IconText
                        icon="link"
                        iconColor="subtext"
                        fontSize="12px"
                        text={
                          sectionNames.length > 1
                            ? t('sectionCount', {
                                count: sectionNames.length,
                                ns: 'general',
                              })
                            : sectionNames[0]
                        }
                        color="subtext"
                      />
                    </Flex>
                  </Flex>
                </DropdownMenuItem>
              );
            },
          )}
      </SlidingBox>
    </DropdownMenu>
  );
};
