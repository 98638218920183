import * as React from 'react';
import { useQueries } from 'react-query';
import { map, zipWith } from 'lodash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader } from '@deepstream/ui-kit/elements/Panel';
import { PanelLoadingWrapper } from '@deepstream/ui/ui/Loading';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { User } from '@deepstream/ui/types';
import { Company } from '@deepstream/ui/ui/types';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { CompanyClaimsTable } from './CompanyClaimsTable';
import * as Title from '../../title';
import { CompanyGeneralPropertyList } from './CompanyGeneralPropertyList';
import { CompanyProfilePropertyList } from './CompanyProfilePropertyList';
import { useCombinedQueryStatus } from '../../useCombinedQueryStatus';
import { ParentCompanyPanel } from './ParentCompanyPanel';
import { ChildCompaniesPanel } from './ChildCompaniesPanel';

const useClaimUsers = (company?: Company) => {
  const adminApi = useAdminApi();

  const companyClaims = company?.claims || [];

  const queryResult = useQueries(
    companyClaims.map(claim => ({
      queryKey: ['user', { userId: claim.userId }],
      queryFn: wrap(adminApi.getUser),
    })),
  );

  return {
    data: map(queryResult, 'data') as Array<User | undefined>,
    statuses: map(queryResult, 'status'),
  };
};

const useClaimsWithUsers = (company?: Company, users?: Array<User | undefined>) => React.useMemo(() => {
  if (!company || !company.claims || !users) {
    return [];
  }

  return zipWith(company.claims, users, (claim, user) => ({
    claim,
    user: user ?? {
      _id: claim.userId,
      name: '',
    } as User,
  })).map((claimWithUser, index) => ({
    ...claimWithUser,
    // add ID to provide a table key
    _id: String(index),
  }));
}, [company, users]);

const OpenCompanyProfileButton: React.FC<{ companyId: string }> = ({ companyId }) => {
  const openCompanyProfile = React.useCallback(
    () => {
      window.open(`${process.env.NX_APP_URL}/network/99/company-profile/${companyId}`);
    },
    [companyId],
  );

  return (
    <Button
      small
      iconLeft="external-link-alt"
      onClick={openCompanyProfile}
    >
      Open full profile
    </Button>
  );
};

export const PageTitle: React.FC<any> = ({ flexDirection, company }) => (
  <Title.Container flexDirection={flexDirection}>
    <Title.Company size={flexDirection === 'column' ? 'large' : 'small'} company={company} />
  </Title.Container>
);

export const CompanyDetails = ({ company }: { company: Company }) => {
  const { data: claimUsers, statuses: claimUserStatuses } = useClaimUsers(company);
  const claimsWithUsersStatus = useCombinedQueryStatus(...claimUserStatuses);
  const claimsWithUsers = useClaimsWithUsers(company, claimUsers);

  return (
    <Stack gap={4}>
      <Panel heading="General">
        <CompanyGeneralPropertyList company={company} />
      </Panel>
      <Panel heading="Claims">
        <PanelLoadingWrapper status={claimsWithUsersStatus} data={claimsWithUsers} emptyDataMessage="No claims yet">
          <CompanyClaimsTable
            company={company}
            claimsWithUsers={claimsWithUsers}
          />
        </PanelLoadingWrapper>
      </Panel>
      {company.parentCompanyId ? (
        <ParentCompanyPanel company={company} />
      ) : (
        <ChildCompaniesPanel company={company} />
      )}
      <Panel>
        <PanelHeader heading="Profile">
          <OpenCompanyProfileButton companyId={company._id} />
        </PanelHeader>
        <PanelDivider />
        <CompanyProfilePropertyList company={company} />
      </Panel>
    </Stack>
  );
};
