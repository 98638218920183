import * as React from 'react';
import styled, { css } from 'styled-components';
import type { NumberFormatProps } from 'react-number-format';
import { Box, BoxProps, Flex, FlexProps } from 'rebass/styled-components';
import { FontSizeProps } from 'styled-system';
import { isEqual, isNil, max, min } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { centeredTextAdjustmentCss, centeredTextAdjustmentSx, textAreaAlignmentCss } from '@deepstream/ui-kit/elements/text/textAdjustment';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { NumberFormat } from '../NumberFormat';
import { formatOptions } from './formatOptions';

// Autofocus will only be used after the user has already expressed their intent to reply
/* eslint-disable jsx-a11y/no-autofocus */

const formControlStyles = css<{ noFocus?: boolean, minHeight?: string }>`
  font-family: ${props => props.theme.fonts.primary};
  line-height: 1.5;
  min-height: ${props => props.minHeight ? props.minHeight : '40px'};
  border-radius: 4px;
  border: ${props => props.theme.borders.lightGray};
  box-shadow: none;
  padding: 0 10px;
  background-clip: padding-box;
  appearance: none;
  transition:
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;

  &:focus {
    outline: 0;
    border-color: ${props => props.noFocus ? props.theme.colors.lightGray : props.theme.colors.primary};
    box-shadow: ${props => props.noFocus ? 'none' : '0 0 2px 0 rgba(52, 152, 219, 0.6)'};
  }

  &:disabled {
    color: ${props => props.theme.colors.disabledText};
    background-color: ${props => props.theme.colors.disabledBackground};
  }
`;

type BoxWithNoFocus = React.FC<BoxProps & { noFocus?: boolean }>;

export const InputBox = styled(Box as BoxWithNoFocus)`
  ${formControlStyles}
  ${centeredTextAdjustmentCss}
`;

const SearchInputBox = styled(Box as BoxWithNoFocus)`
  ${formControlStyles}
  ${centeredTextAdjustmentCss}
  padding-left: 34px;
  padding-right: 36px;
`;

const TextAreaBox = styled(Box as BoxWithNoFocus)`
  ${formControlStyles}
  ${textAreaAlignmentCss}
  ${props => props.minHeight && `min-height: ${props.minHeight}px;`}
`;

export const ReadOnlyTextAreaBox: React.FC<BoxProps> = ({ sx = {}, ...props }) => (
  <TextAreaBox sx={{ cursor: 'default', ...sx }} {...props} />
);

export const DisabledInputBox = React.forwardRef<any, BoxProps>(({ sx, ...props }, ref) => (
  <TextAreaBox
    ref={ref}
    fontSize={2}
    bg="disabledBackground"
    color="disabledText"
    sx={{
      cursor: 'default',
      ...(sx ?? {}),
    }}
    {...props}
  />
));

const SelectBox = styled(Box as BoxWithNoFocus)`
  ${formControlStyles}
  ${centeredTextAdjustmentCss}
  background-position: top 17px right 10px;
  background-repeat: no-repeat;
  background-image: url('https://s3-eu-west-1.amazonaws.com/ek-public/website/select-carat.png') !important;
  background-size: 8.5px 4px;
  padding-right: 25px !important;

  @supports (-moz-appearance: none) {
    padding-top: 8px;
  }
`;

export const InputPrefix = ({ style, children, minWidth = 36, ...props }: FlexProps) => (
  <Flex
    minWidth={minWidth}
    px={3}
    alignItems="center"
    justifyContent="center"
    color="subtext"
    sx={{
      overflowWrap: 'normal',
      borderRadius: 'small',
      border: theme => theme.borders.lightGray,
      borderRight: 0,
      fontSize: 2,
      lineHeight: 1.5,
      backgroundColor: 'lightGray3',
      ...centeredTextAdjustmentSx,
      ...style,
    }}
    {...props}
  >
    <Box mx={-1}>
      {children}
    </Box>
  </Flex>
);

export const InputSuffix = ({ style, children }: { style?: React.CSSProperties; children: React.ReactNode }) => (
  <Flex
    minWidth={36}
    px={3}
    alignItems="center"
    justifyContent="center"
    color="subtext"
    sx={{
      overflowWrap: 'normal',
      borderRadius: 'small',
      border: theme => theme.borders.lightGray,
      borderLeft: 0,
      flexShrink: 0,
      fontSize: 2,
      lineHeight: 1.5,
      backgroundColor: 'lightGray3',
      ...centeredTextAdjustmentSx,
      ...style,
    }}
  >
    <Box mx={-1}>
      {children}
    </Box>
  </Flex>
);

type SearchInputProps =
  React.HTMLProps<HTMLInputElement> &
  BoxProps &
  {
    initialValue?: string;
    isLoading?: boolean;
    canClear?: boolean;
    clearSearch: () => void;
  };

export const SearchInput: React.FC<SearchInputProps> = React.forwardRef(({ // eslint-disable-line
  onChange: onChangeProp,
  initialValue = '',
  disabled,
  isLoading,
  canClear,
  clearSearch,
  ...props
}, ref) => {
  const [value, setValue] = React.useState(initialValue);

  const handleChange = React.useCallback(
    (event) => {
      setValue(event.target.value);

      if (onChangeProp) {
        onChangeProp(event);
      }
    },
    [onChangeProp],
  );

  const onClearSearch = React.useCallback(
    () => {
      setValue('');
      clearSearch();
    },
    [clearSearch],
  );

  return (
    <Flex alignItems="center" fontSize={2}>
      <Icon
        icon="search"
        color={disabled ? 'disabledText' : 'subtext'}
        flex="0 0 auto"
        width={0}
        sx={{ position: 'relative', left: 11 }}
      />
      <SearchInputBox
        value={value}
        onChange={handleChange}
        as="input"
        bg="white"
        width="100%"
        color="text"
        fontSize={2}
        ref={ref}
        disabled={disabled}
        {...props}
      />
      <Flex
        flex="0 0 auto"
        width={0}
        height="40px"
        alignItems="center"
        sx={{ position: 'relative', right: 23 }}
      >
        <Box width="20px" pr={3}>
          {isLoading ? (
            <Icon
              icon="spinner"
              color={disabled ? 'disabledText' : 'subtext'}
              style={{ right: '2px' }}
            />
          ) : canClear ? (
            <IconButton
              icon="times"
              color={disabled ? 'disabledText' : 'subtext'}
              onClick={onClearSearch}
            />
          ) : (
            null
          )}
        </Box>
      </Flex>
    </Flex>
  );
});

export type InputProps =
  React.HTMLProps<HTMLInputElement> &
  BoxProps &
  FontSizeProps &
  { noFocus?: boolean } &
  { format?: keyof typeof formatOptions; onValueChange?: NumberFormatProps['onValueChange'] } &
  { maxValue?: number; decimalPlaces?: number };

export const Input: React.FC<InputProps> = React.forwardRef(({
  format,
  fontSize = 2,
  decimalPlaces = formatOptions[format]?.decimalScale,
  type,
  onValueChange,
  onChange,
  onBlur,
  style,
  value,
  name,
  disabled,
  maxValue,
  ...props
}: any, ref) => {
  const InputComponent = React.useMemo(
    () => React.forwardRef((props: InputProps, ref) => (
      <InputBox
        as="input"
        bg="white"
        width="100%"
        color="text"
        fontSize={fontSize}
        ref={ref}
        {...props}
      />
    )),
    [fontSize],
  );

  const withValueCap = (inputObj) => {
    if (!maxValue) {
      return true;
    }
    const { value } = inputObj;
    if (value <= maxValue) return true;
    return false;
  };

  return format ? (
    <NumberFormat
      name={name}
      {...formatOptions[format as keyof typeof formatOptions]}
      decimalScale={decimalPlaces}
      customInput={InputComponent}
      onValueChange={({ floatValue }) => onValueChange(isNil(floatValue) ? '' : floatValue)}
      onBlur={onBlur}
      getInputRef={ref}
      value={value}
      style={style}
      disabled={disabled}
      isAllowed={withValueCap}
      autoFocus={props.autoFocus}
      onKeyDown={props.onKeyDown}
    />
  ) : (
    <InputComponent
      ref={ref}
      name={name}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      style={style}
      value={value}
      disabled={disabled}
      {...props}
    />
  );
});

Input.displayName = 'Input';

export type TextAreaProps =
  React.HTMLProps<HTMLTextAreaElement> &
  BoxProps &
  FontSizeProps &
  {
    hasDynamicHeight?: boolean;
    maxHeight?: number;
  };

export const TextArea: React.FC<any> = React.forwardRef(({
  hasDynamicHeight,
  maxHeight,
  minHeight,
  fontSize = 2,
  ...props
}: TextAreaProps, ref) => {
  const boxRef = React.useRef<HTMLDivElement>(null);
  const [boxHeight, setBoxHeight] = React.useState(0);
  const textAreaHeight = max([minHeight, min([maxHeight, boxHeight])]);

  const recalculateHeight = React.useCallback(
    () => {
      if (boxRef.current) {
        setBoxHeight(boxRef.current.clientHeight);
      }
    },
    [],
  );

  React.useEffect(
    () => {
      recalculateHeight();
    },
    [recalculateHeight],
  );

  useWatchValue(
    props.value,
    () => {
      if (hasDynamicHeight) {
        recalculateHeight();
      }
    },
    isEqual,
  );

  return (
    <Box
      sx={{ position: 'relative', ...props.sx }}
      width="100%"
      maxWidth="100%"
      minWidth="100%"
    >
      <TextAreaBox
        as="textarea"
        bg="white"
        width="100%"
        fontSize={fontSize}
        color="text"
        display="block"
        ref={ref}
        height={hasDynamicHeight ? textAreaHeight : undefined}
        minHeight={minHeight}
        {...props}
      />
      {/* Hidden component used for measuring the necessary textarea height when it is dynamic */}
      {hasDynamicHeight && (
        <TextAreaBox
          ref={boxRef}
          sx={{
            position: 'absolute',
            visibility: 'hidden',
            whiteSpace: 'pre-wrap',
            width: '100%',
          }}
        >
          {props.value}
        </TextAreaBox>
      )}
    </Box>
  );
});

TextArea.displayName = 'TextArea';

export type SelectProps =
  React.HTMLProps<HTMLSelectElement> &
  BoxProps &
  FontSizeProps;

export const Select: React.FC<SelectProps> = React.forwardRef(({ fontSize = 2, ...props }: any, ref) => (
  <SelectBox
    as="select"
    bg="white"
    width="100%"
    color="text"
    fontSize={fontSize}
    ref={ref}
    {...props}
  />
));

Select.displayName = 'Select';
