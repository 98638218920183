import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';

import { ActiveIntegrationsTable } from './ActiveIntegrationsTable';

export const ActiveIntegrationsModal = ({
  integrationExecutions,
  ...props
}: Omit<DialogProps, 'heading' | 'body'> & {
  integrationExecutions;
}) => {
  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      heading="Integrations"
      body={(
        <Stack gap={0}>
          <ActiveIntegrationsTable integrationExecutions={integrationExecutions} />
        </Stack>
      )}
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};
