import { compact } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Dialog, DialogProps } from '@deepstream/ui-kit/elements/popup/Dialog';
import { LabeledValue } from '@deepstream/ui/draft/LabeledValue';
import { Box, Flex, Link, Text } from 'rebass/styled-components';

import { MultiStageLineItemsEventType, MultiStageLineItemsTrackedUser } from '@deepstream/common';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Datetime2Cell } from '@deepstream/ui/DatetimeCell';
import { SimpleUserCell } from '@deepstream/ui/SimpleUserCell';
import { Table } from '@deepstream/ui/Table';
import { AdoptionRateTableStyle } from '@deepstream/ui/TableStyles';

import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { withProps } from '@deepstream/ui-utils/withProps';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';

import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { EmDash } from '@deepstream/ui-kit/elements/text/EmDash';
import { TextCell } from '@deepstream/ui/TextCell';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { DateFormat } from '@deepstream/utils';
import { CellProps } from 'react-table';
import { useSystemFeatureFlags } from '@deepstream/ui/systemFeatureFlags';
import { renderPercentage } from '../reports/utils';
import { ProductDashboardSectionHeader } from './ProductDashboardUtils';
import { INTEGRATION_TABLE_HEIGHT, INTEGRATION_TABLE_WIDTH } from './constants';
import { LegacyMultiStageLineItemsFunnel } from './LegacyMultiStageLineItemsFunnel';

const funnelStepLablesByEventType = {
  [MultiStageLineItemsEventType.SUGGESTION_PANEL_SEEN]: '1. Suggestion panel seen',
  [MultiStageLineItemsEventType.FLOW_OPENED]: '2. Flow opened',
  [MultiStageLineItemsEventType.GUIDELINES_STEP_COMPLETED]: '3. Flow step completed (Guidelines)',
  [MultiStageLineItemsEventType.REVIEW_STAGES_STEP_COMPLETED]: '4. Flow step completed (Stages)',
  [MultiStageLineItemsEventType.SELECT_SECTIONS_STEP_COMPLETED]: '5. Flow step completed (Select sections)',
  [MultiStageLineItemsEventType.CONFIGURE_SECTION_STEP_COMPLETED]: '6. Flow step completed (Configure section)',
  [MultiStageLineItemsEventType.ADD_SECTIONS_STEP_COMPLETED]: '7. Flow step completed (Add sections)',
  [MultiStageLineItemsEventType.DELETE_SECTIONS_STEP_COMPLETED]: '8. Flow step completed (Delete sections)',
  [MultiStageLineItemsEventType.FLOW_SUBMITTED]: '9. Flow submitted successfully',
};

export const YesNoWithIconCell: React.FC<any> = ({ cell: { value } }: any) => (
  <>
    {value ? (
      <IconText
        icon="arrow-right"
        iconColor="success"
        isIconRegular
        text="Yes"
      />
    ) : (
      <IconText
        icon="times"
        iconColor="danger"
        isIconRegular
        text="No"
      />
    )}
  </>
);

const FunnelUsersTable = ({
  uniqueUsers,
  isFunnelEnd,
}: {
  uniqueUsers: MultiStageLineItemsTrackedUser[];
  isFunnelEnd?: boolean;
}) => {
  const theme = useTheme();

  const columns = useMemo(
    () =>
      compact([
        {
          id: 'date',
          Header: 'Date / Time',
          accessor: 'date',
          Cell: Datetime2Cell,
          format: DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ,
          sort: 'datetime',
          width: 200,
        },
        {
          Header: 'User',
          accessor: 'name',
          Cell: SimpleUserCell,
        },
        {
          Header: 'Request Creator',
          accessor: 'creatorCompanyName',
          Cell: withProps(CompanyMinimizedNameAndLogoCell, {
            hasLink: false,
            companyIdField: 'creatorCompanyId',
          }),
        },
        {
          Header: 'Request Name',
          accessor: 'requestName',
          Cell: TextCell,
        },
        !isFunnelEnd && {
          Header: 'Proceeded?',
          accessor: 'proceeded',
          sortType: 'boolean',
          Cell: YesNoWithIconCell,
          width: 110,
        },
      ]),
    [isFunnelEnd],
  );

  return (
    <AdoptionRateTableStyle
      bordered
      headerBackgroundColor={theme.colors.lightGray3}
      hoverCursor="default"
      stickyHeader
      height={INTEGRATION_TABLE_HEIGHT}
      width={INTEGRATION_TABLE_WIDTH}
      cellPaddingX="10px"
    >
      <Table
        columns={columns}
        data={uniqueUsers}
        isSortable
        initialSortBy={[{ id: 'date', desc: true }]}
      />
    </AdoptionRateTableStyle>
  );
};

const FunnelStepDetailsModal = ({
  uniqueUsers,
  ...props
}: Omit<DialogProps, 'body'> & {
  uniqueUsers: MultiStageLineItemsTrackedUser[];
}) => {
  const proceededUsersCount = useMemo(
    () => uniqueUsers.filter((user) => user.proceeded).length,
    [uniqueUsers],
  );
  const notProceededUsersCount = useMemo(
    () => uniqueUsers.filter((user) => !user.proceeded).length,
    [uniqueUsers],
  );

  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      body={
        <Stack maxWidth="900px" gap={0}>
          <Flex justifyContent="space-between" mb="16px">
            <Flex>
              <LabeledValue
                label="Unique users"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniqueUsers.length}
                mr={4}
              />
              <LabeledValue
                label="Yes"
                align="left"
                labelProps={{ color: 'text' }}
                value={
                  <>
                    <Icon color="success" icon="arrow-right" regular mr={1} />
                    {proceededUsersCount}
                  </>
                }
                mr={4}
              />
              <LabeledValue
                label="No"
                align="left"
                labelProps={{ color: 'text' }}
                value={
                  <>
                    <Icon color="danger" icon="times" regular mr={1} />
                    {notProceededUsersCount}
                  </>
                }
                mr={4}
              />
              <LabeledValue
                label="Rate proceeded"
                align="left"
                labelProps={{ color: 'text' }}
                value={renderPercentage({
                  count: proceededUsersCount,
                  total: uniqueUsers.length,
                })}
              />
            </Flex>
          </Flex>
          <FunnelUsersTable uniqueUsers={uniqueUsers} />
        </Stack>
      }
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};

const FunnelEndDetailsModal = ({
  uniqueUsers,
  firstStepUniqueUsersCount,
  ...props
}: Omit<DialogProps, 'body'> & {
  uniqueUsers: MultiStageLineItemsTrackedUser[],
  firstStepUniqueUsersCount: number,
}) => {
  return (
    <Dialog
      shouldCloseOnEsc
      showCloseIcon
      body={
        <Stack maxWidth="900px" gap={0}>
          <Flex justifyContent="space-between" mb="16px">
            <Flex>
              <LabeledValue
                label="Unique users"
                align="left"
                labelProps={{ color: 'text' }}
                value={uniqueUsers.length}
                mr={4}
              />
              <LabeledValue
                label="Unique users (Step 1)"
                align="left"
                labelProps={{ color: 'text' }}
                value={firstStepUniqueUsersCount}
                mr={4}
              />
              <LabeledValue
                label="Funnel conversion rate"
                align="left"
                labelProps={{ color: 'text' }}
                value={renderPercentage({
                  count: uniqueUsers.length,
                  total: firstStepUniqueUsersCount,
                })}
              />
            </Flex>
          </Flex>
          <FunnelUsersTable uniqueUsers={uniqueUsers} isFunnelEnd />
        </Stack>
      }
      okButtonText="Close"
      showCancelButton={false}
      {...props}
    />
  );
};

type FunnelRow = {
  eventType: MultiStageLineItemsEventType;
  uniqueUsers: MultiStageLineItemsTrackedUser[];
  total: number;
  proceeded: number;
  notProceeded: number;
};

const FunnelStepNameCell = ({
  cell,
  row,
  firstStepUniqueUsersCount,
}: CellProps<FunnelRow, MultiStageLineItemsEventType> & { firstStepUniqueUsersCount: number }) => {
  const eventType = cell.value;
  const { uniqueUsers } = row.original;

  const modal = useModalState();

  const ModalComponent =
    eventType !== MultiStageLineItemsEventType.FLOW_SUBMITTED
      ? FunnelStepDetailsModal
      : FunnelEndDetailsModal;

  const [modalHeading, setModalHeading] = useState<string>('');

  const handleLinkClick = useCallback(() => {
    setModalHeading(`Conversion funnel – ${funnelStepLablesByEventType[eventType]}`);
    modal.open();
  }, [eventType, modal]);

  return (
    <>
      <Link onClick={handleLinkClick}>
        <Flex sx={{ fontWeight: 500, columnGap: 1 }}>
          <Text>{funnelStepLablesByEventType[eventType]}</Text>
          <Icon
            icon="chevron-right"
            fontSize={2}
            sx={{ alignSelf: 'center' }}
          />
        </Flex>
      </Link>
      <ModalComponent
        heading={modalHeading}
        uniqueUsers={uniqueUsers}
        firstStepUniqueUsersCount={firstStepUniqueUsersCount}
        onOk={modal.close}
        onCancel={modal.close}
        isOpen={modal.isOpen}
      />
    </>
  );
};

const FunnelProceededCell = ({
  cell,
  row,
  checkIsLastStep,
}: CellProps<FunnelRow, number> & {
  checkIsLastStep: (eventType: MultiStageLineItemsEventType) => boolean;
}) => {
  const proceeded = cell.value;
  const { eventType, total } = row.original;

  return !checkIsLastStep(eventType) ? (
    <Flex sx={{ justifyContent: 'space-between' }}>
      <Box>
        <Icon color="success" icon="arrow-right" regular mr={1} />
        {proceeded}
      </Box>
      <Text fontSize={1} color="subtext">
        {renderPercentage({ count: proceeded, total })}
      </Text>
    </Flex>
  ) : (
    <EmDash />
  );
};

const FunnelNotProceededCell = ({
  cell,
  row,
  checkIsLastStep,
}: CellProps<FunnelRow, number> & {
  checkIsLastStep: (eventType: MultiStageLineItemsEventType) => boolean;
}) => {
  const notProceeded = cell.value;
  const { eventType, total } = row.original;

  return !checkIsLastStep(eventType) ? (
    <Flex sx={{ justifyContent: 'space-between' }}>
      <Box>
        <Icon color="danger" icon="times" regular mr={1} />
        {notProceeded}
      </Box>
      <Text fontSize={1} color="subtext">
        {renderPercentage({ count: notProceeded, total })}
      </Text>
    </Flex>
  ) : (
    <EmDash />
  );
};

const checkIsLastStep = (eventType: MultiStageLineItemsEventType) : boolean => {
  return eventType === MultiStageLineItemsEventType.FLOW_SUBMITTED;
};

const MultiStageLineItemsFunnelTable = ({ funnelData }) => {
  const theme = useTheme();

  const data = useMemo(() => {
    return Object.values(MultiStageLineItemsEventType).map((eventType) => {
      const usersData = funnelData[eventType];
      const { total, proceeded, notProceeded, uniqueUsers } = usersData;
      return {
        eventType,
        uniqueUsers,
        total,
        proceeded,
        notProceeded,
      };
    });
  }, [funnelData]);

  const firstStepUniqueUsersCount = funnelData[MultiStageLineItemsEventType.SUGGESTION_PANEL_SEEN].uniqueUsers.length;

  const columns = useMemo(
    () => [
      {
        Header: 'Funnel step',
        accessor: 'eventType',
        Cell: withProps(FunnelStepNameCell, { firstStepUniqueUsersCount, checkIsLastStep }),
        width: 477,
      },
      {
        Header: 'Total users',
        accessor: 'total',
        Cell: TextCell,
      },
      {
        Header: 'Proceeded',
        accessor: 'proceeded',
        Cell: withProps(FunnelProceededCell, { checkIsLastStep }),
      },
      {
        Header: 'Did not proceed',
        accessor: 'notProceeded',
        Cell: withProps(FunnelNotProceededCell, { checkIsLastStep }),
      },
    ],
    [firstStepUniqueUsersCount],
  );

  return (
    <AdoptionRateTableStyle
      bordered
      headerBackgroundColor={theme.colors.lightGray3}
      headerTextColor={theme.colors.lightNavy}
      hoverCursor="default"
      stickyHeader
      cellPaddingX="10px"
    >
      <Table columns={columns} data={data} />
    </AdoptionRateTableStyle>
  );
};

const NewMultiStageLineItemsFunnel = ({ funnelData }) => {
  const firstStepUniqueUsersCount = funnelData[MultiStageLineItemsEventType.SUGGESTION_PANEL_SEEN].total;
  const lastStepUniqueUsersCount = funnelData[MultiStageLineItemsEventType.FLOW_SUBMITTED].total;

  return (
    <PanelPadding>
      <Flex flexDirection="column" sx={{ gap: 3 }}>
        <ProductDashboardSectionHeader
          heading="Conversion funnel – suggestion panel and flow"
          tooltip="The funnel counts unique users on unique requests."
          icon="bars-filter"
          isIconRegular
          right={
            <Flex sx={{ columnGap: 1, alignItems: 'baseline' }} >
              <Text fontSize={4} fontWeight={500} >
                {renderPercentage({
                  count: lastStepUniqueUsersCount,
                  total: firstStepUniqueUsersCount,
                })}
              </Text>
              <Text color="subtext">funnel conversion rate</Text>
            </Flex>
          }
        />
        <MultiStageLineItemsFunnelTable funnelData={funnelData} />
      </Flex>
    </PanelPadding>
  );
};

export const MultiStageLineItemsFunnel = ({ funnelData }) => {
  const systemFeatureFlags = useSystemFeatureFlags();

  return systemFeatureFlags?.setupMultiStageLineItemsFlowEnabled ? (
    <NewMultiStageLineItemsFunnel funnelData={funnelData} />
  ) : (
    <LegacyMultiStageLineItemsFunnel funnelData={funnelData} />
  );
};
