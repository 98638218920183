import { useQuery } from 'react-query';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelHeader, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Company } from '@deepstream/ui/ui/types';
import { Box, Text } from 'rebass/styled-components';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { CompanyLogo } from '@deepstream/ui/CompanyLogo';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Badge } from '@deepstream/ui/Badge';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useMemo, useState } from 'react';
import { compact } from 'lodash';
import { useAdminNavigation } from '../../AppRouting';
import { AddChildCompanyModal } from './AddChildCompanyModal';
import { RemoveChildCompanyModal } from './RemoveChildCompanyModal';

export const CompanyLogoAndNameCell = ({
  row,
}: CellProps<{ _id: string; name: string; isPending?: boolean }>) => {
  const { t } = useTranslation('translation');
  const company = row.original;

  return (
    <>
      <Truncate>
        <CompanyLogo companyId={company._id} size="xs" />
        <Box
          as="span"
          verticalAlign="middle"
          color={company.isPending ? 'subtext' : 'text'}
        >
          {company.name || `(${t('general.notSet')})`}
        </Box>
      </Truncate>
      {company.isPending && (
        <Badge ml={2} tooltip={t('general.thisCompanyHasntSignedUpYet')}>
          {t('invite.status.invited')}
        </Badge>
      )}
    </>
  );
};

export const ParentChildCompaniesTable = ({
  currentCompanyId,
  companies,
  canRemove,
}: {
  currentCompanyId: string;
  companies: { _id: string; name: string; isPending?: boolean }[];
  canRemove?: boolean;
}) => {
  const navigation = useAdminNavigation();
  const removeChildCompanyModal = useModalState();
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const columns = useMemo(
    () => compact([
      {
        Header: 'Company',
        accessor: 'name',
        Cell: CompanyLogoAndNameCell,
      },
      canRemove && {
        Header: '',
        accessor: '_id',
        Cell: ({ row }) => {
          return (
            <Button
              small
              variant="danger-outline"
              iconLeft="xmark"
              onClick={(event) => {
                stopEvent(event);
                setSelectedCompany(row.original);
                removeChildCompanyModal.open();
              }}
            >
              Remove
            </Button>
          );
        },
        width: '116px',
      },
    ]),
    [removeChildCompanyModal, canRemove],
  );

  return (
    <>
      <BasicTableStyles>
        <Table
          columns={columns}
          data={companies}
          hideHeader
          onRowClick={company => navigation.navigateToCompany(company._id)}
        />
      </BasicTableStyles>
      {selectedCompany && (
        <RemoveChildCompanyModal
          currentCompanyId={currentCompanyId}
          company={selectedCompany}
          isOpen={removeChildCompanyModal.isOpen}
          onCancel={removeChildCompanyModal.close}
          onSave={removeChildCompanyModal.close}
          onRequestClose={removeChildCompanyModal.close}
        />
      )}
    </>
  );
};

export const ChildCompaniesPanel = ({ company }: { company: Company }) => {
  const adminApi = useAdminApi();
  const addChildCompanyModal = useModalState();

  const companyId = company._id;

  const { data: childCompanies, isLoading, isError } = useQuery(
    ['childCompanies', { companyId }],
    wrap(adminApi.getChildCompanies),
  );

  return (
    <>
      <Panel>
        <PanelHeader heading="Child companies">
          <Button small iconLeft="plus" onClick={addChildCompanyModal.open}>
            Add child company
          </Button>
        </PanelHeader>
        <PanelDivider />
        {isLoading ? (
          <PanelPadding>
            <Loading />
          </PanelPadding>
        ) : isError ? (
          <PanelPadding>Oh no</PanelPadding>
        ) : childCompanies?.length ? (
          <ParentChildCompaniesTable
            currentCompanyId={companyId}
            companies={childCompanies}
            canRemove
          />
        ) : (
          <PanelPadding>
            <Text fontSize={2}>
              No child companies
            </Text>
            <Text color="subtext">
              <Icon icon="info-circle" regular mr={1} mt="10px" />
              Add 1 or more child companies to turn this company into a parent.
            </Text>
          </PanelPadding>
        )}
      </Panel>
      <AddChildCompanyModal
        currentCompany={company}
        isOpen={addChildCompanyModal.isOpen}
        onCancel={addChildCompanyModal.close}
        onSave={addChildCompanyModal.close}
        onRequestClose={addChildCompanyModal.close}
      />
    </>
  );
};
