import * as React from 'react';
import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxListProps,
  useComboboxState,
} from 'ariakit/combobox';
import {
  Select,
  SelectItem,
  SelectPopover,
  useSelectState,
} from 'ariakit/select';
import styled from 'styled-components';
import { constant, isEmpty, keyBy, omit } from 'lodash';
import { Box, Text } from 'rebass/styled-components';
import { transparentize } from 'polished';
import { ComboboxState, SelectState } from 'ariakit';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { usePromise } from '@deepstream/ui-kit/hooks/usePromise';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { centeredTextAdjustmentCss } from '@deepstream/ui-kit/elements/text/textAdjustment';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { stopEvent } from '@deepstream/ui-utils/domEvent';

const ITEM_HEIGHT = 57;
const SEARCHBOX_HEIGHT = 60;
const FOOTER_HEIGHT = 110;
const LIST_TITLE_HEIGHT = 34;

type BasePlacement = 'top' | 'bottom' | 'left' | 'right';
export type Placement = BasePlacement | `${BasePlacement}-start` | `${BasePlacement}-end`;

const NO_VALUE = '__no_value__';

export const SelectContainer = styled(Box)`
  width: 100%;
`;

export const SelectButtonIcon = ({ icon, onClick, disabled }: { icon: 'close' | 'caret-down'; onClick: () => void; disabled?: boolean }) => {
  const theme = useTheme();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      stopEvent(event);
      onClick();
    },
    [onClick],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (([KeyCode.ENTER, KeyCode.SPACE] as string[]).includes(event.code)) {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <IconButton
      icon={icon}
      aria-label={icon === 'close' ? 'Remove' : 'Open'}
      color={disabled ? 'subtext' : 'text'}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      sx={{
        backgroundColor: disabled ? theme.colors.lightGray3 : 'inherit',
        // subtracting 2px to avoid covering focus border of select component
        height: 'calc(100% - 2px)',
        width: '24px',
        position: 'absolute',
        left: 'calc(100% - 28px)',
        ':focus:not(:disabled)': {
          backgroundColor: 'none',
          boxShadow: 'none',
          outline: 'none',
        },
      }}
    />
  );
};

export const SelectButtonContainer = styled(Box)`
  position: relative;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes[2]}px;
  background: ${props => props.theme.colors.white};
  border: 0;
`;

export const StyledSelect = styled(Select as any)`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes[2]}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px 0 10px;
  line-height: 1.5;
  height: 100%;
  width: 100%;
  background: none;
  border-radius: 4px;
  border: ${props => props.theme.borders.lightGray};
  box-shadow: none;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    color: ${props => props.theme.colors.subtext};
    background: ${props => props.theme.colors.lightGray3};
  }

  &:focus {
    box-shadow: 0 0 2px 0 rgba(52,152,219,0.6);
    border-color: ${props => props.theme.colors.primary};
    outline: 0;
  }

  ${centeredTextAdjustmentCss}
`;

export const StyledSelectPopover = styled(SelectPopover)`
  background-color: ${props => props.theme.colors.white};
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: fixed;
  font-weight: normal;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes[2]}px;
  width: 100%;

  overflow: hidden;

  z-index: 202;
  color: ${props => props.theme.colors.text};

  &:focus {
    outline: 0;
  }
`;

export const ComboboxWrapper = styled.div`
  width: 100%;
  padding: 10px;
  position: relative;
`;

export const StyledCombobox = styled(Combobox as any)`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes[2]}px;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  border: ${props => props.theme.borders.lightGray};
  box-sizing: border-box;
  height: 40px;
  padding: 0 10px 0 32px;

  line-height: 1.5;
  color: hsl(204, 10%, 10%);

  &:focus {
    box-shadow: 0 0 2px 0 rgba(52,152,219,0.6);
    border-color: ${props => props.theme.colors.primary};
    outline: 0;
  }

  &::placeholder {
    color: ${props => props.theme.colors.subtext};
  }

  ${centeredTextAdjustmentCss}
`;

export const StyledComboboxFooter = styled.div`
  border-top: ${props => props.theme.borders.lightGray};
  padding: 10px 0;
`;

export const StyledComboboxList = styled<React.FC<ComboboxListProps & { $maxItems: number; $hasVerticalScroll: boolean }>>(ComboboxList)`
  max-height: ${props => /* add 2px for borders */ 2 + ITEM_HEIGHT * props.$maxItems}px;
  overflow-y: auto;
  border-top: ${props => props.$hasVerticalScroll ? props.theme.borders.lightGray : '1px solid white'};
`;

export const ComboboxScrollList: React.FC<ComboboxListProps & { maxItems: number }> = ({ maxItems, ...props }) => {
  const [hasVerticalScroll, setHasVerticalScroll] = React.useState(false);

  const handleScroll = React.useCallback(
    (event) => {
      setHasVerticalScroll(event.target.scrollTop > 0);
    },
    [setHasVerticalScroll],
  );

  return (
    // @ts-expect-error FIXME we're passing 'as' but StyledComboboxList is not a rebass component
    <StyledComboboxList
      {...props}
      onScroll={handleScroll}
      $maxItems={maxItems}
      $hasVerticalScroll={hasVerticalScroll}
    />
  );
};

export const StyledComboboxItem = styled(ComboboxItem)`
  height: ${ITEM_HEIGHT}px;

  outline: none;
  display: flex;
  cursor: pointer;

  scroll-margin-top: 0.5px;

  align-items: center;

  padding-left: 10px;
  padding-right: 10px;

  &[data-active-item] {
    background-color: ${props => transparentize(0.9, props.theme.colors.primary)}
  }

  &[aria-disabled="true"] {
    cursor: default;
    opacity: 0.5;
  }
`;

export interface FilterSelectComboboxProps<T> {
  /**
   * The items that are available for selection, prior to any filtering.
   */
  items?: T[];
   /**
   * The item that is selected initially.
   */
  initialItem?: T;
  /**
   * If true then the value/setter will be controlled from the outside the component
   * through selected and onChange props.
   */
  controlled?: boolean;
   /**
   * The item that is selected.
   * If not provided then the state will be held internally by the component.
   */
  selected?: T;
  /**
   * Called when the selected item has changed.
   */
  onChange?: (item?: T) => void;
  /**
   * Called when the popover closes.
   */
  onTouched?: () => void;
  /**
   * Called when the popover opens.
   */
  onFocus?: () => void;
  /**
   * The placeholder text to show when no item has been selected.
   */
  selectedPlaceholderText?: string;
  /**
   * The component to render inside the select button, always rendered regardless of the selected items state.
   */
  StaticSelectButtonContent?: () => JSX.Element | null;
  /**
  /**
   * The placeholder text to show when no text has been entered into the
   * filter textbox.
   */
  filterPlaceholder: string;
  /**
   * The message to show when the array of filtered items is empty.
   */
  emptyFilteredItemsMessage: string;
  /**
   * Determines which of the `items` should be displayed, depending
   * on the `value` of the filter textbox.
   */
  filterItems: (value: string, items: T[]) => T[] | Promise<T[]>;
  /**
   * Accessor to get the ID of the given item.
   */
  getId: (item: T) => string;
  /**
   * Callback to determine whether an item is disabled.
   */
  isDisabled?: (item: T) => boolean;
  /**
   * Disabled status for the entire control.
   */
  disabled?: boolean;
  /**
   * The component to render the items in the filtered list and (when no `SelectedItem`
   * prop has been passed) the selected item.
   * When rendering the selected item, `disabled` is always `false`.
   */
  Item: ({ item, disabled }: { item: T; disabled: boolean }) => JSX.Element | null;
  /**
   * The component to render the selected item.
   * When rendering the selected item, `disabled` is always `false`.
   * If not provided then Item will be used instead.
   */
  SelectedItem?: ({ item }: { item: T; disabled: boolean }) => JSX.Element | null;
  /**
   * Rendered at the bottom of the list of items.
   * Can be used to render `Create new` actions.
   */
  renderMenuFooter?: (comboboxState: ComboboxState, selectState: SelectState) => JSX.Element | null;
  /**
   * Wrapper for the list of items.
   * Can be used to add a header to the list.
   */
  ListWrapper?: ({ children }) => JSX.Element | null;
  /**
   * The ID of the label element that contains the description of this component.
   */
  labelId: string;
  /**
   * Styling for the container of the button that contains the selected item.
   */
  selectContainerStyle?: React.CSSProperties;
  /**
   * Styling for the container of the button that contains the selected item.
   */
  selectButtonStyle?: React.CSSProperties;
  /**
   * Styling for the list component containing the filtered items.
   */
  listStyle?: React.CSSProperties;
  /**
   * Styling for the component wrapping each filtered item.
   */
  itemWrapperStyle?: React.CSSProperties;
  /**
   * The width of the popover. If not set, the popover will have the width of
   * the select element.
   */
  popoverWidth?: number;
  /**
   * The placement of the popover.
   */
  popoverPlacement?: Placement;
  /**
   * The maximum number of filtered items to display in the popover without
   * scrolling.
   */
  maxItems?: number;
}

export const EMPTY_ARRAY = [];
export const EMPTY_LIST_WRAPPER = ({ children }) => <>{children}</>;

/**
 * Combobox select component with a filter text field.
 */
export const FilterSelectCombobox = <T,>({
  items = EMPTY_ARRAY,
  initialItem,
  controlled,
  selected,
  disabled,
  onChange,
  onTouched,
  onFocus,
  selectedPlaceholderText,
  StaticSelectButtonContent,
  filterPlaceholder,
  emptyFilteredItemsMessage,
  filterItems,
  getId,
  isDisabled = constant(false),
  Item,
  SelectedItem = Item,
  ListWrapper = EMPTY_LIST_WRAPPER,
  renderMenuFooter,
  labelId,
  selectContainerStyle,
  selectButtonStyle,
  listStyle,
  itemWrapperStyle,
  popoverWidth,
  popoverPlacement,
  maxItems = 5,
}: FilterSelectComboboxProps<T>) => {
  if (!selectedPlaceholderText && !StaticSelectButtonContent) {
    throw new Error('`StaticSelectButtonContent` is required if no `selectedPlaceholderText` props included');
  }

  const selectContainerRef = React.useRef<HTMLDivElement>(null);
  const [ids, setIds] = React.useState<string[]>([]);
  const [itemById, setItemById] = React.useState<Record<string, T>>({});

  const comboboxState = useComboboxState({
    list: ids,
    gutter: 4,
    sameWidth: !popoverWidth,
    placement: popoverPlacement,
  });

  const selectState = useSelectState({
    ...omit(comboboxState, ['value', 'setValue']),
    defaultValue: initialItem ? getId(initialItem) : NO_VALUE,
    ...(controlled ? { value: selected ? getId(selected) : NO_VALUE, setValue: (value: string) => onChange?.(itemById[value]) } : {}),
  });

  // reset `comboboxState` value when popover is collapsed
  if (!selectState.mounted && comboboxState.value) {
    comboboxState.setValue('');
  }

  // call `onTouched()` when the popover is collapsed
  useWatchValue(
    selectState.mounted,
    React.useCallback((mounted) => {
      if (!mounted && onTouched) {
        onTouched();
      } else if (mounted && onFocus) {
        onFocus();
      }
    }, [onTouched, onFocus]),
  );

  // call `onChange()` when the selected value changes
  useWatchValue(
    selectState.value,
    React.useCallback((value) => {
      if (!controlled && onChange) {
        onChange(itemById[value]);
      }
    }, [controlled, itemById, onChange]),
  );

  const { data: filteredItems } = usePromise(React.useMemo(
    () => filterItems?.(comboboxState.value, items),
    [comboboxState.value, filterItems, items],
  ), []);

  React.useEffect(() => {
    if (filteredItems) {
      setIds(filteredItems.map(getId));
      setItemById(keyBy(filteredItems, getId));
    }
  }, [filteredItems, getId]);

  const menuFooter = React.useMemo(() => renderMenuFooter?.(comboboxState, selectState), [renderMenuFooter, comboboxState, selectState]);

  const selectedItem = selected || itemById[selectState.value];
  const availableSpaceAbove = (selectContainerRef.current?.getBoundingClientRect().top ?? 0);
  const availableSpaceBelow = window.innerHeight - (selectContainerRef.current?.getBoundingClientRect().bottom ?? 0);
  const maxHeight = Math.max(availableSpaceAbove, availableSpaceBelow);
  const visibleItems = (maxHeight - (SEARCHBOX_HEIGHT + LIST_TITLE_HEIGHT + FOOTER_HEIGHT + maxItems * ITEM_HEIGHT)) > 0 ? maxItems : 2;

  return (
    <SelectContainer>
      <SelectButtonContainer
        ref={selectContainerRef}
        style={selectContainerStyle}
      >
        <StyledSelect
          state={selectState}
          aria-labelledby={labelId}
          disabled={disabled}
          showOnKeyDown
          toggleOnClick
          style={selectButtonStyle}
        >
          {StaticSelectButtonContent ? (
            <StaticSelectButtonContent />
          ) : selectedItem ? (
            <SelectedItem item={selectedItem} disabled={false} />
          ) : (
            <Text color="subtext">{selectedPlaceholderText}</Text>
          )}
        </StyledSelect>
        {!StaticSelectButtonContent && (
          <SelectButtonIcon
            icon={selectedItem ? 'close' : 'caret-down'}
            onClick={() => {
              if (selectedItem) {
                selectState.setValue(NO_VALUE);
              } else {
                selectState.show();
              }
            }}
            disabled={disabled}
          />
        )}
      </SelectButtonContainer>

      <StyledSelectPopover
        state={selectState}
        composite={false}
        portal
        style={popoverWidth ? { width: popoverWidth } : undefined}
      >
        <ComboboxWrapper>
          <Icon
            icon="search"
            color="subtext"
            aria-hidden="true"
            sx={{
              position: 'absolute',
              top: '23px',
              left: '20px',
            }}
          />
          <StyledCombobox
            state={comboboxState}
            autoSelect
            placeholder={filterPlaceholder}
            onBlur={event => {
              // We call `selectState.hide()` to close the popover when the
              // user moves the focus away by pressing TAB. Without this call,
              // the popover would only get closed on click outside.
              // The conditional around `selectState.hide()` prevents closing
              // of the popover when the user clicks on the scrollbars of the
              // combobox list.
              if (event.relatedTarget !== selectState.contentElement && !selectState.contentElement?.contains(event.relatedTarget)) {
                selectState.hide();
              }
            }}
          />
        </ComboboxWrapper>
        {isEmpty(filteredItems) && !comboboxState.value ? (
          null
        ) : (
          <>
            <ListWrapper>
              <ComboboxScrollList state={comboboxState} style={listStyle} maxItems={visibleItems}>
                {isEmpty(filteredItems) ? (
                  <Text color="subtext" pt="18px" pb="24px" px="10px">
                    {emptyFilteredItemsMessage}
                  </Text>
                ) : (
                  filteredItems?.map((item, i) => {
                    const id = getId(item);
                    const disabled = isDisabled(item);

                    return (
                      <StyledComboboxItem
                        key={id + i}
                        focusOnHover
                        disabled={disabled}
                        style={itemWrapperStyle}
                      >
                        {(props: any) => (
                          <SelectItem {...props} value={id}>
                            <Item item={item} disabled={disabled} />
                          </SelectItem>
                        )}
                      </StyledComboboxItem>
                    );
                  })
                )}
              </ComboboxScrollList>
            </ListWrapper>
            {menuFooter ? (
              <StyledComboboxFooter>
                {menuFooter}
              </StyledComboboxFooter>
            ) : null}
          </>
        )}
      </StyledSelectPopover>
    </SelectContainer>
  );
};
