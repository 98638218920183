import { useCallback, useMemo } from 'react';
import { intersection, pick } from 'lodash';
import { useLocalStorageState } from './useLocalStorageState';
import { Company } from './ui/types';

export type CompanySelectItem = { _id: string; name: string };

export const useSelectParentChildCompanies = ({
  storageKey,
  parentCompany,
  childCompanies = [],
}: {
  storageKey: string;
  parentCompany: Company;
  childCompanies?: { _id: string; name: string; isPending?: boolean }[];
}) => {
  const {
    items,
    itemIds,
  } = useMemo(() => {
    const items: CompanySelectItem[] = [
      pick(parentCompany, ['_id', 'name']),
      ...childCompanies,
    ];

    return {
      items,
      itemIds: items.map(item => item._id),
    };
  }, [parentCompany, childCompanies]);

  const [selectedItemIds, setSelectedItemIds] = useLocalStorageState<string[]>({
    key: storageKey,
    defaultValue: itemIds,
    mapInitialValue: (initialItemIds) => {
      return initialItemIds
        ? intersection(itemIds, initialItemIds)
        : [];
    },
  });

  const selectedItems = useMemo(() => {
    return items.filter(item => selectedItemIds.includes(item._id));
  }, [items, selectedItemIds]);

  const setSelectedItems = useCallback((selectedItems: CompanySelectItem[]) => {
    const selectedItemIds = intersection(itemIds, selectedItems.map(item => item._id));

    setSelectedItemIds(selectedItemIds);
  }, [setSelectedItemIds, itemIds]);

  return {
    itemToString: (item: CompanySelectItem | null) => item ? item._id : '',
    renderItem: (item: CompanySelectItem | null) => item ? item.name : '',
    items: items ?? [],
    selectedItems,
    onChange: setSelectedItems,
  };
};
